import { createSlice } from '@reduxjs/toolkit';
import authenticateUser from './signinThunks';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  accountType: '',
  loggedIn: false,
  status: 'idle',
  IsMarketing: false,
  SessionMsg: false,
};

const signinSlice = createSlice({
  name: 'signin',
  initialState,
  reducers: {
    resetUser(state) {
      state.firstName = '';
      state.lastName = '';
      state.loggedIn = false;
      state.status = 'idle';
      state.accountType = '';
    },
    setLoggedIn(state) {
      state.loggedIn = true;
    },
    setSessionMsg(state) {
      state.SessionMsg = true;
    },
    resetSessionMsg(state) {
      state.SessionMsg = false;
    },

    setLoggedOut(state) {
      state.loggedIn = false;
    },

    setUser(state, action) {
      state.firstName = action.payload.firstname;
      state.lastName = action.payload.lastname;
      state.email = action.payload.email;
      state.status = 'fullfilled';
      state.loggedIn = true;
      state.accountType = action.payload.fullAccessAllowed;
      state.IsMarketing = action.payload.IsMarketing;
    },
  },
  extraReducers: {
    [authenticateUser.fulfilled]: (state, action) => {
      state.firstName = action.payload.firstname;
      state.lastName = action.payload.lastname;
      state.email = action.payload.email;
      state.status = 'fullfilled';
      state.loggedIn = true;
      state.accountType = action.payload.fullAccessAllowed;
      state.IsMarketing = action.payload.IsMarketing;
    },
    [authenticateUser.rejected]: (state, action) => {
      state.status = action.error.message;
      state.loggedIn = false;
    },
    [authenticateUser.pending]: (state) => {
      state.status = 'pending';
    },
  },
});

export const {
  resetUser,
  setLoggedIn,
  setLoggedOut,
  setUser,
  setSessionMsg,
  resetSessionMsg,
} = signinSlice.actions;
export default signinSlice.reducer;
