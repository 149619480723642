import React from 'react';

function Icon({
  spinnerColor = '#707070',
  spinnerSize = { width: '2.2em', height: '2.4em' },
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginRight: !(spinnerColor === '#707070') ? '-10px' : '0px',
        padding: '0px',
        background: 'none',
        DarkreaderInlineBgimage: 'initial',
        DarkreaderInlineBgcolor: '#38393a',
        width: spinnerSize.width,
        height: spinnerSize.height,
      }}
      width="100"
      height="100"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
    >
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
      >
        <animate
          attributeName="opacity"
          begin="-0.9166666666666666s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(30 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.8333333333333334s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(60 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.75s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(90 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.6666666666666666s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(120 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.5833333333333334s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(150 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.5s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(180 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.4166666666666667s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(210 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.3333333333333333s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(240 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.25s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(270 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.16666666666666666s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(300 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.08333333333333333s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <rect
        width="6"
        height="12"
        x="47"
        y="24"
        fill={spinnerColor}
        rx="3"
        ry="6"
        style={{ DarkreaderInlineFill: '#9d978e' }}
        transform="rotate(330 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="0s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
    </svg>
  );
}

export default Icon;
