import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { createPaginationState } from '../../utils/paginationUtils';

const updatePagination = createAction('admin/blockbriefUsers/updatePagination');

const fetchUsers = async (payload, thunkAPI) => {
  const requestURL = `${process.env.REACT_APP_API_HOSTNAME}/admin/get-users`;
  const { pagination, searchQuery } = thunkAPI.getState().blockbriefUsers;
  const result = await axios({
    method: 'POST',
    url: requestURL,
    data: {
      page:
        pagination.currentActive - 1
          ? (pagination.currentActive - 1) * 10
          : pagination.currentActive - 1,
      query: searchQuery,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
  if (result.status === 200) {
    const paginationCount =
      thunkAPI.getState().blockbriefUsers.pagination.totalRecords;
    if (
      !pagination.paginationFetched ||
      paginationCount !== result.data.count
    ) {
      thunkAPI.dispatch(
        updatePagination(
          createPaginationState({ totalValues: result.data.count })
        )
      );
    }
    return result.data;
  }
  throw new Error('Some Error Occured');
};

const fetchUsersAction = createAsyncThunk('admin/get-users', fetchUsers);

export { updatePagination };
export default fetchUsersAction;
