/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Link, useLocation } from 'react-router-dom';
import './SigninForm.css';
import BlockbriefLogo from '../../assets/images/logo.png';
import EmailIcon from '../../assets/images/email.svg';
import PasswordIcon from '../../assets/images/padlock.svg';
import authenticateUser from '../../reducers/signinSlice/signinThunks';
import LoadingIndicator from '../../assets/svg/Loading-indicator';
import history from '../../history';

const submitButtonResponse = (requestStatus, urlLocation) => {
  let buttonJSX;
  if (requestStatus.status === 'pending') {
    buttonJSX = (
      <>
        <LoadingIndicator />
      </>
    );
  } else if (requestStatus.status === 'fullfilled') {
    buttonJSX =
      urlLocation && urlLocation.search ? (
        <Redirect
          to={{
            pathname: '/zoning-search/map-search',
            state: urlLocation.selectedSuggestion,
          }}
        />
      ) : (
        <Redirect
          to={{
            pathname: '/zoning-search',
          }}
        />
      );
  } else {
    buttonJSX = <h6>Sign in</h6>;
  }
  return buttonJSX;
};

export default function SigninForm() {
  const { state: urlState } = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [authMsg, setAuthMsg] = useState();
  const sessionMsg = useSelector((state) => state.user.SessionMsg);
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => ({
    loggedIn: state.user.loggedIn,
    status: state.user.status,
  }));
  const submitSigninForm = async (e) => {
    const result = await dispatch(
      authenticateUser({
        username,
        password,
      })
    );
    if (result.type !== 'signin/authenticateUser/fulfilled') setIsVisible(true);
    e.preventDefault();
  };

  history.listen((location1) => {
    if (location1?.state) setAuthMsg(location1.state[0]);
  });

  return (
    <div className="signin-container__signin-form signin-form">
      <Link className="signin-form__logo" to={{ pathname: '/' }}>
        <img src={BlockbriefLogo} alt="Blockbrief Logo" />
      </Link>
      <p className="signin-form__info">
        We&apos;re glad to have you on Blockbrief
      </p>
      <div className="hr" />
      <h4 className="signin-form__heading">Login to your account</h4>
      <div className="signin-form__input-box input-box">
        <div className="input-box__icon">
          <img src={EmailIcon} alt="Mail Icon" />
        </div>
        <input
          type="email"
          name="user-email"
          id="user-email"
          className="input-box__input"
          placeholder="Enter your Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="signin-form__input-box input-box">
        <div className="input-box__icon">
          <img
            src={PasswordIcon}
            alt="Password Icon"
            className="password-icon"
            placeholder="Enter your Password"
          />
        </div>
        <input
          type="password"
          name="user-password"
          id="user-password"
          className="input-box__input"
          placeholder="Enter your Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <p className="signin-form__register-text">
        Don&apos;t have an account?
        <a
          href="https://corestore.corelogic.com.au/product/blockbrief"
          target="_blank"
          rel="noreferrer"
          className="register-text__link"
        >
          Register Now!
        </a>
      </p>

      {isVisible || authMsg || sessionMsg ? (
        <div className="error-message">
          <span className="error-message-text">
            {isVisible
              ? 'Incorrect email and password'
              : sessionMsg
              ? 'Your session has expired'
              : authMsg}
          </span>
        </div>
      ) : null}

      <button
        type="button"
        className={
          loggedIn.status === 'pending'
            ? 'signin-form__submit-btn signin-from__submit-btn--loading'
            : 'signin-form__submit-btn'
        }
        onClick={submitSigninForm}
      >
        {submitButtonResponse(loggedIn, urlState)}
      </button>
    </div>
  );
}
