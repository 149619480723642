import React from 'react';
import FilterWhite from '../../assets/images/filter-white.png';
import FilterGrey from '../../assets/images/filter-grey.png';
import './MapMenuFilterBtn.css';
import Spinner from '../../assets/svg/Spinner';

const genrerateFilterIcon = (filterStatus, loadingState) => {
  if (loadingState) {
    return (
      <Spinner
        spinnerColor="#fff"
        spinnerSize={{ width: '1.8em', height: '1.8em' }}
      />
    );
  }
  if (filterStatus) {
    return <img src={FilterWhite} alt="Filter Icon" />;
  }
  return <img src={FilterGrey} alt="Filter Icon" />;
};

export default function MapMenuFilterBtn({
  children,
  filterName,
  filterStatus,
  loadingState,
  filterButtonHandler,
  toggletrayButton,
}) {
  return (
    <div
      className={
        filterStatus
          ? 'map-menu__filter-btn map-menu__filter-btn--active filter-btn'
          : 'map-menu__filter-btn filter-btn'
      }
    >
      <button
        type="button"
        className="filter-btn__text"
        onClick={filterButtonHandler}
      >
        {filterName}
      </button>
      <div className="filter-btn__divider" />
      <button
        type="button"
        className="filter-btn__img-container"
        onClick={toggletrayButton}
      >
        {genrerateFilterIcon(filterStatus, loadingState)}
      </button>
      {children}
    </div>
  );
}
