import React, { useState, useEffect } from 'react';
import useGooglePlaces from '../../utils/useGooglePlaces';
import './SearchForm.css';
import useSuggestions from '../../utils/useSuggestions';
import SearchSuggestion from '../SearchSuggestion';

export default function SearchForm({ submitHandler }) {
  const [inputStr, setInputStr] = useState('');
  const [suggestionsArr] = useGooglePlaces(inputStr);
  const [{ toggleSuggestion, selectedSuggestion, suggestions }, dispatch] =
    useSuggestions({
      selectedSuggestion: null,
      toggleSuggestion: false,
      suggestions: [],
    });

  useEffect(() => {
    dispatch({
      type: 'updateSuggestion',
      payload: [...suggestionsArr],
    });
  }, [suggestionsArr, dispatch, selectedSuggestion]);

  useEffect(() => {
    dispatch({
      type: 'updateSuggestion',
      payload: [...suggestionsArr],
    });
  }, []);

  return (
    <div className="dashboard-search__search-form search-form">
      <input
        type="text"
        name="search-input"
        placeholder="Search by Suburb"
        value={inputStr}
        className={
          toggleSuggestion && suggestions.length
            ? 'search-form__input search-form__input--tray-active'
            : 'search-form__input'
        }
        autoComplete="off"
        onFocus={() => dispatch({ type: 'toggleSuggestion' })}
        onBlur={() => dispatch({ type: 'toggleSuggestion' })}
        onChange={(e) => {
          setInputStr(e.target.value);
          if (e.target.value === '')
            dispatch({ type: 'selectSuggestion', payload: null });
        }}
      />
      <SearchSuggestion
        toggleSuggestion={toggleSuggestion}
        dispatch={(suggestion) => () => {
          dispatch({ type: 'selectSuggestion', payload: suggestion });
          setInputStr(suggestion.description);
          setTimeout(() => submitHandler(suggestion)(), 700);
        }}
        handleSuggestionSelection={setInputStr}
        suggestions={suggestions}
      />
      <button
        type="button"
        className={
          inputStr && selectedSuggestion
            ? 'search-from__submit-btn submit-btn--active'
            : 'search-from__submit-btn'
        }
        onClick={submitHandler(selectedSuggestion)}
      >
        Search
      </button>
    </div>
  );
}
