import React from 'react';

function LoadingIndicator({ color = '#fff', width = 102, height = 102 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 95 95"
      width={width}
      height={height}
      xmlSpace="preserve"
      id="loading-svg-indicator"
    >
      <circle cx="6" cy="50" r="20" fill={color}>
        <animate
          attributeName="opacity"
          begin="0.1"
          dur="1s"
          repeatCount="indefinite"
          values="0;1;0"
        />
      </circle>
      <circle cx="56" cy="50" r="20" fill="#fff">
        <animate
          attributeName="opacity"
          begin="0.2"
          dur="1s"
          repeatCount="indefinite"
          values="0;1;0"
        />
      </circle>
      <circle cx="100" cy="50" r="20" fill="#fff">
        <animate
          attributeName="opacity"
          begin="0.3"
          dur="1s"
          repeatCount="indefinite"
          values="0;1;0"
        />
      </circle>
    </svg>
  );
}

export default LoadingIndicator;
