import React, { useRef, useEffect, useState } from 'react';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import './Marketing.css';
import { MapContainer, GeoJSON } from 'react-leaflet';
// import PublicHeader from '../../components/PublicHeader';
import PublicFooter from '../../components/PublicFooter';
import CalendarIcon from '../../assets/images/calendar.svg';
import ClockIcon from '../../assets/images/clock.svg';
import WebinarUpcoming from '../../assets/images/webinar-upcoming.jpg';
import LeftArrow from '../../assets/images/arrow--right-white.svg';
import RightIconBlue from '../../assets/images/arrow-right-blue.svg';
import data from '../../assets/GeoJsonData/bb_data_coverage.json';
// import MapLegend from '../../components/MapLegend';
// import MapPermitTray from '../../components/MapPermitTray';
import ZoneFeatures from '../../components/MarketingFeatureTray';
import LoadingIndicator from '../../assets/svg/Loading-indicator';
import axios from '../../Apis/axios';

export default function Marketing() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const emailRef = useRef(null);
  const coverageRef = useRef(null);
  const position = [-29.0317893, 135.219989];
  const [product, setProduct] = useState([]);

  const [isVisible, setIsVisible] = useState(false);
  const [webinarStatus, toggleWebinarStatus] = useState(false);
  const [Card, setCard] = useState();
  const baseMap = useRef();

  const submitWebinar = async () => {
    if (title && description) {
      toggleWebinarStatus(!webinarStatus);
      const response = await axios.post(
        `/sendEmail`,
        {
          title,
          description,
        },

        { isAuth: true }
      );

      if (response.status === 200) {
        setTitle('');
        setDescription('');
        toggleWebinarStatus(false);
        coverageRef.current.value = '';
        emailRef.current.value = '';
      }
    }
  };

  useEffect(async () => {
    const response = await axios.get(`/marketingCardLink`);
    if (response.status === 200) {
      setCard(response.data.data[0]);
    }
    if (baseMap.current) {
      baseMap.current.getContainer();
    }
  }, [baseMap.current]);

  const getAreaColor = (feature) =>
    feature === 'Area One' ? '#000000' : '#ff9800';

  const areaStyle = () => {
    return {
      fillColor: getAreaColor('Area One'),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.5,
    };
  };

  return (
    <div className="marketing1">
      <div className="marketing">
        <div className="marketings__marketing-heading marketing-heading">
          <h1 className="marketing-heading__heading">Blockbrief marketings</h1>
          <h3 className="marketing-heading__sub-heading">
            <span className="marketing-heading__highlight">
              Information and Material to Assist CoreLogic Team Members
            </span>
          </h3>
          <button type="button" className="marketing-heading__more-btn">
            See Upcoming marketings <img src={LeftArrow} alt="Left Arrow" />
          </button>
        </div>
      </div>

      <div className="webinars__upcoming-marketing marketing-item-container">
        <div className="marketings-container__left">
          <h3 className="marketings-container__heading">Upcoming Webinars</h3>
          <div className="marketings-container__webinar-item marketings-item">
            <h3 className="marketings-item__heading">
              Blockbrief Weekly Webinar
            </h3>
            <p className="marketings-item__webinar-details">
              Our upcoming marketing will show you how to turn Blockbrief Data
              into business and investment outcomes.
            </p>
            <div className="marketings-item__date-time-details">
              <div className="date">
                <img
                  src={CalendarIcon}
                  alt="Calendar Icon"
                  className="date-icon"
                />
                25-12-2021
              </div>
              <div className="time">
                <img src={ClockIcon} alt="Clock Icon" />
                12:00 PM AET
              </div>
            </div>
            <a
              href="https://calendly.com/blockbrief/15min"
              target="_blank"
              rel="noreferrer"
              className="marketings-item__register-btn"
            >
              Register Now
            </a>
          </div>
        </div>
        <div className="marketing-container__right marketings-image">
          <img src={WebinarUpcoming} alt="Upcoming Webinar" />
        </div>
      </div>

      {Card?.map((item) => (
        <div className="marketing-container__on-demand-marketing marketing-item-container">
          <div className="marketings-container__left">
            <h3 className="ondemand-marketing__header marketings-container__heading">
              {item.type.replace(/_/g, ' ')}
            </h3>
            <p className="ondemand-marketing__content">
              We&apos;ve prepared detailed product descriptions and
              specifications for you.
            </p>
          </div>
          <div className="marketing-container__right">
            {item.links.length ? (
              item.links.map((res) => (
                <div className="on-demand-item">
                  <h3 className="on-demand__heading">{res.title}</h3>
                  <p className="on-demand__description">{res.description}</p>
                  <a target="_blank" href={res.link} rel="noreferrer">
                    See Details <img src={RightIconBlue} alt="Details" />
                  </a>
                </div>
              ))
            ) : (
              <div className="on-demand-item">
                <h3 className="on-demand__heading">Coming soon...</h3>
              </div>
            )}
          </div>
        </div>
      ))}

      <div className="marketings-container__coverage-form coverage-form-marketing">
        <div className="marketings-container__left">
          <h3 className="ondemand-marketing__header marketings-container__heading">
            submit your question
          </h3>
        </div>
        <div className="marketing-container__right">
          <div className="coverage-form-item">
            <h3 className="email">Title</h3>
            <input
              type="text"
              name="user-email"
              id="user-email"
              ref={emailRef}
              placeholder="Enter your Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="coverage-form-item">
            <h3 className="address">Description</h3>
            <textarea
              name="coverage-address"
              id="coverage-address"
              placeholder="Enter your message here ..."
              cols="30"
              ref={coverageRef}
              rows="10"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="marketings-coverage__submit"
            onClick={() => submitWebinar()}
          >
            {webinarStatus ? <LoadingIndicator /> : 'Submit'}
          </button>
        </div>
      </div>

      <div className="marketings-hello map-container">
        <MapContainer
          zoom={4}
          style={{ height: '500px', width: '100vw' }}
          center={position}
        >
          <GeoJSON
            data={data}
            style={areaStyle}
            eventHandlers={{
              click: (e) => {
                setProduct(e.layer.feature.properties.products);
                setIsVisible(true);
              },
            }}
          />
          <ReactLeafletGoogleLayer
            useGoogMapsLoader={false}
            crossOrigin
            ref={baseMap}
            type="roadmap"
            continuousWorld
          />

          {/* <MapLegend />
          <MapPermitTray /> */}
        </MapContainer>
        {isVisible ? (
          <ZoneFeatures product={product} handleDia={setIsVisible} />
        ) : null}
      </div>
      <div style={{ width: '100vw', height: '70vh' }}>
        <PublicFooter />
      </div>
    </div>
  );
}
