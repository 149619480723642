export function OverlayParcel(layerName) {
  if (layerName === 'nsw_mls') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'nsw',
    };
    return parcelObj;
  }

  if (layerName === 'tas_oc') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'tas',
    };
    return parcelObj;
  }
  if (layerName === 'vic_bf') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'vic',
    };
    return parcelObj;
  }
  if (layerName === 'vic_oc') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'vic',
    };
    return parcelObj;
  }
  if (layerName === 'wa_mls') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'wa',
    };
    return parcelObj;
  }
  if (layerName === 'wa_bh') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'wa',
    };
    return parcelObj;
  }
  if (
    layerName === 'wa_wanneroo' ||
    layerName === 'wa_stirling' ||
    layerName === 'wa_gosnells' ||
    layerName === 'wa_melville'
  ) {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'wa',
    };
    return parcelObj;
  }
  const parcelObj = {
    isParcel: false,
    parcelTabel: '',
  };
  return parcelObj;
}

export function ZoningMapParcel(layerName) {
  if (layerName === 'nsw') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'nsw',
    };
    return parcelObj;
  }
  if (layerName === 'qld') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'qld',
    };
    return parcelObj;
  }
  if (layerName === 'tas') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'tas',
    };
    return parcelObj;
  }
  if (layerName === 'vic') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'vic',
    };
    return parcelObj;
  }
  if (layerName === 'wa') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'wa',
    };
    return parcelObj;
  }
  if (layerName === 'qld_brisbane') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'qld',
    };
    return parcelObj;
  }
  if (layerName === 'qld_cairns') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'qld',
    };
    return parcelObj;
  }
  if (layerName === 'qld_gold_coast') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'qld',
    };
    return parcelObj;
  }
  if (layerName === 'qld_sunshine_coast') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'qld',
    };
    return parcelObj;
  }
  if (layerName === 'qld_townsvilee') {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'qld',
    };
    return parcelObj;
  }
  if (
    layerName === 'wa_wanneroo' ||
    layerName === 'wa_stirling' ||
    layerName === 'wa_gosnells' ||
    layerName === 'wa_melville'
  ) {
    const parcelObj = {
      isParcel: true,
      parcelTabel: 'wa',
    };
    return parcelObj;
  }
  const parcelObj = {
    isParcel: false,
    parcelTabel: '',
  };
  return parcelObj;
}
