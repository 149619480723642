import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BBLogo from '../../assets/images/logo.png';
import './AdminApplication.css';
import AdminApplicationModal from '../../components/AdminApplicationModal';
import HeaderAdminSection from '../../components/HeaderAdminSection';

export default function AdminApplication({ children }) {
  const [extendedMenuTray, toggleExtendedMenuTray] = useState(false);
  return (
    <div className="admin-application-container">
      <AdminApplicationModal />
      <div className="admin-application-container__header admin-application-header">
        <Link className="admin-application-header__logo" to={{ pathname: '/' }}>
          <img src={BBLogo} alt="Search Icon" />
        </Link>
        <HeaderAdminSection
          extenedMenuTray={extendedMenuTray}
          toggleExtendedMenuTray={toggleExtendedMenuTray}
        />
      </div>
      {children}
    </div>
  );
}
