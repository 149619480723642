import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import './AdminSigninForm.css';
import BlockbriefLogo from '../../assets/images/logo.png';
import EmailIcon from '../../assets/images/email.svg';
import PasswordIcon from '../../assets/images/padlock.svg';
import LoadingIndicator from '../../assets/svg/Loading-indicator';
import authenticateAdmin from '../../reducers/AdminSigninSlice/adminSigninThunk';

const submitButtonResponse = (requestStatus) => {
  let buttonJSX;
  if (requestStatus.status === 'pending') {
    buttonJSX = (
      <>
        <LoadingIndicator />
      </>
    );
  } else if (requestStatus.status === 'fullfilled') {
    buttonJSX = (
      <Redirect
        to={{
          pathname: '/corelogic/dashboard',
        }}
      />
    );
  } else {
    buttonJSX = <h6>Signin</h6>;
  }
  return buttonJSX;
};

export default function AdminSigninForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => ({
    loggedIn: state.admin.loggedIn,
    status: state.admin.status,
  }));
  const submitSigninForm = (e) => {
    dispatch(authenticateAdmin({ username, password }));
    e.preventDefault();
  };

  return (
    <div className="signin-container__signin-form signin-form">
      <Link className="signin-form__logo" to={{ pathname: '/' }}>
        <img src={BlockbriefLogo} alt="Blockbrief Logo" />
      </Link>
      <p className="signin-form__info">
        We&apos;re glad to have you on Blockbrief
      </p>
      <div className="hr" />
      <h4 className="signin-form__heading">Login to Blockbrief Admin Portal</h4>
      <div className="signin-form__input-box input-box">
        <div className="input-box__icon">
          <img src={EmailIcon} alt="Mail Icon" />
        </div>
        <input
          type="email"
          name="user-email"
          id="user-email"
          className="input-box__input"
          placeholder="Enter your username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="signin-form__input-box input-box">
        <div className="input-box__icon">
          <img
            src={PasswordIcon}
            alt="Password Icon"
            className="password-icon"
            placeholder="Enter your Password"
          />
        </div>
        <input
          type="password"
          name="user-password"
          id="user-password"
          className="input-box__input"
          placeholder="Enter your Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {/* <p className="signin-form__register-text">
        Don&apos;t have an account?
        <a
          href="https://corestore.corelogic.com.au/product/blockbrief"
          target="_blank"
          rel="noreferrer"
          className="register-text__link"
        >
          Register Now!
        </a>
      </p> */}
      <button
        type="button"
        className={
          loggedIn.status === 'pending'
            ? 'signin-form__submit-btn signin-from__submit-btn--loading'
            : 'signin-form__submit-btn'
        }
        onClick={submitSigninForm}
      >
        {submitButtonResponse(loggedIn)}
      </button>
    </div>
  );
}
