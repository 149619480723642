/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-lone-blocks */
import React from 'react';
import './MarketingFeatureTray.css';
import CancelSVG from '../../assets/images/cancel.png';

const generateHeading = () => {
  return { heading: 'Product Details' };
};

export default function ZoneFeatures({ product, handleDia }) {
  // eslint-disable-next-line consistent-return
  function swith(data) {
    // eslint-disable-next-line no-nested-ternary
    return data.zone ? (
      <>
        <div>Zoning Data</div>

        <div>{data.lastUpdated}</div>
      </>
    ) : // eslint-disable-next-line no-nested-ternary
    data.PLU ? (
      <>
        <div> Permitted Land Uses</div>

        <div>{data.lastUpdated}</div>
      </>
    ) : data.developmentControls ? (
      <>
        <div>Building Controls</div>

        <div>{data.lastUpdated}</div>
      </>
    ) : (
      <></>
    );
    //  : (
    // : // eslint-disable-next-line no-nested-ternary
    // data.developmentControls ? (
    //   <>
    //     <div>
    //       development <span style={{ display: 'block' }}>Controls</span>
    //     </div>
    //     <div>true</div>
    //     <div>{data.lastUpdated}</div>
    //   </>
    // ) : // eslint-disable-next-line no-nested-ternary
    // data.previousZoning ? (
    //   <>
    //     <div>previousZoning</div>
    //     <div>true</div>
    //     <div>{data.lastUpdated}</div>
    //   </>
    // ) : data.rezonings ? (
    //   <>
    //     <div>rezonings</div>
    //     <div>true</div>
    //     <div>{data.lastUpdated}</div>
    //   </>
    // )
    //   <></>
    // );
  }
  const { heading, subHeading } = generateHeading();
  const abc = product.map(
    ({
      zoning = false,
      last_updated,
      permitted_land_uses,
      development_controls,
      previous_zoning,
      rezonings,
    }) => {
      const obj = {
        // eslint-disable-next-line no-unneeded-ternary
        zone: zoning === 'Available' ? true : false,
        lastUpdated: last_updated,
        // eslint-disable-next-line camelcase
        PLU: permitted_land_uses === 'Available' ? true : false,
        developmentControls:
          // eslint-disable-next-line camelcase
          development_controls === 'Available' ? true : false,
        // eslint-disable-next-line camelcase
        previousZoning: previous_zoning === 'Available' ? true : false,
        rezonings: rezonings === 'Available' ? true : false,
      };
      return obj;
    }
  );

  return (
    <div className="map-container__zone-feature-tray1 zone-feature-tray">
      <>
        <div className="zone-feature-tray__feature-heading">
          <h1 className="feature-heading__heading">{heading}</h1>
          <button
            type="button"
            onClick={() => {
              handleDia(false);
            }}
          >
            <img src={CancelSVG} alt="cancel" />
          </button>
        </div>
      </>
      <>
        <h3 className="zone-feature-tray__details-heading">{subHeading}</h3>
        <div className="zone-feature-tray__table-wrapper">
          <div
            style={{
              padding: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 'bold',
            }}
          >
            <div>Available Datasets</div>

            <div>Last Updated</div>
          </div>

          {abc.map((data) => {
            return (
              <div
                style={{
                  padding: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {swith(data)}
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
}
