import Tangram from 'tangram';
import colors from './colors';
import mapInfoUtil from './mapInfoUtil';
import {
  setActiveMapLayer,
  setAvailableMaps,
  setLegend,
} from '../reducers/blockbriefMapSlice';
import {
  fetchPlanningData,
  fetchSitesData,
  fetchZoneFilterValues,
  fetchZoningData,
  fetchZoningFilterValues,
} from '../reducers/blockbriefMapSlice/blockbriefMapThunks';
import { OverlayParcel, ZoningMapParcel } from './yamlFileConfig';
import layerColor from './c_polygonColor';

/**
 * This function creates the tangram layer and registers the hover event on it
 * @param {string} sceneUrl - the scene url to load
 * @returns {object} the tangram scene object
 */
const initalizeTangramLayer = (sceneUrl) =>
  Tangram.leafletLayer({
    scene: sceneUrl ?? '',
    modifyScrollWheel: false,
    introspection: false,
    modifyZoomBehavior: false,
    attribution: '',
    events: {
      hover: (selection) => {
        if (selection.feature) {
          // set the 'cursor' css property of the map element to 'pointer' - if the selection is true
          // jQuery('.map-container').css('cursor', 'pointer !important');
        } else {
          // set the 'cursor' css property of the map element to 'webkit-grab'
          // jQuery('.map-container').css('cursor', 'webkit-grab');
        }
      },
    },
    selectionRadius: 20,
  });

/**
 * A utility function to update the tangram scene and load the correct scene file.
 * Loads the layer for the passed in mapType -
 * @param {string} state - the current state extracted from the address
 * @param {string} city - the current city extracted from the address
 * @param {string} mapType - the mapType - e.g 'Zoning Map', 'Floor Space Ratio Map' etc..
 * @param {object} tangramScene - the tangram layer currently active
 * @param {object} map - the leaflet map object
 * @param {function} setTangramLayer - setter to set the global ref for the active tangram layer
 */

const changeLayer = (state, city, mapType, tangramScene, map, store) => {
  const stateStr = mapInfoUtil(state, city, 'mapYamlFileName', mapType);
  // let tangramLayer = null;
  const MapTypes = {
    'Zoning Map': '',
    'Minimum Lot Size Map': '_mls',
    'Building Height Map': '_bh',
    'Floor Space Ratio Map': '_fsr',
    'Heritage Map': '_her',
    'Other Constraint Maps': '_oc',
    'Bushfire Map': '_bf',
    'Land Use Map': '_lu',
    'Local Plan Precincts Map': '_lpp',
    'Territory Plan Overlay Zones': '_tpoz',
    'Adelaide Policy Maps': '_ap',
    'Zoning Policy Maps': '_zp',
    'Precinct Map': '_precinct',
  };
  store.dispatch(
    setActiveMapLayer({ activeMapLayer: stateStr.concat(MapTypes[mapType]) })
  );
};
const updateTangram = (tangramLayer, map) => {
  tangramLayer.scene.updateConfig();
  tangramLayer.scene.immediateRedraw();
  map.invalidateSize(true);
};
const updateTangramConfig = (tangramLayer, map, store, zoneCode, state) => {
  const { currentActiveMap, activeMapLayer } = store.getState().blockbriefMap;
  let Parcel;
  if (currentActiveMap === 'Zoning Map') {
    Parcel = ZoningMapParcel(activeMapLayer);
  } else {
    Parcel = OverlayParcel(activeMapLayer);
  }

  if (tangramLayer.scene && tangramLayer.scene.config?.layers) {
    tangramLayer.scene.config.sources.zoning.url = `${process.env.REACT_APP_API_HOSTNAME}/tiles/${activeMapLayer}/{z}/{x}/{y}.mvt`;
    if (!Parcel.isParcel) {
      tangramLayer.scene.config.layers.zoning_parcel.enabled = false;
    } else {
      tangramLayer.scene.config.layers.zoning_parcel.enabled = true;
      tangramLayer.scene.config.sources.zoning_parcel.url = `${process.env.REACT_APP_API_HOSTNAME}/parcels/${Parcel.parcelTabel}_parcel/{z}/{x}/{y}.mvt`;
    }

    tangramLayer.scene.config.layers.zoning.draw.c_polygon.color = layerColor(
      zoneCode,
      colors,
      currentActiveMap,
      state
    );

    updateTangram(tangramLayer, map);
  }
};

/**
 * Sets up the legend for this state, it's zone filters - tray values.
 * Loads the layer for the passed in mapType -
 * Load the legend for the current Map Type and state
 * @param {string} state - the current state extracted from the address
 * @param {string} city - the current city extracted from the address
 * @param {string} mapType - the mapType - e.g 'Zoning Map', 'Floor Space Ratio Map' etc..
 * @param {object} tangramScene - the tangram layer currently active
 * @param {object} map - the leaflet map object
 * @param {function} setTangramLayer - setter to set the global ref for the active tangram layer
 */

const configureStateLayer = async (state, city, mapType, store, scene, map) => {
  const legendRequestPayload = mapInfoUtil(
    state,
    city,
    'legendPayload',
    mapType
  );
  changeLayer(state, city, mapType, scene, map, store);

  const zoneLegend = await store.dispatch(
    fetchZoneFilterValues({ legendRequestPayload, mapType })
  );
  const result = zoneLegend.payload.mapLegend?.filterValues.map((zone, idx) => {
    return {
      key: zone,
      color: colors[idx],
    };
  });

  store.dispatch(setLegend(result));
  await store.getState().blockbriefMap.activeZoneFilterValues;
};

/**
 *
 * Initialize Map Layers - Sets up the map state for a specific address - state and city
 *  - Sets up Available Maps for current State and City
 *  - Sets up the Map legend for the current state and city
 *  - Loads the default overlay into the current scene by calling configureStateLayer
 *
 * @param {string} state - the current state extracted from the address
 * @param {string} city - the current city extracted from the address
 *
 */
const inititalizeMapLayers = (
  { state, city },
  store,
  scene,
  map,
  currentActiveMap
) => {
  const mapTypes = mapInfoUtil(state, city, 'mapTypes');

  store.dispatch(setAvailableMaps({ mapTypes }));
  configureStateLayer(state, city, currentActiveMap, store, scene, map);
};

/**
 * Event handler for Map - Zoom and Move events - handles the Off Market Markers, Planning Markers, On Market Markers
 * on map zoom and pan events - by fetching the updated data for these markers
 * @param {object} store - the redux store object
 * @param {object} map - the leaflet map object
 */
const handleMapZoomAndMove = (store, map) => () => {
  const { offMarketStatus, listingSitesData, planningData, zoningData } =
    store.getState().blockbriefMap; // get the current store state explicitly with the getState method - because with closure we get stale state
  const onMarketStatus = listingSitesData.filterStatus;

  if (offMarketStatus || onMarketStatus || planningData.filterStatus) {
    const bounds = map.getBounds();
    const planningMarkers = planningData.filterStatus;
    const zoningMarkers = zoningData.filterStatus;
    if (onMarketStatus) {
      // schedule the dispatch to the next render - because it halts the UI - when at higher zoom levels
      setTimeout(() => {
        store.dispatch(fetchSitesData({ bounds }));
      }, 10);
    }

    if (offMarketStatus) {
      // schedule the dispatches to the next render - becasue it halts the UI
      setTimeout(() => {
        store.dispatch(fetchZoningFilterValues({ bounds }));
        store.dispatch(fetchZoningData({ bounds, zoningMarkers }));
      }, 10);

      setTimeout(() => {
        store.dispatch(fetchPlanningData({ bounds, planningMarkers }));
      }, 100);
    }

    if (planningData.filterStatus && !offMarketStatus) {
      setTimeout(() => {
        store.dispatch(fetchPlanningData({ bounds, planningTray: true }));
      }, 10);

      map.invalidateSize({
        easeLinearity: 0.3,
        animation: false,
        noMoveStart: true,
      });
    }
  }
};

export {
  inititalizeMapLayers,
  configureStateLayer,
  initalizeTangramLayer,
  handleMapZoomAndMove,
  updateTangramConfig,
  updateTangram,
};
