import React, { useState } from 'react';
import DropdownFilterBtn from '../DropdownFilterBtn';
import './FilterItem.css';
import DropdownTray from '../DropdownTray';

function generateFilterItem(
  filterType,
  filterName,
  handleOpenTray,
  placeholderStr,
  selectedValue,
  filterValues,
  Tray,
  selectionFunction
) {
  if (filterType === 'Dropdown')
    return (
      <>
        <DropdownFilterBtn
          handleOpenTray={handleOpenTray}
          placeholderStr={placeholderStr}
          selectedValue={selectedValue}
        />
        <DropdownTray
          Tray={Tray}
          filterValues={filterValues}
          selectedValue={selectedValue}
          selectionFn={selectionFunction}
          trayType="Data-Filter"
        />
      </>
    );
  if (filterType === 'Date')
    return (
      <input type="date" onChange={selectionFunction} value={selectedValue} />
    );
  if (filterType === 'Search')
    return (
      <input
        className="control-item__input"
        type="text"
        placeholder={placeholderStr}
        value={selectedValue}
        onChange={selectionFunction}
        autoComplete="off"
      />
    );
  if (filterType === 'range')
    return (
      <div className="range-filter">
        <input
          type="text"
          name="min-range"
          value={selectedValue.min}
          placeholder="min - m²"
          onChange={selectionFunction.minChangeFn}
        />
        <div className="divider" />
        <input
          type="text"
          name="max-range"
          value={selectedValue.max}
          placeholder="max - m²"
          onChange={selectionFunction.maxChangeFn}
        />
      </div>
    );

  return null;
}

/** This component takes in the following named props -
 * @param {String} FilterName - the name of the filter i-e 'Status', 'State' etc..
 * @param {String} filterType - 'Dropdown' or 'Date'
 * @param {String} placeholderStr - the placeholder for the filter item
 * @param {String} selectedValue - the current selectedValue of the filter item
 * @param {Array} filterValues - the list of filter values to select from
 * @param {function} selectionFunction - a function which is called when an items is selected
 * @returns JSX.Element
 */
export default function FilterItem({
  filterName,
  placeholderStr = 'Search for a Keyword',
  selectedValue,
  filterType,
  selectionFunction,
  filterValues,
}) {
  const [Tray, setTray] = useState(false);
  const handleOpenTray = () => {
    setTray(!Tray);
  };
  return (
    <div className="filter-control">
      <h5 className="filter-control__heading">{filterName}</h5>
      {generateFilterItem(
        filterType,
        filterName,
        handleOpenTray,
        placeholderStr,
        selectedValue,
        filterValues,
        Tray,
        selectionFunction
      )}
    </div>
  );
}
