import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  hidePlanningMarkers,
  hideZoningMarkers,
  resetFilterForm,
  setFilterForm,
} from '../../reducers/blockbriefMapSlice';
import { fetchZoningData } from '../../reducers/blockbriefMapSlice/blockbriefMapThunks';
import OffMarketDataToggle from '../OffMarketDataToggle/index.';
import OffMarketFilterMenu from '../OffMarketFilterMenu';
import './OffMarketFiltersTray.css';
import Spinner from '../../assets/svg/Spinner';

export default function OffMarketFilterTray({ filterStatus, map }) {
  const mapState = useSelector((state) => state.blockbriefMap);
  const { status, projectType, startDate, endDate, searchStr } =
    mapState.zoningData.filters.filterForm;
  const totalPermitRec = mapState.planningData.filterStatus
    ? mapState.planningData.planningRecords.length
    : 0;
  const totalZoningRec = mapState.zoningData.filterStatus
    ? mapState.zoningData.zoningRecords.length
    : 0;
  const totalRecords = totalPermitRec + totalZoningRec;
  const dispatch = useDispatch();
  const [Status, setStatus] = useState(status);
  const [ProjectType, setProjectType] = useState(projectType);
  const [StartDate, setStartDate] = useState(startDate);
  const [EndDate, setEndDate] = useState(endDate);
  const [SearchStr, setSearchStr] = useState(searchStr);

  return (
    <div
      className="offmarket-filter__offmarket-tray offmarket-tray"
      style={filterStatus ? { display: 'flex' } : { display: 'none' }}
    >
      <div className="offmarket-tray__heading-row heading-row">
        <h3 className="heading-row__heading">Off Market Filter</h3>
        <span className="heading-row__total-records">
          Total Records -{' '}
          {mapState.zoningData.zoningLoading ? (
            <Spinner
              spinnerColor="#25b7d3"
              spinnerSize={{ width: '1.8em', height: '1.8em' }}
            />
          ) : (
            totalRecords
          )}
        </span>
      </div>
      {/* OFF MARKET WRAPPER DIV  */}
      <div className="offmarket-tray__wrapper">
        {/* OFF MARKET DATA TOGGLE COMPONENT */}
        <OffMarketDataToggle
          zoningStatus={mapState.zoningData.filterStatus}
          permitStatus={mapState.planningData.filterStatus}
          handleToggle={(dataType) => {
            if (dataType === 'Permit') {
              dispatch(hidePlanningMarkers());
            } else {
              dispatch(hideZoningMarkers());
            }
          }}
        />
        <OffMarketFilterMenu
          EndDate={EndDate}
          StartDate={StartDate}
          SearchStr={SearchStr}
          Status={Status}
          ProjectType={ProjectType}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          setSearchStr={setSearchStr}
          setStatus={setStatus}
          setProjectType={setProjectType}
        />
        <div className="filter-submit-items offmarket-tray__filter-submit-items">
          <button
            type="button"
            className="filter-submit-items__items"
            onClick={() => /* handles filter submission  */ {
              dispatch(hidePlanningMarkers());
              // First update the fitlerForm in the store
              const bounds = map.getBounds();
              dispatch(
                setFilterForm({
                  status: Status,
                  projectType: ProjectType,
                  startDate: StartDate,
                  endDate: EndDate,
                  searchStr: SearchStr,
                })
              );

              // Than call the api to update the response
              dispatch(
                fetchZoningData({
                  bounds,
                })
              );
            }}
          >
            Done
          </button>
          <button
            type="button"
            className="filter-submit-items__items"
            onClick={() => /* Resets the off market filter tray */ {
              const bounds = map.getBounds();
              dispatch(resetFilterForm());
              setStatus('');
              setStartDate('');
              setSearchStr('');
              setEndDate('');
              setProjectType('');
              dispatch(
                fetchZoningData({
                  bounds,
                })
              );
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}
