import React from 'react';
import { Link } from 'react-router-dom';
import './PublicHeader.css';
import Logo from '../../assets/images/logo.png';
import LandingHeaderNav from '../LandingHeaderNav';

export default function PublicHeader() {
  return (
    <div className="header">
      <Link className="header__logo logo" to={{ pathname: '/' }}>
        <img src={Logo} alt="Blockbrief Logo" className="logo__img" />
      </Link>
      <LandingHeaderNav />
    </div>
  );
}
