import React from 'react';
import './APIRequestTable.css';
import { nanoid } from '@reduxjs/toolkit';

export default function APIRequestTable({ tableHeading, requestTableData }) {
  return (
    <div className="api-content__request-details request-details">
      <div className="request-details__heading">{tableHeading}</div>
      <table className="request-details__param-table" cellSpacing="0px">
        <tbody>
          {requestTableData.map((tableRow) => (
            <tr key={nanoid()}>
              {tableRow.map((tableData) => (
                <td key={nanoid()}>{tableData}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
