import { createAsyncThunk } from '@reduxjs/toolkit';
import $ from 'jquery';
import axios from '../../Apis/axios';
import colors from '../../utils/colors';

// =======================
// API QUERY UTIL
// =======================

const getFilterQuery = ({ filterType, payload }) => {
  const { status, projectType, startDate, endDate, searchStr } = payload;
  const filtersQuery = [];

  if (filterType === 'textPropFilter') {
    if (status)
      filtersQuery.push({
        match: { 'status.keyword': status },
      });

    if (projectType)
      filtersQuery.push({
        match: {
          'project_type.keyword': projectType,
        },
      });

    if (searchStr)
      filtersQuery.push({
        query_string: { query: searchStr },
      });
  }

  if (filterType === 'dateFilter')
    if (startDate && endDate)
      filtersQuery.push({
        range: {
          date: {
            gte: startDate,
            lte: endDate,
          },
        },
      });

  return filtersQuery;
};

async function getZoneFilterValues(payload) {
  const { mapType, legendRequestPayload } = payload;

  const apiResponse = await axios
    .post(
      `/zone-filter-values`,
      {
        ...legendRequestPayload,
        mapType,
      },
      { isAuth: true }
    )
    .then((response) => {
      if (response.status === 200)
        return { filterValues: response.data.zoneFilters };
      return [];
    });

  return {
    mapLegend: apiResponse,
  };
}

const fetchZoneFilterValues = createAsyncThunk(
  'BlockbriefMap/fetchZoneFilterValues',
  getZoneFilterValues
);

async function getLegned(payload) {
  const result = payload.map((zone, idx) => {
    return {
      key: zone,
      color: colors[idx],
    };
  });
  return { mapLegend: result };
}

const fetchLegend = createAsyncThunk('BlockbriefMap/fetchLegend', getLegned);

/**
 * Fetches the planning data  for the map bounds provided
 * @param {object} mapBounds - mapBounds to fetch the fitler values for
 *
 */
async function getPlanningData(
  { bounds, planningTray, planningMarkers = true },
  thunkAPI
) {
  const dataBounds = {
    north: bounds.getNorth(),
    south: bounds.getSouth(),
    west: bounds.getWest(),
    east: bounds.getEast(),
  };
  const planningReqObj = await $.ajax({
    url: 'https://api.planningalerts.org.au/applications.js',
    jsonp: 'callback',
    cache: true,
    data: {
      bottom_left_lat: dataBounds.south,
      bottom_left_lng: dataBounds.west,
      key: '3OTZlHOeiXLb0S7XMrR1',
      top_right_lat: dataBounds.north,
      top_right_lng: dataBounds.east,
    },
  }).then((resp) => {
    return [
      ...resp.map((data) => {
        return {
          ...data.application,
          location: {
            lat: data.application.lat,
            lng: data.application.lng,
          },
          address: data.application.address,
          fullName: data.application.authority.full_name,
          dateRecieved: data.application.date_received,
          description: data.application.description,
        };
      }),
    ];
  });
  return {
    planningData: planningReqObj,
    planningTray,
    planningMarkers:
      !(
        thunkAPI.getState().blockbriefMap.currentActiveMap === 'Planning Alerts'
      ) && !thunkAPI.getState().blockbriefMap.offMarketStatus
        ? false
        : planningMarkers,
  };
}

const fetchPlanningData = createAsyncThunk(
  'BlockbriefMap/fetchPlanningRecords',
  getPlanningData
);

const getSitesData = async ({ bounds, markerStatus = true }, thunkAPI) => {
  const requestURL = 'https://zoningdataus.info/listing_data_au/_search';
  const topLeftLat = bounds.getNorth();
  const bottomRightLat = bounds.getSouth();
  const topLeftLng = bounds.getWest();
  const bottomRightLng = bounds.getEast();
  function getCurrentAndThreeMonthsAgoDate() {
    const today = new Date();
    const threeMonthsAgo = new Date(
      today.getFullYear(),
      today.getMonth() - 3,
      today.getDate()
    );
    const todayFormatted =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      today.getDate().toString().padStart(2, '0');
    const threeMonthsAgoFormatted =
      threeMonthsAgo.getFullYear() +
      '-' +
      (threeMonthsAgo.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      threeMonthsAgo.getDate().toString().padStart(2, '0');
    return [todayFormatted, threeMonthsAgoFormatted];
  }
  const [current, threeMonthsAgo] = getCurrentAndThreeMonthsAgoDate();
  const requestPayload = {
    size: 500,
    query: {
      bool: {
        filter: [
          {
            geo_bounding_box: {
              'pin.location': {
                top_left: {
                  lat: topLeftLat,
                  lon: topLeftLng,
                },
                bottom_right: {
                  lat: bottomRightLat,
                  lon: bottomRightLng,
                },
              },
            },
          },
          {
            range: {
              last_update_date: {
                gte: threeMonthsAgo,
                lte: current,
              },
            },
          },
        ],
        must: {
          match_all: {},
        },
      },
    },
  };
  const responseData = await axios.post(requestURL, requestPayload, {
    headers: {
      Authorization:
        'Basic em9uaW5nX2FwcF91c2VyOlpXeGhjM1JwWXpwdmIxVkpNMHh1V25oa2JuQmxXWEJ5Y0VKQllR',
    },
  });
  if (responseData.status === 200) {
    return {
      sitesData: responseData.data.hits.hits,
      markerStatus: thunkAPI.getState().blockbriefMap.listingSitesData
        .filterStatus
        ? markerStatus
        : false,
    };
  }
  return { zoningData: [] };
};

const fetchSitesData = createAsyncThunk(
  'BlockbriefMap/fetchSitesData',
  getSitesData
);

/**
 * Fetches the filter valeus for the zoning data
 * @param {object} mapBounds - mapBounds to fetch the fitler values for
 *
 */
async function getZoningFilterValues({ bounds }) {
  const topLeftLat = bounds.getNorth();
  const bottomRightLat = bounds.getSouth();
  const topLeftLng = bounds.getWest();
  const bottomRightLng = bounds.getEast();
  const filterTypes = ['Status', 'Project Type'];

  const queryBounds = {
    must: { match_all: {} },
    filter: {
      geo_bounding_box: {
        'pin.location': {
          top_left: { lat: topLeftLat, lon: topLeftLng },
          bottom_right: { lat: bottomRightLat, lon: bottomRightLng },
        },
      },
    },
  };

  const promiseArr = filterTypes.map((filterType) => {
    const filter =
      filterType === 'Status' ? 'status.keyword' : 'project_type.keyword';
    const query = {
      /* API Query Structure - For Status Values */
      query: { bool: { ...queryBounds } },
      size: 0,
      aggs: {
        langs: {
          terms: {
            field: filter,
            size: 500,
          },
        },
      },
    };
    return axios
      .post('https://zoningdataus.info/au_zoning_data/_search', query, {
        headers: {
          Authorization:
            'Basic em9uaW5nX2FwcF91c2VyOlpXeGhjM1JwWXpwdmIxVkpNMHh1V25oa2JuQmxXWEJ5Y0VKQllR',
        },
      })
      .then((responseData) =>
        responseData.status === 200
          ? {
              filterValues: responseData.data.aggregations.langs.buckets,
              filterType,
            }
          : { filterType, filterValues: [] }
      );
  });
  const filterArr = await Promise.all(promiseArr);

  return { filterValues: filterArr };
}

const fetchZoningFilterValues = createAsyncThunk(
  'BlockbriefMap/zoningData/fetchFilter',
  getZoningFilterValues
);

/**
 * Fetches the Zoning data for the passed in map bounds
 * @param {object} mapBounds - the map bounds to fetch the data for
 *
 */
async function getZoningData({ bounds, zoningMarkers = true }, thunkAPI) {
  const topLeftLat = bounds.getNorth();
  const bottomRightLat = bounds.getSouth();
  const topLeftLng = bounds.getWest();
  const bottomRightLng = bounds.getEast();
  let textFilters = [];
  let dateRangeFilter = [];
  const requestURL = 'https://zoningdataus.info/au_zoning_data/_search';
  const { status, projectType, searchStr, startDate, endDate } =
    thunkAPI.getState().blockbriefMap.zoningData.filters.filterForm;

  if (status || projectType || searchStr)
    textFilters = getFilterQuery({
      filterType: 'textPropFilter',
      payload: { status, projectType, searchStr, startDate: '', endDate: '' },
    });

  if (startDate && endDate)
    dateRangeFilter = getFilterQuery({
      filterType: 'dateFilter',
      payload: { startDate, endDate },
    });

  let requestPayload;

  if (status || projectType || searchStr || startDate || endDate) {
    requestPayload = {
      query: {
        bool: {
          must: [...textFilters],
          filter: [
            {
              geo_bounding_box: {
                'pin.location': {
                  top_left: {
                    lat: topLeftLat,
                    lon: topLeftLng,
                  },
                  bottom_right: {
                    lat: bottomRightLat,
                    lon: bottomRightLng,
                  },
                },
              },
            },
            ...dateRangeFilter,
          ],
        },
      },
    };
  } else {
    requestPayload = {
      size: 500,
      query: {
        bool: {
          filter: {
            geo_bounding_box: {
              'pin.location': {
                top_left: {
                  lat: topLeftLat,
                  lon: topLeftLng,
                },
                bottom_right: {
                  lat: bottomRightLat,
                  lon: bottomRightLng,
                },
              },
            },
          },
        },
      },
    };
  }
  const responseData = await axios.post(requestURL, requestPayload, {
    headers: {
      Authorization:
        'Basic em9uaW5nX2FwcF91c2VyOlpXeGhjM1JwWXpwdmIxVkpNMHh1V25oa2JuQmxXWEJ5Y0VKQllR',
    },
  });
  if (responseData.status === 200) {
    return {
      zoningData: responseData.data.hits.hits,
      zoningMarkers:
        zoningMarkers && thunkAPI.getState().blockbriefMap.offMarketStatus,
    };
  }
  return { zoningData: [] };
}

const fetchZoningData = createAsyncThunk(
  'BlockbriefMap/fetchZoningData',
  getZoningData
);

async function getZoneDetails({ state, lat, lng }) {
  try {
    if (lat && lng && state) {
      const requestURL = `/zoneDetails/${state}/${lat}/${lng}`;
      const requestData = await axios.get(requestURL);

      return { data: requestData, message: 'ok' };
    }
    return {
      data: null,
      message: 'error',
    };
  } catch (error) {
    return {
      data: null,
      message: error.message,
    };
  }
}

const fetchZoneDetailApi = createAsyncThunk(
  'BlockbriefMap/fetchZoneDetailApi',
  getZoneDetails
);

async function getOverlayDetails({ mapType, state, lat, lng }) {
  try {
    const requestURL = `/overlayDetails/${state}/${lat}/${lng}`;
    const requestData = await axios.post(requestURL, { mapType });

    return { data: requestData, message: 'ok' };
  } catch (error) {
    return {
      data: null,
      message: error.message,
    };
  }
}
const fetchOverlayDetail = createAsyncThunk(
  'BlockbriefMap/fetchZoneDetailApi',
  getOverlayDetails
);

export {
  fetchPlanningData,
  fetchZoningData,
  fetchLegend,
  fetchZoningFilterValues,
  fetchSitesData,
  fetchZoneFilterValues,
  fetchZoneDetailApi,
  fetchOverlayDetail,
};
