import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureGroup, Marker } from 'react-leaflet';
import L from 'leaflet';
import { nanoid } from '@reduxjs/toolkit';
import YellowIcon from '../../assets/images/listings.png';
import { displayModal } from '../../reducers/ApplicationModalSlice';

export default function ZoningMarkers({ zoningStatus, zoningData }) {
  const dispatch = useDispatch();
  const returnedValue = useMemo(
    () => (
      <FeatureGroup>
        {zoningData.map(({ _source: data }) => (
          <Marker
            key={nanoid()}
            position={[
              Number(data.pin.location.lat),
              Number(data.pin.location.lon),
            ]}
            // onClick={() => console.log(data)}
            riseOnHover
            eventHandlers={{
              click: () => {
                dispatch(
                  displayModal({
                    modalType: 'Off Market Site',
                    modalData: data,
                  })
                );
                document.body.classList.toggle('no-scroll');
              },
            }}
            icon={
              new L.Icon({
                iconUrl: YellowIcon,
                iconSize: [32, 37],
                iconAnchor: [16, 40],
              })
            }
          />
        ))}
      </FeatureGroup>
    ),
    [zoningStatus, zoningData]
  );
  if (zoningStatus && zoningData.length) return returnedValue;
  return null;
}
