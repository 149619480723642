import React, { useState } from 'react';
import LoadingIndicator from '../../assets/svg/Loading-indicator';
import FilterItem from '../FilterItem';
import './UserInputForm.css';

export default function UserInputForm({
  corelogicAccount = '',
  email = '',
  password = '',
  firstName = '',
  lastName = '',
  phone = '',
  mobilePhone = '',
  premium = false,
  premiumPro = false,
  address = '',
  submissionFn,
  responseLoading,
  modalType,
  userID,
}) {
  const [corelogicAccount_, setCorelogicAccount] = useState(corelogicAccount);
  const [email_, setEmail] = useState(email);
  const [password_, setPassword] = useState(password);
  const [firstName_, setFirstName] = useState(firstName);
  const [lastName_, setLastName] = useState(lastName);
  const [phone_, setPhone] = useState(phone);
  const [mobilePhone_, setMobilePhone] = useState(mobilePhone);
  const [premium_, setPremium] = useState(Boolean(Number(premium)));
  const [premiumPro_, setPremiumPro] = useState(Boolean(Number(premiumPro)));
  const [address_, setAddress] = useState(address);
  const [formError, setFormError] = useState('');
  return (
    <div className="user-input-form">
      <h3 className="account-info__heading">Account Information</h3>
      <div className="account-info">
        <FilterItem
          filterName="Corelogic Account"
          placeholderStr="Enter Corelogic Account Number"
          filterType="Search"
          selectedValue={corelogicAccount_}
          selectionFunction={(e) => {
            const selectedValue = e.target.value;
            setCorelogicAccount(selectedValue);
          }}
        />
        <FilterItem
          filterName="Email"
          placeholderStr="Enter user Email"
          filterType="Search"
          selectedValue={email_}
          selectionFunction={(e) => {
            const selectedValue = e.target.value;
            setEmail(selectedValue);
          }}
        />
        {!(modalType === 'Edit User') ? (
          <FilterItem
            filterName="Password"
            placeholderStr="Enter user Password"
            filterType="Search"
            selectedValue={password_}
            selectionFunction={(e) => {
              const selectedValue = e.target.value;
              setPassword(selectedValue);
            }}
          />
        ) : null}
      </div>
      <h3 className="account-info__heading">User Information</h3>
      <div className="userDetails">
        <FilterItem
          filterName="First Name"
          placeholderStr="Enter user First Name"
          filterType="Search"
          selectedValue={firstName_}
          selectionFunction={(e) => {
            const selectedValue = e.target.value;
            setFirstName(selectedValue);
          }}
        />
        <FilterItem
          filterName="Last Name"
          placeholderStr="Enter user Last Name"
          filterType="Search"
          selectedValue={lastName_}
          selectionFunction={(e) => {
            const selectedValue = e.target.value;
            setLastName(selectedValue);
          }}
        />
        <FilterItem
          filterName="Phone"
          placeholderStr="Enter user Phone Number"
          filterType="Search"
          selectedValue={phone_}
          selectionFunction={(e) => {
            const selectedValue = e.target.value;
            setPhone(selectedValue);
          }}
        />
        <FilterItem
          filterName="Mobile"
          placeholderStr="Enter user Mobile Number"
          filterType="Search"
          selectedValue={mobilePhone_}
          selectionFunction={(e) => {
            const selectedValue = e.target.value;
            setMobilePhone(selectedValue);
          }}
        />
        <label htmlFor="premium">
          <input
            type="checkbox"
            name="premium"
            id="premium"
            checked={premium_}
            onClick={() => {
              setPremium(!premium_);
            }}
          />
          Premium
        </label>
        <label htmlFor="premium-pro">
          <input
            type="checkbox"
            name="premium"
            id="premium-pro"
            checked={premiumPro_}
            onClick={() => {
              setPremiumPro(!premiumPro_);
            }}
          />
          Premium Pro
        </label>
        <FilterItem
          filterName="Address"
          placeholderStr="Enter user Address"
          filterType="Search"
          selectedValue={address_}
          selectionFunction={(e) => {
            const selectedValue = e.target.value;
            setAddress(selectedValue);
          }}
        />
      </div>
      <p className="user-form-err">{formError}</p>
      <button
        type="button"
        className="user-form-btn"
        onClick={() => {
          if (
            !(modalType === 'Create New User' && !password_) &&
            corelogicAccount_ &&
            email_ &&
            firstName_ &&
            lastName_ &&
            phone_ &&
            mobilePhone_ &&
            address_
          ) {
            submissionFn({
              corelogicAccount: corelogicAccount_,
              email: email_,
              password: password_,
              firstName: firstName_,
              lastName: lastName_,
              phone: phone_,
              mobilePhone: mobilePhone_,
              premium: premium_,
              premiumPro: premiumPro_,
              address: address_,
              userID,
            });
          } else {
            setFormError('*All the fields are required');
          }
        }}
      >
        {responseLoading ? <LoadingIndicator /> : 'Submit'}
      </button>
    </div>
  );
}
