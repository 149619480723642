import React from 'react';
import ExtendedNav from '../ExtendedNav';
import UserAvatar from '../../assets/images/user.png';

export default function HeaderUserSection({
  firstname,
  toggleExtendedMenuTray,
  extendedMenuTray,
}) {
  return (
    <div className="application-header__user user-section">
      <h5 className="user-section__greetings">Hey, {firstname}</h5>
      <div
        className="user-section__user-avatar"
        onClick={() => toggleExtendedMenuTray(!extendedMenuTray)}
      >
        <img src={UserAvatar} alt="user avatar" />
      </div>
      <ExtendedNav
        extendedMenuTray={extendedMenuTray}
        toggleExtendedMenuTray={toggleExtendedMenuTray}
      />
    </div>
  );
}
