import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingIndicator from '../../assets/svg/Loading-indicator';
import { hideAdminModal } from '../../reducers/AdminApplicationModalSlice';
import FilterItem from '../FilterItem';
import './UpdatePasswordFormAdmin.css';

export default function UpdatePasswordFormAdmin({ userData }) {
  const [updatePassword, setUpdatePassoword] = useState('');
  const dispatch = useDispatch();
  const [updateLoading, setUpdateLoading] = useState(false);
  const updatePasswordHandler = async () => {
    setUpdateLoading(true);
    const requestURL = `${process.env.REACT_APP_API_HOSTNAME}/admin/update-password`;
    const result = await axios({
      url: requestURL,
      method: 'POST',
      data: {
        userID: userData.member_id,
        updatedPassword: updatePassword,
      },
      withCredentials: true,
    });
    if (result.status === 200) {
      setUpdateLoading(false);
      dispatch(hideAdminModal());
      document.body.classList.toggle('no-scroll');
    }
  };
  return (
    <div className="admin-application-modal__change-password-form change-password-form">
      <h2 className="change-password-form__heading">
        Update password for, {userData.firstname}
      </h2>
      <FilterItem
        filterName="Updated Password"
        placeholderStr="Enter New Password"
        filterType="Search"
        selectedValue={updatePassword}
        selectionFunction={(e) => {
          const selectedValue = e.target.value.trim();
          setUpdatePassoword(selectedValue);
        }}
      />
      <button
        type="button"
        className="change-passsword-form__btn"
        onClick={updatePasswordHandler}
      >
        {updateLoading ? <LoadingIndicator /> : 'Submit'}
      </button>
    </div>
  );
}
