import React from 'react';
import { useSelector } from 'react-redux';
import './Landing.css';
import LandingBanner from '../../components/LandingBanner';
import LandingSearch from '../../components/LandingSearch';
import LandingInfo from '../../components/LandingInfo';
// import LandingVideo from '../../components/LandingVideo';
import LandingFeatured from '../../components/LandingFeatured';
import PublicHeader from '../../components/PublicHeader';
import PublicFooter from '../../components/PublicFooter';
import Application from '../Application';

export default function Landing() {
  const loggedIn = useSelector((state) => state.user.loggedIn);

  return (
    <div className="main-grid">
      {!loggedIn ? (
        <PublicHeader />
      ) : (
        <div
          style={{ backgroundColor: 'white', width: '100vw', height: '120%' }}
        >
          <Application />
        </div>
      )}

      <LandingBanner />
      <LandingSearch />
      <LandingInfo />
      {/* <LandingVideo /> */}
      <LandingFeatured />
      <PublicFooter />
    </div>
  );
}
