import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MapContainer, Marker } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import L from 'leaflet';
import './leaflet-measure';
import './BlockbriefMap.css';
import MapMenu from '../../components/MapMenu';
import ZoomControls from '../../components/ZoomControls';
import MapControls from '../../components/MapControls';
import MapLegend from '../../components/MapLegend';
import MapPermitTray from '../../components/MapPermitTray';
import { setLocation } from '../../utils/useGooglePlaces';
// import colors from '../../utils/colors';
import {
  inititalizeMapLayers,
  initalizeTangramLayer,
  handleMapZoomAndMove,
  updateTangramConfig,
} from '../../utils/mapHelpersFn';
import LocationIcon from '../../assets/images/marker-icon.png';
import PlanningAlertsMarkers from '../../components/PlanningAlertsMarkers';
import ZoningMarkers from '../../components/ZoningMarkers';
import {
  // resetCurrentSelectedFeature,
  setAddress,
  setCurrentActiveMap,
  setCurrentBuildingControls,
  setCurrentFeatureAddress,
  setCurrentFeaturePreviousZone,
  setCurrentFeatureZoneCode,
  setCurrentFeatureZoneDetails,
  setCurrentPluDetails,
  setLoadingCurrentFeatureAddress,
  setMapLatLng,
  setPlaceID,
  toggleCurrentSelectedFeatureTray,
} from '../../reducers/blockbriefMapSlice';
import ResidentialMarkers from '../../components/ResidentialMarkers';
import CommercialMarkers from '../../components/CommercialMarker';
import './Leaflet-Measure.css';
import ZoneFeatures from '../../components/ZoneFeatureTray';
import ParcelDownload from '../../components/ParcelDownload';
import { featureSelection } from '../../utils/featureSelectionDataUtils';
import YamlFile from '../../assets/tangramSceneYaml/all.yaml';
import getAddress from '../../utils/useGoogleGeoCoding';
import {
  fetchOverlayDetail,
  fetchZoneDetailApi,
} from '../../reducers/blockbriefMapSlice/blockbriefMapThunks';
import stateNameConfig from '../../utils/stateNameConfig';

// LEAFLET: Fix Leaflet's icon paths for Webpack --
// See here: https://github.com/PaulLeCam/react-leaflet/issues/255
// Used in conjunction with url-loader.
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Leaflet Measure Map Control Initalization
const measure = L.control.measure({
  position: 'topright',
  primaryLengthUnit: 'meters',
  secondaryLengthUnit: 'kilometers',
  primaryAreaUnit: 'hectares',
  secondaryAreaUnit: 'sqmeters',
  activeColor: '#5e66cc',
  completedColor: '#5e66cc',
});

export default function BlockbriefMap() {
  const location = useLocation(); // current browser location object
  const dispatch = useDispatch(); // redux-store dispatch
  const store = useStore();
  const mapState = useSelector((state) => state.blockbriefMap);

  const [tangramLayer, setTangramLayer] = useState(null); // state - ref for the the tangramLayer scene
  const [map, setMap] = useState(null); // state - ref for leaeflet map object
  const baseMap = useRef(); // stores the ref to the baseMap container div
  const [mapType, setMapType] = useState('roadmap'); // Google Map Type state

  // This hook updates the placeID when location changes - only run on the initial reders
  useEffect(async () => {
    dispatch(setPlaceID({ placeID: location.state.place_id }));
    dispatch(setCurrentActiveMap('Zoning Map'));
    store.dispatch(setLoadingCurrentFeatureAddress());
    store.dispatch(toggleCurrentSelectedFeatureTray());
  }, [location.state]);

  // This hook initilizes the tangram layer - with an empty scene and adds it to the map on initial render
  useEffect(async () => {
    if (map) {
      const layer = initalizeTangramLayer(YamlFile);
      setTangramLayer(layer);
      layer.addTo(map);
    }
  }, [map, mapType]);

  // This hook updates the map location when the placeID changes and updates the base map
  useEffect(() => {
    if (map && baseMap.current) {
      setLocation(
        mapState.mapLocation.placeID,
        baseMap.current.getContainer(),
        ({ payload }) => {
          store.dispatch(
            setAddress({
              state: payload.state,
              city: payload.city,
              lat: payload.lat,
              lng: payload.lng,
              placeIDGeoCode: {
                lat: payload.lat,
                lng: payload.lng,
              },
            })
          );
        },
        map
      );

      setTimeout(async () => {
        const currentState = store.getState().blockbriefMap.mapLocation.state;
        const state = stateNameConfig(currentState);
        const zoningState = ['nsw', 'vic', 'sa', 'wa', 'tas', 'qld', 'act', ''];

        if (
          zoningState.includes(store.getState().blockbriefMap.activeMapLayer) &&
          store.getState().blockbriefMap.currentActiveMap !== 'Planning Alerts'
        ) {
          const resultZoneDetailApi = await store.dispatch(
            fetchZoneDetailApi({
              state,
              lat: store.getState().blockbriefMap.mapLocation.lat,
              lng: store.getState().blockbriefMap.mapLocation.lng,
            })
          );
          console.log('resultZoneDetailApi', resultZoneDetailApi);
          // plu
          if (resultZoneDetailApi?.payload?.data?.data?.plus) {
            store.dispatch(
              setCurrentPluDetails({
                pluDetails: resultZoneDetailApi?.payload?.data.data,
              })
            );
            store.dispatch(
              setCurrentFeatureZoneCode({
                zoneCode:
                  resultZoneDetailApi?.payload?.data.data.zoneDetails.zone_code,
              })
            );

            Object.entries(
              resultZoneDetailApi?.payload?.data.data.zoneDetails
            ).forEach(([prop, val]) => {
              if (prop !== 'zone_code') {
                const propName = prop.split('_').join(' ');
                const propVal = val.toString().split('_').join(' ');
                store.dispatch(
                  setCurrentFeatureZoneDetails({
                    zoneDetail: { propName, propVal },
                  })
                );
              }
            });
          }
          // building Controls
          if (state === 'nsw') {
            store.dispatch(
              setCurrentBuildingControls({
                buildingControls:
                  resultZoneDetailApi?.payload?.data?.data
                    ?.buildingControlsData,
              })
            );
          }

          // previous zone details
          if (resultZoneDetailApi?.payload?.data?.data?.previousZone) {
            store.dispatch(
              setCurrentFeaturePreviousZone({
                previousZone:
                  resultZoneDetailApi?.payload?.data?.data?.previousZone,
              })
            );
          }
        } else {
          const resultZoneDetailApi = await store.dispatch(
            fetchOverlayDetail({
              mapType: store.getState().blockbriefMap.currentActiveMap,
              state: store.getState().blockbriefMap.activeMapLayer,
              lat: store.getState().blockbriefMap.mapLocation.lat,
              lng: store.getState().blockbriefMap.mapLocation.lng,
            })
          );

          if (resultZoneDetailApi.data?.data?.pluData)
            store.dispatch(
              setCurrentFeatureZoneCode({
                zoneCode: resultZoneDetailApi.data.data.pluData.zone_code,
              })
            );
        }
      }, 1000);

      map.addControl(measure);
    }
  }, [mapState.mapLocation.placeID, map, baseMap.current]);

  useEffect(() => {
    if (
      map &&
      mapState.mapLocation.state &&
      mapState.currentActiveMap !== 'Planning Alerts'
    ) {
      inititalizeMapLayers(
        store.getState().blockbriefMap.mapLocation,
        store,
        tangramLayer,
        map,
        mapState.currentActiveMap
      );

      map.panInside([mapState.mapLocation.lat, mapState.mapLocation.lng]);
      // map.invalidateSize(false);

      if (tangramLayer && tangramLayer.scene) {
        // Set up click on the map to set the marker
        map.on('click', (e) => {
          const mapClickedLat = e.latlng.lat;
          const mapClickedLng = e.latlng.lng;
          store.dispatch(
            setMapLatLng({ lat: mapClickedLat, lng: mapClickedLng })
          );
        });
      }
      // }, 1000);
    }
  }, [map, mapState.mapLocation.city, tangramLayer]);

  // Set up event handlers - 'moveend' and 'zoomend' for the map - called only when the map ref changes
  useEffect(() => {
    if (map) {
      map.on('dragend', handleMapZoomAndMove(store, map));
      map.on('zoomend', handleMapZoomAndMove(store, map));
    }
  }, [map]);

  useEffect(() => {
    store.dispatch(
      setCurrentFeatureAddress({
        address: store.getState().blockbriefMap.currentSelectedFeature2,
      })
    );
  }, [mapState.mapLocation.placeID]);

  useEffect(() => {
    if (tangramLayer) {
      updateTangramConfig(
        tangramLayer,
        map,
        store,
        mapState.activeZoneFilterValues,
        mapState.activeMapLayer
      );
    }
  }, [mapState.activeZoneFilterValues, mapState.activeMapLayer]);

  useEffect(() => {
    if (
      map &&
      mapState.mapLocation.state &&
      mapState.currentActiveMap !== 'Planning Alerts'
    ) {
      inititalizeMapLayers(
        store.getState().blockbriefMap.mapLocation,
        store,
        tangramLayer,
        map,
        mapState.currentActiveMap
      );

      featureSelection(store, tangramLayer, false);
    }
  }, [mapState.currentActiveMap]);

  useEffect(() => {
    if (tangramLayer && tangramLayer.scene) {
      tangramLayer.scene.container.addEventListener(
        'click',
        ({ layerX, layerY }) => {
          tangramLayer.scene
            .getFeatureAt({ x: layerX, y: layerY })
            .then(async (feature) => {
              // if feature is true here - and the currentActive layer is zoning layer than dispatch the action to fetch the zoneDetails
              featureSelection(store, false);
              const address = await getAddress(
                store.getState().blockbriefMap.mapLocation.lat,
                store.getState().blockbriefMap.mapLocation.lng
              );
              store.dispatch(
                setCurrentFeatureAddress({
                  address,
                })
              );
              console.log('hererer', feature);
            });
        }
      );
    }
  }, [tangramLayer]);

  useEffect(() => {
    store.dispatch(setCurrentActiveMap('Zoning Map'));
  }, [mapState.mapLocation.placeID]);

  return (
    <>
      <div className="application-container__map-page map-page">
        <MapMenu
          map={map}
          tangramLayer={tangramLayer}
          setTangramLayer={setTangramLayer}
        />
        <div className="map-page__map-container map-container">
          <MapContainer
            zoom={0}
            center={[mapState.mapLocation.lat, mapState.mapLocation.lng]}
            className="leaflet-map"
            zoomControl={false}
            key={mapType} // Giving the Map Component a key to force update when the basemap changes
            wheelDebounceTime={10} // rate at which the wheelzoom will fire in milliseconds - default 40
            wheelPxPerZoomLevel={200} // To make the when zooming slower and faster - default is 60 - higher values indicate slower zoom and viceversa
            zoomAnimation
            markerZoomAnimation
            transform3DLimit={562144}
            easeLinearity={0.32}
            whenCreated={(mapInst) => {
              setMap(mapInst); // Sets the leaflet map ref
              // updates the location of the map to the current map location
              setLocation(
                mapState.mapLocation.placeID,
                baseMap.current.getContainer(),
                ({ payload }) =>
                  store.dispatch(
                    setAddress({
                      state: payload.state,
                      city: payload.city,
                      lat: payload.lat,
                      lng: payload.lng,
                      placeIDLatLng: {
                        lat: payload.lat,
                        lng: payload.lng,
                      },
                    })
                  ),
                map
              );
            }}
          >
            {mapType === 'roadmap' ? (
              <ReactLeafletGoogleLayer
                useGoogMapsLoader={false}
                crossOrigin
                ref={baseMap}
                type="roadmap"
                continuousWorld
              />
            ) : (
              <ReactLeafletGoogleLayer
                useGoogMapsLoader={false}
                crossOrigin
                ref={baseMap}
                type="satellite"
              />
            )}
            {/* Current Address Pin Marker */}
            <Marker
              position={[mapState.mapLocation.lat, mapState.mapLocation.lng]}
              icon={L.icon({
                iconUrl: LocationIcon,
                iconSize: [42, 66],
                iconAnchor: [21, 75],
                shadowSize: [68, 95],
              })}
            />
            {/* Planning Alert Markers */}
            <PlanningAlertsMarkers
              planningData={mapState.planningData.planningRecords}
              planningStatus={mapState.planningData.filterStatus}
            />
            {/* Zone Cases Markers  */}
            <ZoningMarkers
              zoningData={mapState.zoningData.zoningRecords}
              zoningStatus={mapState.zoningData.filterStatus}
            />
            {/* On Market Sites - Residenital Markers */}
            <ResidentialMarkers
              residentialStatus={
                mapState.listingSitesData.filters.filterForm.listingType
                  .residential
              }
              sitesData={mapState.listingSitesData.sitesRecords.residential}
              onMarketStatus={mapState.listingSitesData.filterStatus}
            />
            {/* On Market Sites - Commercial Markers */}
            <CommercialMarkers
              commercialStatus={
                mapState.listingSitesData.filters.filterForm.listingType
                  .commercial
              }
              sitesData={mapState.listingSitesData.sitesRecords.commercial}
              onMarketStatus={mapState.listingSitesData.filterStatus}
            />
          </MapContainer>
          {/* Positioned Map Items -  */}
          <ZoomControls map={map} />
          <MapControls
            setMapType={setMapType}
            mapType={mapType}
            map={map}
            tangramLayer={tangramLayer}
          />
          <MapLegend />
          <MapPermitTray />
          <ZoneFeatures />
          <ParcelDownload map={map} />
        </div>
      </div>
    </>
  );
}
