import axios from 'axios';

const getAddress = async (lat, lng) => {
  const googleMapAPIKeyWebService = 'AIzaSyAVLV4uAMtBWYX5QCbAhiFPZeQ37R6zyxw';
  const response = await axios({
    method: 'GET',
    url: `https://maps.googleapis.com/maps/api/geocode/json?key=${googleMapAPIKeyWebService}&latlng=${lat},${lng}`,
  });
  const address = response.data.results[0].formatted_address;
  return address;
};

export default getAddress;
