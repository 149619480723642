import React from 'react';
import { useDispatch } from 'react-redux';
import './ApplicationModal.css';
import ApplicationModalTable from '../ApplicationModalTable';
import cancelButton from '../../assets/images/cancel.png';
import { hideModal } from '../../reducers/ApplicationModalSlice';

export default function ApplicationModal({ modalType, active, modalData }) {
  const dispatch = useDispatch();
  const handleModalClose = () => {
    dispatch(hideModal());
    document.body.classList.toggle('no-scroll');
  };
  return (
    <div
      className={
        active
          ? 'application-container__application-modal application-modal'
          : 'application-container__application-modal application-modal application-container__application-modal--hidden'
      }
    >
      <div className="application-conatiner__modal-body modal-body">
        <div className="modal-body__heading">
          <h3>{modalType} Detail</h3>
          <button
            type="button"
            className="modal-heading__img"
            onClick={handleModalClose}
          >
            <img src={cancelButton} alt="cancel button" />
          </button>
        </div>
        <div className="table-wrapper">
          <ApplicationModalTable modalData={modalData} />
        </div>
      </div>
    </div>
  );
}
