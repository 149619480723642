import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import L from 'leaflet';
import { FeatureGroup, Marker } from 'react-leaflet';
import RedIcon from '../../assets/images/listings_red.png';
import { displayModal } from '../../reducers/ApplicationModalSlice';

export default function CommercialMarkers({
  commercialStatus,
  sitesData,
  onMarketStatus,
}) {
  const dispatch = useDispatch();
  // const propertyType = useSelector(
  //   (state) => state.blockbriefMap.listingSitesData.selectedPropertyType
  // );
  const commercial = useSelector(
    (state) =>
      state.blockbriefMap.listingSitesData.filters.filterForm.listingType
        .commercial
  );
  const saleType = useSelector(
    (state) => state.blockbriefMap.listingSitesData.saleType
  );
  const leaseType = useSelector(
    (state) => state.blockbriefMap.listingSitesData.leaseType
  );

  if (commercialStatus && sitesData.length && onMarketStatus)
    return (
      <FeatureGroup>
        {sitesData.map(({ _source: data }) =>
          // eslint-disable-next-line no-nested-ternary
          commercial &&
          data.transaction_type === 'SALE' &&
          data.transaction_type === 'Lease' &&
          saleType &&
          leaseType ? (
            <Marker
              position={[
                Number(data.pin.location.lat),
                Number(data.pin.location.lon),
              ]}
              key={nanoid()}
              riseOnHover
              eventHandlers={{
                click: () => {
                  dispatch(
                    displayModal({
                      modalType: 'On Market Site',
                      modalData: data,
                    })
                  );
                  document.body.classList.toggle('no-scroll');
                },
              }}
              icon={
                new L.Icon({
                  iconUrl: RedIcon,
                  iconSize: [32, 37],
                  iconAnchor: [16, 40],
                })
              }
            />
          ) : // eslint-disable-next-line no-nested-ternary
          commercial && data.transaction_type === 'LEASE' && leaseType ? (
            <Marker
              position={[
                Number(data.pin.location.lat),
                Number(data.pin.location.lon),
              ]}
              key={nanoid()}
              riseOnHover
              eventHandlers={{
                click: () => {
                  dispatch(
                    displayModal({
                      modalType: 'On Market Site',
                      modalData: data,
                    })
                  );
                  document.body.classList.toggle('no-scroll');
                },
              }}
              icon={
                new L.Icon({
                  iconUrl: RedIcon,
                  iconSize: [32, 37],
                  iconAnchor: [16, 40],
                })
              }
            />
          ) : // eslint-disable-next-line no-nested-ternary
          commercial && data.transaction_type === 'SALE' && saleType ? (
            <Marker
              position={[
                Number(data.pin.location.lat),
                Number(data.pin.location.lon),
              ]}
              key={nanoid()}
              riseOnHover
              eventHandlers={{
                click: () => {
                  dispatch(
                    displayModal({
                      modalType: 'On Market Site',
                      modalData: data,
                    })
                  );
                  document.body.classList.toggle('no-scroll');
                },
              }}
              icon={
                new L.Icon({
                  iconUrl: RedIcon,
                  iconSize: [32, 37],
                  iconAnchor: [16, 40],
                })
              }
            />
          ) : commercial &&
            saleType &&
            leaseType &&
            data.transaction_type !== 'SALE' &&
            data.transaction_type !== 'Lease' ? (
            <Marker
              position={[
                Number(data.pin.location.lat),
                Number(data.pin.location.lon),
              ]}
              key={nanoid()}
              riseOnHover
              eventHandlers={{
                click: () => {
                  dispatch(
                    displayModal({
                      modalType: 'On Market Site',
                      modalData: data,
                    })
                  );
                  document.body.classList.toggle('no-scroll');
                },
              }}
              icon={
                new L.Icon({
                  iconUrl: RedIcon,
                  iconSize: [32, 37],
                  iconAnchor: [16, 40],
                })
              }
            />
          ) : null
        )}
      </FeatureGroup>
    );
  return null;
}
