import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { createPaginationState } from '../../utils/paginationUtils';

const updatePagination = createAction(
  'zoningChanges/pagination/updatePagination'
);

// fetchFilterValues: fetches the filter values - state, city, date
async function fetchFilterValues(payload, thunkAPI) {
  try {
    const filterArr = ['status', 'city', 'state', 'suburb'];
    const promiseArr = filterArr.map((filter) =>
      axios({
        url: 'https://zoningdataus.info/au_zoning_data/_search',
        method: 'POST',
        headers: {
          Authorization:
            'Basic em9uaW5nX2FwcF91c2VyOlpXeGhjM1JwWXpwdmIxVkpNMHh1V25oa2JuQmxXWEJ5Y0VKQllR',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          size: 0,
          aggs: { langs: { terms: { field: `${filter}.keyword`, size: 500 } } },
        }),
      })
    );
    const resolvedArr = await Promise.all(promiseArr);
    return {
      status: resolvedArr[0].data.aggregations.langs.buckets,
      city: resolvedArr[1].data.aggregations.langs.buckets,
      state: resolvedArr[2].data.aggregations.langs.buckets,
      suburb: resolvedArr[3].data.aggregations.langs.buckets,
    };
  } catch ({ message, data, status }) {
    throw thunkAPI.rejectWithValue({
      message,
      data,
      status,
    });
  }
}

const fetchFilters = createAsyncThunk(
  'zoningChagnes/fetchFilterValues',
  fetchFilterValues
);

// fetchZoningRecords: fetches the zoning records
async function fetchZoningChangesDocs(payload, thunkAPI) {
  const zoningChangesState = thunkAPI.getState().zoningChanges;
  const currentActiveZoning = zoningChangesState.pagination.currentActive;
  const queryObject = zoningChangesState.query;
  const querySize = zoningChangesState.size;
  const zoningFilters = zoningChangesState.filterSelectedValues;
  const queryFilterArr = []; // keeps track of the filters added to the query prop of API request
  const dateRangeFilters = []; // keep tracks of the filters added to the filter prop of API request
  const mustQueryBlock = [...queryObject.bool.must];
  Object.keys(zoningFilters)
    .slice(0, 3) // slice to get the first three props City, Status, State ..
    .forEach((prop) =>
      zoningFilters[prop]
        ? queryFilterArr.push({
            match: {
              [`${prop}.keyword`]: `${zoningFilters[prop]}`,
            },
          })
        : null
    );
  if (zoningFilters.suburb) {
    mustQueryBlock.push({
      terms: { 'suburb.keyword': [`${zoningFilters.suburb}`] },
    });
  }
  if (zoningFilters.searchStr) {
    queryFilterArr.push({ query_string: { query: zoningFilters.searchStr } });
  }
  Object.keys(zoningFilters)
    .slice(5, 6) // slice to get the last property - date - startDate and endDate
    .forEach((prop) =>
      zoningFilters[prop].startDate && zoningFilters[prop].endDate
        ? dateRangeFilters.push({
            range: {
              date: {
                gte: zoningFilters[prop].startDate,
                lte: zoningFilters[prop].endDate,
              },
            },
          })
        : null
    );

  const zoningRecords = await axios({
    url: 'https://zoningdataus.info/au_zoning_data/_search',
    method: 'POST',
    headers: {
      Authorization:
        'Basic em9uaW5nX2FwcF91c2VyOlpXeGhjM1JwWXpwdmIxVkpNMHh1V25oa2JuQmxXWEJ5Y0VKQllR',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      from: currentActiveZoning * 10 - 10,
      size: querySize,
      query: {
        ...queryObject,
        bool: {
          ...queryObject.bool,
          must: [...mustQueryBlock, ...queryFilterArr],
          filter: dateRangeFilters.length
            ? [...dateRangeFilters]
            : { bool: {} },
        },
      },
    }),
  })
    .then(({ data }) => {
      const { paginationFetched } =
        thunkAPI.getState().zoningChanges.pagination;
      const filters = thunkAPI.getState().zoningChanges.filters.loaded;
      const totalValues = data.hits.total.value;

      if (!paginationFetched)
        thunkAPI.dispatch(
          updatePagination(createPaginationState({ totalValues }))
        );

      if (!filters) thunkAPI.dispatch(fetchFilters());

      return { responseData: data };
    })
    .catch(({ message, status, data }) => {
      // if the responseCode is not in the 200 range the promise is rejected
      throw thunkAPI.rejectWithValue({
        message,
        data,
        status,
      });
    });
  return zoningRecords;
}

const fetchZoningRecords = createAsyncThunk(
  'zoningChanges/fetchZoningChanges',
  fetchZoningChangesDocs
);

export { updatePagination, fetchFilters };
export default fetchZoningRecords;
