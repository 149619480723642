import React from 'react';
import PublicHeader from '../../components/PublicHeader';
import PublicFooter from '../../components/PublicFooter';
import ProductsIllustrationOne from '../../assets/images/Astronaut-one.svg';
import ProductsIllustrationSecond from '../../assets/images/Astronaut-two.svg';
import './Products.css';
import TickIcon from '../../assets/images/check-mark.svg';
import CrossIcon from '../../assets/images/cross.svg';

export default function Products() {
  return (
    <div className="products">
      <PublicHeader />
      <div className="products__product-content product-content">
        <h3 className="product-content__heading">Plans and Pricing</h3>
        <h5 className="product-content__sub-heading">
          Choose the right plan for your Zoning Search needs.{' '}
        </h5>
        <p className="product-content__heading-text">
          Choose the right Membership Plan for Personal Use or Team. Our product
          plans will serve all your Zoning Research Workflows.
        </p>

        <div className="products__product-cards product-cards">
          <div className="product-cards__product-item product-item">
            <h2 className="product-item__heading">Premier</h2>
            <img src={ProductsIllustrationOne} alt="Permier Illustration" />
            <ul className="product-item__featureList">
              <li>
                <img src={TickIcon} alt="Tick" />1 Active User
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                View all Active Listings
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Development Sites Database
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Zoning Alert Database
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Zone Guide
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Zoning Search
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Access to Blockbrief Reports
              </li>
              <li>
                <img src={CrossIcon} alt="Cross" />
                Access to Historical Zoning Data
              </li>
              <li>
                <img src={CrossIcon} alt="Cross" />
                LGA Review Request
              </li>
            </ul>
            <a
              href="https://www.corelogic.com.au/products/blockbrief"
              target="_blank"
              rel="noreferrer"
              className="product-item__buy"
            >
              Contact Us
            </a>
          </div>
          <div className="product-cards__product-item product-item">
            <h2 className="product-item__heading">Premier Pro</h2>
            <img src={ProductsIllustrationSecond} alt="Permier Illustration" />
            <ul className="product-item__featureList">
              <li>
                <img src={TickIcon} alt="Tick" />4 Active User
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                View all Active Listings
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Development Sites Database
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Zoning Alert Database
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Zone Guide
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Zoning Search
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Access to Blockbrief Reports
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                Access to Historical Zoning Data
              </li>
              <li>
                <img src={TickIcon} alt="Tick" />
                LGA Review Request
              </li>
            </ul>
            <a
              href="https://www.corelogic.com.au/products/blockbrief"
              target="_blank"
              rel="noreferrer"
              className="product-item__buy"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <PublicFooter />
    </div>
  );
}
