import React from 'react';
import './DataTable.css';

export default function DataTable() {
  return (
    <table className="zoning-chagnes__data-table  data-table" cellSpacing="0px">
      <thead className="data-table__head data-table -head">
        <tr className="data-table-head__row">
          <th>No</th>
          <th>File Name</th>
          <th>File Type</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody className="zoning-changes__body data-table -body">
        {[
          {
            fileName: 'National Zoning Australia 12032024',
            fileType: 'zip',
            url: 'https://zoneomics-download-data-external-files.s3.us-east-2.amazonaws.com/National Zoning Australia 12032024.zip',
          },
          {
            fileName: 'National Zoning Australia 23052024',
            fileType: 'zip',
            url: 'https://zoneomics-download-data-external-files.s3.us-east-2.amazonaws.com/National+Zoning+Australia+23052024.zip',
          },
        ].map((item, index) => (
          <tr className="data-table -body__row zoning-row">
            <td>{index + 1}</td>
            <td>{item?.fileName}</td>
            <td>{item?.fileType}</td>
            <td>
              <a
                target="_blank"
                href={item?.url}
                download={encodeURIComponent(item.fileName)}
                rel="noreferrer"
              >
                Download
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
