import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './OnMarketFilterTray.css';
import Spinner from '../../assets/svg/Spinner';
import {
  setTypeLease,
  setTypeSale,
  toggleSitesDataToggle,
} from '../../reducers/blockbriefMapSlice';
// import FilterMenu from '../FilterMenu';
// import FilterItem from '../FilterItem/index';

const getTotalValues = ({ listingSitesData }) => {
  const commercialStatus =
    listingSitesData.filters.filterForm.listingType.commercial;
  const residentialStatus =
    listingSitesData.filters.filterForm.listingType.residential;
  const totalCommercial = listingSitesData.sitesRecords.commercial;
  const totalResidential = listingSitesData.sitesRecords.residential;

  if (commercialStatus && residentialStatus) {
    return totalCommercial.length + totalResidential.length;
  }
  if (commercialStatus) {
    return totalCommercial.length;
  }
  if (residentialStatus) {
    return totalResidential.length;
  }

  return 0;
};

export default function OnMarketFilterTray({ filterStatus }) {
  // const [propertyTypeFilter, setPropertyTypeFilter] = useState([]);
  // useEffect(async () => {
  //   await axios({
  //     url: 'https://zoningdataus.info/listing_data_au/_search',
  //     method: 'POST',
  //     headers: {
  //       Authorization:
  //         'Basic em9uaW5nX2FwcF91c2VyOlpXeGhjM1JwWXpwdmIxVkpNMHh1V25oa2JuQmxXWEJ5Y0VKQllR',
  //       'Content-Type': 'application/json',
  //     },
  //     data: JSON.stringify({
  //       size: 0,
  //       aggs: {
  //         langs: { terms: { field: 'property_type.keyword', size: 500 } },
  //       },
  //     }),
  //   }).then(({ data }) => {
  //     console.log(data.aggregations.langs.buckets);
  //     setPropertyTypeFilter(data.aggregations.langs.buckets);
  //   });
  // }, []);

  const saleType = useSelector(
    (state) => state.blockbriefMap.listingSitesData.saleType
  );
  const leaseType = useSelector(
    (state) => state.blockbriefMap.listingSitesData.leaseType
  );
  // const [menuFilter, setMenuFilter] = useState({
  //   property_type,
  // });
  const mapState = useSelector((state) => state.blockbriefMap);
  const dispatch = useDispatch();

  // Filter Menu: Reset Handler - to reset the filters form
  // const resetHandler = (e) => {
  //   console.log(e);
  //   setMenuFilter({ property_type: '' });
  //   dispatch(setPropertyType(''));
  // };

  return (
    <div
      className="onmarket-filter__onmarket-tray onmarket-tray"
      style={filterStatus ? { display: 'flex' } : { display: 'none' }}
    >
      <div className="onmarket-tray__heading-row heading-row">
        <h3 className="heading-row__heading">On Market Filter</h3>
        <span className="heading-row__total-records">
          Total Records -{' '}
          {mapState.listingSitesData.sitesLoading ? (
            <Spinner
              spinnerColor="#25b7d3"
              spinnerSize={{ width: '1.8em', height: '1.8em' }}
            />
          ) : (
            getTotalValues(mapState)
          )}
        </span>
      </div>
      <div className="onmarket-filter__data-toggle data-toggle">
        <h3 className="data-toggle__heading">Listing Type</h3>
        <div className="data-toggle__wrapper">
          <label htmlFor="residential">
            <input
              type="checkbox"
              id="residential"
              name="residential"
              checked={
                mapState.listingSitesData.filters.filterForm.listingType
                  .residential
              }
              onClick={() =>
                dispatch(toggleSitesDataToggle({ toggleType: 'Residential' }))
              }
            />
            Residential
          </label>
          <label htmlFor="commercial">
            <input
              type="checkbox"
              id="commercial"
              name="commercial"
              checked={
                mapState.listingSitesData.filters.filterForm.listingType
                  .commercial
              }
              onClick={() =>
                dispatch(toggleSitesDataToggle({ toggleType: 'Commercial' }))
              }
            />
            Commercial
          </label>
        </div>
        <hr
          style={{ width: '50%', size: '3', color: 'black', marginTop: '15px' }}
        />
        <div className="onmarket-filter__data-toggle data-toggle">
          <h3 className="data-toggle__heading">Transaction Type</h3>
          <div className="data-toggle__wrapper">
            <label htmlFor="sale">
              <input
                type="checkbox"
                id="sale"
                name="sale"
                checked={mapState.listingSitesData.saleType}
                onClick={() => dispatch(setTypeSale(!saleType))}
              />
              Sale
            </label>

            <label htmlFor="lease">
              <input
                type="checkbox"
                id="lease"
                name="lease"
                checked={mapState.listingSitesData.leaseType}
                onClick={() => dispatch(setTypeLease(!leaseType))}
              />
              Lease
            </label>
            {/* <FilterItem
            filterName="property type"
            placeholderStr="Select a property"
            filterType="Dropdown"
            selectedValue={menuFilter.property_type}
            selectionFunction={(e) => {
              const selectedValue = e.target.value.trim();
              dispatch(setPropertyType(selectedValue));
              setMenuFilter((filterMenu) => ({
                ...filterMenu,
                property_type: selectedValue,
              }));
            }}
            filterValues={propertyTypeFilter}
          />
          <button
            type="button"
            className="onMarket__reset-button"
            onClick={(e) => {
              resetHandler(e);
            }}
          >
            Reset
          </button> */}

            {/* <FilterMenu
            resetBtnHide="hide"
            filters={[
              {
                filterName: 'Propety Type',
                filterType: 'Dropdown',
                filterPlaceholder: 'Select Property Type',
                selectedValue: menuFilter.property_type,
                filterValues: propertyTypeFilter,
                handleFilterValueChange: (e) => {
                  const selectedValue = e.target.innerText.trim();
                  dispatch(setPropertyType(selectedValue));
                  setMenuFilter((filterMenu) => ({
                    ...filterMenu,
                    property_type: selectedValue,
                  }));
                },
              },
            ]}
            resetHandler={resetHandler}
          /> */}
          </div>
        </div>
        {/* <button
          type="button"
          onClick={() => {
            dispatch(toggleSitesDataToggle({ toggleType: 'Residential' }));
          }}
          className={
            mapState.listingSitesData.filters.filterForm.listingType.residential
              ? 'data-toggle__btn data-toggle__btn--brown'
              : 'data-toggle__btn'
          }
        >
          Residential
        </button>
        <button
          type="button"
          onClick={() => {
            dispatch(toggleSitesDataToggle({ toggleType: 'Commercial' }));
          }}
          className={
            mapState.listingSitesData.filters.filterForm.listingType.commercial
              ? 'data-toggle__btn data-toggle__btn--red'
              : 'data-toggle__btn'
          }
        >
          Commercial
        </button> */}
      </div>
    </div>
  );
}
