import { createSlice } from '@reduxjs/toolkit';
import authenticateAdmin from './adminSigninThunk';

const initialState = {
  firstName: '',
  lastName: '',
  loggedIn: false,
  status: 'idle',
};

const signinSlice = createSlice({
  name: 'adminSignin',
  initialState,
  reducers: {
    resetAdmin(state) {
      state.firstName = '';
      state.lastName = '';
      state.loggedIn = false;
      state.status = 'idle';
    },
  },
  extraReducers: {
    [authenticateAdmin.fulfilled]: (state, action) => {
      state.firstName = action.payload.firstname;
      state.lastName = action.payload.lastname;
      state.status = 'fullfilled';
      state.loggedIn = true;
    },
    [authenticateAdmin.rejected]: (state, action) => {
      state.status = action.error.message;
      state.loggedIn = false;
    },
    [authenticateAdmin.pending]: (state) => {
      state.status = 'pending';
    },
  },
});

export const { resetAdmin } = signinSlice.actions;
export default signinSlice.reducer;
