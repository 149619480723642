import React from 'react';

import './Signin.css';
import SigninForm from '../../components/SigninForm';
import SigninBackgroundImage from '../../assets/images/login-bg.png';

export default function Signin() {
  return (
    <div className="signin-container">
      <SigninForm />
      <div className="signin-container__background-image-container">
        <img src={SigninBackgroundImage} alt="Background for the login page" />
      </div>
    </div>
  );
}
