import React, { useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from '../../Apis/axios';
import LoadingIndicator from '../../assets/svg/Loading-indicator';
import { resetUser } from '../../reducers/signinSlice';
import './changePassword.css';

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);
  const history = useHistory();
  const store = useStore();
  const updatePassword = async () => {
    if (currentPassword && newPassword && !updateLoading) {
      setUpdateLoading(!updateLoading);
      const requestURL = '/change-password';
      const response = await axios.post(
        requestURL,
        {
          oldPassword: currentPassword,
          newPassword,
        },
        { isAuth: true }
      );

      if (response.status === 200) {
        store.dispatch(resetUser());
        history.push('/signin');
      } else {
        setUpdateLoading(!updateLoading);
      }
    }
  };

  return (
    <div className="application-container__change-password change-password">
      <h1 className="change-password__heading">Change your Password</h1>
      <div className="change-password__change-form">
        <div className="change-form__item">
          <h5>Current Password</h5>
          <input
            type="password"
            name="old-password"
            id="old-password"
            placeholder="Enter current password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className="change-form__item">
          <h5>New Password</h5>
          <input
            type="password"
            name="new-password"
            id="new-password"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <button
          type="button"
          className="update-password-btn"
          onClick={updatePassword}
        >
          {updateLoading ? <LoadingIndicator /> : 'Change Password'}
        </button>
      </div>
    </div>
  );
}
