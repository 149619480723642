import { useReducer } from 'react';

const suggestionReducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'toggleSuggestion':
      return { ...state, toggleSuggestion: !state.toggleSuggestion };
    case 'selectSuggestion':
      return { ...state, selectedSuggestion: payload };
    case 'updateSuggestion':
      return { ...state, suggestions: payload };
    default:
      return state;
  }
};

/**
 * This hook handles the useSuggestion state when the suggestion tray is used -
 * It keeps track of three values -
 * - toggleSuggestion: if the suggestion tray is open or not
 * - selectedSuggestion: The suggestion which is currently active
 * - suggestions: List of suggestions in the suggestion tray
 *  @param {object} initialState - the initialState Object passes the following information -
 *  toggleSuggestion,
 *  selectedSuggestion,
 *  suggestions
 *
 * @returns [state, dispatch]
 */
export default function useSuggestions({
  toggleSuggestion = false,
  selectedSuggestion = null,
  suggestions = [],
}) {
  const [state, dispatch] = useReducer(suggestionReducer, {
    toggleSuggestion,
    selectedSuggestion,
    suggestions,
  });
  return [state, dispatch];
}
