import React from 'react';
import { Link } from 'react-router-dom';
import './PublicFooter.css';
import InstagramIcon from '../../assets/images/instagram.svg';
import TwitterIcon from '../../assets/images/twitter.svg';
import FacebookIcon from '../../assets/images/facebook.svg';
import CoreLogicLogo from '../../assets/images/banner_corelogic.png';

export default function PublicFooter() {
  return (
    <div className="main-grid__footer footer-component">
      <div className="footer-component__copyright-social copyright-social">
        <div className="copyright-social__copy copyright">
          <img
            src={CoreLogicLogo}
            alt="corelogic logo"
            className="copyright__img"
          />
          <h3 className="copyright__heading">© 2021 - Blockbrief</h3>
        </div>
        <div className="copyright-social__social">
          <img src={InstagramIcon} alt="instagram logo" />
          <img src={FacebookIcon} alt="facebook logo" />
          <img src={TwitterIcon} alt="twitter logo" />
        </div>
      </div>
      <div className="footer-component__account link-column">
        <h2 className="link-column__heading">Account</h2>
        <Link className="link-column__link" to="/signin">
          Login
        </Link>
        <a
          href="https://www.corelogic.com.au/products/blockbrief"
          className="link-column__link"
          target="_blank"
          rel="noreferrer"
        >
          Register
        </a>
      </div>
      <div className="footer-component__products link-column">
        <h3 className="link-column__heading">Products</h3>
        <Link to="/products" className="link-column__link">
          Blockbrief Premier
        </Link>
        <Link to="/products" className="link-column__link">
          Blockbrief Leads
        </Link>
      </div>
      <div className="footer-component__webinars link-column">
        <h3 className="link-column__heading">Webinars</h3>
        <Link to="/webinars" className="link-column__link">
          Introduction to Blockbrief
        </Link>
        <Link to="/webinars" className="link-column__link">
          How to Search Blockbrief Mapsearch
        </Link>
        <Link to="/webinars" className="link-column__link">
          Search Sites Explained
        </Link>
      </div>
      <div className="footer-component__misc-links misc-link-row">
        <a href="#" className="misc-link-row__link">
          About us
        </a>
        <a href="#" className="misc-link-row__link">
          Term Of Use
        </a>
        <a href="#" className="misc-link-row__link">
          Contact Us
        </a>
      </div>
    </div>
  );
}
