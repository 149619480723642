import React from 'react';
import ls from 'localstorage-slim';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../containers/Dashboard';
import ZoningChanges from '../containers/ZoningChanges';
import Application from '../containers/Application';
import DevelopmentSites from '../containers/DevelopmentSites';
import BlockbriefMap from '../containers/BlockbriefMap';
import Subscribe from '../containers/Subscribe';
import APIDocumentation from '../containers/APIDocumentation';
import ChangePassword from '../containers/ChangePassword';
import Marketing from '../containers/Marketing';

import {
  setUser,
  setLoggedIn,
  setLoggedOut,
  resetUser,
  setSessionMsg,
} from '../reducers/signinSlice';

export default function PrivateRoutes() {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const isMarketing = useSelector((state) => state.user.IsMarketing);

  const dispatch = useDispatch();
  const item = ls.get('user', { decrypt: true });
  if (!loggedIn && item?.data) {
    dispatch(setUser(item.data));
    dispatch(setLoggedIn());
  } else if (loggedIn && !item?.data) {
    dispatch(setSessionMsg());
    dispatch(setLoggedOut());
    dispatch(resetUser());
  } else if (!loggedIn && !item?.data) {
    ls.remove('user');
    dispatch(setLoggedOut());
    dispatch(resetUser());
  }
  return (
    <Application>
      <Switch>
        <Route
          exact
          strict
          path="/marketing"
          render={() =>
            loggedIn && isMarketing ? (
              <Marketing />
            ) : (
              <Redirect
                to={{
                  pathname: '/signin',
                }}
              />
            )
          }
        />
        <Route
          exact
          strict
          path="/zoning-search"
          render={() =>
            loggedIn ? (
              <Dashboard />
            ) : (
              <Redirect
                to={{
                  pathname: '/signin',
                }}
              />
            )
          }
        />
        <Route
          exact
          strict
          path="/zoning-search/map-search"
          render={({ location }) =>
            loggedIn &&
            location.state /* only let the user access this route when he's searched the term on the dashboard */ ? (
              <BlockbriefMap />
            ) : (
              <Redirect
                to={{
                  pathname: '/dashboard',
                }}
              />
            )
          }
        />
        <Route
          exact
          strict
          path="/zoning-search"
          render={() =>
            loggedIn ? (
              <Dashboard />
            ) : (
              <Redirect
                to={{
                  pathname: '/signin',
                }}
              />
            )
          }
        />
        <Route
          exact
          strict
          path="/zoning-changes"
          render={() =>
            loggedIn ? (
              <ZoningChanges />
            ) : (
              <Redirect
                to={{
                  pathname: '/signin',
                }}
              />
            )
          }
        />
        <Route
          exact
          strict
          path="/development-sites"
          render={() =>
            loggedIn ? (
              <DevelopmentSites />
            ) : (
              <Redirect
                to={{
                  pathname: '/signin',
                }}
              />
            )
          }
        />
        <Route
          path="/subscribe"
          exact
          strict
          render={() =>
            loggedIn ? (
              <Subscribe />
            ) : (
              <Redirect
                to={{
                  pathname: '/signin',
                }}
              />
            )
          }
        />
        <Route
          path="/change-password"
          exact
          strict
          render={() =>
            loggedIn ? (
              <ChangePassword />
            ) : (
              <Redirect
                to={{
                  pathname: '/signin',
                }}
              />
            )
          }
        />
        <Route
          path="/api"
          render={() =>
            loggedIn ? (
              <APIDocumentation />
            ) : (
              <Redirect
                to={{
                  pathname: '/signin',
                }}
              />
            )
          }
        />
        <Route path="*">
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        </Route>
      </Switch>
    </Application>
  );
}
