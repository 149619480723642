import React from 'react';
import ls from 'localstorage-slim';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import APICredentials from '../../assets/images/api_credentails.svg';
import APIDocumentation from '../../assets/images/api.svg';
import ChangePassword from '../../assets/images/change_password.svg';
import Signout from '../../assets/images/logout.svg';
import Webinar from '../../assets/images/webinar-icon.svg';
import './ExtendedNav.css';
import { resetUser } from '../../reducers/signinSlice';
import axios from '../../Apis/axios';

export default function ExtendedNav({
  extendedMenuTray,
  toggleExtendedMenuTray,
}) {
  const isMarketing = useSelector((state) => state.user.IsMarketing);
  const dispatch = useDispatch();
  const history = useHistory();
  if (extendedMenuTray)
    return (
      <div className="user-section__map-menu-ext map-menu-ext">
        <Link
          to="/api/api-credentials"
          className="map-menu-ext__item"
          onClick={() => toggleExtendedMenuTray(!extendedMenuTray)}
        >
          <img src={APICredentials} alt="API Credentials" />
          API Credentials
        </Link>
        <Link
          to="/api/docs/zoning"
          className="map-menu-ext__item"
          onClick={() => toggleExtendedMenuTray(!extendedMenuTray)}
        >
          <img src={APIDocumentation} alt="API Documentation" />
          API Documentation
        </Link>
        <Link className="map-menu-ext__item" to="/change-password">
          <img src={ChangePassword} alt="Change Password" />
          Change Password
        </Link>
        <Link className="map-menu-ext__item" to="/webinars">
          <img src={Webinar} alt="Webinar" />
          Webinar
        </Link>
        {isMarketing ? (
          <Link className="map-menu-ext__item" to="/marketing">
            <img src={Webinar} alt="Marketing" />
            Marketing
          </Link>
        ) : null}

        <Link
          className="map-menu-ext__item"
          onClick={async () => {
            const requestURL = `/signout`;
            const response = await axios.post(requestURL, {}, { isAuth: true });
            if (response.status === 200) {
              ls.remove('user');

              dispatch(resetUser());
              history.push('/');
            }
          }}
        >
          <img src={Signout} alt="Sign Out" />
          Sign out
        </Link>
      </div>
    );
  return null;
}
