import React from 'react';
import './APITermsOfService.css';

export default function APITermsOfService() {
  return (
    <div className="API-tos-container">
      <h1>Blockbrief API Terms of Service</h1>
      <p>
        <i>Effective 5 March, 2021</i>
      </p>
      <div className="API-tos-container__content">
        <p>
          Thank you for using the Blockbrief API the following terms and
          conditions outline usage requirements and terms for use for the
          Blockbrief API. By using the Blockbrief API (the “API”) you and the
          organization listed in your Request for Service (“You”) accept and
          agree to be bound by the following terms and conditions (the “Terms of
          Service”)
        </p>
        <ol>
          <li>Blockbrief API</li>
          <ol>
            <li>Applying to Become an Approved Licensee</li>
            <ol>
              <li>
                To apply to become an approved licensee, please submit a Request
                for access to the Blockbrief API (“Request”). You hereby
                represent and warrant to Blockbrief that all information
                included in your Request is true, complete and accurate.
                Furthermore, You are responsible for submitting a Request for,
                and operating under accordance with, the Usage Plans and
                associated Terms of Service
              </li>
            </ol>
            <li>API Service</li>
            <ol>
              <li>
                Blockbrief reserves the right to release updated versions of the
                API and Blockbrief Content at any time and to require You to
                obtain and use the most recent version. Blockbrief may modify
                the Terms of Service at any time with or without notice, and You
                can review the most current version of the Terms of Service
                online at any time at
                https://blockbrief.com/api/terms-of-service (or any other URL as
                Blockbrief may decide from time to time). If a modification is
                unacceptable to You, You may cancel the Service by stopping your
                use of the Service and terminating Service. If You continue to
                use the Service, You will be deemed to have accepted the
                modifications.
              </li>
            </ol>
            <li>Modifications</li>
            <ol>
              <li>
                Blockbrief reserves the right to release updated versions of the
                API and Blockbrief Content at any time and to require You to
                obtain and use the most recent version. Blockbrief may modify
                the Terms of Service at any time with or without notice, and You
                can review the most current version of the Terms of Service
                online at any time at{' '}
                <a href="https://www.blockbrief.com/api/terms-of-service">
                  https://www.blockbrief.com/api/terms-of-service
                </a>{' '}
                (or any other URL as Blockbrief may decide from time to time).
                If a modification is unacceptable to You, You may cancel the
                Service by stopping your use of the Service and terminating
                Service. If You continue to use the Service, You will be deemed
                to have accepted the modifications.
              </li>
            </ol>
            <li>License Blockbrief API Service</li>
            <ol>
              <li>
                Effective upon Blockbrief’ acceptance of You as an approved
                licensee pursuant to your Request, and subject to the terms of
                these Terms of Service, Blockbrief will grant You a
                non-transferable, non-sublicensable, non-exclusive license
                during Your period of API access to: (a) access the API under
                Your Plan, (b) implement the API on Your public-facing
                Website(s) or Mobile Application specified in your Request
                (“Website(s) or Mobile Application”), and (c) display Blockbrief
                API Service and Blockbrief Content on Your Website(s) or Mobile
                Application for consumers to access such data for their use.
              </li>
            </ol>
            <li>Acceptable Use; Restrictions.</li>
            <ol>
              <li>
                You agree not to use the API, Blockbrief API Service, Blockbrief
                Content or Blockbrief Brands in any way that is unlawful, or
                that harms Blockbrief, its service providers, its suppliers,
                Your users, or any other person or entity. Blockbrief may
                terminate Your use of the API, Blockbrief API Service,
                Blockbrief Content or Blockbrief Brands at any time, including
                if and when Blockbrief determines (at its sole determination)
                Your use is inappropriate. The licenses granted to You herein
                are subject to these Terms of Service, including without
                limitation the following restrictions:
              </li>
              <ol>
                <li>
                  All calls to the API must be made with Your unique API Key.
                </li>
                <li>
                  You agree to keep Your API Key confidential, and to not share
                  it with any third party, including without limitation
                  subsidiaries, affiliates and partner organizations. Any third
                  party must submit its own Request and obtain its own API Key.
                </li>
                <li>
                  You may not use the Blockbrief API Service or Blockbrief
                  Content to develop a stand-alone application that
                  substantially re-creates the functionality offered by
                  www.blockbrief.com, or for the purpose of competing directly
                  with www.blockbrief.com.
                </li>
                <li>
                  You must clearly and conspicuously display the Blockbrief
                  Brand on any Website(s) or Mobile Application page that
                  displays or uses the API or Blockbrief API Service. Your
                  Website(s) or Mobile Application pages that contain Blockbrief
                  Content must cite Blockbrief as the source and link to{' '}
                  <a href="https://blockbrief.com/">https://blockbrief.com/</a>.
                  Or, alternatively, the Blockbrief logo must be displayed and
                  it must link to{' '}
                  <a href="https://blockbrief.com">https://blockbrief.com/</a>
                </li>
                <li>
                  You may present Blockbrief API data only on a transactional
                  basis. You may not cache or store the Blockbrief API data.
                </li>
                <li>
                  You must use commercially reasonable efforts to prevent the
                  Blockbrief Content or API Service from being downloaded in
                  bulk or otherwise scraped. Such efforts include “spider
                  traps,”C.A.P.T.C.H.A.,” velocity checks, source IP analysis,
                  or other methods that are as effective.
                </li>
                <li>
                  You may not keep any copies of the Blockbrief Content. Your
                  license to Blockbrief Content and Blockbrief API Service is
                  limited to making direct server calls to Blockbrief.com for
                  the Blockbrief API data and to distributing the Blockbrief
                  Content to Your users immediately upon receipt by Your
                  server(s)
                </li>
                <li>
                  You agree to refrain from reproducing, modifying,
                  distributing, decompiling, disassembling or reverse
                  engineering any portion of the Blockbrief Content, API or any
                  Blockbrief API Service.
                </li>
              </ol>
            </ol>
          </ol>
        </ol>
      </div>
    </div>
  );
}
