import React from 'react';
import './APIDataDownload.css';
import DataTable from '../DataTable';

export default function APIDataDownload() {
  return (
    <div className="API-data-container">
      <h1>Blockbrief API Data Download</h1>
      <div>
        <DataTable />
      </div>
    </div>
  );
}
