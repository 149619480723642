import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import FileDownload from 'js-file-download';
import { CSVDownload } from 'react-csv';

import './ParcelDownload.css';
import CancelSVG from '../../assets/images/cancel.png';
import { resetParcelDownload } from '../../reducers/blockbriefMapSlice';
import LoadingIndicator from '../../assets/svg/Loading-indicator';
import axios from '../../Apis/axios';

export default function ParcelDownload({ map }) {
  const [lessThanAreaBound, setLessThanAreaBound] = useState('');
  const [greaterThanAreaBound, setGreaterThanAreaBound] = useState('');
  const mapState = useSelector((state) => state.blockbriefMap);
  const [parcelDownloading, setParcelDownloading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  // const [header, setHeader] = useState([]);
  const dispatch = useDispatch();
  const handleParelDownload = async () => {
    let state = '';
    if (mapState.mapLocation.state === 'New South Wales') state = 'nsw';
    else if (mapState.mapLocation.state === 'Victoria') state = 'vic';
    else if (mapState.mapLocation.state === 'South Australia') state = 'sa';
    else if (mapState.mapLocation.state === 'Western Australia') state = 'wa';
    else if (mapState.mapLocation.state === 'Tasmania') state = 'tas';
    else if (mapState.mapLocation.state === 'Queensland') state = 'qld';
    else if (mapState.mapLocation.state === 'Acton') state = 'act';
    setParcelDownloading(!parcelDownloading);
    setCsvData([]);
    // Set up the Form Parameters
    const params = new FormData();
    params.append('east', map.getBounds().getEast());
    params.append('south', map.getBounds().getSouth());
    params.append('west', map.getBounds().getWest());
    params.append('north', map.getBounds().getNorth());
    params.append('state_abbr', state);
    params.append('area_greater_than', Number(greaterThanAreaBound));
    params.append('area_less_than', Number(lessThanAreaBound));
    params.append('download_parcel_zones', '');
    const response = await axios.post(`/parcels-csv`, params);
    // setHeader(response.data.data.data[0]);
    setCsvData(response.data.data.data);
    setParcelDownloading(false);
    // delete csvData[0];

    // const newArr = csvData.filter((item) => item !== '' || item !== undefined);
    // setParcelDownloading(false);
    // setCsvData(newArr);
    // console.log(newArr);

    // FileDownload(response.data.data.data[0], 'parcels.csv');
  };
  return mapState.parcelDownloadInputs.parcelStatus ? (
    <div className="map-container__parcel-download parcel-download">
      <div className="parcel-download__heading-row">
        <h1 className="parcel-download__heading">Download Parcels </h1>
        <button
          type="button"
          onClick={() => {
            dispatch(resetParcelDownload());
            setLessThanAreaBound('');
            setGreaterThanAreaBound('');
          }}
        >
          <img src={CancelSVG} alt="cancel" />
        </button>
      </div>
      <div className="parcel-download__parcel-form parcel-form">
        <div className="parcel-form__parcel-form-item parcel-form-item">
          <h3 className="parcel-form-item__heading">
            Area - m<span>2</span>
          </h3>
          <input
            type="text"
            placeholder="Enter the Area  (minimum)"
            value={greaterThanAreaBound}
            onChange={(e) => setGreaterThanAreaBound(e.target.value)}
          />
        </div>
        <div className="parcel-form__parcel-form-item parcel-form-item">
          <h3 className="parcel-form-item__heading">
            Area - m<span>2</span>
          </h3>
          <input
            type="text"
            placeholder="Enter the Area (maximum)"
            value={lessThanAreaBound}
            onChange={(e) => setLessThanAreaBound(e.target.value)}
          />
        </div>
        <button
          type="button"
          className="download-parcel-btn"
          onClick={handleParelDownload}
        >
          {parcelDownloading ? <LoadingIndicator /> : 'Download'}
        </button>
        {csvData.length > 0 && <CSVDownload data={csvData} target="_blank" />}
      </div>
    </div>
  ) : null;
}
