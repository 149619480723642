import React, { useState, useEffect } from 'react';
import axios from '../../Apis/axios';
import './APICredentials.css';

export default function APICredentials() {
  const [apiCredentials, updateAPICredentials] = useState('');
  useEffect(async () => {
    const url = `/api-credentials`;
    const response = await axios.get(url, { isAuth: true });
    if (response.status === 200) updateAPICredentials(response.data.token);
  }, []);
  return (
    <div className="api-documentation__api-content api-content">
      <div className="api-credentials-content">
        <h2 className="api-crendentials-content__heading">API Credentials</h2>
        <div className="api-crendentials-content__api-crendentials-details">
          {apiCredentials}
        </div>
      </div>
    </div>
  );
}
