import React from 'react';
import UnderConstructionSVG from '../../assets/svg/Under-Construction';
import './UnderConstruction.css';

export default function UnderConstruction() {
  return (
    <div className="under-construction">
      <div className="under-construction__col-1">
        <UnderConstructionSVG />
      </div>
      <div className="under-construction__col-2 under-construction-text ">
        <h2 className="under-construction-text__heading">
          Sorry, we&rsquo;re doing some work on the site
        </h2>
        <h5 className="under-construction-text__sub-heading">
          Thank you for being patient. We are doing some work on this URL.
          <br />
          We will be back soon
        </h5>
      </div>
    </div>
  );
}
