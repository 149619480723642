function makeYamlFilter(arr, color) {
  if (arr.length < 1) {
    return ' return "#ff0000"; ';
  }
  // if (currentActiveMap !== 'Minimum Lot Size Map') {
  let html = ' ';
  for (let i = 0; i < arr.length; i += 1) {
    if (i === 0) {
      html +=
        'if (feature.z == "' + arr[i] + '") { return "' + color[i] + '"; }';
    } else {
      html +=
        'else if (feature.z == "' +
        arr[i] +
        '") { return "' +
        color[i] +
        '"; }';
    }
  }
  html += 'else { return "#708090"; }';
  return html;
}
function makeYamlFilterOverLay(arr, color) {
  if (arr.length < 1) {
    return ' return "#ff0000"; ';
  }
  // if (currentActiveMap !== 'Minimum Lot Size Map') {
  let html = ' ';
  for (let i = 0; i < arr.length; i += 1) {
    let before = arr[i].substring(0, arr[i].indexOf('-'));
    // eslint-disable-next-line radix
    before = parseInt(before);
    let after;
    if (i !== arr.length - 1)
      after = arr[i + 1].substring(0, arr[i + 1].indexOf('-'));
    // eslint-disable-next-line radix
    after = parseInt(after);
    // if (i === 0) {
    //   html += `if (feature.z < ${after}) { return "${color[i]}" }`;
    // } else if (i === arr.length - 1);

    if (i > -1 && i < arr.length) {
      html += `if (feature.z >= ${before} && feature.z < ${after}) { return "${color[i]}" }`;
    }
  }
  // html += 'else { return "#708090"; }';
  return html;
}

export default function layerColor(zoneCode, colors, currentActiveMap, state) {
  // if (state === 'wa_bh' && currentActiveMap === 'Building Height Map') {
  //   return `function(){ if (feature.z == "100 metres") { return "#8a2be2"; }else if (feature.z == "29 metres") { return "#a52a2a"; }else if (feature.z == "33 metres") { return "#deb887"; }else if (feature.z == "39 metres") { return "#5f9ea0"; }else if (feature.z == "40 metres") { return "#7fff00"; }else if (feature.z == "45 metres") { return "#d2691e"; }else if (feature.z == "70 metres") { return "#ff7f50"; }else if (feature.z == "7 metres above the Street Building Height and then within a 45 degree height plane") { return "#6495ed"; }else if (feature.z == "Additional height above the Street Building Height within a 45 degree height measure from the street to the south (excludes laneways)") { return "#dc143c"; }else if (feature.z == "Additional height above the Street Building Height within a 45 degree height plane measured from both the streets to the north and south.") { return "#00ffff"; }else if (feature.z == "No prescribed height limit") { return "#00008b"; }else if (feature.z == "Refer to Goderich Design Policy") { return "#008b8b"; }else if (feature.z == "Refer to Hamilton (P11) Precinct Plan") { return "#b8860b"; }else if (feature.z == "Refer to King Street Heritage Precinct Design Guidelines") { return "#a9a9a9"; }else if (feature.z == "Refer to Mount Street Design Policy") { return "#006400"; }else { return "#708090"; }}`;
  // }
  // if (
  //   state === 'qld_gold_coast_bh' &&
  //   currentActiveMap === 'Building Height Map'
  // ) {
  //   return `function(){ if (feature.z == "HX") { return "#8a2be2"; }else if (feature.z == "9m") { return "#a52a2a"; }else if (feature.z == "84m") { return "#deb887"; }else if (feature.z == "54m") { return "#5f9ea0"; }else if (feature.z == "53m") { return "#7fff00"; }else if (feature.z == "45m") { return "#d2691e"; }else if (feature.z == "44m") { return "#ff7f50"; }else if (feature.z == "40m") { return "#6495ed"; }else if (feature.z == "39m") { return "#dc143c"; }else if (feature.z == "38m") { return "#00ffff"; }else if (feature.z == "35m") { return "#00008b"; }else if (feature.z == "33m") { return "#008b8b"; }else if (feature.z == "32m") { return "#b8860b"; }else if (feature.z == "30m") { return "#a9a9a9"; }else if (feature.z == "29m") { return "#006400"; }else if (feature.z == "27m") { return "#bdb76b"; }else if (feature.z == "26m") { return "#8b008b"; }else if (feature.z == "24m") { return "#556b2f"; }else if (feature.z == "23m") { return "#ff8c00"; }else if (feature.z == "20m") { return "#9932cc"; }else if (feature.z == "19m") { return "#8b0000"; }else if (feature.z == "16m") { return "#e9967a"; }else if (feature.z == "15m") { return "#8fbc8f"; }else if (feature.z == "14m") { return "#483d8b"; }else if (feature.z == "140m") { return "#2f4f4f"; }else { return "#708090"; }}`;
  // }
  if (currentActiveMap === 'Minimum Lot Size Map') {
    return `function(){${makeYamlFilterOverLay(zoneCode, colors)} }`;
  }
  if (currentActiveMap === 'Building Height Map' && state === 'nsw_bh') {
    // return ` function(){ if (feature.z > 200){return '#ffcdd8';}else if (feature.z > 100){return '#ffff00';}else if (feature.z > 80){return '#ffa500';}else if (feature.z > 60){return '#ff0000';}else if (feature.z > 40){return '#8c8cff';}else if (feature.z > 20){return '#0000ff';}else if (feature.z > 15){return '#89c35c';}else if (feature.z > 10){return '#008000';}else if (feature.z > 5){return '#cf88ff';}else{return '#8942f6';}}`;
    return `function(){${makeYamlFilterOverLay(zoneCode, colors)} }`;
  }
  if (currentActiveMap === 'Floor Space Ratio Map') {
    return `function(){${makeYamlFilterOverLay(zoneCode, colors)} }`;
    // return `   function(){ if (feature.z > 2){return '#ffcdd8';}else if (feature.z > 1){return '#ffff00';}else if (feature.z > 0.91){return '#ffa500';}else if (feature.z > 0.81){return '#ff0000';}else if (feature.z > 0.71){return '#8c8cff';}else if (feature.z > 0.61){return '#0000ff';}else if (feature.z > 0.51){return '#89c35c';}else if (feature.z > 0.41){return '#008000';}else if (feature.z > 0.31){return '#cf88ff';}else if (feature.z > 0.21){return '#8942f6';}else if (feature.z > 0.11){return '#FF8040';}else{return '#BBBBBB';}}`;
  }
  return ` function(){function hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\\d])([a-f\\d])([a-f\\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\\d]{2})([a-f\\d]{2})([a-f\\d]{2})$/i.exec(hex);
        return result ?
            parseInt(result[1], 16)+','+
            parseInt(result[2], 16)+','+
            parseInt(result[3], 16)
         : null;
      }

      function getHexa(){${makeYamlFilter(zoneCode, colors)}}

      return "rgba("+hexToRgb(getHexa())+","+global.alpha+")"
  }`;
}
