import React from 'react';
import { useHistory } from 'react-router-dom';
import './Dashboard.css';
import SearchIcon from '../../assets/images/search.svg';
import SearchForm from '../../components/SearchForm';

export default function Dashboard() {
  const history = useHistory();
  return (
    <div className="application-container__search-section search-section">
      <div className="search-section__dashboard-search dashboard-search">
        <h3 className="dashboard-search__heading search-heading">
          <span>
            <img
              src={SearchIcon}
              alt="search-icon"
              className="search-heading__img"
            />
          </span>
          Find Your Zoning
        </h3>
        <SearchForm
          submitHandler={(selectedSuggestion) => () =>
            history.push('/zoning-search/map-search', selectedSuggestion)}
        />
      </div>
    </div>
  );
}
