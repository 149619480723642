import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import UserAvatar from '../../assets/images/user.png';
import './HeaderAdminSection.css';
import { resetAdmin } from '../../reducers/AdminSigninSlice';
import Signout from '../../assets/images/logout.svg';

export default function HeaderAdminSection({
  extenedMenuTray,
  toggleExtendedMenuTray,
}) {
  const adminApplication = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="admin-application-container__admin-section admin-section">
      <h4 className="admin-section__heading">
        Hey {adminApplication.firstName}
      </h4>
      <button
        type="button"
        className="admin-section__avatar"
        onClick={() => toggleExtendedMenuTray(!extenedMenuTray)}
      >
        <img src={UserAvatar} alt="avatar" />
      </button>
      <div
        className={
          extenedMenuTray
            ? 'extended-admin-tray'
            : 'extended-admin-tray--hidden'
        }
      >
        <Link
          type="button"
          className="admin-application-header__back-to-menu"
          onClick={async () => {
            const requestURL = `${process.env.REACT_APP_API_HOSTNAME}/admin/signout`;
            const response = await axios({
              url: requestURL,
              method: 'POST',
              withCredentials: true,
            });
            if (response.status === 200) {
              dispatch(resetAdmin());
              history.push('/');
            }
          }}
        >
          <img src={Signout} alt="Left Arrow Icon" />
          Signout
        </Link>
      </div>
    </div>
  );
}
