export default function stateNameConfig(currentState) {
  if (currentState === 'New South Wales') return 'nsw';
  if (currentState === 'Victoria') return 'vic';
  if (currentState === 'South Australia') return 'sa';
  if (currentState === 'Western Australia') return 'wa';
  if (currentState === 'Tasmania') return 'tas';
  if (currentState === 'Queensland') return 'qld';
  if (currentState === 'Australian Capital Territory') return 'act';
  return '';
}
