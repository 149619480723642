import React from 'react';
import './LandingInfo.css';
import SearchIcon from '../../assets/images/search-box.png';
import DiscoverIcon from '../../assets/images/exploration.png';
import InventoryIcon from '../../assets/images/maps-and-location.png';
import MonitorIcon from '../../assets/images/monitor.png';
import LearnMoreIcon from '../../assets/images/knowledge.png';

export default function LandingInfo() {
  return (
    <div className="landing-info-section" id="more-info">
      <h2 className="landing-info-section__heading">
        Why
        <br />
        Blockbrief?
      </h2>
      <div className="landing-info-section__boxes info-box-cont">
        <div className="info-box-cont__item search">
          <img src={SearchIcon} alt="Search Icon" />
          <h4>Search</h4>
        </div>
        <div className="info-box-cont__item discover">
          <img src={DiscoverIcon} alt="exploration icon" />
          <h4>Discover</h4>
        </div>
        <div className="info-box-cont__item inventory">
          <img src={InventoryIcon} alt="Inventory Icon" />
          <h4>Inventory</h4>
        </div>
        <div className="info-box-cont__item monitor">
          <img src={MonitorIcon} alt="monitor icon" />
          <h4>Monitor</h4>
        </div>
        <div className="info-box-cont__item learn-more">
          <img src={LearnMoreIcon} alt="knowledge icon" />
          <h4>Learn More</h4>
        </div>
      </div>
    </div>
  );
}
