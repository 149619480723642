export default function mapInfoUtil(state, city, infoType, mapType) {
  if (state === 'New South Wales') {
    if (infoType === 'mapTypes') {
      return [
        'Zoning Map',
        'Planning Alerts',
        'Minimum Lot Size Map',
        'Building Height Map',
        'Floor Space Ratio Map',
        'Heritage Map',
        'Other Constraint Maps',
      ];
    }
    if (infoType === 'legendPayload') {
      return {
        state: 'NSW',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return 'nsw';
    }
  } else if (state === 'South Australia') {
    // handle south australia layers here
    if (infoType === 'mapTypes') {
      return [
        'Zoning Map',
        'Planning Alerts',
        'Adelaide Policy Maps',
        'Heritage Map',
        'Land Use Map',
        'Bushfire Map',
      ];
    }
    if (infoType === 'legendPayload') {
      return {
        state: 'SA',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return 'sa';
    }
  } else if (state === 'Victoria') {
    // handle victoria layers here
    if (infoType === 'mapTypes') {
      return [
        'Zoning Map',
        'Planning Alerts',
        'Bushfire Map',
        'Other Constraint Maps',
      ];
    }
    if (infoType === 'legendPayload') {
      return {
        state: 'VIC',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return 'vic';
    }
  } else if (
    state === 'Queensland' &&
    (city === 'Brisbane' || city === 'Brisbane City')
  ) {
    // handle layers for queensland and the city is Brisbane or Brisbane City
    if (infoType === 'mapTypes') {
      return [
        'Zoning Map',
        'Planning Alerts',
        'Bushfire Map',
        'Other Constraint Maps',
      ];
    }
    if (infoType === 'legendPayload') {
      return {
        state: !(mapType === 'Zoning Map') ? 'QLD' : 'QLD_BRISBANE',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return !(mapType === 'Zoning Map') ? 'qld' : 'qld_brisbane';
    }
  } else if (
    state === 'Queensland' &&
    (city === 'Logan' || city === 'Logan City')
  ) {
    // handle layers when state is queensland and the city is logan and logan city
    if (infoType === 'mapTypes') {
      return ['Zoning Map', 'Precinct Map'];
    }
    if (infoType === 'legendPayload') {
      return {
        state: !(mapType === 'Zoning Map') ? 'QLD' : 'QLD_LOGAN',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return !(mapType === 'Zoning Map') ? 'qld_logan' : 'qld_logan';
    }
  } else if (
    state === 'Queensland' &&
    (city === 'Gold Coast City' ||
      city === 'Gold Coast' ||
      city === 'City of Gold Coast')
  ) {
    // handle layers when state is queensland and the city is Gold Coast City or Gold Coast or City of Gold Coast
    if (infoType === 'mapTypes') {
      return ['Zoning Map', 'Planning Alerts', 'Building Height Map'];
    }
    if (infoType === 'legendPayload') {
      return {
        state: !(mapType === 'Zoning Map' || mapType === 'Building Height Map')
          ? 'QLD'
          : 'QLD_GOLD_COAST',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return mapType === 'Zoning Map' || mapType === 'Building Height Map'
        ? 'qld_gold_coast'
        : 'qld';
    }
  } else if (
    state === 'Queensland' &&
    (city === 'Sunshine Coast' || city === 'Sunshine Coast Regional')
  ) {
    // handle layers when state is queensland and the city is Sunshine Coast or Sunshine Coast Regional
    if (infoType === 'mapTypes') {
      return [
        'Zoning Map',
        'Planning Alerts',
        'Other Constraint Maps',
        'Bushfire Map',
      ];
    }
    if (infoType === 'legendPayload') {
      return {
        state: !(mapType === 'Zoning Map') ? 'QLD' : 'QLD_SUNSHINE_COAST',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return !(mapType === 'Zoning Map') ? 'qld' : 'qld_sunshine_coast';
    }
  } else if (
    state === 'Queensland' &&
    (city === 'Cairns' || city === 'Cairns City' || city === 'Cairns Regional')
  ) {
    // handle layers when state is queensland and the city is Cairns City or Cairns Regional
    if (infoType === 'mapTypes') {
      return [
        'Zoning Map',
        'Planning Alerts',
        'Other Constraint Maps',
        'Bushfire Map',
      ];
    }
    if (infoType === 'legendPayload') {
      return {
        state: !(mapType === 'Zoning Map') ? 'QLD' : 'QLD_CAIRNS',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return !(mapType === 'Zoning Map') ? 'qld' : 'qld_cairns';
    }
  } else if (
    state === 'Queensland' &&
    (city === 'Townsville' || city === 'Townsville City')
  ) {
    // handle layers when state is queensland and the city is Townsville or Townsville city
    if (infoType === 'mapTypes') {
      return [
        'Zoning Map',
        'Planning Alerts',
        'Other Constraint Maps',
        'Bushfire Map',
      ];
    }
    if (infoType === 'legendPayload') {
      return {
        state: mapType === 'Zoning Map' ? 'QLD_TOWNSVILLE' : 'QLD',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return !(mapType === 'Zoning Map') ? 'qld' : 'qld_townsville';
    }
  } else if (state === 'Queensland') {
    // Handle layers when the state is queensland
    if (infoType === 'mapTypes') {
      return [
        'Zoning Map',
        'Planning Alerts',
        'Other Constraint Maps',
        'Bushfire Map',
        'Local Plan Precincts Map',
      ];
    }
    if (infoType === 'legendPayload') {
      return {
        state: 'QLD',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return 'qld';
    }
  } else if (
    state === 'Western Australia' &&
    (city === 'City of Gosnells' ||
      city === 'Gosnells City' ||
      city === 'Gosnells Regional')
  ) {
    // Handle layers when the state is Western Australia
    if (infoType === 'mapTypes') {
      return ['Zoning Map', 'Planning Alerts', 'Building Height Map'];
    }
    if (infoType === 'legendPayload') {
      return {
        state: mapType === 'Zoning Map' ? 'WA_GOSNELLS' : 'WA',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return !(mapType === 'Zoning Map') ? 'wa' : 'wa_gosnells';
    }
  } else if (
    state === 'Western Australia' &&
    (city === 'City of Stirling' ||
      city === 'Stirling City' ||
      city === 'Stirling Regional')
  ) {
    // Handle layers when the state is Western Australia
    if (infoType === 'mapTypes') {
      return ['Zoning Map', 'Planning Alerts', 'Building Height Map'];
    }
    if (infoType === 'legendPayload') {
      return {
        state: mapType === 'Zoning Map' ? 'WA_STIRLING' : 'WA',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return !(mapType === 'Zoning Map') ? 'wa' : 'wa_stirling';
    }
  } else if (
    state === 'Western Australia' &&
    (city === 'City of Wanneroo' ||
      city === 'Wanneroo City' ||
      city === 'Wanneroo Regional')
  ) {
    // Handle layers when the state is Western Australia
    if (infoType === 'mapTypes') {
      return ['Zoning Map', 'Planning Alerts', 'Building Height Map'];
    }
    if (infoType === 'legendPayload') {
      return {
        state: mapType === 'Zoning Map' ? 'WA_WANNEROO' : 'WA',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return !(mapType === 'Zoning Map') ? 'wa' : 'wa_wanneroo';
    }
  } else if (
    state === 'Western Australia' &&
    (city === 'City of Melville' ||
      city === 'Melville City' ||
      city === 'Melville Regional')
  ) {
    // Handle layers when the state is Western Australia
    if (infoType === 'mapTypes') {
      return ['Zoning Map', 'Planning Alerts', 'Building Height Map'];
    }
    if (infoType === 'legendPayload') {
      return {
        state: mapType === 'Zoning Map' ? 'WA_MELVILLE' : 'WA',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return !(mapType === 'Zoning Map') ? 'wa' : 'wa_melville';
    }
  } else if (state === 'Western Australia') {
    // Handle layers when the state is Western Australia
    if (infoType === 'mapTypes') {
      return ['Zoning Map', 'Planning Alerts', 'Building Height Map'];
    }
    if (infoType === 'legendPayload') {
      return {
        state: 'WA',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return 'wa';
    }
  } else if (state === 'Tasmania') {
    // Handle layers when the state is Tasmania
    if (infoType === 'mapTypes') {
      return ['Zoning Map', 'Planning Alerts', 'Other Constraint Maps'];
    }
    if (infoType === 'legendPayload') {
      return {
        state: 'tas',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return 'tas';
    }
  } else if (state === 'Australian Capital Territory') {
    // Handle layers when the state is Australian Capital Territory
    if (infoType === 'mapTypes') {
      return ['Zoning Map', 'Planning Alerts', 'Territory Plan Overlay Zones'];
    }
    if (infoType === 'legendPayload') {
      return {
        state: 'ACT',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return 'act';
    }
  } else if (state === 'Northern Territory') {
    // Handle layers when the state is Australian Capital Territory
    if (infoType === 'mapTypes') {
      return ['Zoning Map', 'Planning Alerts'];
    }
    if (infoType === 'legendPayload') {
      return {
        state: 'NT',
      };
    }
    if (infoType === 'mapYamlFileName') {
      return 'nt';
    }
  } else {
    // Requset for coverage
    return null;
  }
  return null;
}
