import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import './AdminDashboard.css';
import AddUser from '../../assets/images/add-friend.svg';
import UserIcon from '../../assets/images/user.svg';
import EditIcon from '../../assets/images/synchronize.svg';
import fetchUsersAction from '../../reducers/BlockbriefUsers/BlockbriefUsersThunk';
import {
  startRowPageBtnAction,
  endRowPageBtnAction,
  nextPageBtnAction,
  prevPageBtnAction,
  setSearchQuery,
} from '../../reducers/BlockbriefUsers';
import Pagination from '../../components/Pagination';
import { displayAdminModal } from '../../reducers/AdminApplicationModalSlice';

// Set up the defaults for the time ago library
TimeAgo.addDefaultLocale(en);

export default function AdminDashboard() {
  const users = useSelector(
    (state) => state.blockbriefUsers.currentActiveUsers
  );
  const { currentActive, highestPageSetOne, totalPages, totalPagesSecondSet } =
    useSelector((state) => state.blockbriefUsers.pagination);
  const dispatch = useDispatch();
  const [searchStr, setSearchStr] = useState('');

  useEffect(() => {
    dispatch(fetchUsersAction());
  }, [currentActive]);

  useEffect(() => {
    dispatch(setSearchQuery({ searchStr }));
    dispatch(fetchUsersAction());
  }, [searchStr]);

  // Pagination: Pagination Change Event Handler
  const handlePaginationChange = (eventType) => (event) => {
    const text = Number(event.target.innerText);
    if (eventType === 'Next') dispatch(nextPageBtnAction({ incAmount: 1 }));
    else if (eventType === 'Back')
      dispatch(prevPageBtnAction({ incAmount: -1 }));
    else if (eventType === 'pagBtn')
      dispatch(startRowPageBtnAction({ incAmount: Number(text) }));
    else if (eventType === 'pagBtn-later')
      dispatch(endRowPageBtnAction({ incAmount: Number(text) }));
  };

  const editUser = (userID) => {
    users.forEach((user) => {
      if (user.member_id === userID) {
        dispatch(displayAdminModal({ modalType: 'Edit User', user }));
      }
    });
  };

  const updatePassword = (userID) => {
    users.forEach((user) => {
      if (user.member_id === userID) {
        dispatch(displayAdminModal({ modalType: 'Update Password', user }));
      }
    });
  };

  return (
    //  <!-- Zoning Changes Section -->
    <div className="admin-application-container__admin-dashboard admin-dashboard">
      {/* <!-- Zoning Changes header --> */}
      <h2 className="admin-dashboard__header">Admin Dashboard</h2>
      {/* <!-- Zoning Changes Info Text --> */}

      {/* <!-- Zoning Changes fitler and pagination row --> */}
      <div className="admin-dashboard__controls-row controls-row">
        <div className="admin-filter-row">
          <input
            type="text"
            name="search"
            id="search-admin"
            placeholder="Search for a User .."
            autoComplete="off"
            value={searchStr}
            onChange={(e) => {
              const str = e.target.value;
              setSearchStr(str);
            }}
          />
          <button
            type="button"
            className="admin-controls-row__filter"
            onClick={() => {
              dispatch(displayAdminModal({ modalType: 'Create New User' }));
              document.body.classList.toggle('no-scroll');
            }}
          >
            <span>Add User</span>
            <img src={AddUser} alt="Filter icon" />
          </button>
        </div>
        <Pagination
          currentActive={currentActive}
          currentHighestFirstSet={highestPageSetOne}
          totalPages={totalPages}
          updatePaginationFn={handlePaginationChange}
          totalPagesSecondSet={totalPagesSecondSet}
        />
      </div>
      {/* <!-- Zoning Changes Table --> */}
      <table
        className="admin-dashboard__admin-dashboard-table admin-dashboard-table"
        cellSpacing="0px"
      >
        <thead className="admin-dashboard-table__head admin-table-head">
          <tr className="admin-dashboard-table-head__row">
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Date Created</th>
            <th>Last Log in</th>
            <th> </th>
          </tr>
        </thead>
        <tbody className="admin-dashboard-table__body admin-dashboard-table-body">
          {users.map((el) => (
            <tr className="admin-dashboard-table__row admin-dashboard-table__row">
              <td className="admin-dashboard-table__data">{el.firstname}</td>
              <td className="admin-dashboard-table__data">{el.lastname}</td>
              <td className="admin-dashboard-table__data">{el.email}</td>
              <td className="admin-dashboard-table__data">
                {el.date_created
                  ? new TimeAgo('en-US').format(new Date(el.date_created))
                  : 'N/A'}
              </td>
              <td className="admin-dashboard-table__data">
                {el.last_log_in
                  ? new TimeAgo('en-US').format(new Date(el.last_log_in))
                  : 'N/A'}
              </td>
              <td className="admin-row__data">
                <div className="user-control">
                  <button
                    type="button"
                    onClick={() => {
                      editUser(el.member_id);
                      document.body.classList.toggle('no-scroll');
                    }}
                  >
                    <img src={UserIcon} alt="User Icon" />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      updatePassword(el.member_id);
                      document.body.classList.toggle('no-scroll');
                    }}
                  >
                    <img src={EditIcon} alt="Edit Icon" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
