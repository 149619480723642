// Util Functions for the Pagiantion Slice Reducer

// handles the state update for the startRowPage Btn action
const startRowPageBtnReducer = (state, { payload: { incAmount } }) => {
  const { totalPages, totalPagesSecondSet, highestPageSetOne } =
    state.pagination;
  const secondSetStartValue = totalPages - totalPagesSecondSet;

  if (highestPageSetOne <= secondSetStartValue) {
    state.pagination.currentActive = Number(incAmount);
  } else {
    state.pagination.currentActive = Number(incAmount);
    state.pagination.highestPageSetOne = Number(incAmount) + 3;
  }
};

// handles the state update for the endRowPage Btn action
const endRowPageBtnReducer = (state, { payload: { incAmount } }) => {
  state.pagination.currentActive = Number(incAmount);
};

// handles the state update for the nextRowPage Btn action
const nextPageBtnReducer = (state, { payload: { incAmount } }) => {
  const { currentActive, totalPages, totalPagesSecondSet, highestPageSetOne } =
    state.pagination;
  const secondSetStartValue = totalPages - totalPagesSecondSet;

  if (currentActive === totalPages) {
    return;
  }
  if (currentActive >= secondSetStartValue) {
    // handles the case when the currentActive reaches the second set startValue
    state.pagination.currentActive += incAmount;
    return;
  }

  if (highestPageSetOne < secondSetStartValue) {
    // only increment set one highest value when less than second set start value
    state.pagination.currentActive += incAmount;
    state.pagination.highestPageSetOne += incAmount;
  } else {
    state.pagination.currentActive += incAmount;
  }
};

// handles the state update for the prevRowPage Btn action
const prevPageBtnReducer = (state, { payload: { incAmount } }) => {
  const { currentActive, totalPages, totalPagesSecondSet } = state.pagination;
  const secondSetStartValue = totalPages - totalPagesSecondSet;

  if (currentActive === 1 || currentActive > secondSetStartValue - 1) {
    return;
  }

  state.pagination.currentActive += incAmount;
  state.pagination.highestPageSetOne += incAmount;
};

// returns the pagination state object based on the values passed in config
const createPaginationState = ({ totalValues }) => {
  const totalPages = Math.ceil(totalValues / 10);

  let totalPagesSecondSet;
  if (totalPages > 6) {
    totalPagesSecondSet = 3; // if totalPages are greater than 6 than default 3 buttons are to be shown in the secondSet of BTN's after the (... btn)
  } else if (totalPages === 6) {
    totalPagesSecondSet = 2; // if totalPages are equal to 6 than first 4 are pages are accessed through the firstSet of Buttons - the last 2 are handled by the secondSet
  } else if (totalPages === 5) {
    totalPagesSecondSet = 1; // first 4 are in the firstSet of buttons - only single item in the second list
  } else {
    totalPagesSecondSet = 0;
  }

  return {
    paginationFetched: true,
    currentActive: 1,
    totalRecords: totalValues,
    totalPages: Number(totalPages),
    highestPageSetOne: totalPages > 4 ? 4 : totalPages, // update the default total values in set one - based on the records recived
    totalPagesSecondSet: Number(totalPagesSecondSet),
  };
};

export {
  startRowPageBtnReducer,
  endRowPageBtnReducer,
  nextPageBtnReducer,
  prevPageBtnReducer,
  createPaginationState,
};
