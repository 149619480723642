import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '../../assets/images/settings.svg';
import Pagination from '../../components/Pagination';
import ZoningTable from '../../components/ZoningTable';
import FilterMenu from '../../components/FilterMenu';
import './ZoningChanges.css';
import InfoIcon from '../../assets/images/information-button.png';
import fetchZoningRecords from '../../reducers/zoningChangesSlice/zoningChangesThunk';
import {
  startRowPageBtnAction,
  endRowPageBtnAction,
  nextPageBtnAction,
  prevPageBtnAction,
  setFilterValues,
  resetFilterValues,
  resetPagination,
} from '../../reducers/zoningChangesSlice';

export default function ZoningChanges() {
  const dispatch = useDispatch();
  const [FilterTray, setFilterTray] = useState(false);
  const { currentActive, highestPageSetOne, totalPages, totalPagesSecondSet } =
    useSelector((state) => state.zoningChanges.pagination);
  const { statusFilter, stateFilter, cityFilter, suburbFilter } = useSelector(
    (state) => state.zoningChanges.filters
  );
  const { state, status, city, date, suburb, searchStr } = useSelector(
    (fitlerMenuValues) => fitlerMenuValues.zoningChanges.filterSelectedValues
  );
  const [menuFilter, setMenuFilter] = useState({
    suburb,
    state,
    status,
    city,
    searchStr,
    startDate: date.startDate,
    endDate: date.endDate,
  });

  // Fetch the recent records on the initial render of zoningChanges component
  useEffect(() => {
    dispatch(fetchZoningRecords());
  }, [
    currentActive,
    highestPageSetOne,
    totalPages,
    totalPagesSecondSet,
    dispatch,
  ]);

  /* Event Handlers */

  // Filter Menu: Submission Handler - to submit the filters form
  const submissionHandler = () => {
    dispatch(setFilterValues({ ...menuFilter }));
    dispatch(resetPagination());
  };

  // Filter Menu: Reset Handler - to reset the filters form
  const resetHandler = () => {
    setMenuFilter({
      status: '',
      city: '',
      state: '',
      suburb: '',
      searchStr: '',
      startDate: '',
      endDate: '',
    });
    dispatch(resetFilterValues());
    dispatch(resetPagination());
  };

  // Filter Menu: Open Filter Menu - EventHandler to open the Filter Menu Tray
  const openFilterTray = () => setFilterTray(!FilterTray);

  // Pagination: Pagination Change Event Handler
  const handlePaginationChange = (eventType) => (event) => {
    const text = Number(event.target.innerText);
    if (eventType === 'Next') dispatch(nextPageBtnAction({ incAmount: 1 }));
    else if (eventType === 'Back')
      dispatch(prevPageBtnAction({ incAmount: -1 }));
    else if (eventType === 'pagBtn')
      dispatch(startRowPageBtnAction({ incAmount: Number(text) }));
    else if (eventType === 'pagBtn-later')
      dispatch(endRowPageBtnAction({ incAmount: Number(text) }));
  };
  return (
    <div className="application-container__zoning-changes zoning-changes">
      <h2 className="zoning-changes__header">Zoning Changes</h2>
      <h3 className="zoning-changes__info-text zoning-changes-info info-text">
        <img src={InfoIcon} alt="info icon" /> Search and filter the zoning
        changes for different states. For updates regarding zoning changes
        subscribe to our News Letter
      </h3>
      <div className="zoning-changes__controls-row controls-row">
        <div className="controls-row__filter" onClick={openFilterTray}>
          <span>Filter</span>
          <img src={SettingsIcon} alt="Filter icon" />
        </div>
        <Pagination
          currentActive={currentActive}
          currentHighestFirstSet={highestPageSetOne}
          totalPages={totalPages}
          updatePaginationFn={handlePaginationChange}
          totalPagesSecondSet={totalPagesSecondSet}
        />
      </div>
      {FilterTray ? (
        <FilterMenu
          componentName="Zoning"
          filters={[
            {
              filterName: 'Search Keyword',
              filterPlaceholder: 'Search for a keyword',
              filterType: 'Search',
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.value.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  searchStr: selectedValue,
                }));
              },
            },
            {
              filterName: 'Suburb',
              filterPlaceholder: 'Select a Suburub',
              selectedValue: menuFilter.suburb,
              filterType: 'Dropdown',
              filterValues: suburbFilter,
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.innerText.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  suburb: selectedValue,
                }));
              },
            },
            {
              filterName: 'State',
              filterPlaceholder: 'Select a State',
              selectedValue: menuFilter.state,
              filterType: 'Dropdown',
              filterValues: stateFilter,
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.innerText.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  state: selectedValue,
                }));
              },
            },
            {
              filterName: 'Status',
              filterType: 'Dropdown',
              filterPlaceholder: 'Select a Status',
              selectedValue: menuFilter.status,
              filterValues: statusFilter,
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.innerText.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  status: selectedValue,
                }));
              },
            },
            {
              filterName: 'City',
              filterPlaceholder: 'Select a City',
              selectedValue: menuFilter.city,
              filterType: 'Dropdown',
              filterValues: cityFilter,
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.innerText.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  city: selectedValue,
                }));
              },
            },
            {
              filterName: 'Start Date',
              filterType: 'Date',
              selectedValue: menuFilter.startDate,
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.value.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  startDate: selectedValue,
                }));
              },
            },
            {
              filterName: 'End Date',
              filterType: 'Date',
              selectedValue: menuFilter.endDate,
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.value.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  endDate: selectedValue,
                }));
              },
            },
          ]}
          submissionHandler={submissionHandler}
          resetHandler={resetHandler}
        />
      ) : null}
      <ZoningTable />
    </div>
  );
}
