import React from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './APIDocumentation.css';
import APIMenu from '../../components/APIMenu';
import APIContent from '../../components/APIContent';
import {
  zoningAPIData,
  thirdPartyAPIData,
  requestCoverageData,
  blockbriefPermitData,
  rezoningPermitData,
} from './APIDocumentationData';
import APICredentials from '../../components/APICredentials';
import APITermsOfService from '../../components/APITermsOfService';
import APIDataDownload from '../../components/APIDataDownload';

export default function APIDocumentation() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { email } = useSelector((state) => state.user);
  const APIEndPoints = [
    {
      endpointName: 'Zoning',
      endpointItems: [{ method: 'GET', url: '/api/zoning' }],
    },
    {
      endpointName: '3rd Party Permits',
      endpointItems: [{ method: 'GET', url: '/api/permit-api' }],
    },
    {
      endpointName: 'Rezoning Permit',
      endpointItems: [{ method: 'GET', url: '/api/zoning-permit-api' }],
    },
    {
      endpointName: 'Blockbrief Permit',
      endpointItems: [{ method: 'GET', url: '/api/blockbrief-permit-api' }],
    },
    {
      endpointName: 'Request For Zoning Info',
      endpointItems: [{ method: 'GET', url: '/api/request-for-coverage' }],
    },
  ];
  return (
    <div className="application-container__api-container api-container">
      <APIMenu
        APIEndPoints={APIEndPoints}
        currentLocation={location.pathname}
      />
      <Switch>
        <Route
          sensitive
          path="/api/api-credentials"
          render={() => <APICredentials />}
        />
        <Route
          sensitive
          path="/api/terms-of-service"
          render={() => <APITermsOfService />}
        />
        {String(email || '').match(/corelogic/gi) ||
        String(email || '').match(/magma/gi) ? (
          <Route
            sensitive
            path="/api/data-download"
            render={() => <APIDataDownload />}
          />
        ) : null}
        <Route
          sensitive
          path={`${path}/docs/zoning`}
          render={() => (
            <APIContent
              requestMethod={zoningAPIData.requestMethod}
              requestURL={zoningAPIData.requestURL}
              requestDescr={zoningAPIData.requestDescr}
              responseData={zoningAPIData.responseData}
              requestData={zoningAPIData.requestData}
              sampleCode={zoningAPIData.sampleCode}
            />
          )}
        />
        <Route
          sensitive
          path={`${path}/docs/third-party-permit`}
          render={() => (
            <APIContent
              requestMethod={thirdPartyAPIData.requestMethod}
              requestURL={thirdPartyAPIData.requestURL}
              requestDescr={thirdPartyAPIData.requestDescr}
              responseData={thirdPartyAPIData.responseData}
              requestData={thirdPartyAPIData.requestData}
              sampleCode={thirdPartyAPIData.sampleCode}
            />
          )}
        />
        <Route
          sensitive
          path={`${path}/docs/rezoning-permit`}
          render={() => (
            <APIContent
              requestMethod={rezoningPermitData.requestMethod}
              requestURL={rezoningPermitData.requestURL}
              requestDescr={rezoningPermitData.requestDescr}
              responseData={rezoningPermitData.responseData}
              requestData={rezoningPermitData.requestData}
              sampleCode={rezoningPermitData.sampleCode}
            />
          )}
        />
        <Route
          sensitive
          path={`${path}/docs/blockbrief-permit`}
          render={() => (
            <APIContent
              requestMethod={blockbriefPermitData.requestMethod}
              requestURL={blockbriefPermitData.requestURL}
              requestDescr={blockbriefPermitData.requestDescr}
              responseData={blockbriefPermitData.responseData}
              requestData={blockbriefPermitData.requestData}
              sampleCode={blockbriefPermitData.sampleCode}
            />
          )}
        />
        <Route
          sensitive
          path={`${path}/docs/request-zoning`}
          render={() => (
            <APIContent
              requestMethod={requestCoverageData.requestMethod}
              requestURL={requestCoverageData.requestURL}
              requestDescr={requestCoverageData.requestDescr}
              responseData={requestCoverageData.responseData}
              requestData={requestCoverageData.requestData}
              sampleCode={requestCoverageData.sampleCode}
            />
          )}
        />
      </Switch>
    </div>
  );
}
