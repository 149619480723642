import React from 'react';
import './FeaturePluTable.css';

export default function FeaturePluTable({ pluData }) {
  // const displayPropConfig = [
  //   {
  //     propName: 'prohibited_uses',
  //     renderedName: 'Prohibited Uses',
  //     key: 3,
  //   },
  //   {
  //     propName: 'permitted_with_consent',
  //     renderedName: 'Permitted With Consent',
  //     key: 1,
  //   },
  //   {
  //     propName: 'permitted_without_consent',
  //     renderedName: 'Permitted Without Consent',
  //     key: 2,
  //   },
  //   {
  //     propName: 'permitted_if_performance_accessed_development',
  //     renderedName: 'Permitted If Performance Accessed Development',
  //     key: 4,
  //   },
  //   {
  //     propName: 'permitted_with_if_you_satisfy_development_classification',
  //     renderedName: 'Permitted With If You Satisfy Development Classification',
  //     key: 5,
  //   },
  //   {
  //     propName: 'restricted_development_classification',
  //     renderedName: 'Restricted Development Classification',
  //     key: 6,
  //   },
  //   {
  //     propName: 'discretion_uses',
  //     renderedName: 'Discretion Uses',
  //     key: 7,
  //   },

  //   {
  //     propName: 'clause_64_discretion_uses',
  //     renderedName: 'clause_64 Discretion Uses',
  //     key: 7,
  //   },
  // ];

  let generatePluObj = pluData?.plus
    ? Object.entries(pluData?.plus).reduce((acc, [propName, propVal]) => {
        const propToRender = Object.entries(pluData?.plus).reduce(
          (finalObj, curr) => {
            const result1 = propName.split('_').join(' ');

            return {
              ...finalObj,
              [result1]: { propVal, key: curr.key },
            };
          },
          {}
        );

        return {
          ...acc,
          ...propToRender,
        };
      }, {})
    : null;

  if (generatePluObj && Object.keys(generatePluObj).length === 0) {
    generatePluObj = null;
  }
  return generatePluObj ? (
    Object.entries(generatePluObj) // convert the object into [[prop, {propVal, key}], ..]
      .sort(([, { key: keyA }], [, { key: keyB }]) => keyA - keyB) // sort the above array based on the key prop
      .map(([propName, { propVal, key }]) => (
        <div className="feature-table__plu-item" key={key}>
          <h3 className="plu-item__heading">{propName}</h3>
          <div className="plu-item__plu-list">
            {propVal.split(';').map((el) => (
              <span>{el}</span>
            ))}
          </div>
        </div>
      ))
  ) : (
    <div className="plu-item--not-found">
      Permitted Land Use not available for this address
    </div>
  );
}
