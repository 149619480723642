import React from 'react';
import './DevelopmentTableRow.css';
import { useSelector, useDispatch } from 'react-redux';
import { displayModal } from '../../reducers/ApplicationModalSlice';

export default function DevelopmentTableRow({
  id,
  address,
  state,
  propertyType,
  transactionType,
  availableSpace,
}) {
  const detailedDevelopmentSitesRecords = useSelector(
    (state_) => state_.developmentSites.detailedDevelopmentSitesRecords
  );
  const dispatch = useDispatch();
  const handleOpenDetailedRecords = () => {
    detailedDevelopmentSitesRecords.map(({ _source }) =>
      _source.id === id
        ? dispatch(
            displayModal({
              modalType: 'development  site',
              modalData: _source,
            })
          )
        : null
    );
    document.body.classList.toggle('no-scroll');
  };
  return (
    <tr
      className="development-table-body__row development-row"
      onClick={handleOpenDetailedRecords}
    >
      <td className="development-row__data">{address}</td>
      <td className="development-row__data">{state}</td>
      <td className="development-row__data">{propertyType}</td>
      <td className="development-row__data">{transactionType}</td>
      <td className="development-row__data">{availableSpace}</td>
    </tr>
  );
}
