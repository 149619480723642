import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import './ApplicationModalTable.css';
import ApplicationModalTableRow from '../ApplicationModalTableRow';

export default function ApplicationModalTable({ modalData }) {
  return (
    <table
      className="modal-body__modal-table modal-table"
      style={{ tableLayout: 'fixed' }}
    >
      <tbody className="modal-table__body">
        {modalData
          ? Object.keys(modalData).map((propName) => (
              <ApplicationModalTableRow
                propName={propName.replace(/_/g, ' ')}
                propVal={modalData[propName] ? modalData[propName] : 'N/A'}
                key={nanoid()}
              />
            ))
          : null}
      </tbody>
    </table>
  );
}
