import React from 'react';
import './FilterMenu.css';
import FilterItem from '../FilterItem';
/**
 * FilterMenu: Filter Menu is a generic component that renders different filters based on the input
 * @param {String} componentName - Name of the component i-e 'Zoning', 'Development'
 * @param {Array} filters - Array of Objects - each object has the following properties: filterName (The name of the filter i-e 'Status'), filterType(Dropdown or Date), filterValues(Array of filter values to select from), eventHandler(event that is called when a values is selected from the dropdown or a date is selected)
 * @param {function} submissionHandler - the submit function to call when the user presses 'Done' btn
 * @param {function} resetHandler - the function to call to reset the controls
 * @returns JSX.Element
 */
export default function FilterMenu({
  componentName,
  filters,
  submissionHandler,
  resetHandler,
}) {
  return (
    <div className="table-menu__filter-menu filter-menu">
      <h3 className="filter-menu__heading">{componentName} Filters</h3>
      <div className="filter-menu__filter-row filter-row">
        {filters.map((filter) => (
          <FilterItem
            filterName={filter.filterName}
            placeholderStr={filter.filterPlaceholder}
            selectedValue={filter.selectedValue}
            filterType={filter.filterType}
            filterValues={filter.filterValues}
            selectionFunction={filter.handleFilterValueChange}
          />
        ))}
      </div>
      <div className="filter-submit-items">
        <button
          type="button"
          className="filter-submit-items__items"
          onClick={submissionHandler}
        >
          Done
        </button>
        <button
          type="button"
          className="filter-submit-items__items"
          onClick={resetHandler}
        >
          Reset
        </button>
      </div>
    </div>
  );
}
