import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BBLogo from '../../assets/images/logo.png';
import './Application.css';
import ApplicationModal from '../../components/ApplicationModal';
import ApplicationNav from '../../components/ApplicationNav';
import HeaderUserSection from '../../components/HeaderUserSection';
import LeftArrow from '../../assets/images/left-arrow.svg';

export default function Application({ children }) {
  const currentLocation = useLocation();
  const firstname = useSelector((state) => state.user.firstName);
  const modalState = useSelector((state) => state.applicationModal);
  const [extendedMenuTray, toggleExtendedMenuTray] = useState(false);

  return (
    <div className="application-container">
      <ApplicationModal
        modalType={modalState.modalType}
        active={modalState.active}
        modalData={modalState.modalData}
      />
      <div className="application-container__header application-header">
        <Link className="application-header__logo" to={{ pathname: '/' }}>
          <img src={BBLogo} alt="Search Icon" />
        </Link>
        <ApplicationNav currentLocation={currentLocation} />
        {[
          '/zoning-search',
          '/zoning-search/map-search',
          '/zoning-changes',
          '/development-sites',
          '/subscribe',
          '/marketing',
          '/webinars',
          '/',
        ].reduce((acc, curr) => {
          if (acc) return acc;
          return curr === currentLocation.pathname;
        }, false) ? (
          <HeaderUserSection
            firstname={firstname}
            extendedMenuTray={extendedMenuTray}
            toggleExtendedMenuTray={toggleExtendedMenuTray}
          />
        ) : (
          <Link
            type="button"
            className="application-header__back-to-menu"
            to={{ pathname: '/zoning-search' }}
          >
            {' '}
            <img src={LeftArrow} alt="Left Arrow Icon" />
            Back to Search{' '}
          </Link>
        )}
      </div>
      {children}
    </div>
  );
}
