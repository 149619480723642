import React from 'react';
import Suggestion from '../Suggestion';
import './SearchSuggestion.css';
import GoogleAttributionLogo from '../../assets/images/Powered_by_google.png';

export default function SearchSuggestion({
  toggleSuggestion,
  suggestions,
  dispatch,
  handleSuggestionSelection,
}) {
  return (
    <div
      className={
        toggleSuggestion && suggestions.length
          ? 'search-form__suggestions suggestions--active'
          : 'search-form__suggestions suggestions'
      }
    >
      <Suggestion
        dispatch={dispatch}
        handleSuggestionSelection={handleSuggestionSelection}
        suggestions={suggestions}
      />
      <div className="dashboard-search__g-icon">
        <img src={GoogleAttributionLogo} alt="Google Attribution" />
      </div>
    </div>
  );
}
