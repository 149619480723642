import React from 'react';
import './Pagination.css';

import LeftIcon from '../../assets/images/chevron-left.png';
import RightIcon from '../../assets/images/chevron-right.png';

/**
 * The Pagiantion Component renders a Pagination with two set of Pagiantion Buttons.
 *  - One from the starting number - i-e the Start Value of the Pagination.
 *  - One from the max number - i-e Total Pagination Items.
 *
 * Each item in the pagination will be a button - Indivisual page items - such as 1, 2, 3 .. as well as
 * '>', '<' Buttons.
 *
 * The updatePaginationFn will be called when any of these buttons are clicked.
 * @param {Number} currentActive - active item in the Pagination - i-e the page that is displayed currently
 * @param {Number} totalPages - total Pages that are in the Pagaination
 * @param {Number} totalPagesSecondSet - Total Pages in the second Pagination Set
 * @param {Number} currentHighestFirstSet - Current Item highest in First Set - we have to keep track of it because if the user moves to secondSet we wont be able to keep track of the firstSet rendered btns - because the firstSet is dynamic and it changes.
 * @param {Function} updatePaginationFn - Function to update the pagination
 * @returns JSX.Element
 */
export default function Pagination({
  currentActive,
  totalPages,
  currentHighestFirstSet,
  totalPagesSecondSet,
  updatePaginationFn,
}) {
  const createPagination = () => {
    const paginationArr = [];

    // First add the 'Back' button to Pagination
    paginationArr.push(
      <button
        type="button"
        key="prev"
        className="controls-pagination__left-icon"
        onClick={updatePaginationFn('Back')}
      >
        <img src={LeftIcon} alt="Left chvron" />
      </button>
    );
    const firstBtnSetStart =
      currentHighestFirstSet - 3 > 0 ? currentHighestFirstSet - 3 : 1; // sets up the start value of the first Pagination Btn Set

    // Set up the buttons for the first Pagination Btn Set (before the ...)
    for (let i = firstBtnSetStart; i <= currentHighestFirstSet; i += 1) {
      paginationArr.push(
        <button
          type="button"
          key={i}
          className={
            currentActive === i
              ? 'controls-pagination__pagination-item control-pagination--active'
              : 'controls-pagination__pagination-item '
          }
          onClick={updatePaginationFn('pagBtn')}
        >
          {i}
        </button>
      );
    }

    // check first if the second set of buttons are needed
    if (totalPagesSecondSet >= 0 && totalPages > 4) {
      // Set up the divider (disabled button - with the ...)
      paginationArr.push(
        <button
          type="button"
          key="disabled"
          className="controls-pagination__right-icon"
          disabled
        >
          ...
        </button>
      );

      // sets up the start value for the second Pagination Btn Set (after the ... btn)
      const secondBtnSetStart = totalPages - totalPagesSecondSet + 1;

      // Set up the buttons for the second Pagination Btn Set (after the ...)
      for (let i = secondBtnSetStart; i <= totalPages; i += 1) {
        paginationArr.push(
          <button
            key={i}
            type="button"
            className={
              currentActive === i
                ? 'controls-pagination__pagination-item control-pagination--active'
                : 'controls-pagination__pagination-item '
            }
            onClick={updatePaginationFn('pagBtn-later')}
          >
            {i}
          </button>
        );
      }
    }

    // add the 'Next' Btn to the pagination
    paginationArr.push(
      <button
        type="button"
        key="next"
        className="controls-pagination__right-icon"
        onClick={updatePaginationFn('Next')}
      >
        <img src={RightIcon} alt="Right Chevron" />
      </button>
    );

    return paginationArr;
  };

  return (
    <div className="controls-row__pagination controls-pagination">
      {[...createPagination()]}
    </div>
  );
}
