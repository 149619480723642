import React from 'react';
import './MapLegend.css';
import { useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';

export default function MapLegend() {
  const legendItems = useSelector((state) => state.blockbriefMap.activeLegend);
  if (legendItems.length) {
    return (
      <div className="map-container__map-legend map-legend">
        <h3 className="map-legend__heading">Map Legend</h3>
        <div className="legend-wrapper">
          <div className="map-legend__legend-items legend-items">
            {legendItems.map((item) => (
              <div className="legend-items__item" key={nanoid()}>
                <span
                  className="bullet"
                  style={{ backgroundColor: item.color }}
                />
                <span className="l_text">{item.key}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
}
