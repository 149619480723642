import React from 'react';
import ls from 'localstorage-slim';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Landing from '../containers/Landing';
import Signin from '../containers/Signin';
import UnderConstruction from '../components/UnderConstruction';
import PrivateRoutes from './PrivateRoutes';
import Webinars from '../containers/Webinars';
import Products from '../containers/Products';
import AdminSignin from '../containers/AdminSignin';
import AdminApplication from '../containers/AdminApplication';
import AdminDashboard from '../containers/AdminDashboard';
import { setLoggedIn, setUser } from '../reducers/signinSlice';

// This Components handles all the public routes of Blockbrief-
export default function PublicRoutes() {
  const AdminloggedIn = useSelector((state) => state.admin.loggedIn);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const dispatch = useDispatch();
  const item = ls.get('user', { decrypt: true });
  if (!loggedIn && item?.data) {
    dispatch(setUser(item.data));
    dispatch(setLoggedIn());
  }

  return (
    <Switch>
      <Route exact strict path="/products">
        <Products />
      </Route>
      <Route exact strict path="/webinars">
        <Webinars />
      </Route>
      <Route exact strict path="/register">
        <UnderConstruction />
      </Route>
      <Route exact strict path="/signin">
        <Signin />
      </Route>
      <Route exact strict path="/">
        <Landing />
      </Route>

      {/* Routes for the Corelogic Dashboard  - Admin Portal */}
      <Route exact strict path="/corelogic/signin">
        <AdminSignin />
      </Route>

      <Route
        exact
        strict
        path="/corelogic/dashboard"
        render={() =>
          AdminloggedIn ? (
            <AdminApplication>
              <AdminDashboard />
            </AdminApplication>
          ) : (
            <Redirect
              to={{
                pathname: '/corelogic/signin',
              }}
            />
          )
        }
      />
      {/* User Dashboard Routes */}
      <PrivateRoutes />
    </Switch>
  );
}
