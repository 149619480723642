import React from 'react';
import './LandingHeaderNav.css';
import { Link } from 'react-router-dom';

export default function LandingHeaderNav() {
  return (
    <div className="header__links">
      <Link to="/products">Products</Link>
      <Link to="/webinars">Webinars</Link>
      <a
        href="https://www.corelogic.com.au/products/blockbrief"
        target="_blank"
        rel="noreferrer"
      >
        Register
      </a>
      <Link to="/signin">Signin</Link>
    </div>
  );
}
