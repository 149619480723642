import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: false,
  modalType: '',
  modalData: null,
};

const ApplicationModalSlice = createSlice({
  name: 'applicationModal',
  initialState,
  reducers: {
    displayModal: (state, action) => {
      state.modalType = action.payload.modalType;
      if (!(action.payload.modalType === 'Planning Alert')) {
        const { pin, json, ...rest } = action.payload.modalData;
        state.active = true;
        state.modalData = {
          ...rest,
        };
        return;
      }
      const { location, authority, fullName, dateRecieved, ...rest } =
        action.payload.modalData;
      state.active = true;
      state.modalData = {
        ...rest,
        date_recieved: dateRecieved,
        authority: fullName,
      };
    },
    hideModal: (state) => {
      state.active = false;
      state.modalType = '';
      state.modalData = null;
    },
  },
});

export const { displayModal, hideModal } = ApplicationModalSlice.actions;
export default ApplicationModalSlice.reducer;
