import { createAsyncThunk } from '@reduxjs/toolkit';

import ls from 'localstorage-slim';
import axios from '../../Apis/axios';

const authenticateUser = createAsyncThunk(
  'signin/authenticateUser',
  async function checkUserCredentials(payload) {
    const result = await axios
      .post(`/signin`, {
        username: payload.username,
        password: payload.password,
      })
      .then((response) => {
        if (response.status === 401) {
          throw new Error('Wrong Credentials');
        }

        // Comparing Email Domain
        const regExp = /@blockbrief.com|@corelogic.com|asim.magma@gmail.com/;
        const IsEmailMatch = response.data.email.match(regExp);
        if (IsEmailMatch != null) {
          response.data.IsMarketing = true;
        } else {
          response.data.IsMarketing = false;
        }

        ls.set(
          'user',
          { data: response.data },
          {
            encrypt: true,
          }
        ); // with optional ttl in seconds

        return response.data;
      });

    return result;
  }
);

export default authenticateUser;
