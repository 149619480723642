import React from 'react';
import './ApplicationModalTableRow.css';

export default function ApplicationModalTableRow({ propName, propVal }) {
  return (
    <tr>
      <td>{propName}</td>
      <td>{propVal}</td>
    </tr>
  );
}
