import React, { useEffect, useState } from 'react';
// import ls from 'localstorage-slim';

import { toast } from 'react-toastify';
import axios from 'axios';
// import { useHistory, Router } from 'react-router-dom';
import axiosInstance from '../../Apis/axios';

import 'react-toastify/dist/ReactToastify.css';
import './Subscribe.css';

import LeftImageIllustration from '../../assets/images/Email_Illustration_left.png';
import RightImageIllustration from '../../assets/images/Email_Illustration_desktop.png';
import DownIcon from '../../assets/images/down-icon.png';
import DropdownTray from '../../components/DropdownTray';
import crossSubscribe from '../../assets/images/cross-subscribe.svg';
import LoadingIndicator from '../../assets/svg/Loading-indicator';

export default function Subscribe() {
  const [postCodeTray, togglePostCodeTray] = useState(false);
  const [subsurbsTray, setSubsurbsTray] = useState([]);
  const [selectedSuburb, setSelectedSuburb] = useState([]);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isSuburbLoading, setIsSuburbLoading] = useState(false);
  toast.configure();
  // const history = useHistory();
  // const options = {
  //   autoClose: 5000,
  //   className: '',
  //   position: toast.POSITION.TOP_RIGHT,
  // };

  useEffect(async () => {
    axios
      .post(
        `https://zoningdataus.info/au_zoning_data/_search`,
        {
          size: 0,
          aggs: {
            langs: {
              terms: {
                field: 'suburb.keyword',
                size: 500,
              },
            },
          },
        },
        {
          headers: {
            Authorization:
              'Basic ' +
              'em9uaW5nX2FwcF91c2VyOlpXeGhjM1JwWXpwdmIxVkpNMHh1V25oa2JuQmxXWEJ5Y0VKQllR',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response.status === 200)
          setSubsurbsTray(response.data.aggregations.langs.buckets);
        return [];
      });

    const responseData = await axiosInstance.get(`/suburbs`, { isAuth: true });
    // .catch((error) => {
    //   if (!error?.response) {
    //     toast.error('NetWork error', options);
    //   } else if (error.response.status === 401) {
    //     toast.error(
    //       'Hey, you! Stop right there \n Authorization required',
    //       options
    //     );
    //   }
    //   ls.remove('user');
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 5000);
    // });

    if (responseData?.status === 200 && responseData?.data.data) {
      setSelectedSuburb(responseData.data.data);
    }
  }, []);

  const handleClose = (e) => {
    const filteredArray = selectedSuburb.filter((item) => item !== e);
    setSelectedSuburb([...filteredArray]);
  };

  const handleSubmit = async () => {
    togglePostCodeTray(false);
    setIsSuburbLoading(true);
    const data = {
      suburbs: selectedSuburb,
    };

    const responseData = await axiosInstance.put(`/suburbs`, data, {
      isAuth: true,
    });

    if (responseData.status === 200) {
      setIsSuburbLoading(false);
      setIsSuccessModalVisible(true);
      setTimeout(() => {
        setIsSuccessModalVisible(false);
      }, 3000);
    }
  };

  return (
    <div
      className="application-container__subscribe-page subscribe-page"
      onClick={() => togglePostCodeTray(!postCodeTray)}
    >
      <div
        className="subsribe-page__subscribe-banner subscribe-banner"
        onClick={() => {
          togglePostCodeTray(false);
        }}
      >
        <div className="subscribe-banner__left-illustration">
          <img
            src={LeftImageIllustration}
            alt="Illustration  for Subscription"
          />
        </div>
        <div className="subscribe-banner__banner-content banner-content">
          <h1 className="banner-content__heading">
            Subscribe to our Email Alerts
          </h1>
          <p className="banner-content__text">
            We at Blockbrief update our data routinely.Get a competitive
            advantage using Blockbrief, receive Zoning Alerts to keep on top of
            all Investment and Development Opportunities.
          </p>
        </div>
        <div className="subscribe-banner__right-illustration">
          <img
            alt="Illustration  for Subscription"
            src={RightImageIllustration}
          />
        </div>
      </div>
      <div className="subscribe-page__subscribe-form subscribe-form">
        <div className="subscribe-form__subscribe-dropdown-btn subscribe-dropdown-btn">
          <h3> Select a Suburb </h3> <hr />
          <div className="subscribe-dropdown__img">
            <img src={DownIcon} alt="Dropdown icon" />
          </div>
          <DropdownTray
            Tray={postCodeTray}
            filterValues={subsurbsTray}
            selectedValue={[...selectedSuburb]}
            itemType="checkboxes"
            selectionFn={(e) => {
              // eslint-disable-next-line no-unused-expressions
              !selectedSuburb.includes(e)
                ? setSelectedSuburb((oldSub) => [...oldSub, e])
                : null;
            }}
            trayType="Data-Filter"
          />
        </div>
        <div className="registered-items">
          {selectedSuburb.map((data) => {
            return (
              <div key={data} className="registered-item--btn">
                <div
                  className="registered-item__closeBtn"
                  onClick={() => handleClose(data)}
                >
                  <img
                    src={crossSubscribe}
                    alt="crossbtn  for Subscription"
                    width="15px"
                    height="15px"
                  />
                </div>
                <div className="registered-item__postcode"> {data} </div>
              </div>
            );
          })}
        </div>
      </div>
      <button
        type="button"
        className="registered-item__button"
        onClick={handleSubmit}
      >
        {isSuburbLoading ? <LoadingIndicator /> : 'update suburbs'}
      </button>
      {isSuccessModalVisible ? (
        <div className="alert-box__success">
          <span>Success: </span>You have subscribed to the above suburbs.
        </div>
      ) : null}
    </div>
  );
}
