import React from 'react';
import './OffMarketDataToggle.css';

export default function OffMarketDataToggle({
  zoningStatus,
  permitStatus,
  handleToggle,
}) {
  return (
    <div className="offmarket-filter__data-toggle data-toggle">
      <label htmlFor="permits">
        <input
          type="checkbox"
          id="permits"
          name="permits"
          checked={permitStatus}
          onClick={() => handleToggle('Permit')}
        />
        Permits
      </label>
      <label htmlFor="zoning">
        <input
          type="checkbox"
          id="zoning"
          name="zoning"
          checked={zoningStatus}
          onClick={() => handleToggle('Zoning')}
        />
        Zoning
      </label>
    </div>
  );
}
