import React from 'react';
import './LandingBanner.css';
import CorelogicImage from '../../assets/images/banner_corelogic.png';

export default function LandingBanner() {
  return (
    <div className="landing-heading">
      <div className="landing-heading__heading">
        <img src={CorelogicImage} alt="Corelogic Banner" />
        <h1>
          The <span>Largest</span> Database of <br />
          Development Sites and <br />
          Zoning Data
        </h1>
        <a href="#more-info">Learn More </a>
      </div>
    </div>
  );
}
