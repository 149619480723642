import axios from 'axios';
import ls from 'localstorage-slim';
import history from '../history';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOSTNAME}`,
});
const requestArray = [];
let isRefreshing = false;

instance.interceptors.request.use(
  async (config) => {
    let item = {};

    if (ls.get('user', { decrypt: true })) {
      item = ls.get('user', { decrypt: true });
    }
    if (item.data) {
      if (item.data?.token) {
        ls.remove('user');
        history.replace({ path: '/signin', state: {} });
        history.push({ path: '/signin', state: ['Your session has expired'] });
      }
    }

    if (config.isAuth) {
      if (!item.data) {
        return config;
      }
      if (item.data.accessToken)
        config.headers.Authorization = `Bearer ${item.data.accessToken}`;
    }
    if (config.IsRefreshAuth) {
      if (!item.data) {
        return config;
      }

      config.headers.Authorization = `Bearer ${item.data.refreshToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    if (requestArray.length !== 0) {
      requestArray.forEach((x, i) => {
        if (response.config.url === x.url) {
          requestArray.splice(i, 1);
        }
      });
    }

    return response;
  },
  async (err) => {
    if (err.config.url === '/refreshToken' && err.response.status === 401) {
      ls.remove('user');
      history.push('/login');
      return Promise.reject(err);
    }

    const originalConfig = err.config;
    requestArray.push(originalConfig);

    if (err.response.status === 401) {
      if (!originalConfig._retry) {
        if (!isRefreshing) {
          isRefreshing = true;
          originalConfig._retry = true;
          return instance
            .get('/refreshToken', { IsRefreshAuth: true })
            .then((res) => {
              const item = ls.get('user', { decrypt: true });
              ls.remove('user');

              if (res.status === 200) {
                delete item.data.refreshToken;
                delete item.data.accessToken;
                item.data.accessToken = res.data.data.accessToken;
                item.data.refreshToken = res.data.data.refreshToken;
                ls.set('user', { data: item.data }, { encrypt: true });

                return instance(originalConfig);
              }
              if (requestArray.length !== 0) {
                requestArray.forEach((x) => {
                  x.headers.Authorization = `Bearer ${item.data.refreshToken}`;
                  instance(x);
                });
              }

              return instance;
            })

            .finally(() => {
              isRefreshing = false;
            });
        }
        // token is being refreshed and a promise that resolve has not been executed is returned
        return new Promise((resolve) => {
          resolve(instance(originalConfig));
        });
      }
    }

    if (err.response?.status === 403) {
      ls.remove('user');
      history.replace({ path: '/signin', state: {} });
      history.push({ path: '/signin', state: ['Your session has expired'] });
    }

    return Promise.reject(err);
  }
);

export default instance;
