import React from 'react';
import { useSelector } from 'react-redux';
import './ZoningTable.css';
import ZoningRow from '../ZoningRow';

export default function ZoningTable() {
  const zoningRecords = useSelector(
    (state) => state.zoningChanges.activeZoningProps
  );
  return (
    <table
      className="zoning-chagnes__zoning-table zoning-table"
      cellSpacing="0px"
    >
      <thead className="zoning-table__head zoning-table-head">
        <tr className="zoning-table-head__row">
          <th>City</th>
          <th>Record Id</th>
          <th>Date</th>
          <th>Address</th>
          <th>Project Brief</th>
        </tr>
      </thead>
      <tbody className="zoning-changes__body zoning-table-body">
        {[
          ...zoningRecords.map((zoningData) => (
            <ZoningRow
              id={zoningData.recordID}
              city={zoningData.city}
              recordID={zoningData.recordID}
              recordDate={zoningData.recordDate}
              address={zoningData.address}
              projectBrief={zoningData.projectBrief}
              key={zoningData.recordID}
            />
          )),
        ]}
      </tbody>
    </table>
  );
}
