import React from 'react';
import { useSelector } from 'react-redux';
import './DevelopmentTable.css';
import DevelopmentTableRow from '../DevelopmentTableRow';

export default function DevelopmentTable() {
  const siteRecords = useSelector(
    (state) => state.developmentSites.activeDevelopmentSitesRecords
  );

  return (
    <table
      className="development-sites__development-table development-table"
      cellSpacing="0px"
    >
      <thead className="development-table__head development-table-head">
        <tr className="development-table-head__row">
          <th>Address</th>
          <th>State</th>
          <th>Property Type</th>
          <th>Transaction Type</th>
          <th>Available Space</th>
        </tr>
      </thead>
      <tbody className="development-sites__body development-table-body">
        {[
          ...siteRecords.map((siteData) => (
            <DevelopmentTableRow
              id={siteData.id}
              address={siteData.address}
              state={siteData.state}
              propertyType={siteData.propertyType}
              transactionType={siteData.transactionType}
              availableSpace={siteData.availableSpace}
              key={siteData.address}
            />
          )),
        ]}
      </tbody>
    </table>
  );
}
