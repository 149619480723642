import { configureStore } from '@reduxjs/toolkit';
import AdminApplicationModal from './reducers/AdminApplicationModalSlice';
import adminReducer from './reducers/AdminSigninSlice';
import applicationModalReducer from './reducers/ApplicationModalSlice';
import blockbriefMapSlice from './reducers/blockbriefMapSlice';
import BlockbriefUsers from './reducers/BlockbriefUsers';
import developmentSitesReducer from './reducers/developmentSitesSlice';
import signinReducer from './reducers/signinSlice';
import zoningChangesReducer from './reducers/zoningChangesSlice';

export default configureStore({
  reducer: {
    user: signinReducer,
    zoningChanges: zoningChangesReducer,
    developmentSites: developmentSitesReducer,
    applicationModal: applicationModalReducer,
    blockbriefMap: blockbriefMapSlice,
    admin: adminReducer,
    adminApplicationModal: AdminApplicationModal,
    blockbriefUsers: BlockbriefUsers,
  },
});
