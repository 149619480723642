import { createSlice, createAction } from '@reduxjs/toolkit';
import fetchdevelopmentRecords, {
  updatePagination,
  fetchFilters,
} from './developmentSitesThunks';

import {
  startRowPageBtnReducer,
  endRowPageBtnReducer,
  nextPageBtnReducer,
  prevPageBtnReducer,
} from '../../utils/paginationUtils';

/* Development Sites Actions  */

// Pagination: ResetPagination - Resets the Pagination to initial state
const resetPagination = createAction(
  'developmentSites/pagination/resetPagination'
);

// Pagination: StartRowPageBtn - Changes the Active Pagination to one of the rendered Page in firstSet
const startRowPageBtnAction = createAction(
  'developmentSites/pagination/startRowPageBtn'
);

// Pagination: EndRowPageBtn - Changes the Active Pagination to one of the rendered Page in the endRow
const endRowPageBtnAction = createAction(
  'developmentSites/pagination/endRowPageBtn'
);

// Pagination: NextPage - Changes the currentActive rendered Page to next page
const nextPageBtnAction = createAction(
  'developmentSites/pagination/nextPageBtn'
);

// Pagination: prevPage - Changes the currentActive rendered Page to prevpage
const prevPageBtnAction = createAction(
  'developmentSites/pagination/prevPageBtn'
);

// FilterMenu: Set Selected Fitler Values Actions
const setFilterValues = createAction(
  'developmentSites/filterSelectedValues/setFilterValues'
);

const resetFilterValues = createAction(
  'developmentSites/filterSelectedValue/resetValues'
);

const initialState = {
  activeDevelopmentSitesRecords: [], // contains the development records, currently active in development-table - with the properties to show on the table
  detailedDevelopmentSitesRecords: [], // contains the complete development documents
  filterSelectedValues: {
    state: '',
    property_type: '',
    suburb: '',
    transaction_type: '',
    availableSpaceRange: {
      min: '',
      max: '',
    },
  },
  filters: {
    loaded: false,
    stateFilter: [],
    propertyTypeFilter: [],
    suburbFilter: [],
    transactionTypeFilter: [],
  },
  pagination: {
    // Development Sites Pagination State: tracking it in the store to render the previous history when the user changes tabs.
    paginationFetched: false, // indicates whether pagination is updated after mounting of the pagination component
    currentActive: 1, // indicates the current active page (number) in the pagination component
    totalRecords: 1000, // indicates the totalRecords returned
    totalPages: 100, // indicates the total pages
    /* 
      Note: totalPagesSecondSet and highestPageSetOne can both be derived from the currentActive, totalPages 
      We're tracking these both states to handle two edge cases specific to our Pagination Component - 
       1. totalPagesSecondSet: this can be calculated based on total pages and a check that the the
          items in secondSet cannot be greater than 3 - 
          keeping track of it here to not calculate it in every reducer  
       2. highestPageSetOne: to keep track of the firstRow Pagination -
          because when the currentActive moves to second row -
          we wont be able to keep track of pagination rendered in firstSet
    */
    totalPagesSecondSet: 3,
    highestPageSetOne: 4,
  },
  size: 10,
  query: {
    // Development Sites API Query Object
    bool: {
      must: [],
    },
  },
  status: 'idle', // change to one of the following enumns: 'idle' 'loading' 'error'
  error: {
    status: false,
    message: '',
  },
};

const developmentSitesSlice = createSlice({
  name: 'developmentSites',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchdevelopmentRecords.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.detailedDevelopmentSitesRecords = [
        ...action.payload.responseData.hits.hits,
      ];
      state.activeDevelopmentSitesRecords =
        action.payload.responseData.hits.hits.map(({ _source }) => ({
          id: _source.id,
          state: _source.state ? _source.state : 'N/A',
          transactionType: _source.transaction_type
            ? _source.transaction_type
            : 'N/A',
          city: _source.city ? _source.city : 'N/A',
          address: _source.address ? _source.address : 'N/A',
          propertyType: _source.property_type ? _source.property_type : 'N/A',
          availableSpace: _source.available_space
            ? _source.available_space
            : 'N/A',
        }));
    },
    [fetchdevelopmentRecords.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchdevelopmentRecords.rejected]: (state, action) => {
      state.error.status = true;
      state.error.message = action.payload.message;
      state.status = 'error';
    },
    [fetchFilters.fulfilled]: (state, action) => {
      state.filters.loaded = true;
      state.filters.stateFilter = action.payload.state;
      state.filters.propertyTypeFilter = action.payload.propertyType;
      state.filters.suburbFilter = action.payload.suburb;
      state.filters.transactionTypeFilter = action.payload.transactionType;
    },
    [startRowPageBtnAction]: startRowPageBtnReducer,
    [endRowPageBtnAction]: endRowPageBtnReducer,
    [nextPageBtnAction]: nextPageBtnReducer,
    [prevPageBtnAction]: prevPageBtnReducer,
    [resetPagination]: (state) => {
      state.pagination = initialState.pagination;
    },
    [updatePagination]: (state, action) => {
      state.pagination = action.payload;
    },
    [setFilterValues]: (state, action) => {
      state.filterSelectedValues.state = action.payload.state;
      state.filterSelectedValues.property_type = action.payload.property_type;
      state.filterSelectedValues.suburb = action.payload.suburb;
      state.filterSelectedValues.transaction_type =
        action.payload.transaction_type;
      state.filterSelectedValues.availableSpaceRange.min =
        action.payload.availableSpaceRange.min;
      state.filterSelectedValues.availableSpaceRange.max =
        action.payload.availableSpaceRange.max;
    },
    [resetFilterValues]: (state) => {
      state.filterSelectedValues = initialState.filterSelectedValues;
    },
  },
});

export {
  resetPagination,
  updatePagination,
  startRowPageBtnAction,
  endRowPageBtnAction,
  nextPageBtnAction,
  prevPageBtnAction,
  setFilterValues,
  resetFilterValues,
};
export default developmentSitesSlice.reducer;
