import React from 'react';
import APIRequestTable from '../APIRequestTable';
import './APIResponseSection.css';

const generateResponseMenuStr = (responseCode) => {
  if (responseCode === 200) return 'Success';
  if (responseCode === 401) return 'Acess Forbidden';
  if (responseCode === 400) return 'Bad Request';
  return null;
};

export default function APIResponseSection({
  responseData,
  responseStatus,
  toggleResponseStatus,
}) {
  return (
    <>
      <div className="api-content__response-status-menu response-status-menu">
        {responseData.map((response) => (
          <button
            type="button"
            className={
              responseStatus === response.code ? 'response-code--active' : null
            }
            onClick={() => toggleResponseStatus(response.code)}
          >
            <div
              className={
                response.code === 200 ? 'ok-btn-icon' : 'fail-btn-icon'
              }
            />
            {response.code} {generateResponseMenuStr(response.code)}
          </button>
        ))}
      </div>
      {responseData.map((response) =>
        response.code === responseStatus ? (
          <APIRequestTable
            tableHeading="Response JSON"
            requestTableData={response.responseTableData}
          />
        ) : null
      )}
    </>
  );
}
