export default [
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#9400d3',
  '#ff1493',
  '#00bfff',
  '#696969',
  '#1e90ff',
  '#b22222',
  '#228b22',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#778899',
  '#b0c4de',
  '#ff00ff',
  '#0000cd',
  '#ba55d3',
  '#9370db',
  '#191970',
  '#ffa500',
  '#da70d6',
  '#ffdab9',
  '#cd853f',
  '#ffc0cb',
  '#dda0dd',
  '#800080',
  '#bc8f8f',
  '#4169e1',
  '#fa8072',
  '#f4a460',
  '#2e8b57',
  '#a0522d',
  '#87ceeb',
  '#6a5acd',
  '#4682b4',
  '#d2b48c',
  '#d8bfd8',
  '#ff6347',
  '#ee82ee',
];
// 513 onward
// 1024 onward
