import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useGooglePlaces from '../../utils/useGooglePlaces';
import './MapSearchForm.css';
import useSuggestions from '../../utils/useSuggestions';
import MapSearchSuggestion from '../MapSearchSuggestion';

export default function MapSearchForm({ updateCurrentAddress }) {
  const { state: globalSelectedPlace } = useLocation();
  const [inputStr, setInputStr] = useState(
    globalSelectedPlace ? globalSelectedPlace.description : ''
  );
  const [suggestionsArr] = useGooglePlaces(inputStr);
  const [{ toggleSuggestion, selectedSuggestion, suggestions }, dispatch] =
    useSuggestions({
      selectedSuggestion: globalSelectedPlace,
      toggleSuggestion: false,
      suggestions: [],
    });

  useEffect(() => {
    dispatch({
      type: 'updateSuggestion',
      payload: [...suggestionsArr],
    });
  }, [suggestionsArr, dispatch, selectedSuggestion]);

  return (
    <div className="map-menu__map-search-form map-search-form">
      <input
        type="text"
        name="map-search-input"
        placeholder="Search by Suburb"
        value={inputStr}
        className={
          toggleSuggestion && suggestions.length
            ? 'map-search-form__input map-search-form__input--tray-active'
            : 'map-search-form__input'
        }
        autoComplete="off"
        onFocus={() => dispatch({ type: 'toggleSuggestion' })}
        onBlur={() => dispatch({ type: 'toggleSuggestion' })}
        onChange={(e) => {
          setInputStr(e.target.value);
          if (e.target.value === '')
            dispatch({ type: 'selectSuggestion', payload: null });
        }}
      />
      <MapSearchSuggestion
        toggleSuggestion={toggleSuggestion}
        suggestions={suggestions}
        dispatch={(suggestion) => () => {
          dispatch({ type: 'selectSuggestion', payload: suggestion });
          setInputStr(suggestion.description);
          updateCurrentAddress(suggestion.place_id);
        }}
        handleSuggestionSelection={setInputStr}
      />
      <button
        type="button"
        className="map-search-form__submit-btn"
        onClick={() => updateCurrentAddress(selectedSuggestion.place_id)}
      >
        Search
      </button>
    </div>
  );
}
