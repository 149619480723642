import React from 'react';
import AdminSigninForm from '../../components/AdminSigninForm';
import SigninBackgroundImage from '../../assets/images/login-bg.png';
import './AdminSignin.css';

export default function AdminSignin() {
  return (
    <div className="signin-container">
      <AdminSigninForm />
      <div className="signin-container__background-image-container">
        <img src={SigninBackgroundImage} alt="Background for the login page" />
      </div>
    </div>
  );
}
