import React from 'react';
import FeaturePluTable from '../FeaturePluTable';
import './ZoneFeatureMenu.css';
import FeatureDaTab from '../FeatureDaTab';

const toFixedWithoutRoundingOff = (num, fixed) => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
};

const generateTableMarkup = (currentSelected, data) => {
  if (currentSelected === 'Zone Data') {
    return (
      <table className="zone-feature-tray__feature-table">
        <tbody>
          {data.zoneData
            .map((el) => (
              <tr>
                <td>{el.propName === 'zone' ? 'zone name' : el.propName}</td>
                <td>{el.propVal}</td>
              </tr>
            ))
            .reverse()}

          {
            <>
              <tr>
                <td>Previous zone name</td>
                {data.previousZone?.zone_name ? (
                  <td>{data.previousZone.zone_name}</td>
                ) : (
                  <td>N/A</td>
                )}
              </tr>
              <tr>
                <td>Previous zone code</td>
                {data.previousZone?.zone_code ? (
                  <td>{data.previousZone.zone_code}</td>
                ) : (
                  <td>N/A</td>
                )}
              </tr>
            </>
          }
        </tbody>
      </table>
    );
  }
  if (currentSelected === 'PLU Data')
    return <FeaturePluTable pluData={data.pluData ? data.pluData : null} />;
  if (currentSelected === 'DA Data') return <FeatureDaTab />;
  if (currentSelected === 'Building Controls') {
    return (
      <div className="building-controls-table">
        <div className="building-controls-table__item">
          <h3>Building Height</h3>
          <span>
            {data.buildingControls &&
            data.buildingControls['Building Height'] !== 'N/A'
              ? String(
                  toFixedWithoutRoundingOff(
                    data.buildingControls['Building Height'].max_b_h,
                    1
                  )
                ).concat(data.buildingControls['Building Height'].units)
              : 'N/A'}
          </span>
        </div>
        <div className="building-controls-table__item">
          <h3>Floor Space Ratio</h3>
          <span>
            {data.buildingControls &&
            data.buildingControls['Floor Space Ratio'] !== 'N/A'
              ? String(
                  toFixedWithoutRoundingOff(
                    data.buildingControls['Floor Space Ratio'].fsr,
                    1
                  )
                )
              : 'N/A'}
          </span>
        </div>
        <div className="building-controls-table__item">
          <h3>Minimum Lot Size</h3>
          <span>
            {data.buildingControls &&
            data.buildingControls['Minimum Lot Size'] !== 'N/A'
              ? String(
                  toFixedWithoutRoundingOff(
                    data.buildingControls['Minimum Lot Size'].lot_size,
                    1
                  )
                ).concat(' ', data.buildingControls['Minimum Lot Size'].units)
              : 'N/A'}
          </span>
        </div>
      </div>
    );
  }
  return null;
};

export default function ZoneFeatureMenu({
  zoneData,
  pluData,
  buildingControls,
  previousZone,
  currentSelected,
  setCurrentSelected,
}) {
  // const store = useStore();
  // const mapState = useSelector((state) => state.blockbriefMap);
  // const slecter = useSelector((state) => state.developmentSites);
  // const [preZone, setPreZone] = useState(null);
  // useEffect(() => {
  //   axios
  //     .get(
  //       `/previous-zone/${mapState.mapLocation.lat}/${mapState.mapLocation.lng}/${mapState.activeMapLayer}`,
  //       { isAuth: true }
  //     )
  //     .then((response) => {
  //       setPreZone(response.data.data);
  //     });
  // }, [
  //   mapState.mapLocation.lat,
  //   mapState.mapLocation.lng,
  //   mapState.mapLocation.activeMapLayer,
  // ]);
  return (
    <div className="zone-feature-menu">
      <div className="zone-feature-menu__selection-menu-items">
        {['Zone Data', 'PLU Data', 'Building Controls', 'DA Data'].map((el) => (
          <button
            type="button"
            onClick={() => setCurrentSelected(el)}
            className={
              el === currentSelected ? 'selection-menu-items--active' : null
            }
          >
            {el}
          </button>
        ))}
      </div>
      <div className="zone-feature-tray__table-wrapper">
        {generateTableMarkup(currentSelected, {
          zoneData,
          pluData,
          buildingControls,
          previousZone,
        })}
      </div>
    </div>
  );
}
