import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { createPaginationState } from '../../utils/paginationUtils';

const updatePagination = createAction(
  'developmentSites/pagination/updatePagination'
);

// fetchFilterValues: fetches the filter values - state, city, date
async function fetchFilterValues(payload, thunkAPI) {
  try {
    const filterArr = ['state', 'property_type', 'suburb', 'transaction_type'];
    const promiseArr = filterArr.map((filter) =>
      axios({
        url: 'https://zoningdataus.info/listing_data_au/_search',
        method: 'POST',
        headers: {
          Authorization:
            'Basic em9uaW5nX2FwcF91c2VyOlpXeGhjM1JwWXpwdmIxVkpNMHh1V25oa2JuQmxXWEJ5Y0VKQllR',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          size: 0,
          aggs: { langs: { terms: { field: `${filter}.keyword`, size: 500 } } },
        }),
      })
    );
    const resolvedArr = await Promise.all(promiseArr);
    return {
      state: resolvedArr[0].data.aggregations.langs.buckets,
      propertyType: resolvedArr[1].data.aggregations.langs.buckets,
      suburb: resolvedArr[2].data.aggregations.langs.buckets,
      transactionType: resolvedArr[3].data.aggregations.langs.buckets,
    };
  } catch ({ message, data, status }) {
    throw thunkAPI.rejectWithValue({
      message,
      data,
      status,
    });
  }
}

const fetchFilters = createAsyncThunk(
  'developmentSites/fetchFilterValues',
  fetchFilterValues
);

async function fetchDevelopmentSitesDocs(payload, thunkAPI) {
  const developmentSitesState = thunkAPI.getState().developmentSites;

  const currentActiveSite = developmentSitesState.pagination.currentActive;

  const queryObject = developmentSitesState.query;
  const querySize = developmentSitesState.size;
  const develpomentFilters = developmentSitesState.filterSelectedValues;
  const queryFilterArr = []; // keeps track of the filters added to the query prop of API request
  const rangeFilter = [];
  Object.keys(develpomentFilters)
    .slice(0, 4) // slice the first three filter values - i-e the state, propertyType, suburb, transactionType
    .forEach((prop) =>
      develpomentFilters[prop]
        ? queryFilterArr.push({
            match: {
              [`${prop}.keyword`]: `${develpomentFilters[prop]}`,
            },
          })
        : null
    );

  queryFilterArr.push({
    terms: {
      active: ['true'],
    },
  });
  const { min, max } = develpomentFilters.availableSpaceRange;
  if (max) {
    rangeFilter.push({
      range: { formatted_size: { gte: Number(min), lte: Number(max) } },
    });
  }

  const developmentRecords = await axios({
    url: 'https://zoningdataus.info/listing_data_au/_search',
    method: 'POST',
    headers: {
      Authorization:
        'Basic em9uaW5nX2FwcF91c2VyOlpXeGhjM1JwWXpwdmIxVkpNMHh1V25oa2JuQmxXWEJ5Y0VKQllR',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      from: currentActiveSite * 10 - 10,
      size: querySize,
      query: {
        bool: {
          ...queryObject.bool,
          must: [...queryFilterArr],
          filter: rangeFilter.length ? [...rangeFilter] : [],
        },
      },
    }),
  })
    .then(({ data }) => {
      const { paginationFetched } =
        thunkAPI.getState().developmentSites.pagination;
      const totalValues = data.hits.total.value;
      const filters = thunkAPI.getState().developmentSites.filters.loaded;

      if (!paginationFetched)
        thunkAPI.dispatch(
          updatePagination(createPaginationState({ totalValues }))
        );

      if (!filters) thunkAPI.dispatch(fetchFilters());

      return { responseData: data };
    })
    .catch(({ message, status, data }) => {
      // if the responseCode is not in the 200 range the promise is rejected
      throw thunkAPI.rejectWithValue({
        message,
        data,
        status,
      });
    });
  return developmentRecords;
}

const fetchdevelopmentRecords = createAsyncThunk(
  'developmentSites/fetchdevelopmentSites',
  fetchDevelopmentSitesDocs
);

export { updatePagination, fetchFilters };
export default fetchdevelopmentRecords;
