import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { googlecode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import APIRequestMenu from '../APIRequestMenu';
import APIRequestTable from '../APIRequestTable';
import APIResponseSection from '../APIResponseSection';
import './APIContent.css';

const createRequestDetailsComp = ({
  activeRequestMenu,
  responseStatus,
  toggleResponseStatus,
  requestData,
  responseData,
  sampleCode,
}) => {
  if (activeRequestMenu === 'Request')
    return (
      <APIRequestTable
        tableHeading="Query Parameters"
        requestTableData={requestData}
      />
    );
  if (activeRequestMenu === 'Response')
    return (
      <APIResponseSection
        responseData={responseData}
        responseStatus={responseStatus}
        toggleResponseStatus={toggleResponseStatus}
      />
    );
  if (activeRequestMenu === 'Code Sample')
    return (
      <div className="api-content__code">
        <SyntaxHighlighter language="javascript" wrapLines style={googlecode}>
          {sampleCode}
        </SyntaxHighlighter>
      </div>
    );
  return null;
};

export default function APIContent({
  requestMethod,
  requestURL,
  requestDescr,
  requestData,
  responseData,
  sampleCode,
}) {
  const [activeRequestMenu, toggelActiveRequestMenu] = useState('Response');
  const [responseStatus, toggleResponseStatus] = useState(200);
  return (
    <div className="api-container__api-content api-content">
      <div className="api-content__request-heading request-heading">
        <span className="request-heading__method">{requestMethod}</span>
        <span className="request-heading__url">{requestURL}</span>
      </div>
      <p className="api-content__request-description">
        {requestDescr} The request must contain your API Credentials, get your
        API Credentials from{' '}
        <Link
          className="api-credentials__link"
          to={{ pathname: '/api-credentails' }}
        >
          here
        </Link>
      </p>
      <APIRequestMenu
        activeRequestMenu={activeRequestMenu}
        toggelActiveRequestMenu={toggelActiveRequestMenu}
      />
      {createRequestDetailsComp({
        activeRequestMenu,
        responseStatus,
        toggleResponseStatus,
        requestData,
        responseData,
        sampleCode,
      })}
    </div>
  );
}
