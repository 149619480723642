import React from 'react';
import './APIEndpoitnTray.css';

export default function APIEndpointTray({ endpointItems, endpointTray }) {
  return (
    <div
      className={
        endpointTray
          ? 'api-item__api-item-details api-item-details api-item-details--active'
          : 'api-item__api-item-details api-item-details'
      }
    >
      {endpointItems.map((endpoint) => (
        <div className="api-item-endpoint">
          <span>{endpoint.method}</span>
          <span className="api-item-details__url">{endpoint.url}</span>
        </div>
      ))}
    </div>
  );
}
