import React from 'react';
import DropdownItems from '../DropdownItems';
import DropdownTray from '../DropdownTray';
import './DropdownMapMenuBtn.css';

const generateBtnClass = (disabled = false, tray) => {
  if (disabled) {
    return 'map-menu__dropdown-btn dropdown-btn dropdown-btn--disabled';
  }
  if (!tray) {
    return 'map-menu__dropdown-btn dropdown-btn';
  }
  return 'map-menu__dropdown-btn dropdown-btn map-menu__dropdown-btn--active';
};

/**
 * This component takes in the following named props -
 * @param {function} handleOpenTray - This function toggles the classes for the dropdown tray open and closed state
 * @param {string} selectedValue - The currently active string to show in the dropdown button
 * @param {string} placeholderStr - The placeholder string for the dropdown
 * @returns
 */
export default function DropdownMapMenuBtn({
  handleOpenTray,
  selectedValue,
  placeholderStr,
  Tray,
  filterValues,
  selectionFn,
  itemType,
  loading,
  disabled,
}) {
  return (
    <button
      type="button"
      className={generateBtnClass(disabled, Tray)}
      onClick={handleOpenTray}
    >
      <DropdownItems
        selectedValue={selectedValue}
        placeholderStr={placeholderStr}
        loading={loading}
      />
      <DropdownTray
        Tray={Tray}
        filterValues={filterValues}
        selectedValue={selectedValue}
        selectionFn={selectionFn}
        trayType="Map-Menu"
        itemType={itemType}
      />
    </button>
  );
}
