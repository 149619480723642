import React from 'react';
import LocationIcon from '../../assets/images/pin-blue.png';
import './Suggestion.css';

export default function Suggestion({ suggestions, dispatch }) {
  return (
    <div className="suggestions__wrapper">
      {suggestions.map((suggestion) => (
        <div
          className="suggestions__item"
          key={suggestion.place_id}
          onMouseDown={dispatch(suggestion)}
        >
          <img src={LocationIcon} alt="Location Icon" />
          <li>{suggestion.description}</li>
        </div>
      ))}
    </div>
  );
}
