import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import './LandingSearch.css';
import SearchIcon from '../../assets/images/search.png';
import SearchForm from '../SearchForm';

export default function LandingSearch() {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const history = useHistory();
  return (
    <div className="landing-search">
      <h3 className="landing-search__heading search-heading">
        <span>
          <img
            src={SearchIcon}
            alt="search-icon"
            className="search-heading__img"
          />
        </span>
        Search For Development Sites
      </h3>
      <SearchForm
        submitHandler={(selectedSuggestion) => () => {
          if (loggedIn) {
            history.push('/zoning-search/map-search', selectedSuggestion);
            return;
          }
          history.push('/signin', {
            search: true,
            selectedSuggestion,
          });
        }}
      />
      <div className="landing-search__btns btns-row">
        <a
          href="https://www.corelogic.com.au/products/blockbrief"
          target="_blank"
          rel="noreferrer"
          type="button"
          className="btns-row__btn"
        >
          Contact Us
        </a>
        <Link to="/webinars" type="button" className="btns-row__btn">
          Learn More
        </Link>
      </div>
    </div>
  );
}
