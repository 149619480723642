import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: false,
  modalType: '',
  user: null,
};

const AdminApplicationModalSlice = createSlice({
  name: 'applicationModal',
  initialState,
  reducers: {
    displayAdminModal: (state, action) => {
      state.active = !state.active;
      state.modalType = action.payload.modalType;
      state.user = action.payload.user ? action.payload.user : null;
    },
    hideAdminModal: (state) => {
      state.active = false;
      state.modalType = '';
      state.user = null;
      state.modalErr = '';
    },
  },
});

export const { displayAdminModal, hideAdminModal, setError } =
  AdminApplicationModalSlice.actions;
export default AdminApplicationModalSlice.reducer;
