import React from 'react';
import DownIcon from '../../assets/images/down-icon.png';
import './DropdownItems.css';
import Spinner from '../../assets/svg/Spinner';

/**
 * This is a component that abstracts the DropdownItems from the Dropdown Button.
 * It takes in the following named props
 * @param {string} selectedValue - The currently active string to show in the dropdown button
 * @param {string} placeholderStr - The placeholder string for the dropdown
 * @returns JSX.Element
 */
export default function DropdownItems({
  selectedValue,
  placeholderStr,
  loading = false,
}) {
  return (
    <>
      <h3 className="dropdown-btn__text">
        {!(selectedValue && !Array.isArray(selectedValue))
          ? placeholderStr
          : selectedValue}
      </h3>
      <hr />
      <span className="dropdown-btn__btn">
        {!loading ? <img src={DownIcon} alt="down icon" /> : <Spinner />}
      </span>
    </>
  );
}
