import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CalendarIcon from '../../assets/images/calendar-interface-symbol.png';
import ToggleIcon from '../../assets/images/cancel.png';
import { displayModal } from '../../reducers/ApplicationModalSlice';
import { hidePlanningTray } from '../../reducers/blockbriefMapSlice';
import './MapPermitTray.css';

export default function MapPermitTray() {
  const mapState = useSelector((state) => state.blockbriefMap);
  const { planningTray: trayStatus, planningRecords: permitData } = useSelector(
    (state) => state.blockbriefMap.planningData
  );
  const dispatch = useDispatch();

  return (
    <div
      className="map-container__permit-tray permit-tray"
      style={
        permitData.length &&
        trayStatus &&
        mapState.currentActiveMap === 'Planning Alerts'
          ? { style: 'flex' }
          : { display: 'none' }
      }
    >
      <div className="permit-tray__permit-heading permit-heading">
        <button
          type="button"
          className="permit-heading__toggle-btn"
          onClick={() => {
            dispatch(hidePlanningTray());
          }}
        >
          <img src={ToggleIcon} alt="Toggle Permits Tray" />
        </button>
        <h3 className="permit-heading__heading">Planning Alerts</h3>
      </div>
      <div className="permit-tray__permit-content permit-content">
        {[
          ...permitData.map((permit) => (
            <div
              className="permit-content__permit-item permit-item"
              key={nanoid()}
            >
              <h3 className="permit-item__heading">{permit.address}</h3>
              <p className="permit-item__sub-heading">
                {permit.fullName ?? 'N/A'}
              </p>
              <h6 className="permit-item__date">
                <span>
                  <img src={CalendarIcon} alt="Calendar Icon" />
                </span>
                {permit.dateRecieved ?? 'N/A'}
              </h6>
              <div className="permit-item__permit-details">
                <p>{permit.description ?? 'N/A'}</p>
                <button
                  type="button"
                  onClick={() => {
                    dispatch(
                      displayModal({
                        modalType: 'Planning Alert',
                        modalData: permit,
                      })
                    );
                    document.body.classList.toggle('no-scroll');
                  }}
                >
                  See More
                </button>
              </div>
            </div>
          )),
        ]}
      </div>
    </div>
  );
}
