import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from '@googlemaps/js-api-loader';

import { setCurrentFeatureAddress2 } from '../reducers/blockbriefMapSlice';

function getDetailsFromPlace(place) {
  let region;
  let locality;
  Object.keys(place.address_components).forEach((i) => {
    if (
      place.address_components[i].types[0] === 'administrative_area_level_1'
    ) {
      region = place.address_components[i].long_name;
    }
    if (
      place.address_components[i].types[0] === 'colloquial_area' ||
      place.address_components[i].types[0] === 'locality' ||
      place.address_components[i].types[0] === 'administrative_area_level_2'
    ) {
      locality = place.address_components[i].long_name;
    }
  });
  return [region, locality];
}

const getGoogleMapsAPI = async () => {
  const GoggleAPILoader = await new Loader({
    libraries: ['places'],
    version: 3.44,
    region: 'au',
  }).load();
  return GoggleAPILoader;
};

/**
 * This is a custom hook over the GooglePlaces API. This hook takes in a input string
 * and returns a list of places suggestion for the input string.
 * @param {string} inputStr
 * @returns [objects]
 */
export default function UseGooglePlaces(inputStr) {
  const [suggestionsArr, setSuggestionsArr] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentFeatureAddress2(inputStr));
    getGoogleMapsAPI().then(async (google) => {
      const GoogleMapsPlaces = new google.maps.places.AutocompleteService();
      if (inputStr) {
        GoogleMapsPlaces.getPlacePredictions(
          {
            input: inputStr,
            componentRestrictions: {
              country: 'au',
            },
          },
          (suggest) => (suggest ? setSuggestionsArr([...suggest]) : null)
        );
      } else {
        setSuggestionsArr([]);
      }
    });
  }, [inputStr]);

  return [suggestionsArr];
}

function setLocation(placeID, googleMapLayer, mapStateDispatch, map) {
  getGoogleMapsAPI().then((google) => {
    const service = new google.maps.places.PlacesService(googleMapLayer);
    service.getDetails(
      {
        placeId: placeID,
        fields: ['geometry', 'address_component'],
      },
      (place, status) => {
        if (status === 'OK') {
          const [state, city] = getDetailsFromPlace(place);
          mapStateDispatch({
            type: 'updateLocation',
            payload: {
              lat: Number(place.geometry.location.lat()),
              lng: Number(place.geometry.location.lng()),
              state,
              city,
            },
          });

          if (map) {
            map.setView(
              [
                Number(place.geometry.location.lat()),
                Number(place.geometry.location.lng()),
              ],
              18,
              { duration: 0.25 }
            );
            map.invalidateSize(true);
          }
        }
      }
    );
  });
}

export { setLocation };
