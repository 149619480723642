import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapSearchForm from '../MapSearchForm';
import DropdownMapMenuBtn from '../DropdownMapMenuBtn';
import OffMarketFilterTray from '../OffMarketFiltersTray';
import './MapMenu.css';
import MapMenuFilterBtn from '../MapMenuFilterBtn';
import {
  hidePlanningTray,
  resetLegend,
  resetPlanningData,
  setCurrentActiveMap,
  setPlaceID,
  resetZoningData,
  toggleZoningDataStatus,
  toggleOffMarketFilter,
  resetFilterForm,
  resetSitesData,
  toggleOffMarketTrayStatus,
  toggleOnMarketTrayStatus,
  closeAllFilterTrays,
  setMapLatLng,
} from '../../reducers/blockbriefMapSlice';
import {
  fetchPlanningData,
  fetchSitesData,
  fetchZoningData,
  fetchZoningFilterValues,
} from '../../reducers/blockbriefMapSlice/blockbriefMapThunks';
import OnMarketFilterTray from '../OnMarketFilterTray';
import { initalizeTangramLayer } from '../../utils/mapHelpersFn';
import YamlFile from '../../assets/tangramSceneYaml/all.yaml';

export default function MapMenu({ map, tangramLayer, setTangramLayer }) {
  const [MapLayerTray, setMapLayerTray] = useState(false);
  const [ZoneSelectionTray, setZoneSelectionTray] = useState(false);
  const [zoneSelection, setZoneSelection] = useState([]);
  const mapState = useSelector((globalState) => globalState.blockbriefMap);
  const dispatch = useDispatch();

  // This effect update the tangram layer - scene config as zone selection changes to filter different zones
  useEffect(() => {
    if (zoneSelection.length) {
      tangramLayer.scene.config.global.filter_zone_code = [...zoneSelection];
      tangramLayer.scene.updateConfig({ rebuild: true });
      tangramLayer.scene.requestRedraw();
      return;
    }
    // First check if tangramLayer ref is set or not and the scene object is either available
    if (tangramLayer && tangramLayer.scene) {
      tangramLayer.scene.config.global.filter_zone_code = zoneSelection;
      tangramLayer.scene.updateConfig({ rebuild: true });
    }
  }, [zoneSelection.length]);

  return (
    <div className="map-page__map-menu map-menu">
      {/* <!-- Search Button - Componenet --> */}
      <MapSearchForm
        updateCurrentAddress={(placeID) => {
          const { placeID: currPlaceID, placeIDGeoCode } = mapState.mapLocation;
          // handles the case when the same map address is selected
          if (currPlaceID === placeID) {
            dispatch(
              setMapLatLng({ lat: placeIDGeoCode.lat, lng: placeIDGeoCode.lng })
            );
            map.setView([placeIDGeoCode.lat, placeIDGeoCode.lng], 18);
            map.invalidateSize(true);
            return;
          }
          dispatch(setPlaceID({ placeID }));
        }}
      />
      {/* <!-- Map Menu - Drop Down Button - Change Map  --> */}
      <DropdownMapMenuBtn
        handleOpenTray={() => setMapLayerTray(!MapLayerTray)}
        selectedValue={mapState.currentActiveMap}
        placeholderStr="Map Layer"
        Tray={MapLayerTray}
        filterValues={[...mapState.availableMaps]}
        loading={mapState.planningData.planningLoading}
        selectionFn={
          (e) /* changeLayer: this function changes the map layers */ => {
            dispatch(resetLegend());
            setZoneSelection([]);
            dispatch(closeAllFilterTrays());
            const currentMapLayer = mapState.currentActiveMap;
            const selectedMapLayer = e.target.innerText.trim();
            if (!(selectedMapLayer === 'Planning Alerts')) {
              // Set the min zoom here explicitly - it's set to 16 when the planning alerts map layer is active
              map.setMinZoom(0);
              if (
                currentMapLayer === 'Planning Alerts' &&
                !mapState.offMarketStatus
              ) {
                dispatch(resetPlanningData());
                // console.log(currentMapLayer, 'line 98');
                const layer = initalizeTangramLayer(YamlFile);
                setTangramLayer(layer);
                layer.addTo(map);
              }
              // Update the currentActiveMap to the selected Map
              dispatch(setCurrentActiveMap(selectedMapLayer));
            } else {
              dispatch(closeAllFilterTrays());
              if (!mapState.zoningData.filterStatus) {
                const bounds = map.getBounds();

                // Fetch the planning data for the current bounds
                dispatch(
                  fetchPlanningData({
                    bounds,
                    planningTray: !mapState.zoningData.filterStatus, // if OFF MARKET FIlTER is in-active than show the planning tray
                  })
                );
              }

              // handle the case when previously a tangram overlay was displayed
              if (currentMapLayer !== 'Planning Alerts') {
                map.removeLayer(tangramLayer);
                map.invalidateSize(false);
                setTangramLayer(null);
                map.setMinZoom(16);
                map.setZoom(16);

                // Reset the legend
                dispatch(resetLegend());
              }
              dispatch(setCurrentActiveMap(selectedMapLayer));
            }
          }
        }
      />
      {/* <!-- Map Menu - Drop Down Button - Zone Filter  --> */}
      <DropdownMapMenuBtn
        selectedValue={[...zoneSelection]}
        placeholderStr="Select a Zone"
        Tray={ZoneSelectionTray}
        filterValues={[...mapState.activeZoneFilterValues]}
        loading={mapState.activeZoneFilterLoading}
        itemType="checkboxes"
        disabled={mapState.currentActiveMap === 'Planning Alerts'}
        selectionFn={(selectedValue) => {
          if (selectedValue) {
            /* if the selected value already exsists in the zoneSelection array than remove it */
            if (zoneSelection.includes(selectedValue)) {
              setZoneSelection(
                zoneSelection.filter((val) => val !== selectedValue)
              );
              return;
            }
            zoneSelection.push(selectedValue.trim());
          }
        }}
        handleOpenTray={
          !mapState.zoningData.zoningTray
            ? () => setZoneSelectionTray(!ZoneSelectionTray)
            : null
        }
      />
      {/* <!-- Map Menu - Filter Button - On Market Filter Button  --> */}
      <MapMenuFilterBtn
        filterName="On Market Filter"
        filterStatus={mapState.listingSitesData.filterStatus}
        loadingState={mapState.listingSitesData.sitesLoading}
        toggletrayButton={() => {
          if (mapState.listingSitesData.filterStatus)
            dispatch(toggleOnMarketTrayStatus());
        }}
        filterButtonHandler={() => {
          if (!mapState.listingSitesData.filterStatus) {
            const bounds = map.getBounds();

            dispatch(
              fetchSitesData({
                bounds,
              })
            );
          } else {
            dispatch(resetSitesData());
            dispatch(toggleOnMarketTrayStatus({ filterStatus: false }));
          }
        }}
      >
        <OnMarketFilterTray filterStatus={mapState.onMarketTrayStatus} />
      </MapMenuFilterBtn>
      {/* <!-- Map Menu - Filter Button - Off Market Filter Button --> */}
      <MapMenuFilterBtn
        filterName="Off Market Filter"
        filterStatus={mapState.offMarketStatus}
        loadingState={
          mapState.zoningData.filters.filterLoading ||
          (mapState.offMarketStatus &&
            !mapState.planningData.planningTray &&
            mapState.planningData.planningLoading)
        }
        toggletrayButton={() => {
          if (
            mapState.zoningData.filterStatus ||
            mapState.planningData.filterStatus
          )
            dispatch(toggleOffMarketTrayStatus());
        }}
        filterButtonHandler={
          () /* Off Market Filter Button Handler */ => {
            // If Off Market Filter Button is active and the user click's it again - than turn it off and reset the state
            if (mapState.offMarketStatus) {
              dispatch(toggleOffMarketFilter());
              dispatch(resetFilterForm());
              dispatch(resetZoningData());
              if (!(mapState.currentActiveMap === 'Planning Alerts'))
                /* if the planning alerts tab is true donot remove the planning markers */
                dispatch(resetPlanningData());
              dispatch(toggleOffMarketTrayStatus({ filterStatus: false }));
              return;
            }

            dispatch(toggleOffMarketFilter());

            dispatch(toggleZoningDataStatus()); // toggle the loading state

            // Extract the Map Bounds to fetch the filter and data
            const bounds = map.getBounds();

            // dispatch an action to fetch zoning Filters
            dispatch(
              fetchZoningFilterValues({
                bounds,
              })
            );

            // dispatch an action to fetch zoning  data
            dispatch(
              fetchZoningData({
                bounds,
              })
            );

            // dispatch an action to fetch planning data - if the Planning Alerts is not the currentMap
            if (!(mapState.currentActiveMap === 'Planning Alerts'))
              dispatch(
                fetchPlanningData({
                  bounds,
                  planningTray: false,
                })
              );

            // Open the Off Market filter tray
            dispatch(toggleOffMarketTrayStatus({ filterStatus: true }));
            dispatch(hidePlanningTray()); // hide the planning tray - if off market is clicked when Planning Alerts is active from Map Type dropdown
          }
        }
      >
        <OffMarketFilterTray
          filterStatus={mapState.offMarketTrayStatus}
          map={map}
        />
      </MapMenuFilterBtn>
    </div>
  );
}
