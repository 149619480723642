import { nanoid } from '@reduxjs/toolkit';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CalendarIcon from '../../assets/images/calendar-interface-symbol.png';
// import ToggleIcon from '../../assets/images/cancel.png';
import { displayModal } from '../../reducers/ApplicationModalSlice';
// import { hidePlanningTray } from '../../reducers/blockbriefMapSlice';
import './FeatureDaTab.css';
import LoadingIndicator from '../../assets/svg/Loading-indicator';

export default function MapplanningTray() {
  const mapState = useSelector((state) => state.blockbriefMap);
  const [isMsg, setMsg] = useState(false);
  //   const { planningTray: trayStatus, planningRecords: planningData } = useSelector(
  //     (state) => state.blockbriefMap.planningData
  //   );

  const [planingAlertData, setPlaningAlertData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://api.planningalerts.org.au/applications.json?key=3OTZlHOeiXLb0S7XMrR1&lat=${mapState.mapLocation.lat}&lng=${mapState.mapLocation.lng}&radius=5`
      );
      const data = await response.json();

      if (data.length) {
        setMsg(true);
      } else {
        setMsg(true);
      }
      setPlaningAlertData(data);
    };
    fetchData();
  }, [mapState.mapLocation.lat, mapState.mapLocation.lat]);

  return (
    <div
      className="map-container__planning-tray planning-tray"
      style={{ style: 'flex' }}
    >
      {/* <div className="planning-tray__planning-heading planning-heading">
        <button
          type="button"
          className="planning-heading__toggle-btn"
          onClick={() => {
            dispatch(hidePlanningTray());
          }}
        >
          <img src={ToggleIcon} alt="Toggle plannings Tray" />
        </button>
        <h3 className="planning-heading__heading">Planning Alerts</h3>
      </div> */}
      <div className="planning-tray__planning-content planning-content">
        {planingAlertData.length
          ? [
              planingAlertData.map((planning) => (
                <div
                  className="planning-content__planning-item planning-item"
                  key={nanoid()}
                >
                  <h3 className="planning-item__heading">{planning.address}</h3>
                  <p className="planning-item__sub-heading">
                    {planning.application.council_reference ?? 'N/A'}
                  </p>
                  <h6 className="planning-item__date">
                    <span>
                      <img src={CalendarIcon} alt="Calendar Icon" />
                    </span>
                    {planning.application.date_received ?? 'N/A'}
                  </h6>
                  <div className="planning-item__planning-details">
                    <p>{planning.application.description ?? 'N/A'}</p>

                    <button
                      type="button"
                      onClick={() => {
                        dispatch(
                          displayModal({
                            modalType: 'Planning Alert',
                            modalData: planning.application,
                          })
                        );
                        document.body.classList.toggle('no-scroll');
                      }}
                    >
                      See More
                    </button>
                  </div>
                </div>
              )),
            ]
          : [
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexFlow: 'row nowrap',
                  lineHeight: '2.5em',
                  margin: '10px 25px',
                  color: 'hsla(0,0%,43.9%,.6980392156862745)',
                  fontSize: '.9em',
                }}
              >
                {!isMsg ? (
                  <LoadingIndicator color="black" width="200" height="200" />
                ) : (
                  'Planning alerts are not available for this address'
                )}
              </div>,
            ]}
      </div>
    </div>
  );
}
