import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const authenticateAdmin = createAsyncThunk(
  'admin/authenticateAdmin',
  async function checkAdminCredentials(payload) {
    const requestObj = {
      username: payload.username,
      password: payload.password,
    };
    const result = await axios({
      url: `${process.env.REACT_APP_API_HOSTNAME}/admin/signin`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      data: requestObj,
    }).then((response) => {
      if (response.status === 401) {
        throw new Error('Wrong Credentials');
      }
      return response.data;
    });

    return result;
  }
);

export default authenticateAdmin;
