import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import L from 'leaflet';
import { FeatureGroup, Marker } from 'react-leaflet';
import SilverIcon from '../../assets/images/zearch-listings-silver.png';
import { displayModal } from '../../reducers/ApplicationModalSlice';

export default function PlanningAlertsMarkers({
  planningStatus,
  planningData,
}) {
  const dispatch = useDispatch();
  const returnedValue = useMemo(
    () => (
      <FeatureGroup>
        {planningData.map((data) => (
          <Marker
            position={[Number(data.location.lat), Number(data.location.lng)]}
            key={nanoid()}
            riseOnHover
            eventHandlers={{
              click: () => {
                dispatch(
                  displayModal({
                    modalType: 'Planning Alert',
                    modalData: data,
                  })
                );
                document.body.classList.toggle('no-scroll');
              },
            }}
            icon={
              new L.Icon({
                iconUrl: SilverIcon,
                iconSize: [32, 37],
                iconAnchor: [16, 40],
              })
            }
          />
        ))}
      </FeatureGroup>
    ),
    [planningStatus, planningData]
  );
  if (planningStatus && planningData.length) return returnedValue;
  return null;
}
