import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { hideAdminModal } from '../../reducers/AdminApplicationModalSlice';
import './AdminApplicationModal.css';
import cancelButton from '../../assets/images/cancel.png';
import UserInputForm from '../UserInputForm';
import fetchUsersAction from '../../reducers/BlockbriefUsers/BlockbriefUsersThunk';
import UpdatePasswordFormAdmin from '../UpdatePasswordFormAdmin';

const createModalForm = ({ modalData }) => {
  if (modalData.modalType === 'Create New User') {
    return (
      <UserInputForm
        submissionFn={modalData.createNewUser}
        responseLoading={modalData.responseLoading}
        modalType={modalData.modalType}
      />
    );
  }
  if (modalData.modalType === 'Edit User') {
    return (
      <UserInputForm
        userID={modalData.user.member_id}
        firstName={modalData.user.firstname}
        lastName={modalData.user.lastname}
        address={modalData.user.address}
        corelogicAccount={modalData.user.corelogic_acc_num}
        email={modalData.user.email}
        mobilePhone={modalData.user.m_phone}
        phone={modalData.user.phone}
        premium={modalData.user.premium}
        premiumPro={modalData.user.premium_pro}
        modalType={modalData.modalType}
        responseLoading={modalData.editLoading}
        submissionFn={modalData.editUser}
      />
    );
  }

  if (modalData.modalType === 'Update Password') {
    return <UpdatePasswordFormAdmin userData={modalData.user} />;
  }
  return null;
};

export default function AdminApplicationModal() {
  const dispatch = useDispatch();
  const { active, modalType, user } = useSelector(
    (state) => state.adminApplicationModal
  );
  const [responseLoading, setResponseLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const handleModalClose = () => {
    dispatch(hideAdminModal());
    document.body.classList.toggle('no-scroll');
  };

  const createNewUser = async (userObj) => {
    const requestURL = `${process.env.REACT_APP_API_HOSTNAME}/admin/add-user`;
    setResponseLoading(true);
    const result = await axios({
      url: requestURL,
      method: 'POST',
      data: {
        user: { ...userObj },
      },
      withCredentials: true,
    });
    if (result.status === 200) {
      setResponseLoading(false);
      dispatch(hideAdminModal());
      dispatch(fetchUsersAction());
      document.body.classList.toggle('no-scroll');
    }
  };

  const editUser = async (userObj) => {
    const requestURL = `${process.env.REACT_APP_API_HOSTNAME}/admin/update-user`;
    setEditLoading(true);
    const result = await axios({
      url: requestURL,
      method: 'POST',
      data: {
        user: { ...userObj },
      },
      withCredentials: true,
    });
    if (result.status === 200) {
      setEditLoading(false);
      dispatch(hideAdminModal());
      dispatch(fetchUsersAction());
      document.body.classList.toggle('no-scroll');
    }
  };

  const setUpModalState = () => {
    if (modalType === 'Create New User') {
      return {
        modalType,
        createNewUser,
        responseLoading,
      };
    }
    if (modalType === 'Edit User') {
      return {
        modalType,
        user,
        editUser,
        editLoading,
      };
    }
    if (modalType === 'Update Password') {
      return {
        user,
        modalType,
      };
    }

    return { modalType: '' };
  };

  return (
    <div
      className={
        active
          ? 'admin-application-container__application-modal admin-application-modal'
          : 'admin-application-container__application-modal admin-application-modal admin-application-container__application-modal--hidden'
      }
    >
      <div className="admin-application-conatiner__modal-body modal-body">
        <div className="modal-body__heading">
          <h3>{modalType}</h3>
          <button
            type="button"
            className="modal-heading__img"
            onClick={handleModalClose}
          >
            <img src={cancelButton} alt="cancel button" />
          </button>
        </div>
        <div className="modal-form">
          {createModalForm({
            modalData: setUpModalState(),
          })}
        </div>
      </div>
    </div>
  );
}
