import React from 'react';
import DropdownTrayItems from '../DropdownTrayItems';
import './DropdownTray.css';

const generateClassNames = (trayStatus, trayType = '') => {
  let className = '';
  if (trayType === 'Map-Menu') {
    /* displays dropdowns - normal - below the button */
    className = !trayStatus
      ? 'map-menu__list-wrapper list-wrapper'
      : 'map-menu__list-wrapper list-wrapper list-wrapper--active';
  } else if (trayType === 'Data-Filter') {
    /* displays dropdown as overlay - over the button */
    className = !trayStatus
      ? 'filter-control__list-wrapper list-wrapper'
      : 'filter-control__list-wrapper list-wrapper list-wrapper--active';
  }
  return className;
};

/**
 *
 * Takes in the following named props:
 * @param {bool} Tray (state - open or closed)
 * @param {array} filterValues (available filter values)
 * @param {string} selectedValue (the current selected value)
 * @param {function} selectionFn (handler that is called when an item is selected from the dropdown)
 * @param {string} trayType (an enum to indicate different types of trays - Map-Menu and Data-Filters)
 * @returns JSX.element
 */
export default function DropdownTray({
  Tray,
  filterValues,
  selectedValue,
  selectionFn,
  trayType,
  itemType = 'default',
}) {
  return (
    <div className={generateClassNames(Tray, trayType)}>
      <div className="list-wrapper__list-items list-items">
        {trayType === 'Data-Filter'
          ? filterValues.map((filter) => (
              <DropdownTrayItems
                key={filter.key}
                filter={filter.key}
                itemType={itemType}
                selectedValue={selectedValue}
                selectionFn={selectionFn}
              />
            ))
          : filterValues.map((filter) => (
              <DropdownTrayItems
                filter={filter}
                itemType={itemType}
                selectedValue={selectedValue}
                selectionFn={selectionFn}
                key={filter}
              />
            ))}
      </div>
    </div>
  );
}
