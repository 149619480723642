import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { displayModal } from '../../reducers/ApplicationModalSlice';
import './ZoningRow.css';

export default function ZoningRow({
  id,
  city,
  recordID,
  recordDate,
  address,
  projectBrief,
}) {
  const detailedZoningRecords = useSelector(
    (state_) => state_.zoningChanges.detailedZoningRecords
  );
  const dispatch = useDispatch();
  const handleOpenDetailedRecords = () => {
    detailedZoningRecords.map(({ _source }) =>
      _source.record_id === id
        ? dispatch(
            displayModal({
              modalType: 'Zoning Change',
              modalData: _source,
            })
          )
        : null
    );
    document.body.classList.toggle('no-scroll');
  };
  return (
    <tr
      className="zoning-table-body__row zoning-row"
      onClick={handleOpenDetailedRecords}
    >
      <td className="zoning-row__data">{city}</td>
      <td className="zoning-row__data">{recordID}</td>
      <td className="zoning-row__data">{recordDate}</td>
      <td className="zoning-row__data">{address}</td>
      <td className="zoning-row__data">{projectBrief}</td>
    </tr>
  );
}
