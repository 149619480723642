import { createSlice } from '@reduxjs/toolkit';
import {
  fetchPlanningData,
  fetchZoningData,
  fetchLegend,
  fetchZoningFilterValues,
  fetchSitesData,
  fetchZoneFilterValues,
} from './blockbriefMapThunks';

const initialState = {
  mapLocation: {
    placeID: '',
    placeIDGeoCode: {
      lat: '',
      lng: '',
    },
    city: '',
    state: '',
    lat: '',
    lng: '',
  },
  activeMapLayer: '',
  parcelDownloadInputs: {
    parcelStatus: false,
  },
  availableMaps: [],
  currentActiveMap: 'Zoning Map',
  activeLegend: [],
  activeZoneFilterValues: [],
  activeZoneFilterLoading: false,
  planningData: {
    planningTray: false,
    planningLoading: false,
    planningRecords: [],
    filterStatus: false, // if this filter is currently active or not
  },
  zoningData: {
    filters: {
      filterLoading: false,
      filterValues: { status: [], projectType: [] },
      filterForm: {
        status: '',
        projectType: '',
        startDate: '',
        endDate: '',
        searchStr: '',
      },
    },
    zoningRecords: [],
    zoningLoading: false,
    filterStatus: false, // if this filter is currently active or not
  },
  listingSitesData: {
    filters: {
      filterForm: {
        listingType: {
          residential: true,
          commercial: true,
        },
      },
    },
    sitesRecords: {
      residential: [],
      commercial: [],
    },
    saleType: true,
    leaseType: true,

    sitesLoading: false,
    filterStatus: false,
  },
  // Indicates if the filter is active or not -
  // We have to track it here explicitly because offMarketFilter contains both listing data and zoning data -
  // We have to handle the planning alerts map layer edge case - that is why storing the status here explicitly
  offMarketStatus: false,
  offMarketTrayStatus: false, // Indicates if the filter tray is active or not
  onMarketTrayStatus: false, // Indicates if the filter tray is active or not
  currentSelectedFeature: {
    address: {
      loading: true,
      value: '',
    },

    zoneCode: '',
    zoneDetails: [],
    pluDetails: [],
    buildingControls: null,
    tray: false,
    previousZone: {},
  },
  currentSelectedFeature2: '',
};

const BlockbriefMapSlice = createSlice({
  name: 'BlockbriefMap',
  initialState,
  reducers: {
    resetMapState: (state) => {
      // eslint-disable-next-line no-unused-vars
      state.mapLocation = initialState.mapLocation;
      state.offMarketStatus = initialState.offMarketStatus;
      state.activeMapLayer = initialState.activeMapLayer;
      state.parcelDownloadInputs = initialState.parcelDownloadInputs;
      state.availableMaps = initialState.availableMaps;
      state.currentActiveMap = initialState.currentActiveMap;
      state.activeZoneFilterLoading = initialState.activeZoneFilterLoading;
      state.offMarketStatus = initialState.offMarketStatus;
      state.offMarketTrayStatus = initialState.offMarketStatus;
      state.activeLegend = initialState.activeLegend;
      state.activeZoneFilterValues = initialState.activeZoneFilterValues;
      state.zoningData = initialState.zoningData;
      state.listingSitesData = initialState.listingSitesData;
      state.currentSelectedFeature = initialState.currentSelectedFeature;
      state.planningData = initialState.planningData;
    },
    setPlaceID: (state, action) => {
      state.mapLocation.placeID = action.payload.placeID;
    },
    setPlaceIDLatLng: (state, action) => {
      state.mapLocation.placeIDGeoCode.lat = action.payload.lat;
      state.mapLocation.placeIDGeoCode.lng = action.payload.lng;
    },
    setAddress: (state, action) => {
      state.mapLocation.placeIDGeoCode = action.payload.placeIDGeoCode;
      state.mapLocation.lat = action.payload.lat;
      state.mapLocation.lng = action.payload.lng;
      state.mapLocation.state = action.payload.state;
      state.mapLocation.city = action.payload.city;
    },
    setMapLatLng: (state, action) => {
      state.mapLocation.lat = action.payload.lat;
      state.mapLocation.lng = action.payload.lng;
    },
    setAvailableMaps: (state, action) => {
      state.availableMaps = action.payload.mapTypes;
    },
    setCurrentActiveMap: (state, action) => {
      state.currentActiveMap = action.payload;
    },
    resetLegend: (state) => {
      state.activeLegend = [];
    },
    resetPlanningData: (state) => {
      state.planningData.planningLoading = false;
      state.planningData.planningRecords = [];
      state.planningData.planningTray = false;
      state.planningData.filterStatus = false;
    },
    hidePlanningTray: (state) => {
      state.planningData.planningTray = false;
    },
    resetZoningData: (state) => {
      state.zoningData.zoningRecords = [];
      state.zoningData.zoningLoading = false;
      state.zoningData.filterStatus = false;
      state.offMarketStatus = false;
    },
    resetSitesData: (state) => {
      state.listingSitesData = initialState.listingSitesData;
    },
    toggleZoningDataStatus: (state) => {
      state.zoningData.filterStatus = true;
    },
    setFilterForm: (state, action) => {
      const { status, projectType, startDate, endDate, searchStr } =
        action.payload;
      state.zoningData.filters.filterForm.status = status;
      state.zoningData.filters.filterForm.projectType = projectType;
      state.zoningData.filters.filterForm.startDate = startDate;
      state.zoningData.filters.filterForm.endDate = endDate;
      state.zoningData.filters.filterForm.searchStr = searchStr;
    },
    resetFilterForm: (state) => {
      state.zoningData.filters.filterForm =
        initialState.zoningData.filters.filterForm;
    },
    hidePlanningMarkers: (state) => {
      state.planningData.filterStatus = !state.planningData.filterStatus;
    },
    hideZoningMarkers: (state) => {
      state.zoningData.filterStatus = !state.zoningData.filterStatus;
    },
    setTypeSale: (state, action) => {
      state.listingSitesData.saleType = action.payload;
    },
    setTypeLease: (state, action) => {
      state.listingSitesData.leaseType = action.payload;
    },

    toggleSitesDataToggle: (state, action) => {
      const { toggleType } = action.payload;
      const residentialStatus =
        state.listingSitesData.filters.filterForm.listingType.residential;
      const commercialStatus =
        state.listingSitesData.filters.filterForm.listingType.commercial;
      if (toggleType === 'Residential') {
        state.listingSitesData.filters.filterForm.listingType.residential =
          !residentialStatus;
      } else {
        state.listingSitesData.filters.filterForm.listingType.commercial =
          !commercialStatus;
      }
    },
    toggleOffMarketFilter: (state) => {
      state.offMarketStatus = !state.offMarketStatus;
    },
    toggleOffMarketTrayStatus: (state, action) => {
      state.offMarketTrayStatus = action.payload
        ? action.payload.filterStatus
        : !state.offMarketTrayStatus;
      if (state.offMarketTrayStatus) {
        state.onMarketTrayStatus = false;
        state.currentSelectedFeature.tray = false;
      }
    },
    toggleOnMarketTrayStatus: (state, action) => {
      state.onMarketTrayStatus = action.payload
        ? action.payload.filterStatus
        : !state.onMarketTrayStatus;
      if (state.onMarketTrayStatus) {
        state.offMarketTrayStatus = false;
        state.currentSelectedFeature.tray = false;
        state.planningData.planningTray = false;
      }
    },
    setCurrentFeatureAddress: (state, action) => {
      state.currentSelectedFeature.address = {
        loading: false,
        value: action.payload.address,
      };
    },
    setCurrentFeatureAddress2: (state, action) => {
      state.currentSelectedFeature2 = action.payload;
    },
    setCurrentFeatureZoneCode: (state, action) => {
      state.currentSelectedFeature.zoneCode = action.payload.zoneCode;
    },
    setCurrentFeaturePreviousZone: (state, action) => {
      state.currentSelectedFeature.previousZone = action.payload.previousZone;
    },
    setCurrentFeatureZoneDetails: (state, action) => {
      state.currentSelectedFeature.zoneDetails = [action.payload.zoneDetail];
    },
    setCurrentPluDetails: (state, action) => {
      state.currentSelectedFeature.pluDetails = action.payload.pluDetails;
    },
    setCurrentBuildingControls: (state, action) => {
      state.currentSelectedFeature.buildingControls =
        action.payload.buildingControls;
    },
    resetCurrentSelectedFeature: (state) => {
      state.currentSelectedFeature = initialState.currentSelectedFeature;
    },
    toggleCurrentSelectedFeatureTray: (state) => {
      state.currentSelectedFeature.tray = !state.currentSelectedFeature.tray;
      if (state.currentSelectedFeature.tray) {
        state.offMarketTrayStatus = false;
        state.onMarketTrayStatus = false;
      }
    },
    setLoadingCurrentFeatureAddress: (state) => {
      state.currentSelectedFeature.address.loading = true;
    },
    closeAllFilterTrays: (state) => {
      state.offMarketTrayStatus = false;
      state.listingSitesData.filterStatus = false;
      state.currentSelectedFeature.tray = false;
      state.planningData.planningTray = false;
    },
    setActiveMapLayer: (state, action) => {
      state.activeMapLayer = action.payload.activeMapLayer;
    },
    toggleParcelDownloadStatus: (state) => {
      state.parcelDownloadInputs.parcelStatus =
        !state.parcelDownloadInputs.parcelStatus;
    },
    resetParcelDownload: (state) => {
      state.parcelDownloadInputs = initialState.parcelDownloadInputs;
    },
    setZoneFilterValue: (state, action) => {
      state.activeZoneFilterValues = action.payload.activeZoneFilterValues;
    },
    setLegend: (state, action) => {
      state.activeLegend = action.payload;
    },
  },
  extraReducers: {
    [fetchPlanningData.pending]: (state) => {
      if (!state.zoningData.zoningLoading)
        state.planningData.planningLoading = true;
    },
    [fetchPlanningData.fulfilled]: (state, action) => {
      state.planningData.planningLoading = false;
      state.planningData.planningRecords = action.payload.planningData;
      state.planningData.planningTray = action.payload.planningTray;
      state.planningData.filterStatus = action.payload.planningMarkers;
    },
    [fetchZoningData.pending]: (state) => {
      state.zoningData.zoningLoading = true;
    },
    [fetchZoningData.fulfilled]: (state, action) => {
      state.zoningData.zoningLoading = false;
      state.zoningData.zoningRecords = action.payload.zoningData;
      state.zoningData.filterStatus = action.payload.zoningMarkers;
    },
    [fetchLegend.pending]: () => {},
    [fetchLegend.fulfilled]: (state, action) => {
      state.activeLegend = action.payload.mapLegend.mapLegend;
    },
    [fetchZoningFilterValues.pending]: (state) => {
      state.zoningData.filters.filterLoading = true;
    },
    [fetchZoningFilterValues.fulfilled]: (state, action) => {
      const { filterValues } = action.payload;
      const [statusFilter, projectTypeFilter] = filterValues;
      state.zoningData.filters.filterLoading = false;
      state.zoningData.filters.filterValues.status = statusFilter.filterValues;
      state.zoningData.filters.filterValues.projectType =
        projectTypeFilter.filterValues;
    },
    [fetchSitesData.pending]: (state) => {
      state.listingSitesData.sitesLoading = true;
      state.listingSitesData.filterStatus = true;
    },
    [fetchSitesData.fulfilled]: (state, action) => {
      state.listingSitesData.sitesLoading = false;
      state.listingSitesData.filterStatus = action.payload.markerStatus;
      const { sitesData } = action.payload;
      const residentialValues = [];
      const commercialValues = [];
      sitesData.map((data) =>
        data._source.source === 'RealCommercial'
          ? commercialValues.push(data)
          : residentialValues.push(data)
      );
      state.listingSitesData.sitesRecords.residential = residentialValues;
      state.listingSitesData.sitesRecords.commercial = commercialValues;
    },
    [fetchZoneFilterValues.pending]: (state) => {
      state.activeZoneFilterLoading = true;
    },
    [fetchZoneFilterValues.fulfilled]: (state, action) => {
      state.activeZoneFilterLoading = false;
      state.activeZoneFilterValues = action.payload.mapLegend.filterValues;
    },
  },
});

export const {
  setAddress,
  setAvailableMaps,
  setCurrentActiveMap,
  setPlaceID,
  resetLegend,
  resetPlanningData,
  hidePlanningTray,
  resetZoningData,
  toggleZoningDataStatus,
  setFilterForm,
  resetFilterForm,
  hidePlanningMarkers,
  hideZoningMarkers,
  toggleOffMarketFilter,
  resetSitesData,
  toggleSitesDataToggle,
  setMapLatLng,
  setCurrentFeatureAddress,
  setCurrentFeatureZoneCode,
  setCurrentFeatureZoneDetails,
  resetCurrentSelectedFeature,
  toggleCurrentSelectedFeatureTray,
  setLoadingCurrentFeatureAddress,
  closeAllFilterTrays,
  toggleOffMarketTrayStatus,
  toggleOnMarketTrayStatus,
  setActiveMapLayer,
  toggleParcelDownloadStatus,
  resetParcelDownload,
  resetMapState,
  setCurrentPluDetails,
  setCurrentBuildingControls,
  setPlaceIDLatLng,
  setTypeSale,
  setTypeLease,
  setLegend,
  setCurrentFeatureAddress2,
  setCurrentFeaturePreviousZone,
} = BlockbriefMapSlice.actions;

export default BlockbriefMapSlice.reducer;
