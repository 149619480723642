import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '../../assets/images/settings.svg';
import InfoIcon from '../../assets/images/information-button.png';
import './DevelopmentSites.css';
import DevelopmentTable from '../../components/DevelopmentTable';
import Pagination from '../../components/Pagination';
import FilterMenu from '../../components/FilterMenu';
import fetchdevelopmentRecords from '../../reducers/developmentSitesSlice/developmentSitesThunks';
import {
  startRowPageBtnAction,
  endRowPageBtnAction,
  nextPageBtnAction,
  prevPageBtnAction,
  resetPagination,
  setFilterValues,
  resetFilterValues,
} from '../../reducers/developmentSitesSlice';

export default function DevelopmentSites() {
  const dispatch = useDispatch();
  const [FilterTray, setFilterTray] = useState(false);
  const { currentActive, highestPageSetOne, totalPages, totalPagesSecondSet } =
    useSelector((state) => state.developmentSites.pagination);
  const {
    stateFilter,
    propertyTypeFilter,
    suburbFilter,
    transactionTypeFilter,
  } = useSelector((state) => state.developmentSites.filters);
  const {
    state,
    property_type,
    suburb,
    availableSpaceRange,
    transaction_type,
  } = useSelector(
    (fitlerMenuValues) => fitlerMenuValues.developmentSites.filterSelectedValues
  );
  const [menuFilter, setMenuFilter] = useState({
    state,
    property_type,
    suburb,
    availableSpaceRange,
    transaction_type,
  });
  // const transactionType = ['SALE', 'LEASE'];

  useEffect(() => {
    dispatch(fetchdevelopmentRecords());
  }, [
    currentActive,
    highestPageSetOne,
    totalPages,
    totalPagesSecondSet,
    dispatch,
  ]);

  /* Event Handlers */

  // Filter Menu: Open Filter Menu - EventHandler to open the Filter Menu Tray
  const openFilterTray = () => setFilterTray(!FilterTray);

  // Filter Menu: Submission Handler - to submit the filters form
  const submissionHandler = () => {
    dispatch(setFilterValues({ ...menuFilter }));
    dispatch(resetPagination());
  };

  // Filter Menu: Reset Handler - to reset the filters form
  const resetHandler = () => {
    setMenuFilter({
      status: '',
      property_type: '',
      suburb: '',
      transaction_type: '',
      availableSpaceRange: {
        min: '',
        max: '',
      },
    });
    dispatch(resetFilterValues());
    dispatch(resetPagination());
  };

  const handlePaginationChange = (eventType) => (event) => {
    const text = Number(event.target.innerText);
    if (eventType === 'Next') dispatch(nextPageBtnAction({ incAmount: 1 }));
    else if (eventType === 'Back')
      dispatch(prevPageBtnAction({ incAmount: -1 }));
    else if (eventType === 'pagBtn')
      dispatch(startRowPageBtnAction({ incAmount: Number(text) }));
    else if (eventType === 'pagBtn-later')
      dispatch(endRowPageBtnAction({ incAmount: Number(text) }));
  };

  return (
    <div className="application-container__development-sites development-sites">
      <h2 className="development-sites__header">Development Sites</h2>
      <h3 className="development-sites__info-text development-sites-info">
        <img src={InfoIcon} alt="info icon" /> Search and filter the development
        sites for different states. For updates regarding development sites
        subscribe to our News Letter
      </h3>
      <div className="development-sites__controls-row controls-row">
        <div className="controls-row__filter" onClick={openFilterTray}>
          <span>Filter</span>
          <img src={SettingsIcon} alt="Filter icon" />
        </div>
        <Pagination
          currentActive={currentActive}
          currentHighestFirstSet={highestPageSetOne}
          totalPages={totalPages}
          updatePaginationFn={handlePaginationChange}
          totalPagesSecondSet={totalPagesSecondSet}
        />
      </div>
      {FilterTray ? (
        <FilterMenu
          componentName="Development"
          filters={[
            {
              filterName: 'State',
              filterPlaceholder: 'Select a State',
              selectedValue: menuFilter.state,
              filterType: 'Dropdown',
              filterValues: stateFilter,
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.innerText.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  state: selectedValue,
                }));
              },
            },
            {
              filterName: 'Propety Type',
              filterType: 'Dropdown',
              filterPlaceholder: 'Select Property Type',
              selectedValue: menuFilter.property_type,
              filterValues: propertyTypeFilter,
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.innerText.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  property_type: selectedValue,
                }));
              },
            },

            {
              filterName: 'Subrub',
              filterType: 'Dropdown',
              filterPlaceholder: 'Select a Suburb',
              selectedValue: menuFilter.suburb,
              filterValues: suburbFilter,
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.innerText.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  suburb: selectedValue,
                }));
              },
            },
            {
              filterName: 'Transaction Type',
              filterType: 'Dropdown',
              filterPlaceholder: 'Select Transaction Type',
              selectedValue: menuFilter.transaction_type,
              filterValues: transactionTypeFilter,
              handleFilterValueChange: (e) => {
                const selectedValue = e.target.innerText.trim();
                setMenuFilter((filterMenu) => ({
                  ...filterMenu,
                  transaction_type: selectedValue,
                }));
              },
            },

            {
              filterName: 'Available Space',
              filterType: 'range',
              filterPlaceholder: 'Select a Suburb',
              selectedValue: menuFilter.availableSpaceRange,
              handleFilterValueChange: {
                minChangeFn: (e) => {
                  const minValue = e.target.value;
                  if (Number(minValue) || Number(minValue) === 0) {
                    return setMenuFilter((filterMenu) => ({
                      ...filterMenu,
                      availableSpaceRange: {
                        ...filterMenu.availableSpaceRange,
                        min: minValue,
                      },
                    }));
                  }
                  return setMenuFilter((filterMenu) => ({
                    ...filterMenu,
                    availableSpaceRange: {
                      ...filterMenu.availableSpaceRange,
                      min: '',
                    },
                  }));
                },
                maxChangeFn: (e) => {
                  const maxValue = e.target.value;
                  if (Number(maxValue)) {
                    return setMenuFilter((filterMenu) => ({
                      ...filterMenu,
                      availableSpaceRange: {
                        ...filterMenu.availableSpaceRange,
                        max: maxValue,
                      },
                    }));
                  }
                  return setMenuFilter((filterMenu) => ({
                    ...filterMenu,
                    availableSpaceRange: {
                      ...filterMenu.availableSpaceRange,
                      max: '',
                    },
                  }));
                },
              },
            },
          ]}
          submissionHandler={submissionHandler}
          resetHandler={resetHandler}
        />
      ) : null}
      <DevelopmentTable />
    </div>
  );
}
