import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import RightIcon from '../../assets/images/right-arrow.svg';
import APIEndpointTray from '../APIEndpointTray';
import './APIMenuEndpointItem.css';

export default function APIMenuEndpointItem({
  endpointName,
  endpointItems,
  endpointActive,
}) {
  const [endpointTray] = useState(endpointActive);
  const history = useHistory();
  const endpointMap = {
    Zoning: '/api/docs/zoning',
    '3rd Party Permits': '/api/docs/third-party-permit',
    'Rezoning Permit': '/api/docs/rezoning-permit',
    'Blockbrief Permit': '/api/docs/blockbrief-permit',
    'Request For Zoning Info': '/api/docs/request-zoning',
  };
  return (
    <div
      className="api-menu-items__api-item api-item"
      onClick={() => history.push(endpointMap[endpointName])}
    >
      <div
        className={
          endpointTray
            ? 'api-item__content api-item__content--active'
            : 'api-item__content api-item__content'
        }
      >
        <h4 className="api-item__heading">{endpointName}</h4>
        <span className={endpointTray ? 'right-icon--active' : null}>
          <img src={RightIcon} alt="Right Icon" />
        </span>
      </div>
      <APIEndpointTray
        endpointItems={endpointItems}
        endpointTray={endpointTray}
      />
    </div>
  );
}
