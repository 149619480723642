import axios from '../Apis/axios';
import {
  setCurrentFeatureZoneCode,
  setCurrentFeatureZoneDetails,
  resetCurrentSelectedFeature,
  setCurrentFeatureAddress,
  setLoadingCurrentFeatureAddress,
  toggleCurrentSelectedFeatureTray,
  setCurrentPluDetails,
  setCurrentBuildingControls,
  setCurrentFeaturePreviousZone,
} from '../reducers/blockbriefMapSlice';
import stateNameConfig from './stateNameConfig';
import getAddress from './useGoogleGeoCoding';

/**
 * Returns the Building Controls for a specific GeoCode and state
 * @param {number} lat - the latitude
 * @param {number} lng - the longitude
 * @param {string} state - the state e.g 'nsw'
 * @returns {object} - returns the building controls in the data prop
 */
const getZoneDetails = async ({ state, lat, lng }) => {
  try {
    if (lat && lng && state) {
      const requestURL = `/zoneDetails/${state}/${lat}/${lng}`;
      const requestData = await axios.get(requestURL);

      return { data: requestData, message: 'ok' };
    }
    return {
      data: null,
      message: 'error',
    };
  } catch (error) {
    return {
      data: null,
      message: error.message,
    };
  }
};

const getOverlayDetails = async ({ mapType, state, lat, lng }) => {
  try {
    const requestURL = `/overlayDetails/${state}/${lat}/${lng}`;
    const requestData = await axios.post(requestURL, { mapType });

    return { data: requestData, message: 'ok' };
  } catch (error) {
    return {
      data: null,
      message: error.message,
    };
  }
};

/**
 * A curried function that handles the feature selection on the map
 * @param {object} store - the redux store
 * @param {object} tangramLayer - tangram layer scene object
 * @param {object} event - the event object on the map with the x, y coords where to select the feature from
 *
 */
const featureSelection = async (store, isAddress) => {
  // const lyrs = tangramLayer.scene.config.layers;

  // Get the feature from the tile at the click location

  store.dispatch(resetCurrentSelectedFeature());
  store.dispatch(setLoadingCurrentFeatureAddress());
  store.dispatch(toggleCurrentSelectedFeatureTray());

  // Get the address of the current feature

  if (!isAddress) {
    store.dispatch(
      setCurrentFeatureAddress({
        address: store.getState().blockbriefMap.currentSelectedFeature2,
      })
    );
  } else {
    const address = await getAddress(
      store.getState().blockbriefMap.mapLocation.lat,
      store.getState().blockbriefMap.mapLocation.lng
    );

    store.dispatch(
      setCurrentFeatureAddress({
        address,
      })
    );
  }
  const currentState = store.getState().blockbriefMap.mapLocation.state;
  const state = stateNameConfig(currentState);
  const zoningState = ['nsw', 'vic', 'sa', 'wa', 'tas', 'qld', 'act'];

  if (
    zoningState.includes(store.getState().blockbriefMap.activeMapLayer) &&
    store.getState().blockbriefMap.currentActiveMap !== 'Planning Alerts'
  ) {
    const resultZoneDetailApi = await getZoneDetails({
      state,
      lat: store.getState().blockbriefMap.mapLocation.lat,
      lng: store.getState().blockbriefMap.mapLocation.lng,
    });

    // plu
    if (resultZoneDetailApi?.data?.data?.plus) {
      store.dispatch(
        setCurrentPluDetails({
          pluDetails: resultZoneDetailApi.data.data,
        })
      );
      store.dispatch(
        setCurrentFeatureZoneCode({
          zoneCode: resultZoneDetailApi.data.data.zoneDetails.zone_code,
        })
      );

      Object.entries(resultZoneDetailApi.data.data.zoneDetails).forEach(
        ([prop, val]) => {
          if (prop !== 'zone_code') {
            const propName = prop.split('_').join(' ');
            const propVal = val.toString().split('_').join(' ');
            store.dispatch(
              setCurrentFeatureZoneDetails({
                zoneDetail: { propName, propVal },
              })
            );
          }
        }
      );
    }
    // building Controls
    if (state === 'nsw') {
      store.dispatch(
        setCurrentBuildingControls({
          buildingControls: resultZoneDetailApi.data.data.buildingControlsData,
        })
      );
    }

    // previous zone details
    if (resultZoneDetailApi?.data?.data?.previousZone) {
      store.dispatch(
        setCurrentFeaturePreviousZone({
          previousZone: resultZoneDetailApi.data.data.previousZone,
        })
      );
    }
  }

  // for overlay data
  else {
    const resultZoneDetailApi = await getOverlayDetails({
      mapType: store.getState().blockbriefMap.currentActiveMap,
      state: store.getState().blockbriefMap.activeMapLayer,
      lat: store.getState().blockbriefMap.mapLocation.lat,
      lng: store.getState().blockbriefMap.mapLocation.lng,
    });

    if (resultZoneDetailApi.data?.data?.pluData)
      store.dispatch(
        setCurrentFeatureZoneCode({
          zoneCode: resultZoneDetailApi.data.data.pluData.zone_code,
        })
      );
  }
};

export { featureSelection, getZoneDetails };
