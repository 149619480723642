import React from 'react';
import DropdownItems from '../DropdownItems';
import './DropdownFilterBtn.css';

/**
 * This component takes in the following named props -
 * @param {function} handleOpenTray - This function toggles the classes for the dropdown tray open and closed state
 * @param {string} selectedValue - The currently active string to show in the dropdown button
 * @param {string} placeholderStr - The placeholder string for the dropdown
 * @returns
 */
export default function DropdownFilterBtn({
  handleOpenTray,
  selectedValue,
  placeholderStr,
}) {
  return (
    <button
      type="button"
      className="filter-control__dropdown-btn dropdown-btn"
      onBlur={handleOpenTray}
      onClick={handleOpenTray}
    >
      <DropdownItems
        selectedValue={selectedValue}
        placeholderStr={placeholderStr}
      />
    </button>
  );
}
