import React from 'react';
import { useSelector } from 'react-redux';
import './OffMarketFilterMenu.css';
import FilterItem from '../FilterItem/index';

export default function OffMarketFilterMenu({
  Status,
  setStatus,
  ProjectType,
  setProjectType,
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  SearchStr,
  setSearchStr,
}) {
  const { status, projectType } = useSelector(
    (state) => state.blockbriefMap.zoningData.filters.filterValues
  );
  return (
    <div className="offmarket-filter__filter-menu">
      <FilterItem
        filterName="Search"
        placeholderStr="Search for a keyword"
        filterType="Search"
        selectedValue={SearchStr}
        selectionFunction={(e) => {
          const selectedValue = e.target.value.trim();
          setSearchStr(selectedValue);
        }}
      />
      <FilterItem
        filterName="Status"
        placeholderStr="Select Status"
        filterType="Dropdown"
        selectedValue={Status}
        selectionFunction={(e) => {
          const selectedValue = e.target.innerText.trim();
          setStatus(selectedValue);
        }}
        filterValues={[...status]}
      />
      <FilterItem
        filterName="Project Type"
        placeholderStr="Select Project Type"
        filterType="Dropdown"
        selectedValue={ProjectType}
        selectionFunction={(e) => {
          const selectedValue = e.target.innerText.trim();
          setProjectType(selectedValue);
        }}
        filterValues={[...projectType]}
      />
      <FilterItem
        filterName="Start Date"
        placeholderStr="YYYY-MM-DD"
        filterType="Date"
        selectedValue={StartDate}
        selectionFunction={(e) => {
          const selectedValue = e.target.value.trim();
          setStartDate(selectedValue);
        }}
      />
      <FilterItem
        filterName="End Date"
        placeholderStr="YYYY-MM-DD"
        filterType="Date"
        selectedValue={EndDate}
        selectionFunction={(e) => {
          const selectedValue = e.target.value.trim();
          setEndDate(selectedValue);
        }}
      />
    </div>
  );
}
