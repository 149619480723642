import React from 'react';
import AddIcon from '../../assets/images/add.png';
import MinusIcon from '../../assets/images/minus.png';
import './ZoomControls.css';

export default function ZoomControls({ map }) {
  return (
    <div className="map-container__zoom-controls zoom-controls">
      <button
        type="button"
        className="zoom-controls__control"
        onClick={() => map.zoomIn()}
      >
        <img src={AddIcon} alt="Map zoom in icon" />
      </button>
      <button
        type="button"
        className="zoom-controls__control"
        onClick={() => map.zoomOut()}
      >
        <img src={MinusIcon} alt="map zoom out icon" />
      </button>
    </div>
  );
}
