import { nanoid } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';
import APIMenuEndpointItem from '../APIMenuEndpointItem';
import './APIMenu.css';

export default function APIMenu({ APIEndPoints, currentLocation }) {
  const { email } = useSelector((state) => state.user);
  const endpointMap = {
    '/api/api-credentials': 'API Credentials',
    '/api/docs/zoning': 'Zoning',
    '/api/docs/third-party-permit': '3rd Party Permits',
    '/api/docs/rezoning-permit': 'Rezoning Permit',
    '/api/docs/blockbrief-permit': 'Blockbrief Permit',
    '/api/docs/request-zoning': 'Request For Zoning Info',
  };
  const history = useHistory();
  return (
    <div className="api-container__api-menu api-menu">
      <div className="api-menu__introduction introduction">
        <h3 className="introduction__heading api-menu__heading">
          Introduction
        </h3>
        <button
          type="button"
          className={
            currentLocation === '/api/api-credentials'
              ? 'highlight api-credentials'
              : 'api-credentials'
          }
          onClick={() => history.push('/api/api-credentials')}
        >
          API Credentials
        </button>
        <button
          type="button"
          className={
            currentLocation === '/api/terms-of-service'
              ? 'highlight api-credentials'
              : 'api-credentials'
          }
          onClick={() => history.push('/api/terms-of-service')}
        >
          API Terms of Service
        </button>
        {String(email || '').match(/corelogic/gi) ||
        String(email || '').match(/magma/gi) ? (
          <button
            type="button"
            className={
              currentLocation === '/api/data-download'
                ? 'highlight api-credentials'
                : 'api-credentials'
            }
            onClick={() => history.push('/api/data-download')}
          >
            Data Download
          </button>
        ) : null}
      </div>
      <h3 className="api-menu__heading">API Reference</h3>
      <div className="api-menu__api-menu-items api-menu-items">
        {APIEndPoints.map((endpoint) => (
          <APIMenuEndpointItem
            endpointName={endpoint.endpointName}
            endpointItems={endpoint.endpointItems}
            endpointActive={
              endpoint.endpointName === endpointMap[currentLocation]
            }
            key={nanoid()}
          />
        ))}
      </div>
    </div>
  );
}
