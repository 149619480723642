import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetMapState } from '../../reducers/blockbriefMapSlice';
import './ApplicationNav.css';

export default function ApplicationNav({ currentLocation }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetMapState());
  }, [currentLocation]);

  return (
    <div
      className={
        [
          '/zoning-search',
          '/zoning-search/map-search',
          '/zoning-changes',
          '/development-sites',
          '/subscribe',
          '/marketing',
          '/webinars',
          '/',
        ].reduce((acc, curr) => {
          if (acc) return acc;
          return curr === currentLocation.pathname;
        }, false)
          ? 'application-header__nav application-nav'
          : 'application-header__nav application-nav application-nav--hidden'
      }
    >
      <Link
        to="/zoning-search"
        className={
          currentLocation.pathname === '/zoning-search' ||
          currentLocation.pathname === '/zoning-search/map-search'
            ? 'application-nav__link application-nav__link--active'
            : 'application-nav__link'
        }
      >
        Zoning Search
      </Link>
      <Link
        to="/zoning-changes"
        className={
          currentLocation.pathname === '/zoning-changes'
            ? 'application-nav__link application-nav__link--active'
            : 'application-nav__link'
        }
      >
        Zoning Changes
      </Link>
      <Link
        to="/development-sites"
        className={
          currentLocation.pathname === '/development-sites'
            ? 'application-nav__link application-nav__link--active'
            : 'application-nav__link'
        }
      >
        Development Sites
      </Link>
      <Link
        to="/subscribe"
        className={
          currentLocation.pathname === '/subscribe'
            ? 'application-nav__link application-nav__link--active'
            : 'application-nav__link'
        }
      >
        Subscribe
      </Link>
    </div>
  );
}
