import React from 'react';
import './LandingFeatured.css';
import FeaturedImage from '../../assets/images/press.png';

export default function LandingFeatured() {
  return (
    <div className="landing-featured">
      <h3 className="landing-featured__heading">Featured In</h3>
      <div className="landing-featured__line" />
      <img
        className="landing-featured__img"
        src={FeaturedImage}
        alt="Companies Featured in Logos"
      />
    </div>
  );
}
