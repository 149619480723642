import { createSlice, createAction } from '@reduxjs/toolkit';
import fetchZoningRecords, {
  updatePagination,
  fetchFilters,
} from './zoningChangesThunk';
import {
  startRowPageBtnReducer,
  endRowPageBtnReducer,
  nextPageBtnReducer,
  prevPageBtnReducer,
} from '../../utils/paginationUtils';

/* Zoning Changes Actions */

// Pagination: Reset Pagination Action
const resetPagination = createAction(
  'zoningChanges/pagination/resetPagination'
);

// Pagination: Select Page in the Start Row Action
const startRowPageBtnAction = createAction(
  'zoningChanges/pagination/startRowPageBtn'
);

// Pagination: Select Page in the End Row Action
const endRowPageBtnAction = createAction(
  'zoningChanges/pagination/endRowPageBtn'
);

// Pagination: Go to the Next Page Action
const nextPageBtnAction = createAction('zoningChanges/pagination/nextPageBtn');

// Pagination: Go to the Prev Page Action
const prevPageBtnAction = createAction('zoningChanges/pagination/prevPageBtn');

// FilterMenu: Set Selected Fitler Values Actions
const setFilterValues = createAction(
  'zoningChanges/filterSelectedValues/setFilterValues'
);

const resetFilterValues = createAction(
  'zoningChanges/filterSelectedValue/resetValues'
);

const initialState = {
  activeZoningProps: [], // contains the zoningRecords, currently active in zoning-table - with the properties to show on the table
  detailedZoningRecords: [], // contains the complete zoningDocuments
  filterSelectedValues: {
    status: '',
    state: '',
    city: '',
    suburb: '',
    searchStr: '',
    date: {
      startDate: '',
      endDate: '',
    },
  },
  filters: {
    loaded: false,
    statusFilter: [],
    stateFilter: [],
    cityFilter: [],
    suburbFilter: [],
  },
  pagination: {
    // ZoningChanges Pagination State: tracking it in the store to render the previous history when the user changes tabs.
    paginationFetched: false, // indicates whether pagination is updated after mounting of the pagination component
    currentActive: 1, // indicates the current active page (number) in the pagination component
    totalRecords: 1000, // indicates the totalRecords returned
    totalPages: 100, // indicates the total pages
    /* 
      Note: totalPagesSecondSet and highestPageSetOne can both be derived from the currentActive, totalPages 
      We're tracking these both states to handle two edge cases specific to our Pagination Component - 
       1. totalPagesSecondSet: this can be calculated based on total pages and a check that the the
          items in secondSet cannot be greater than 3 - 
          keeping track of it here to not calculate it in every reducer  
       2. highestPageSetOne: to keep track of the firstRow Pagination -
          because when the currentActive moves to second row -
          we wont be able to keep track of pagination rendered in firstSet
    */
    totalPagesSecondSet: 3,
    highestPageSetOne: 4,
  },
  size: 10,
  query: {
    // Zoning Changes API Query Object
    bool: {
      must: [
        {
          terms: {
            'source.keyword': [
              'NSW_Rezoning_API_v1',
              'VIC_Rezoning_API_v1',
              'QLD_Rezoning_API_v1',
              'SA_Rezoning_API_v1',
              'WA_Rezoning_API_v1',
              'TAS_Rezoning_API_v1',
              'NT_Rezoning_API_v1',
              'NSW_Permit_API_v1',
            ],
          },
        },
      ],
      filter: {
        bool: {},
      },
    },
  },
  status: 'idle', // change to one of the following enumns: 'idle' 'loading' 'error'
  error: {
    status: false,
    message: '',
  },
};

const zoningChangesSlice = createSlice({
  name: 'zoningChanges',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchZoningRecords.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.detailedZoningRecords = [...action.payload.responseData.hits.hits];
      state.activeZoningProps = action.payload.responseData.hits.hits.map(
        ({ _source }) => ({
          recordID: _source.record_id,
          recordDate: _source.date,
          city: _source.city,
          address: _source.address,
          projectBrief: _source.project_brief,
        })
      );
    },
    [fetchZoningRecords.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchZoningRecords.rejected]: (state, action) => {
      state.error.status = true;
      state.error.message = action.payload.message;
      state.status = 'error';
    },
    [updatePagination]: (state, action) => {
      state.pagination = action.payload;
    },
    [fetchFilters.fulfilled]: (state, action) => {
      state.filters.loaded = true;
      state.filters.statusFilter = action.payload.status;
      state.filters.stateFilter = action.payload.state;
      state.filters.cityFilter = action.payload.city;
      state.filters.suburbFilter = action.payload.suburb;
    },
    [setFilterValues]: (state, action) => {
      state.filterSelectedValues.state = action.payload.state;
      state.filterSelectedValues.status = action.payload.status;
      state.filterSelectedValues.city = action.payload.city;
      state.filterSelectedValues.suburb = action.payload.suburb;
      state.filterSelectedValues.date.startDate = action.payload.startDate;
      state.filterSelectedValues.date.endDate = action.payload.endDate;
      state.filterSelectedValues.searchStr = action.payload.searchStr;
    },
    [resetFilterValues]: (state) => {
      state.filterSelectedValues = {
        state: '',
        status: '',
        city: '',
        suburb: '',
        searchStr: '',
        date: {
          startDate: '',
          endData: '',
        },
      };
    },
    [startRowPageBtnAction]: startRowPageBtnReducer,
    [endRowPageBtnAction]: endRowPageBtnReducer,
    [nextPageBtnAction]: nextPageBtnReducer,
    [prevPageBtnAction]: prevPageBtnReducer,
    [resetPagination]: (state) => {
      state.pagination = initialState.pagination;
    },
  },
});

export {
  resetPagination,
  updatePagination,
  startRowPageBtnAction,
  endRowPageBtnAction,
  nextPageBtnAction,
  prevPageBtnAction,
  setFilterValues,
  resetFilterValues,
};
export default zoningChangesSlice.reducer;
