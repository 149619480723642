import React from 'react';

function UnderConstructionSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="freepik_stories-under-construction"
      className="animated"
      version="1.1"
      viewBox="0 0 500 500"
    >
      <g
        id="freepik--Floor--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 250,
          MsTransformOrigin: 250,
          transformOrigin: 250,
        }}
      >
        <ellipse
          id="freepik--floor--inject-9"
          cx="250"
          cy="340.15"
          fill="#F5F5F5"
          rx="235.59"
          ry="128.96"
          style={{
            WebkitTransformOrigin: 250,
            MsTransformOrigin: 250,
            transformOrigin: 250,
          }}
          className="animable"
        />
      </g>
      <g
        id="freepik--Shadows--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 251.078,
          MsTransformOrigin: 251.078,
          transformOrigin: 251.078,
        }}
      >
        <path
          id="freepik--Shadow--inject-9"
          d="M94.05 390.84c5.06 2.85 5.11 7.56.11 10.52s-13.15 3-18.22.19-5.11-7.55-.11-10.55 13.17-3 18.22-.16z"
          style={{
            WebkitTransformOrigin: 84.9931,
            MsTransformOrigin: 84.9931,
            transformOrigin: 84.9931,
          }}
          fill="#E0E0E0"
          className="animable"
        />
        <path
          id="freepik--shadow--inject-9"
          d="M228 354.53l-49-28a3.18 3.18 0 00-2.88 0l-.51.29c-.8.46-.79 1.2 0 1.64l16.69 9.34-17.1 9.9-16.49-9.52a3.18 3.18 0 00-2.88 0l-.51.29a.88.88 0 000 1.67l49.8 28.75.54-.64.27.15 19.66-11.33.15.09.29-.34.42-.24-.15-.09z"
          style={{
            WebkitTransformOrigin: 191.359,
            MsTransformOrigin: 191.359,
            transformOrigin: 191.359,
          }}
          fill="#E0E0E0"
          className="animable"
        />
        <path
          id="freepik--shadow--inject-9"
          d="M174.43 436.83l24.1-13.91a1 1 0 000-1.87l-24.1-13.91a3.62 3.62 0 00-3.24 0l-24.09 13.91a1 1 0 000 1.87l24.09 13.91a3.56 3.56 0 003.24 0z"
          style={{
            WebkitTransformOrigin: 172.815,
            MsTransformOrigin: 172.815,
            transformOrigin: 172.815,
          }}
          fill="#E0E0E0"
          className="animable"
        />
        <path
          id="freepik--shadow--inject-9"
          d="M347.69 421.39l24.09-13.92a1 1 0 000-1.87l-24.09-13.91a3.62 3.62 0 00-3.24 0l-24.09 13.91a1 1 0 000 1.87l24.09 13.92a3.62 3.62 0 003.24 0z"
          style={{
            WebkitTransformOrigin: 346.07,
            MsTransformOrigin: 346.07,
            transformOrigin: 346.07,
          }}
          fill="#E0E0E0"
          className="animable"
        />
        <path
          id="freepik--shadow--inject-9"
          d="M243.5 435.33l-28.32-16.41a.71.71 0 010-1.35L283 378.39a2.61 2.61 0 012.34 0l28.37 16.38a.71.71 0 010 1.35l-67.91 39.21a2.61 2.61 0 01-2.3 0z"
          style={{
            WebkitTransformOrigin: 264.445,
            MsTransformOrigin: 264.445,
            transformOrigin: 264.445,
          }}
          fill="#E0E0E0"
          className="animable"
        />
        <path
          id="freepik--shadow--inject-9"
          d="M379.44 376.39l-59.86-34.56c-4.78-2.76-4.78-7.23 0-10l68-39.25c4.79-2.76 12.54-2.76 17.32 0l59.86 34.56c4.79 2.77 4.79 7.24 0 10l-68 39.25c-4.76 2.76-12.54 2.76-17.32 0z"
          style={{
            WebkitTransformOrigin: 392.174,
            MsTransformOrigin: 392.174,
            transformOrigin: 392.174,
          }}
          fill="#E0E0E0"
          className="animable"
        />
        <path
          id="freepik--shadow--inject-9"
          d="M56.91 382.49L34.7 369.67a1.32 1.32 0 010-2.5L270 231.3a4.79 4.79 0 014.33 0l22.21 12.82a1.32 1.32 0 010 2.5L61.24 382.49a4.79 4.79 0 01-4.33 0z"
          style={{
            WebkitTransformOrigin: 165.62,
            MsTransformOrigin: 165.62,
            transformOrigin: 165.62,
          }}
          fill="#E0E0E0"
          className="animable"
        />
        <path
          id="freepik--shadow--inject-9"
          d="M145.2 362c5.94 3.35 6 8.87.13 12.34s-15.43 3.58-21.37.23-6-8.87-.13-12.34 15.43-3.59 21.37-.23z"
          style={{
            WebkitTransformOrigin: 134.58,
            MsTransformOrigin: 134.58,
            transformOrigin: 134.58,
          }}
          fill="#E0E0E0"
          className="animable"
        />
      </g>
      <g
        id="freepik--Gears--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 104.084,
          MsTransformOrigin: 104.084,
          transformOrigin: 104.084,
        }}
      >
        <g
          id="freepik--gears--inject-9"
          className="animable"
          style={{
            WebkitTransformOrigin: 104.084,
            MsTransformOrigin: 104.084,
            transformOrigin: 104.084,
          }}
        >
          <path
            d="M152.15 65.25a.68.68 0 00-.74.12l-1.48 1.12-.28.94a1.79 1.79 0 00.53 1.69 7.45 7.45 0 01.76.82 1.06 1.06 0 001.47.22l3.45-2.61a.69.69 0 01.72-.12zm-4.67 13.59c-.7-2.88-2.66-4.22-5.06-3.86a6.86 6.86 0 01.61 1.68c1.11 4.57-1.39 11.33-5.57 15.09a8.88 8.88 0 01-4.46 2.37c1.65 3 5.33 3.06 8.94-.18 4.15-3.76 6.64-10.52 5.54-15.1zm-1.23-19.78A1 1 0 00146 59l-2.75-.44a1.47 1.47 0 00-1.49.82l-2.24 5a2 2 0 01-.52.68 2.12 2.12 0 01-.41.33l1 2.6a.93.93 0 001.32.5 11.71 11.71 0 011.64-.66 3 3 0 00.93-.57 2.28 2.28 0 00.54-.69l2.22-5a1.5 1.5 0 011.49-.82l2.76.43a1 1 0 01.28.09zm-4.53 47.62a.75.75 0 01-.29-.39l-1.12-3a.92.92 0 00-1.32-.5 11.71 11.71 0 01-1.64.66 1.89 1.89 0 00-.51.26l.15.38a.79.79 0 00.38.45zm-8.17-44.31a.6.6 0 00-.3-.06.74.74 0 00-.48.23L128.9 66a1.07 1.07 0 00-.14.16 1.13 1.13 0 00-.1.13 3 3 0 00-.32.67.94.94 0 010 .16 1.91 1.91 0 00-.06.66l.5 3.86a3.15 3.15 0 01-.51 1.91c-.62.84-1.19 1.72-1.73 2.62a2 2 0 01-.85.76 2.09 2.09 0 01-.35.13h-.16a2 2 0 01-.24 0H122.04l-.19.06-.21.1a2.36 2.36 0 00-.36.26 1.59 1.59 0 00-.38.56l-2.09 5.68a1 1 0 00.5 1.3l3 1.48a1.3 1.3 0 01.12.75c-.14.89-.24 1.79-.29 2.66a3.23 3.23 0 01-.75 1.84l-3.39 3.7a.83.83 0 00-.16.21 1.19 1.19 0 00-.13.25 2.08 2.08 0 00-.18 1.33l.94 3.89a.82.82 0 00.35.51l4.43 2.18a.71.71 0 01-.33-.5l-.94-3.89a1.76 1.76 0 010-.76 2.1 2.1 0 01.5-1.06l3.34-3.51a3.4 3.4 0 00.77-1.85 22.3 22.3 0 01.28-2.65 1.33 1.33 0 00-.83-1.42l-2.17-.75a1 1 0 01-.64-1.36l2.09-5.68a1.5 1.5 0 01.39-.56 1.62 1.62 0 011-.46h2.63a1.65 1.65 0 00.75-.2 2 2 0 00.85-.75c.53-.9 1.11-1.78 1.72-2.62a3.11 3.11 0 00.51-1.92l-.5-3.85a1.57 1.57 0 01.06-.67 2.26 2.26 0 01.6-1.11l3.86-3.47a.71.71 0 01.8-.16zm-3.38 41.49a1.81 1.81 0 00-.54-1.68 6.52 6.52 0 01-.75-.82 1 1 0 00-1.47-.23l-2 1.49-1.23 4.14a.91.91 0 00.44 1.08l4.48 2.16a.89.89 0 01-.44-1.08z"
            style={{
              WebkitTransformOrigin: 137.035,
              MsTransformOrigin: 137.035,
              transformOrigin: 137.035,
            }}
            id="el85gj29r1b5p"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M152.15 65.25a.68.68 0 00-.74.12l-1.48 1.12-.28.94a1.79 1.79 0 00.53 1.69 7.45 7.45 0 01.76.82 1.06 1.06 0 001.47.22l3.45-2.61a.69.69 0 01.72-.12zm-4.67 13.59c-.7-2.88-2.66-4.22-5.06-3.86a6.86 6.86 0 01.61 1.68c1.11 4.57-1.39 11.33-5.57 15.09a8.88 8.88 0 01-4.46 2.37c1.65 3 5.33 3.06 8.94-.18 4.15-3.76 6.64-10.52 5.54-15.1zm-1.23-19.78A1 1 0 00146 59l-2.75-.44a1.47 1.47 0 00-1.49.82l-2.24 5a2 2 0 01-.52.68 2.12 2.12 0 01-.41.33l1 2.6a.93.93 0 001.32.5 11.71 11.71 0 011.64-.66 3 3 0 00.93-.57 2.28 2.28 0 00.54-.69l2.22-5a1.5 1.5 0 011.49-.82l2.76.43a1 1 0 01.28.09zm-4.53 47.62a.75.75 0 01-.29-.39l-1.12-3a.92.92 0 00-1.32-.5 11.71 11.71 0 01-1.64.66 1.89 1.89 0 00-.51.26l.15.38a.79.79 0 00.38.45zm-8.17-44.31a.6.6 0 00-.3-.06.74.74 0 00-.48.23L128.9 66a1.07 1.07 0 00-.14.16 1.13 1.13 0 00-.1.13 3 3 0 00-.32.67.94.94 0 010 .16 1.91 1.91 0 00-.06.66l.5 3.86a3.15 3.15 0 01-.51 1.91c-.62.84-1.19 1.72-1.73 2.62a2 2 0 01-.85.76 2.09 2.09 0 01-.35.13h-.16a2 2 0 01-.24 0H122.04l-.19.06-.21.1a2.36 2.36 0 00-.36.26 1.59 1.59 0 00-.38.56l-2.09 5.68a1 1 0 00.5 1.3l3 1.48a1.3 1.3 0 01.12.75c-.14.89-.24 1.79-.29 2.66a3.23 3.23 0 01-.75 1.84l-3.39 3.7a.83.83 0 00-.16.21 1.19 1.19 0 00-.13.25 2.08 2.08 0 00-.18 1.33l.94 3.89a.82.82 0 00.35.51l4.43 2.18a.71.71 0 01-.33-.5l-.94-3.89a1.76 1.76 0 010-.76 2.1 2.1 0 01.5-1.06l3.34-3.51a3.4 3.4 0 00.77-1.85 22.3 22.3 0 01.28-2.65 1.33 1.33 0 00-.83-1.42l-2.17-.75a1 1 0 01-.64-1.36l2.09-5.68a1.5 1.5 0 01.39-.56 1.62 1.62 0 011-.46h2.63a1.65 1.65 0 00.75-.2 2 2 0 00.85-.75c.53-.9 1.11-1.78 1.72-2.62a3.11 3.11 0 00.51-1.92l-.5-3.85a1.57 1.57 0 01.06-.67 2.26 2.26 0 01.6-1.11l3.86-3.47a.71.71 0 01.8-.16zm-3.38 41.49a1.81 1.81 0 00-.54-1.68 6.52 6.52 0 01-.75-.82 1 1 0 00-1.47-.23l-2 1.49-1.23 4.14a.91.91 0 00.44 1.08l4.48 2.16a.89.89 0 01-.44-1.08z"
            style={{
              WebkitTransformOrigin: 137.035,
              MsTransformOrigin: 137.035,
              transformOrigin: 137.035,
            }}
            id="elcyio98qqz2n"
            fill="#FFF"
            className="animable"
            opacity="0.7"
          />
          <path
            d="M152.15 65.25a.68.68 0 00-.74.12l-1.48 1.12-.28.94a1.79 1.79 0 00.53 1.69 7.45 7.45 0 01.76.82 1.06 1.06 0 001.47.22l3.45-2.61a.69.69 0 01.72-.12zm-4.67 13.59c-.7-2.88-2.66-4.22-5.06-3.86a6.86 6.86 0 01.61 1.68c1.11 4.57-1.39 11.33-5.57 15.09a8.88 8.88 0 01-4.46 2.37c1.65 3 5.33 3.06 8.94-.18 4.15-3.76 6.64-10.52 5.54-15.1zm-1.23-19.78A1 1 0 00146 59l-2.75-.44a1.47 1.47 0 00-1.49.82l-2.24 5a2 2 0 01-.52.68 2.12 2.12 0 01-.41.33l1 2.6a.93.93 0 001.32.5 11.71 11.71 0 011.64-.66 3 3 0 00.93-.57 2.28 2.28 0 00.54-.69l2.22-5a1.5 1.5 0 011.49-.82l2.76.43a1 1 0 01.28.09zm-4.53 47.62a.75.75 0 01-.29-.39l-1.12-3a.92.92 0 00-1.32-.5 11.71 11.71 0 01-1.64.66 1.89 1.89 0 00-.51.26l.15.38a.79.79 0 00.38.45zm-8.17-44.31a.6.6 0 00-.3-.06.74.74 0 00-.48.23L128.9 66a1.07 1.07 0 00-.14.16 1.13 1.13 0 00-.1.13 3 3 0 00-.32.67.94.94 0 010 .16 1.91 1.91 0 00-.06.66l.5 3.86a3.15 3.15 0 01-.51 1.91c-.62.84-1.19 1.72-1.73 2.62a2 2 0 01-.85.76 2.09 2.09 0 01-.35.13h-.16a2 2 0 01-.24 0H122.04l-.19.06-.21.1a2.36 2.36 0 00-.36.26 1.59 1.59 0 00-.38.56l-2.09 5.68a1 1 0 00.5 1.3l3 1.48a1.3 1.3 0 01.12.75c-.14.89-.24 1.79-.29 2.66a3.23 3.23 0 01-.75 1.84l-3.39 3.7a.83.83 0 00-.16.21 1.19 1.19 0 00-.13.25 2.08 2.08 0 00-.18 1.33l.94 3.89a.82.82 0 00.35.51l4.43 2.18a.71.71 0 01-.33-.5l-.94-3.89a1.76 1.76 0 010-.76 2.1 2.1 0 01.5-1.06l3.34-3.51a3.4 3.4 0 00.77-1.85 22.3 22.3 0 01.28-2.65 1.33 1.33 0 00-.83-1.42l-2.17-.75a1 1 0 01-.64-1.36l2.09-5.68a1.5 1.5 0 01.39-.56 1.62 1.62 0 011-.46h2.63a1.65 1.65 0 00.75-.2 2 2 0 00.85-.75c.53-.9 1.11-1.78 1.72-2.62a3.11 3.11 0 00.51-1.92l-.5-3.85a1.57 1.57 0 01.06-.67 2.26 2.26 0 01.6-1.11l3.86-3.47a.71.71 0 01.8-.16zm-3.38 41.49a1.81 1.81 0 00-.54-1.68 6.52 6.52 0 01-.75-.82 1 1 0 00-1.47-.23l-2 1.49-1.23 4.14a.91.91 0 00.44 1.08l4.48 2.16a.89.89 0 01-.44-1.08z"
            style={{
              WebkitTransformOrigin: 137.035,
              MsTransformOrigin: 137.035,
              transformOrigin: 137.035,
            }}
            id="elgsvpdw3i8tn"
            className="animable"
            opacity="0.05"
          />
          <path
            d="M125.75 94.13l-3.34 3.51a2.1 2.1 0 00-.5 1.06l-4.43-2.18a2.28 2.28 0 01.17-.58 1.19 1.19 0 01.13-.25.83.83 0 01.16-.21l3.35-3.51z"
            style={{
              WebkitTransformOrigin: 121.615,
              MsTransformOrigin: 121.615,
              transformOrigin: 121.615,
            }}
            id="el6tsbp13191t"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M130.11 79.11a1.65 1.65 0 01-.75.2h-2.63a1.62 1.62 0 00-1 .46l-4.45-2.19a2.36 2.36 0 01.36-.26l.21-.1.19-.06H124.94a2 2 0 00.24 0h.16l.34-.14z"
            style={{
              WebkitTransformOrigin: 125.695,
              MsTransformOrigin: 125.695,
              transformOrigin: 125.695,
            }}
            id="elvs99itxtqh"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M156.58 67.42a.68.68 0 00-.72.14l-3.45 2.61a1.05 1.05 0 01-1.47-.24 8.36 8.36 0 00-.76-.81 1.79 1.79 0 01-.53-1.69l.28-.94 1.48-1.12a.68.68 0 01.74-.12z"
            style={{
              WebkitTransformOrigin: 153.095,
              MsTransformOrigin: 153.095,
              transformOrigin: 153.095,
            }}
            id="eltb76m85qi"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M150.68 61.24a1 1 0 00-.28-.09l-2.76-.43a1.5 1.5 0 00-1.49.82l-2.22 5a2.28 2.28 0 01-.54.69L139 65.07a2 2 0 00.52-.68l2.24-5a1.47 1.47 0 011.49-.82L146 59a1 1 0 01.3.09z"
            style={{
              WebkitTransformOrigin: 144.84,
              MsTransformOrigin: 144.84,
              transformOrigin: 144.84,
            }}
            id="el8x13vbfxnv"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M138 64.56a.71.71 0 00-.8.16l-3.86 3.47a2.26 2.26 0 00-.6 1.11l-4.45-2.18a.94.94 0 000-.16 3 3 0 01.32-.67 1.13 1.13 0 01.1-.13 1.07 1.07 0 01.14-.16l3.87-3.46a.74.74 0 01.48-.23.6.6 0 01.3.06z"
            style={{
              WebkitTransformOrigin: 133.145,
              MsTransformOrigin: 133.145,
              transformOrigin: 133.145,
            }}
            id="elrkq4j1nmw8o"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M125.75 94.13l-3.34 3.51a2.1 2.1 0 00-.5 1.06l-4.43-2.18a2.28 2.28 0 01.17-.58 1.19 1.19 0 01.13-.25.83.83 0 01.16-.21l3.35-3.51z"
            style={{
              WebkitTransformOrigin: 121.615,
              MsTransformOrigin: 121.615,
              transformOrigin: 121.615,
            }}
            id="eljdf9xn6qx9"
            fill="#FFF"
            className="animable"
            opacity="0.9"
          />
          <path
            d="M130.11 79.11a1.65 1.65 0 01-.75.2h-2.63a1.62 1.62 0 00-1 .46l-4.45-2.19a2.36 2.36 0 01.36-.26l.21-.1.19-.06H124.94a2 2 0 00.24 0h.16l.34-.14z"
            style={{
              WebkitTransformOrigin: 125.695,
              MsTransformOrigin: 125.695,
              transformOrigin: 125.695,
            }}
            id="el3ysoldxjybl"
            fill="#FFF"
            className="animable"
            opacity="0.9"
          />
          <path
            d="M156.58 67.42a.68.68 0 00-.72.14l-3.45 2.61a1.05 1.05 0 01-1.47-.24 8.36 8.36 0 00-.76-.81 1.79 1.79 0 01-.53-1.69l.28-.94 1.48-1.12a.68.68 0 01.74-.12z"
            style={{
              WebkitTransformOrigin: 153.095,
              MsTransformOrigin: 153.095,
              transformOrigin: 153.095,
            }}
            id="elkzhdwtbmg9t"
            fill="#FFF"
            className="animable"
            opacity="0.9"
          />
          <path
            d="M150.68 61.24a1 1 0 00-.28-.09l-2.76-.43a1.5 1.5 0 00-1.49.82l-2.22 5a2.28 2.28 0 01-.54.69L139 65.07a2 2 0 00.52-.68l2.24-5a1.47 1.47 0 011.49-.82L146 59a1 1 0 01.3.09z"
            style={{
              WebkitTransformOrigin: 144.84,
              MsTransformOrigin: 144.84,
              transformOrigin: 144.84,
            }}
            id="eln05nz2vz1y9"
            fill="#FFF"
            className="animable"
            opacity="0.9"
          />
          <path
            d="M138 64.56a.71.71 0 00-.8.16l-3.86 3.47a2.26 2.26 0 00-.6 1.11l-4.45-2.18a.94.94 0 000-.16 3 3 0 01.32-.67 1.13 1.13 0 01.1-.13 1.07 1.07 0 01.14-.16l3.87-3.46a.74.74 0 01.48-.23.6.6 0 01.3.06z"
            style={{
              WebkitTransformOrigin: 133.145,
              MsTransformOrigin: 133.145,
              transformOrigin: 133.145,
            }}
            id="elenkjfsnaw9e"
            fill="#FFF"
            className="animable"
            opacity="0.9"
          />
          <path
            d="M157.9 71.84L157 68a.65.65 0 00-1.11-.39l-3.44 2.61a1.05 1.05 0 01-1.47-.24 8.36 8.36 0 00-.76-.81 1.79 1.79 0 01-.53-1.69l1.51-5.09a.92.92 0 00-.76-1.19l-2.75-.42a1.44 1.44 0 00-1.49.81l-2.24 5a2.72 2.72 0 01-1.46 1.27 12.22 12.22 0 00-1.64.66.93.93 0 01-1.32-.5l-1.12-3a.68.68 0 00-1.17-.29l-3.86 3.47a2.31 2.31 0 00-.66 1.78l.5 3.85a3 3 0 01-.51 1.92c-.61.84-1.19 1.72-1.72 2.62a2.11 2.11 0 01-1.6 1h-2.62a1.7 1.7 0 00-1.45 1l-2.09 5.68a1 1 0 00.64 1.35l2.17.77a1.29 1.29 0 01.82 1.4c-.14.9-.23 1.79-.28 2.66a3.24 3.24 0 01-.76 1.85l-3.38 3.55a2.18 2.18 0 00-.49 1.82l.94 3.88a.64.64 0 001.1.4l3.45-2.61a1 1 0 011.46.23 6.54 6.54 0 00.76.81 1.78 1.78 0 01.54 1.69l-1.55 5.15a.91.91 0 00.75 1.19l2.76.43a1.47 1.47 0 001.49-.81l2.23-5a2.65 2.65 0 011.47-1.26 15.47 15.47 0 001.63-.66.92.92 0 011.32.5l1.12 3a.68.68 0 001.18.29l3.85-3.47a2.25 2.25 0 00.66-1.77l-.5-3.86a3.06 3.06 0 01.51-1.91c.62-.85 1.19-1.72 1.73-2.63a2.09 2.09 0 011.6-1h2.62a1.68 1.68 0 001.44-1l2.1-5.68a1.07 1.07 0 00-.64-1.36l-2.18-.76a1.31 1.31 0 01-.82-1.41 24.78 24.78 0 00.28-2.66 3.35 3.35 0 01.76-1.84l3.39-3.56a2.16 2.16 0 00.49-1.93zm-16 22.1c-4.18 3.76-8.47 3.09-9.57-1.49s1.39-11.33 5.57-15.09 8.46-3.1 9.57 1.48-1.38 11.34-5.56 15.1z"
            style={{
              WebkitTransformOrigin: 139.94,
              MsTransformOrigin: 139.94,
              transformOrigin: 139.94,
            }}
            id="elud3wulqipbd"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M157.9 71.84L157 68a.65.65 0 00-1.11-.39l-3.44 2.61a1.05 1.05 0 01-1.47-.24 8.36 8.36 0 00-.76-.81 1.79 1.79 0 01-.53-1.69l1.51-5.09a.92.92 0 00-.76-1.19l-2.75-.42a1.44 1.44 0 00-1.49.81l-2.24 5a2.72 2.72 0 01-1.46 1.27 12.22 12.22 0 00-1.64.66.93.93 0 01-1.32-.5l-1.12-3a.68.68 0 00-1.17-.29l-3.86 3.47a2.31 2.31 0 00-.66 1.78l.5 3.85a3 3 0 01-.51 1.92c-.61.84-1.19 1.72-1.72 2.62a2.11 2.11 0 01-1.6 1h-2.62a1.7 1.7 0 00-1.45 1l-2.09 5.68a1 1 0 00.64 1.35l2.17.77a1.29 1.29 0 01.82 1.4c-.14.9-.23 1.79-.28 2.66a3.24 3.24 0 01-.76 1.85l-3.38 3.55a2.18 2.18 0 00-.49 1.82l.94 3.88a.64.64 0 001.1.4l3.45-2.61a1 1 0 011.46.23 6.54 6.54 0 00.76.81 1.78 1.78 0 01.54 1.69l-1.55 5.15a.91.91 0 00.75 1.19l2.76.43a1.47 1.47 0 001.49-.81l2.23-5a2.65 2.65 0 011.47-1.26 15.47 15.47 0 001.63-.66.92.92 0 011.32.5l1.12 3a.68.68 0 001.18.29l3.85-3.47a2.25 2.25 0 00.66-1.77l-.5-3.86a3.06 3.06 0 01.51-1.91c.62-.85 1.19-1.72 1.73-2.63a2.09 2.09 0 011.6-1h2.62a1.68 1.68 0 001.44-1l2.1-5.68a1.07 1.07 0 00-.64-1.36l-2.18-.76a1.31 1.31 0 01-.82-1.41 24.78 24.78 0 00.28-2.66 3.35 3.35 0 01.76-1.84l3.39-3.56a2.16 2.16 0 00.49-1.93zm-16 22.1c-4.18 3.76-8.47 3.09-9.57-1.49s1.39-11.33 5.57-15.09 8.46-3.1 9.57 1.48-1.38 11.34-5.56 15.1z"
            style={{
              WebkitTransformOrigin: 139.94,
              MsTransformOrigin: 139.94,
              transformOrigin: 139.94,
            }}
            id="ellp64hkccfl"
            fill="#FFF"
            className="animable"
            opacity="0.75"
          />
          <path
            d="M98 74.19a1 1 0 00-1 .16l-2 1.54-.39 1.31a2.46 2.46 0 00.74 2.32 9.48 9.48 0 011 1.13 1.45 1.45 0 002 .31l4.75-3.6a1 1 0 011-.17zm-6.47 18.73c-1-4-3.65-5.81-7-5.32a9.87 9.87 0 01.84 2.31c1.52 6.3-1.92 15.61-7.67 20.79a12.31 12.31 0 01-6.14 3.3c2.27 4.14 7.34 4.21 12.31-.25 5.75-5.21 9.19-14.52 7.66-20.83zm-1.69-27.25a1.06 1.06 0 00-.4-.14l-3.8-.53a2 2 0 00-2 1.12L80.51 73a3 3 0 01-.72.95 3.35 3.35 0 01-.57.44L80.55 78a1.27 1.27 0 001.82.69 19.64 19.64 0 012.25-.91 3.81 3.81 0 001.29-.78 3 3 0 00.73-1l3.07-6.94A2.06 2.06 0 0191.77 68l3.8.6a1.41 1.41 0 01.38.11zm-6.24 65.6a1 1 0 01-.39-.55l-1.55-4.11a1.26 1.26 0 00-1.81-.69 17.5 17.5 0 01-2.26.91 2.86 2.86 0 00-.7.35l.2.53a1.1 1.1 0 00.52.62zm-11.24-61a.85.85 0 00-.42-.08 1.09 1.09 0 00-.67.31L66 75.24a1.07 1.07 0 00-.2.22 1.15 1.15 0 00-.14.17 3.89 3.89 0 00-.43.92c0 .07 0 .15-.05.22a2.45 2.45 0 00-.09.91l.64 5.32a4.17 4.17 0 01-.7 2.64c-.85 1.16-1.63 2.38-2.37 3.62a2.87 2.87 0 01-1.17 1 2.34 2.34 0 01-.48.18 1 1 0 01-.24.05 1.13 1.13 0 01-.32 0l-3.61.06h-.38a1.12 1.12 0 00-.26.08 1.92 1.92 0 00-.29.14 2.54 2.54 0 00-.5.36 2.22 2.22 0 00-.53.77L52 99.85a1.44 1.44 0 00.69 1.78l4.16 2a1.79 1.79 0 01.15 1 33.17 33.17 0 00-.38 3.66 4.57 4.57 0 01-1.05 2.54l-4.62 4.84a2.09 2.09 0 00-.22.28 1.11 1.11 0 00-.18.34 2.85 2.85 0 00-.25 1.84l1.3 5.35a1.08 1.08 0 00.48.71l6.1 3a1 1 0 01-.46-.69l-1.29-5.35a2.42 2.42 0 010-1 3 3 0 01.68-1.47l4.5-4.68v-.06a4.58 4.58 0 001.06-2.54 33.68 33.68 0 01.38-3.66 1.8 1.8 0 00-1.05-1.96l-3-1a1.45 1.45 0 01-.89-1.87L61 95a1.88 1.88 0 01.53-.77 2.29 2.29 0 011.47-.6l3.62-.06a2.34 2.34 0 001-.28 2.81 2.81 0 001.17-1c.74-1.24 1.53-2.46 2.37-3.61a4.21 4.21 0 00.7-2.68l-.69-5.31a2.32 2.32 0 01.08-.93 3 3 0 01.83-1.52l5.31-4.78a1 1 0 011.1-.23zm-4.66 57.12a2.5 2.5 0 00-.75-2.33 9.26 9.26 0 01-1-1.12 1.44 1.44 0 00-2-.32l-2.71 2-1.69 5.71a1.24 1.24 0 00.6 1.49l6.14 3a1.24 1.24 0 01-.6-1.5z"
            style={{
              WebkitTransformOrigin: 77.1725,
              MsTransformOrigin: 77.1725,
              transformOrigin: 77.1725,
            }}
            id="elnxecwtzyh5"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M98 74.19a1 1 0 00-1 .16l-2 1.54-.39 1.31a2.46 2.46 0 00.74 2.32 9.48 9.48 0 011 1.13 1.45 1.45 0 002 .31l4.75-3.6a1 1 0 011-.17zm-6.47 18.73c-1-4-3.65-5.81-7-5.32a9.87 9.87 0 01.84 2.31c1.52 6.3-1.92 15.61-7.67 20.79a12.31 12.31 0 01-6.14 3.3c2.27 4.14 7.34 4.21 12.31-.25 5.75-5.21 9.19-14.52 7.66-20.83zm-1.69-27.25a1.06 1.06 0 00-.4-.14l-3.8-.53a2 2 0 00-2 1.12L80.51 73a3 3 0 01-.72.95 3.35 3.35 0 01-.57.44L80.55 78a1.27 1.27 0 001.82.69 19.64 19.64 0 012.25-.91 3.81 3.81 0 001.29-.78 3 3 0 00.73-1l3.07-6.94A2.06 2.06 0 0191.77 68l3.8.6a1.41 1.41 0 01.38.11zm-6.24 65.6a1 1 0 01-.39-.55l-1.55-4.11a1.26 1.26 0 00-1.81-.69 17.5 17.5 0 01-2.26.91 2.86 2.86 0 00-.7.35l.2.53a1.1 1.1 0 00.52.62zm-11.24-61a.85.85 0 00-.42-.08 1.09 1.09 0 00-.67.31L66 75.24a1.07 1.07 0 00-.2.22 1.15 1.15 0 00-.14.17 3.89 3.89 0 00-.43.92c0 .07 0 .15-.05.22a2.45 2.45 0 00-.09.91l.64 5.32a4.17 4.17 0 01-.7 2.64c-.85 1.16-1.63 2.38-2.37 3.62a2.87 2.87 0 01-1.17 1 2.34 2.34 0 01-.48.18 1 1 0 01-.24.05 1.13 1.13 0 01-.32 0l-3.61.06h-.38a1.12 1.12 0 00-.26.08 1.92 1.92 0 00-.29.14 2.54 2.54 0 00-.5.36 2.22 2.22 0 00-.53.77L52 99.85a1.44 1.44 0 00.69 1.78l4.16 2a1.79 1.79 0 01.15 1 33.17 33.17 0 00-.38 3.66 4.57 4.57 0 01-1.05 2.54l-4.62 4.84a2.09 2.09 0 00-.22.28 1.11 1.11 0 00-.18.34 2.85 2.85 0 00-.25 1.84l1.3 5.35a1.08 1.08 0 00.48.71l6.1 3a1 1 0 01-.46-.69l-1.29-5.35a2.42 2.42 0 010-1 3 3 0 01.68-1.47l4.5-4.68v-.06a4.58 4.58 0 001.06-2.54 33.68 33.68 0 01.38-3.66 1.8 1.8 0 00-1.05-1.96l-3-1a1.45 1.45 0 01-.89-1.87L61 95a1.88 1.88 0 01.53-.77 2.29 2.29 0 011.47-.6l3.62-.06a2.34 2.34 0 001-.28 2.81 2.81 0 001.17-1c.74-1.24 1.53-2.46 2.37-3.61a4.21 4.21 0 00.7-2.68l-.69-5.31a2.32 2.32 0 01.08-.93 3 3 0 01.83-1.52l5.31-4.78a1 1 0 011.1-.23zm-4.66 57.12a2.5 2.5 0 00-.75-2.33 9.26 9.26 0 01-1-1.12 1.44 1.44 0 00-2-.32l-2.71 2-1.69 5.71a1.24 1.24 0 00.6 1.49l6.14 3a1.24 1.24 0 01-.6-1.5z"
            style={{
              WebkitTransformOrigin: 77.1725,
              MsTransformOrigin: 77.1725,
              transformOrigin: 77.1725,
            }}
            id="eljo4unarpvb"
            fill="#FFF"
            className="animable"
            opacity="0.7"
          />
          <path
            d="M98 74.19a1 1 0 00-1 .16l-2 1.54-.39 1.31a2.46 2.46 0 00.74 2.32 9.48 9.48 0 011 1.13 1.45 1.45 0 002 .31l4.75-3.6a1 1 0 011-.17zm-6.47 18.73c-1-4-3.65-5.81-7-5.32a9.87 9.87 0 01.84 2.31c1.52 6.3-1.92 15.61-7.67 20.79a12.31 12.31 0 01-6.14 3.3c2.27 4.14 7.34 4.21 12.31-.25 5.75-5.21 9.19-14.52 7.66-20.83zm-1.69-27.25a1.06 1.06 0 00-.4-.14l-3.8-.53a2 2 0 00-2 1.12L80.51 73a3 3 0 01-.72.95 3.35 3.35 0 01-.57.44L80.55 78a1.27 1.27 0 001.82.69 19.64 19.64 0 012.25-.91 3.81 3.81 0 001.29-.78 3 3 0 00.73-1l3.07-6.94A2.06 2.06 0 0191.77 68l3.8.6a1.41 1.41 0 01.38.11zm-6.24 65.6a1 1 0 01-.39-.55l-1.55-4.11a1.26 1.26 0 00-1.81-.69 17.5 17.5 0 01-2.26.91 2.86 2.86 0 00-.7.35l.2.53a1.1 1.1 0 00.52.62zm-11.24-61a.85.85 0 00-.42-.08 1.09 1.09 0 00-.67.31L66 75.24a1.07 1.07 0 00-.2.22 1.15 1.15 0 00-.14.17 3.89 3.89 0 00-.43.92c0 .07 0 .15-.05.22a2.45 2.45 0 00-.09.91l.64 5.32a4.17 4.17 0 01-.7 2.64c-.85 1.16-1.63 2.38-2.37 3.62a2.87 2.87 0 01-1.17 1 2.34 2.34 0 01-.48.18 1 1 0 01-.24.05 1.13 1.13 0 01-.32 0l-3.61.06h-.38a1.12 1.12 0 00-.26.08 1.92 1.92 0 00-.29.14 2.54 2.54 0 00-.5.36 2.22 2.22 0 00-.53.77L52 99.85a1.44 1.44 0 00.69 1.78l4.16 2a1.79 1.79 0 01.15 1 33.17 33.17 0 00-.38 3.66 4.57 4.57 0 01-1.05 2.54l-4.62 4.84a2.09 2.09 0 00-.22.28 1.11 1.11 0 00-.18.34 2.85 2.85 0 00-.25 1.84l1.3 5.35a1.08 1.08 0 00.48.71l6.1 3a1 1 0 01-.46-.69l-1.29-5.35a2.42 2.42 0 010-1 3 3 0 01.68-1.47l4.5-4.68v-.06a4.58 4.58 0 001.06-2.54 33.68 33.68 0 01.38-3.66 1.8 1.8 0 00-1.05-1.96l-3-1a1.45 1.45 0 01-.89-1.87L61 95a1.88 1.88 0 01.53-.77 2.29 2.29 0 011.47-.6l3.62-.06a2.34 2.34 0 001-.28 2.81 2.81 0 001.17-1c.74-1.24 1.53-2.46 2.37-3.61a4.21 4.21 0 00.7-2.68l-.69-5.31a2.32 2.32 0 01.08-.93 3 3 0 01.83-1.52l5.31-4.78a1 1 0 011.1-.23zm-4.66 57.12a2.5 2.5 0 00-.75-2.33 9.26 9.26 0 01-1-1.12 1.44 1.44 0 00-2-.32l-2.71 2-1.69 5.71a1.24 1.24 0 00.6 1.49l6.14 3a1.24 1.24 0 01-.6-1.5z"
            style={{
              WebkitTransformOrigin: 77.1725,
              MsTransformOrigin: 77.1725,
              transformOrigin: 77.1725,
            }}
            id="eluec2ez92bq"
            className="animable"
            opacity="0.05"
          />
          <path
            d="M61.61 114L57 118.81a3 3 0 00-.68 1.47l-6.11-3a3.53 3.53 0 01.24-.8 1.11 1.11 0 01.18-.34 2.09 2.09 0 01.22-.28l4.64-4.86z"
            style={{
              WebkitTransformOrigin: 55.91,
              MsTransformOrigin: 55.91,
              transformOrigin: 55.91,
            }}
            id="el0i863sty0ebq"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M67.61 93.29a2.34 2.34 0 01-1 .28l-3.61.06a2.29 2.29 0 00-1.45.64l-6.13-3a2.54 2.54 0 01.5-.36 1.92 1.92 0 01.29-.14 1.12 1.12 0 01.26-.08h.38l3.61-.06a1.13 1.13 0 00.32 0l.24-.05c.15 0 .3-.12.46-.19z"
            style={{
              WebkitTransformOrigin: 61.515,
              MsTransformOrigin: 61.515,
              transformOrigin: 61.515,
            }}
            id="els0t4fdub4ke"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M104.08 77.19a1 1 0 00-1 .19L98.33 81a1.44 1.44 0 01-2-.32 9.33 9.33 0 00-1-1.12 2.47 2.47 0 01-.74-2.33l.39-1.29 2-1.55a1 1 0 011-.16z"
            style={{
              WebkitTransformOrigin: 99.3078,
              MsTransformOrigin: 99.3078,
              transformOrigin: 99.3078,
            }}
            id="elhntl564ul7m"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M96 68.67a1.41 1.41 0 00-.38-.11l-3.8-.6a2.06 2.06 0 00-2.06 1.12L86.64 76a3 3 0 01-.73 1l-6.12-3a3 3 0 00.72-.95l3.08-6.94a2 2 0 012-1.12l3.8.59a1.06 1.06 0 01.4.14z"
            style={{
              WebkitTransformOrigin: 87.895,
              MsTransformOrigin: 87.895,
              transformOrigin: 87.895,
            }}
            id="elmqmdf934219"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M78.49 73.24a1 1 0 00-1.1.23l-5.31 4.78a3 3 0 00-.83 1.52l-6.12-3c0-.07 0-.15.05-.22a3.89 3.89 0 01.43-.92 1.15 1.15 0 01.14-.17 1.07 1.07 0 01.2-.22l5.32-4.78a1.09 1.09 0 01.67-.31.85.85 0 01.42.08z"
            style={{
              WebkitTransformOrigin: 71.81,
              MsTransformOrigin: 71.81,
              transformOrigin: 71.81,
            }}
            id="el0fhx0zzhcetu"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M61.61 114L57 118.81a3 3 0 00-.68 1.47l-6.11-3a3.53 3.53 0 01.24-.8 1.11 1.11 0 01.18-.34 2.09 2.09 0 01.22-.28l4.64-4.86z"
            style={{
              WebkitTransformOrigin: 55.91,
              MsTransformOrigin: 55.91,
              transformOrigin: 55.91,
            }}
            id="elm3g16ah6lq"
            fill="#FFF"
            className="animable"
            opacity="0.9"
          />
          <path
            d="M67.61 93.29a2.34 2.34 0 01-1 .28l-3.61.06a2.29 2.29 0 00-1.45.64l-6.13-3a2.54 2.54 0 01.5-.36 1.92 1.92 0 01.29-.14 1.12 1.12 0 01.26-.08h.38l3.61-.06a1.13 1.13 0 00.32 0l.24-.05c.15 0 .3-.12.46-.19z"
            style={{
              WebkitTransformOrigin: 61.515,
              MsTransformOrigin: 61.515,
              transformOrigin: 61.515,
            }}
            id="elxnl8i579lr"
            fill="#FFF"
            className="animable"
            opacity="0.9"
          />
          <path
            d="M104.08 77.19a1 1 0 00-1 .19L98.33 81a1.44 1.44 0 01-2-.32 9.33 9.33 0 00-1-1.12 2.47 2.47 0 01-.74-2.33l.39-1.29 2-1.55a1 1 0 011-.16z"
            style={{
              WebkitTransformOrigin: 99.3078,
              MsTransformOrigin: 99.3078,
              transformOrigin: 99.3078,
            }}
            id="elysjldt1hqiq"
            fill="#FFF"
            className="animable"
            opacity="0.9"
          />
          <path
            d="M96 68.67a1.41 1.41 0 00-.38-.11l-3.8-.6a2.06 2.06 0 00-2.06 1.12L86.64 76a3 3 0 01-.73 1l-6.12-3a3 3 0 00.72-.95l3.08-6.94a2 2 0 012-1.12l3.8.59a1.06 1.06 0 01.4.14z"
            style={{
              WebkitTransformOrigin: 87.895,
              MsTransformOrigin: 87.895,
              transformOrigin: 87.895,
            }}
            id="el45k07pqba4a"
            fill="#FFF"
            className="animable"
            opacity="0.9"
          />
          <path
            d="M78.49 73.24a1 1 0 00-1.1.23l-5.31 4.78a3 3 0 00-.83 1.52l-6.12-3c0-.07 0-.15.05-.22a3.89 3.89 0 01.43-.92 1.15 1.15 0 01.14-.17 1.07 1.07 0 01.2-.22l5.32-4.78a1.09 1.09 0 01.67-.31.85.85 0 01.42.08z"
            style={{
              WebkitTransformOrigin: 71.81,
              MsTransformOrigin: 71.81,
              transformOrigin: 71.81,
            }}
            id="el7xltud2abjg"
            fill="#FFF"
            className="animable"
            opacity="0.9"
          />
          <path
            d="M105.89 83.27l-1.29-5.35a.89.89 0 00-1.52-.54L98.33 81a1.44 1.44 0 01-2-.32 10.21 10.21 0 00-1-1.12 2.43 2.43 0 01-.73-2.32l2.07-7a1.25 1.25 0 00-1-1.64l-3.9-.6a2 2 0 00-2 1.12L86.64 76a3.65 3.65 0 01-2 1.74 17.39 17.39 0 00-2.25.91 1.27 1.27 0 01-1.84-.65L79 73.87a.94.94 0 00-1.62-.4l-5.31 4.78a3.14 3.14 0 00-.91 2.45l.7 5.3a4.26 4.26 0 01-.7 2.64c-.85 1.16-1.64 2.37-2.38 3.61a2.89 2.89 0 01-2.2 1.31l-3.58.07A2.3 2.3 0 0061 95l-2.89 7.82a1.45 1.45 0 00.89 1.87l3 1a1.8 1.8 0 011.13 1.94 31.14 31.14 0 00-.38 3.66 4.51 4.51 0 01-1.06 2.54L57 118.82a3 3 0 00-.67 2.5l1.29 5.35a.9.9 0 001.52.55l4.75-3.6a1.44 1.44 0 012 .32 11.2 11.2 0 001 1.12 2.46 2.46 0 01.74 2.32l-2.08 7a1.26 1.26 0 001 1.64l3.8.59a2 2 0 002-1.13l3.08-6.93a3.66 3.66 0 012-1.74 17 17 0 002.26-.91 1.28 1.28 0 011.82.69l1.54 4.11a.94.94 0 001.62.4l5.31-4.78a3.18 3.18 0 00.91-2.45l-.69-5.31a4.2 4.2 0 01.7-2.64c.85-1.16 1.64-2.37 2.38-3.61a2.87 2.87 0 012.2-1.31l3.61-.06a2.3 2.3 0 002-1.41l2.88-7.82a1.44 1.44 0 00-.88-1.87l-3-1a1.8 1.8 0 01-1.13-1.94 36.08 36.08 0 00.38-3.66 4.57 4.57 0 011-2.54l4.67-4.9a3 3 0 00.88-2.53zm-22 30.45c-5.76 5.17-11.66 4.26-13.18-2s1.92-15.62 7.68-20.79 11.65-4.27 13.18 2-1.95 15.61-7.71 20.79z"
            style={{
              WebkitTransformOrigin: 81.0969,
              MsTransformOrigin: 81.0969,
              transformOrigin: 81.0969,
            }}
            id="elovmu39dkhe"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M105.89 83.27l-1.29-5.35a.89.89 0 00-1.52-.54L98.33 81a1.44 1.44 0 01-2-.32 10.21 10.21 0 00-1-1.12 2.43 2.43 0 01-.73-2.32l2.07-7a1.25 1.25 0 00-1-1.64l-3.9-.6a2 2 0 00-2 1.12L86.64 76a3.65 3.65 0 01-2 1.74 17.39 17.39 0 00-2.25.91 1.27 1.27 0 01-1.84-.65L79 73.87a.94.94 0 00-1.62-.4l-5.31 4.78a3.14 3.14 0 00-.91 2.45l.7 5.3a4.26 4.26 0 01-.7 2.64c-.85 1.16-1.64 2.37-2.38 3.61a2.89 2.89 0 01-2.2 1.31l-3.58.07A2.3 2.3 0 0061 95l-2.89 7.82a1.45 1.45 0 00.89 1.87l3 1a1.8 1.8 0 011.13 1.94 31.14 31.14 0 00-.38 3.66 4.51 4.51 0 01-1.06 2.54L57 118.82a3 3 0 00-.67 2.5l1.29 5.35a.9.9 0 001.52.55l4.75-3.6a1.44 1.44 0 012 .32 11.2 11.2 0 001 1.12 2.46 2.46 0 01.74 2.32l-2.08 7a1.26 1.26 0 001 1.64l3.8.59a2 2 0 002-1.13l3.08-6.93a3.66 3.66 0 012-1.74 17 17 0 002.26-.91 1.28 1.28 0 011.82.69l1.54 4.11a.94.94 0 001.62.4l5.31-4.78a3.18 3.18 0 00.91-2.45l-.69-5.31a4.2 4.2 0 01.7-2.64c.85-1.16 1.64-2.37 2.38-3.61a2.87 2.87 0 012.2-1.31l3.61-.06a2.3 2.3 0 002-1.41l2.88-7.82a1.44 1.44 0 00-.88-1.87l-3-1a1.8 1.8 0 01-1.13-1.94 36.08 36.08 0 00.38-3.66 4.57 4.57 0 011-2.54l4.67-4.9a3 3 0 00.88-2.53zm-22 30.45c-5.76 5.17-11.66 4.26-13.18-2s1.92-15.62 7.68-20.79 11.65-4.27 13.18 2-1.95 15.61-7.71 20.79z"
            style={{
              WebkitTransformOrigin: 81.0969,
              MsTransformOrigin: 81.0969,
              transformOrigin: 81.0969,
            }}
            id="elthtxz99r0i"
            fill="#FFF"
            className="animable"
            opacity="0.75"
          />
        </g>
      </g>
      <g
        id="freepik--Cones--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 259.45,
          MsTransformOrigin: 259.45,
          transformOrigin: 259.45,
        }}
      >
        <g
          id="freepik--Cone--inject-9"
          className="animable"
          style={{
            WebkitTransformOrigin: 172.82,
            MsTransformOrigin: 172.82,
            transformOrigin: 172.82,
          }}
        >
          <path
            d="M150 418.65l21.4-12.36a2.91 2.91 0 011.43-.34 3 3 0 011.44.34l21.39 12.36a1 1 0 01.59.83V421a1 1 0 01-.59.83l-21.39 12.35a3 3 0 01-1.44.34 2.91 2.91 0 01-1.43-.34L150 421.87a1 1 0 01-.59-.83v-1.56a1 1 0 01.59-.83z"
            style={{
              WebkitTransformOrigin: 172.83,
              MsTransformOrigin: 172.83,
              transformOrigin: 172.83,
            }}
            id="elkoxi9xirgzp"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M149.39 419.48V421a1 1 0 00.59.83l21.4 12.35a2.91 2.91 0 001.43.34V406a2.91 2.91 0 00-1.43.34L150 418.65a1 1 0 00-.61.83z"
            style={{
              WebkitTransformOrigin: 161.1,
              MsTransformOrigin: 161.1,
              transformOrigin: 161.1,
            }}
            id="elmwqc3x4w92e"
            className="animable"
            opacity="0.1"
          />
          <path
            d="M172.81 406v28.61a3 3 0 001.44-.34l21.39-12.35a1 1 0 00.59-.83v-1.56a1 1 0 00-.59-.83l-21.39-12.36a3 3 0 00-1.44-.34z"
            style={{
              WebkitTransformOrigin: 184.52,
              MsTransformOrigin: 184.52,
              transformOrigin: 184.52,
            }}
            id="ell4icl8gewsd"
            className="animable"
            opacity="0.3"
          />
          <path
            d="M174.25 432.66l21.4-12.35a.88.88 0 000-1.66l-21.4-12.35a3.18 3.18 0 00-2.88 0L150 418.65a.88.88 0 000 1.66l21.39 12.35a3.18 3.18 0 002.86 0z"
            style={{
              WebkitTransformOrigin: 172.825,
              MsTransformOrigin: 172.825,
              transformOrigin: 172.825,
            }}
            id="el579pclfhfbp"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M174.25 432.66l21.4-12.35a.88.88 0 000-1.66l-21.4-12.35a3.18 3.18 0 00-2.88 0L150 418.65a.88.88 0 000 1.66l21.39 12.35a3.18 3.18 0 002.86 0z"
            style={{
              WebkitTransformOrigin: 172.825,
              MsTransformOrigin: 172.825,
              transformOrigin: 172.825,
            }}
            id="elt19p73xlgnj"
            fill="#FFF"
            className="animable"
            opacity="0.5"
          />
          <path
            d="M172.81 406a3 3 0 011.44.35l21.4 12.35a.88.88 0 010 1.66l-21.4 12.35a3 3 0 01-1.44.35z"
            style={{
              WebkitTransformOrigin: 184.524,
              MsTransformOrigin: 184.524,
              transformOrigin: 184.524,
            }}
            id="eldtsjy13ppkq"
            className="animable"
            opacity="0.05"
          />
          <path
            d="M159.6 417.92l9.47-28.43v-.05a2.14 2.14 0 011-1.1 6 6 0 015.39 0 2.23 2.23 0 011 1.1l9.54 28.48c.87 2.48-.35 5.15-3.67 7.07-5.27 3-13.81 3-19.07 0-3.26-1.92-4.53-4.59-3.66-7.07z"
            style={{
              WebkitTransformOrigin: 172.802,
              MsTransformOrigin: 172.802,
              transformOrigin: 172.802,
            }}
            id="el2usisqs7mgt"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M166.35 397.64l1.35-4a1.93 1.93 0 00.59 2 3.88 3.88 0 00.84.65 8.13 8.13 0 007.35 0 4.14 4.14 0 00.84-.65 2 2 0 00.58-2l1.35 4.05a2.32 2.32 0 01-.3 2 4.24 4.24 0 01-1.51 1.37 7.62 7.62 0 01-1.63.66 10.85 10.85 0 01-6 0 7.5 7.5 0 01-1.64-.66 4.33 4.33 0 01-1.51-1.37 2.32 2.32 0 01-.31-2.05z"
            style={{
              WebkitTransformOrigin: 172.796,
              MsTransformOrigin: 172.796,
              transformOrigin: 172.796,
            }}
            id="el2zy4gumdi4t"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M160.93 413.93l1.34-4.05a3.6 3.6 0 00-.07 2 4.59 4.59 0 001.14 2.05 8.08 8.08 0 001.88 1.47 16.8 16.8 0 0015.16 0 7.88 7.88 0 001.88-1.47 4.72 4.72 0 001.15-2.05 3.69 3.69 0 00-.08-2l1.34 4a4.17 4.17 0 01.15 2c-.28 1.54-1.44 3-3.48 4.22-4.73 2.72-12.39 2.72-17.12 0-2-1.19-3.2-2.68-3.47-4.22a4.1 4.1 0 01.18-1.95z"
            style={{
              WebkitTransformOrigin: 172.792,
              MsTransformOrigin: 172.792,
              transformOrigin: 172.792,
            }}
            id="elqdxl38fd5i"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M163.64 405.79l1.35-4.05a2.73 2.73 0 00.12 2 4.85 4.85 0 002 2h.07a12.46 12.46 0 0011.25 0h.06a4.79 4.79 0 002-2 2.78 2.78 0 00.13-2l1.34 4.05a3.1 3.1 0 010 2 4.61 4.61 0 01-1.48 2 8.53 8.53 0 01-1.08.76 12 12 0 01-3.59 1.28 16.09 16.09 0 01-6 0 11.85 11.85 0 01-3.59-1.28 7.87 7.87 0 01-1.08-.76 4.56 4.56 0 01-1.49-2 3.24 3.24 0 01-.01-2z"
            style={{
              WebkitTransformOrigin: 172.806,
              MsTransformOrigin: 172.806,
              transformOrigin: 172.806,
            }}
            id="el7uogbimo7xq"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M172.81 387.7a5.53 5.53 0 012.7.64 2.23 2.23 0 011 1.1l9.49 28.48c.87 2.48-.35 5.15-3.67 7.07a19.53 19.53 0 01-9.54 2.28h.08a8 8 0 006.56-9l-3.94-28.31z"
            style={{
              WebkitTransformOrigin: 179.531,
              MsTransformOrigin: 179.531,
              transformOrigin: 179.531,
            }}
            id="ely2n8ire1erm"
            className="animable"
            opacity="0.1"
          />
          <path
            d="M175.51 391.46c1.49-.86 1.49-2.26 0-3.12a6 6 0 00-5.39 0c-1.49.86-1.49 2.26 0 3.12a6 6 0 005.39 0z"
            style={{
              WebkitTransformOrigin: 172.815,
              MsTransformOrigin: 172.815,
              transformOrigin: 172.815,
            }}
            id="el4ulm76py9b7"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M175.51 391.46c1.49-.86 1.49-2.26 0-3.12a6 6 0 00-5.39 0c-1.49.86-1.49 2.26 0 3.12a6 6 0 005.39 0z"
            style={{
              WebkitTransformOrigin: 172.815,
              MsTransformOrigin: 172.815,
              transformOrigin: 172.815,
            }}
            id="eltxkflhf0ele"
            fill="#FFF"
            className="animable"
            opacity="0.5"
          />
        </g>
        <g
          id="freepik--cone--inject-9"
          className="animable"
          style={{
            WebkitTransformOrigin: 346.07,
            MsTransformOrigin: 346.07,
            transformOrigin: 346.07,
          }}
        >
          <path
            d="M323.24 403.2l21.4-12.35a2.78 2.78 0 011.43-.35 2.85 2.85 0 011.44.35l21.39 12.35a1 1 0 01.59.83v1.56a1 1 0 01-.59.83l-21.39 12.35a3 3 0 01-1.44.34 2.88 2.88 0 01-1.43-.34l-21.4-12.35a1 1 0 01-.59-.83V404a1 1 0 01.59-.8z"
            style={{
              WebkitTransformOrigin: 346.07,
              MsTransformOrigin: 346.07,
              transformOrigin: 346.07,
            }}
            id="elzpvhs4qf1ub"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M322.65 404v1.56a1 1 0 00.59.83l21.4 12.35a2.88 2.88 0 001.43.34V390.5a2.78 2.78 0 00-1.43.35l-21.4 12.35a1 1 0 00-.59.8z"
            style={{
              WebkitTransformOrigin: 334.36,
              MsTransformOrigin: 334.36,
              transformOrigin: 334.36,
            }}
            id="elyjiogj1ej4b"
            className="animable"
            opacity="0.1"
          />
          <path
            d="M346.07 390.5v28.61a3 3 0 001.44-.34l21.39-12.35a1 1 0 00.59-.83V404a1 1 0 00-.59-.83l-21.39-12.35a2.85 2.85 0 00-1.44-.32z"
            style={{
              WebkitTransformOrigin: 357.78,
              MsTransformOrigin: 357.78,
              transformOrigin: 357.78,
            }}
            id="elyunjrh4mhy"
            className="animable"
            opacity="0.3"
          />
          <path
            d="M347.51 417.21l21.39-12.35a.87.87 0 000-1.66l-21.39-12.35a3.18 3.18 0 00-2.88 0l-21.39 12.35a.87.87 0 000 1.66l21.39 12.35a3.18 3.18 0 002.88 0z"
            style={{
              WebkitTransformOrigin: 346.07,
              MsTransformOrigin: 346.07,
              transformOrigin: 346.07,
            }}
            id="elzi44gddlhk"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M347.51 417.21l21.39-12.35a.87.87 0 000-1.66l-21.39-12.35a3.18 3.18 0 00-2.88 0l-21.39 12.35a.87.87 0 000 1.66l21.39 12.35a3.18 3.18 0 002.88 0z"
            style={{
              WebkitTransformOrigin: 346.07,
              MsTransformOrigin: 346.07,
              transformOrigin: 346.07,
            }}
            id="elu25r740s5m9"
            fill="#FFF"
            className="animable"
            opacity="0.5"
          />
          <path
            d="M346.07 390.5a3 3 0 011.44.35l21.39 12.35a.87.87 0 010 1.66l-21.39 12.35a3 3 0 01-1.44.35z"
            style={{
              WebkitTransformOrigin: 357.79,
              MsTransformOrigin: 357.79,
              transformOrigin: 357.79,
            }}
            id="el7jnzivi19fw"
            className="animable"
            opacity="0.05"
          />
          <path
            d="M332.86 402.47l9.47-28.47a2.27 2.27 0 011-1.1 6 6 0 015.4 0 2.27 2.27 0 011 1.1l9.47 28.44c.87 2.48-.35 5.15-3.68 7.07-5.26 3-13.8 3-19.06 0-3.25-1.89-4.46-4.51-3.6-7.04z"
            style={{
              WebkitTransformOrigin: 346.032,
              MsTransformOrigin: 346.032,
              transformOrigin: 346.032,
            }}
            id="elcqbpajfwxn4"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M339.61 382.19l1.35-4a2 2 0 00.58 2 4.22 4.22 0 00.85.65 8.11 8.11 0 007.34 0 4.22 4.22 0 00.85-.65 2 2 0 00.58-2l1.35 4.06a2.32 2.32 0 01-.3 2 4.33 4.33 0 01-1.51 1.37 8.19 8.19 0 01-1.63.67 11.07 11.07 0 01-6 0 8.19 8.19 0 01-1.63-.67 4.33 4.33 0 01-1.51-1.37 2.32 2.32 0 01-.32-2.06z"
            style={{
              WebkitTransformOrigin: 346.057,
              MsTransformOrigin: 346.057,
              transformOrigin: 346.057,
            }}
            id="elsk5zttrb567"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M334.19 398.48l1.35-4a3.6 3.6 0 00-.07 2 4.59 4.59 0 001.14 2.05 7.88 7.88 0 001.88 1.47 16.8 16.8 0 0015.16 0 7.88 7.88 0 001.88-1.47 4.59 4.59 0 001.14-2.05 3.56 3.56 0 00-.07-2l1.34 4a4 4 0 01.14 2 6.46 6.46 0 01-3.47 4.22c-4.73 2.72-12.39 2.72-17.12 0a6.46 6.46 0 01-3.47-4.22 4 4 0 01.17-2z"
            style={{
              WebkitTransformOrigin: 346.055,
              MsTransformOrigin: 346.055,
              transformOrigin: 346.055,
            }}
            id="el4tn4rbdj3ea"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M336.9 390.34l1.34-4.05a2.78 2.78 0 00.13 2 4.79 4.79 0 002 2h.06a12.48 12.48 0 0011.26 0h.06a4.79 4.79 0 002-2 2.78 2.78 0 00.13-2l1.34 4.05a3.17 3.17 0 010 2 4.61 4.61 0 01-1.48 2 7.74 7.74 0 01-1.09.76 11.79 11.79 0 01-3.58 1.28 15.74 15.74 0 01-6 0 11.79 11.79 0 01-3.58-1.28 7.74 7.74 0 01-1.09-.76 4.61 4.61 0 01-1.48-2 3.24 3.24 0 01-.02-2z"
            style={{
              WebkitTransformOrigin: 346.067,
              MsTransformOrigin: 346.067,
              transformOrigin: 346.067,
            }}
            id="elsorz2h28rws"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M346.07 372.25a5.53 5.53 0 012.7.64 2.27 2.27 0 011 1.1l9.47 28.44c.87 2.48-.35 5.15-3.68 7.07a19.47 19.47 0 01-9.53 2.28h.08a8 8 0 006.55-9l-3.93-28.31z"
            style={{
              WebkitTransformOrigin: 352.771,
              MsTransformOrigin: 352.771,
              transformOrigin: 352.771,
            }}
            id="elqqmzdmtr07g"
            className="animable"
            opacity="0.1"
          />
          <path
            d="M348.77 376c1.49-.86 1.49-2.26 0-3.12a6 6 0 00-5.4 0c-1.49.86-1.49 2.26 0 3.12a6 6 0 005.4 0z"
            style={{
              WebkitTransformOrigin: 346.07,
              MsTransformOrigin: 346.07,
              transformOrigin: 346.07,
            }}
            id="elv687xhia7y8"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M348.77 376c1.49-.86 1.49-2.26 0-3.12a6 6 0 00-5.4 0c-1.49.86-1.49 2.26 0 3.12a6 6 0 005.4 0z"
            style={{
              WebkitTransformOrigin: 346.07,
              MsTransformOrigin: 346.07,
              transformOrigin: 346.07,
            }}
            id="elf8eu1iag1jg"
            fill="#FFF"
            className="animable"
            opacity="0.5"
          />
        </g>
      </g>
      <g
        id="freepik--Plant--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 35.6179,
          MsTransformOrigin: 35.6179,
          transformOrigin: 35.6179,
        }}
      >
        <g
          id="freepik--Plants--inject-9"
          className="animable"
          style={{
            WebkitTransformOrigin: 35.6179,
            MsTransformOrigin: 35.6179,
            transformOrigin: 35.6179,
          }}
        >
          <path
            d="M51.34 358.06s2.45-12-.37-22.82-8.9-19-16.08-22-15.33 1.85-9 9.08 18.82 16.53 19.6 36z"
            style={{
              WebkitTransformOrigin: 37.9714,
              MsTransformOrigin: 37.9714,
              transformOrigin: 37.9714,
            }}
            id="el3ajalidacf3"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M51.34 358.06s2.45-12-.37-22.82-8.9-19-16.08-22-15.33 1.85-9 9.08 18.82 16.53 19.6 36z"
            style={{
              WebkitTransformOrigin: 37.9714,
              MsTransformOrigin: 37.9714,
              transformOrigin: 37.9714,
            }}
            id="elqlah95o3aiq"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M50.16 354.35h.08a.43.43 0 00.39-.45c-1.54-21.68-13.73-34.5-20-38.24a.41.41 0 00-.57.14.42.42 0 00.15.57c6.1 3.67 18.05 16.25 19.56 37.59a.42.42 0 00.39.39z"
            style={{
              WebkitTransformOrigin: 40.3175,
              MsTransformOrigin: 40.3175,
              transformOrigin: 40.3175,
            }}
            id="elaospg6c9ii6"
            fill="#FFF"
            className="animable"
          />
          <path
            d="M15.59 339.07c-.79 2.42.93 4.1 2.56 5.46 1.24 1.05 2.61 2.18 3 3.78.69 3.22-3.12 6.19-2.3 9.38a5.18 5.18 0 002.69 3c1 .55 2.11.9 3.17 1.35a14.52 14.52 0 001.58.38 7.39 7.39 0 014.33 1.93c1.76 1.91 1.54 4.85 2.17 7.37v.09a7.78 7.78 0 0012.06 4.25l8.9-6.34c3.53-4.74 2.64-13.24-3-16-1.2-.59-2.57-.93-3.53-1.87a6.54 6.54 0 01-1.51-4.5 13 13 0 00-.66-4.78 7 7 0 00-5.34-3.7c-2.61-.45-5.21.6-7.68-.3s-4.75-2.35-7.31-3c-2.8-.7-6-.32-8.12 1.94a4.47 4.47 0 00-1.01 1.56z"
            style={{
              WebkitTransformOrigin: 35.6179,
              MsTransformOrigin: 35.6179,
              transformOrigin: 35.6179,
            }}
            id="elq5oijggy4m"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M41.66 353.31a.33.33 0 00-.11-.15c-6.62-9.38-17-13.82-20.5-14.61a.38.38 0 00-.44.28.37.37 0 00.28.44c3.33.74 13 4.85 19.44 13.45a20.29 20.29 0 00-16.14 1.41.37.37 0 00-.11.51.4.4 0 00.3.17.42.42 0 00.21-.06 19.53 19.53 0 0116.49-1 30.74 30.74 0 015.51 18.92.37.37 0 00.33.37.37.37 0 00.38-.35 31.47 31.47 0 00-5.64-19.38z"
            style={{
              WebkitTransformOrigin: 33.9654,
              MsTransformOrigin: 33.9654,
              transformOrigin: 33.9654,
            }}
            id="el5y5floz4tjj"
            fill="#FFF"
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--Window--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 191.534,
          MsTransformOrigin: 191.534,
          transformOrigin: 191.534,
        }}
      >
        <g
          id="freepik--window--inject-9"
          className="animable"
          style={{
            WebkitTransformOrigin: 191.534,
            MsTransformOrigin: 191.534,
            transformOrigin: 191.534,
          }}
        >
          <g
            id="freepik--window--inject-9"
            className="animable"
            style={{
              WebkitTransformOrigin: 173.059,
              MsTransformOrigin: 173.059,
              transformOrigin: 173.059,
            }}
          >
            <path
              d="M295.48 54.78l-5.12-3-240.22 138.7v181a3.83 3.83 0 001.6 3.53l5.13 3a3.84 3.84 0 003.86-.38L290 245.21a12.07 12.07 0 005.46-9.47z"
              style={{
                WebkitTransformOrigin: 172.799,
                MsTransformOrigin: 172.799,
                transformOrigin: 172.799,
              }}
              id="el7d13j3x3d1o"
              fill="#EBEBEB"
              className="animable"
            />
            <path
              d="M55.27 374.42c0 3.49 2.45 4.9 5.46 3.16L290 245.21a12.07 12.07 0 005.46-9.47v-181L55.27 193.46z"
              style={{
                WebkitTransformOrigin: 175.365,
                MsTransformOrigin: 175.365,
                transformOrigin: 175.365,
              }}
              id="elgbdb22uf8t8"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M55.27 374.42c0 3.49 2.45 4.9 5.46 3.16L290 245.21a12.07 12.07 0 005.46-9.47v-181L55.27 193.46z"
              style={{
                WebkitTransformOrigin: 175.365,
                MsTransformOrigin: 175.365,
                transformOrigin: 175.365,
              }}
              id="el1uu4yhx3tiy"
              fill="#FAFAFA"
              className="animable"
            />
            <path
              d="M58.29 378.83a3.31 3.31 0 01-1.67-.44 4.32 4.32 0 01-1.85-4V193.17L55 193 296 53.92v181.82a12.65 12.65 0 01-5.71 9.9L61 378a5.48 5.48 0 01-2.71.83zm-2.52-185.08v180.67a3.39 3.39 0 001.35 3.11 3.38 3.38 0 003.36-.39l229.29-132.36a11.57 11.57 0 005.21-9V55.65z"
              style={{
                WebkitTransformOrigin: 175.374,
                MsTransformOrigin: 175.374,
                transformOrigin: 175.374,
              }}
              id="elz0d40exaol"
              fill="#E0E0E0"
              className="animable"
            />
            <path
              style={{
                WebkitTransformOrigin: 175.375,
                MsTransformOrigin: 175.375,
                transformOrigin: 175.375,
              }}
              id="el8r04lxa8bx4"
              fill="#EF8C2D"
              d="M55.27 193.46L55.27 214.87 295.48 76.19 295.48 54.78 55.27 193.46z"
              className="animable"
            />
            <path
              style={{
                WebkitTransformOrigin: 52.705,
                MsTransformOrigin: 52.705,
                transformOrigin: 52.705,
              }}
              id="el2vi727p7w"
              fill="#EF8C2D"
              d="M55.27 214.87L50.14 211.89 50.14 190.48 55.27 193.46 55.27 214.87z"
              className="animable"
            />
            <path
              style={{
                WebkitTransformOrigin: 52.705,
                MsTransformOrigin: 52.705,
                transformOrigin: 52.705,
              }}
              id="elhzcvxf6qjne"
              d="M55.27 214.87L50.14 211.89 50.14 190.48 55.27 193.46 55.27 214.87z"
              className="animable"
              opacity="0.2"
            />
            <path
              d="M245 92.37v5.1c0 1 .74 1.47 1.64 1l33.46-19.4v-8.88l-33.43 19.34a3.64 3.64 0 00-1.67 2.84z"
              style={{
                WebkitTransformOrigin: 262.55,
                MsTransformOrigin: 262.55,
                transformOrigin: 262.55,
              }}
              id="elm3qbxotjvo"
              fill="#FAFAFA"
              className="animable"
            />
            <path
              d="M288.71 65.21c.91-.52 1.64-.1 1.64 1v5.09a3.6 3.6 0 01-1.64 2.84l-8.61 5v-8.95z"
              style={{
                WebkitTransformOrigin: 285.225,
                MsTransformOrigin: 285.225,
                transformOrigin: 285.225,
              }}
              id="elxc7hl9d3788"
              fill="#455A64"
              className="animable"
            />
            <path
              d="M288.08 72.2l-1-.3a5.84 5.84 0 00.15-1.24 2.24 2.24 0 00-.88-2 1.36 1.36 0 00-1.4.06 5 5 0 00-1.92 4 2.25 2.25 0 00.88 2 1.19 1.19 0 00.61.17 1.57 1.57 0 00.79-.24 4 4 0 001.55-2.07l1 .3h.14a.35.35 0 00.34-.25.34.34 0 00-.26-.43zM285 74a.66.66 0 01-.68.07 1.66 1.66 0 01-.52-1.44 4.28 4.28 0 011.56-3.38.9.9 0 01.43-.14.59.59 0 01.26.07 1.69 1.69 0 01.52 1.44A4.29 4.29 0 01285 74z"
              style={{
                WebkitTransformOrigin: 285.687,
                MsTransformOrigin: 285.687,
                transformOrigin: 285.687,
              }}
              id="ellf64f7xmrnr"
              fill="#FAFAFA"
              className="animable"
            />
            <path
              d="M288.81 36.84c-1-.61-2.38-.54-3.92.35L55.61 169.55a12.11 12.11 0 00-5.47 9.45v11.46l5.13 3L295.48 54.78V43.33a3.86 3.86 0 00-1.58-3.53c-.9-.53-4.21-2.41-5.09-2.96z"
              style={{
                WebkitTransformOrigin: 172.82,
                MsTransformOrigin: 172.82,
                transformOrigin: 172.82,
              }}
              id="els9ql7d0dimh"
              fill="#263238"
              className="animable"
            />
            <path
              d="M55.27 182a12.09 12.09 0 015.46-9.47L290 40.17c3-1.74 5.47-.33 5.47 3.16v11.45L55.27 193.46z"
              style={{
                WebkitTransformOrigin: 175.37,
                MsTransformOrigin: 175.37,
                transformOrigin: 175.37,
              }}
              id="elo8010y20ve"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M294 39.84c-1-.62-2.38-.56-3.94.34L60.74 172.53a11.28 11.28 0 00-3.87 4.08l-5.13-3a11.26 11.26 0 013.87-4.1L284.89 37.19c1.54-.89 2.93-.95 3.91-.35s4.18 2.43 5.1 3c.01-.03.04-.02.1 0z"
              style={{
                WebkitTransformOrigin: 172.87,
                MsTransformOrigin: 172.87,
                transformOrigin: 172.87,
              }}
              id="el5vlykr5gx32"
              fill="#455A64"
              className="animable"
            />
            <path
              d="M264.71 60.69a5.67 5.67 0 00-2.56 4.44c0 1.64 1.15 2.3 2.56 1.48a5.67 5.67 0 002.57-4.44c0-1.64-1.15-2.3-2.57-1.48z"
              style={{
                WebkitTransformOrigin: 264.715,
                MsTransformOrigin: 264.715,
                transformOrigin: 264.715,
              }}
              id="el5035da6awhi"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M273.69 55.52a5.67 5.67 0 00-2.57 4.48c0 1.64 1.15 2.3 2.57 1.48a5.67 5.67 0 002.56-4.48c0-1.63-1.15-2.3-2.56-1.48z"
              style={{
                WebkitTransformOrigin: 273.685,
                MsTransformOrigin: 273.685,
                transformOrigin: 273.685,
              }}
              id="el9xzhjda7ifk"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M282.66 50.35a5.66 5.66 0 00-2.56 4.44c0 1.64 1.14 2.3 2.56 1.48a5.63 5.63 0 002.56-4.44c0-1.63-1.14-2.3-2.56-1.48z"
              style={{
                WebkitTransformOrigin: 282.66,
                MsTransformOrigin: 282.66,
                transformOrigin: 282.66,
              }}
              id="el64g6aqty95t"
              fill="#EF8C2D"
              className="animable"
            />
          </g>
          <path
            d="M72.68 304.61a1 1 0 01-.48-.13 1 1 0 01-.48-.84V245a1 1 0 01.48-.83l70.35-40.62a1 1 0 011 0 1 1 0 01.48.83V263a1 1 0 01-.48.83l-70.38 40.65a1.05 1.05 0 01-.49.13zm1-59.08V302l68.42-39.51V206z"
            style={{
              WebkitTransformOrigin: 107.875,
              MsTransformOrigin: 107.875,
              transformOrigin: 107.875,
            }}
            id="eldry7jyqx42o"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M73.51 291.11l-1.65-1 21.29-35.92a1 1 0 011.3-.34l13.72 7.78 14.49-29a1 1 0 01.67-.51.94.94 0 01.82.22l19.52 17.23-1.27 1.43-18.58-16.4-14.38 28.78a1 1 0 01-1.33.41L94.32 256z"
            style={{
              WebkitTransformOrigin: 107.765,
              MsTransformOrigin: 107.765,
              transformOrigin: 107.765,
            }}
            id="els1tg6u3a00f"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M104.46 247.22a2.92 2.92 0 01-1.48-.39 3.58 3.58 0 01-1.61-3.31 9.25 9.25 0 014.26-7.37 3.28 3.28 0 015.28 3 9.29 9.29 0 01-4.26 7.38 4.46 4.46 0 01-2.19.69zm3.35-9.69a2.42 2.42 0 00-1.16.38 7.26 7.26 0 00-3.24 5.61 1.72 1.72 0 00.59 1.55 1.75 1.75 0 001.63-.26 7.29 7.29 0 003.24-5.62 1.7 1.7 0 00-.59-1.54.89.89 0 00-.47-.12z"
            style={{
              WebkitTransformOrigin: 106.147,
              MsTransformOrigin: 106.147,
              transformOrigin: 106.147,
            }}
            id="el6br8mjzib7f"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M151.54 201.79l59.23-34.2c1.35-.77 2.44-.14 2.44 1.41v4.94a5.39 5.39 0 01-2.44 4.23l-59.23 34.2c-1.35.78-2.44.15-2.44-1.41V206a5.39 5.39 0 012.44-4.21z"
            style={{
              WebkitTransformOrigin: 181.155,
              MsTransformOrigin: 181.155,
              transformOrigin: 181.155,
            }}
            id="eluz4x2jadk5n"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M150.08 219.3l43.84-25.3c.54-.32 1-.06 1 .56v3a2.14 2.14 0 01-1 1.69l-43.84 25.32c-.54.31-1 .06-1-.57v-3a2.16 2.16 0 011-1.7z"
            style={{
              WebkitTransformOrigin: 172,
              MsTransformOrigin: 172,
              transformOrigin: 172,
            }}
            id="elr33hyqhf8wa"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M149.1 232.67a.58.58 0 01-.49-.28.57.57 0 01.21-.77l86.88-50.16a.56.56 0 11.56 1l-86.88 50.16a.57.57 0 01-.28.05z"
            style={{
              WebkitTransformOrigin: 192.546,
              MsTransformOrigin: 192.546,
              transformOrigin: 192.546,
            }}
            id="elwx8qhud3z9c"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M149.1 238a.57.57 0 01-.28-1.06l86.88-50.15a.56.56 0 01.77.2.57.57 0 01-.21.77l-86.88 50.16a.57.57 0 01-.28.08z"
            style={{
              WebkitTransformOrigin: 192.542,
              MsTransformOrigin: 192.542,
              transformOrigin: 192.542,
            }}
            id="elctfh69i92lm"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M149.1 243.24a.58.58 0 01-.49-.28.57.57 0 01.21-.77L235.7 192a.56.56 0 11.56 1l-86.88 50.16a.55.55 0 01-.28.08z"
            style={{
              WebkitTransformOrigin: 192.545,
              MsTransformOrigin: 192.545,
              transformOrigin: 192.545,
            }}
            id="elnkkk74w7qh"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M149.1 248.53a.58.58 0 01-.49-.28.57.57 0 01.21-.77l86.88-50.16a.56.56 0 11.56 1l-86.88 50.16a.55.55 0 01-.28.05z"
            style={{
              WebkitTransformOrigin: 192.545,
              MsTransformOrigin: 192.545,
              transformOrigin: 192.545,
            }}
            id="elcnkx2c47czr"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M149.1 253.82a.58.58 0 01-.49-.28.57.57 0 01.21-.77l86.88-50.16a.56.56 0 11.56 1l-86.88 50.16a.55.55 0 01-.28.05z"
            style={{
              WebkitTransformOrigin: 192.545,
              MsTransformOrigin: 192.545,
              transformOrigin: 192.545,
            }}
            id="elju9sk88zr2n"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M149.1 259.11a.58.58 0 01-.49-.28.57.57 0 01.21-.77l41.18-23.8a.57.57 0 01.57 1L149.38 259a.57.57 0 01-.28.11z"
            style={{
              WebkitTransformOrigin: 169.699,
              MsTransformOrigin: 169.699,
              transformOrigin: 169.699,
            }}
            id="el08nzu1x5jbgi"
            fill="#E0E0E0"
            className="animable"
          />
          <g
            id="freepik--Text--inject-9"
            className="animable"
            style={{
              WebkitTransformOrigin: 129.135,
              MsTransformOrigin: 129.135,
              transformOrigin: 129.135,
            }}
          >
            <path
              d="M79.25 347.33L129 318.57a4.61 4.61 0 002.08-3.61v-26.78c0-1.33-.93-1.86-2.08-1.2l-49.75 28.76a4.61 4.61 0 00-2.08 3.61v26.78c0 1.33.93 1.87 2.08 1.2z"
              style={{
                WebkitTransformOrigin: 104.125,
                MsTransformOrigin: 104.125,
                transformOrigin: 104.125,
              }}
              id="el2bjju7peosj"
              fill="#FAFAFA"
              className="animable"
            />
            <path
              d="M78.32 348.1a1.62 1.62 0 01-.78-.2 1.93 1.93 0 01-.85-1.77v-26.78a5.07 5.07 0 012.32-4l49.76-28.77a1.74 1.74 0 012.8 1.62V315a5.07 5.07 0 01-2.32 4L79.5 347.75a2.44 2.44 0 01-1.18.35zm51.62-60.92a1.38 1.38 0 00-.69.22L79.5 316.16a4.15 4.15 0 00-1.85 3.19v26.78a1 1 0 00.37.93 1 1 0 001-.14l49.76-28.77a4.14 4.14 0 001.84-3.19v-26.78a1 1 0 00-.37-.93.58.58 0 00-.31-.07z"
              style={{
                WebkitTransformOrigin: 104.136,
                MsTransformOrigin: 104.136,
                transformOrigin: 104.136,
              }}
              id="el27xro6r9fpk"
              fill="#E0E0E0"
              className="animable"
            />
            <path
              d="M82.12 317.58l9.64-5.58c.58-.33 1-.06 1 .6v11.13a2.29 2.29 0 01-1 1.81l-9.64 5.56c-.57.34-1 .07-1-.6v-11.12a2.31 2.31 0 011-1.8z"
              style={{
                WebkitTransformOrigin: 86.94,
                MsTransformOrigin: 86.94,
                transformOrigin: 86.94,
              }}
              id="elw1p9e94p4t"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M97.13 308.91l29.64-17.15c.23-.13.42 0 .42.24v1.46a.91.91 0 01-.42.72l-29.64 17.15c-.23.14-.42 0-.42-.24v-1.46a.93.93 0 01.42-.72z"
              style={{
                WebkitTransformOrigin: 111.95,
                MsTransformOrigin: 111.95,
                transformOrigin: 111.95,
              }}
              id="elmei4kuprxc"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M97.13 314.47l29.64-17.15c.23-.14.42 0 .42.24V299a.93.93 0 01-.42.72l-29.64 17.17c-.23.13-.42 0-.42-.24v-1.46a.91.91 0 01.42-.72z"
              style={{
                WebkitTransformOrigin: 111.95,
                MsTransformOrigin: 111.95,
                transformOrigin: 111.95,
              }}
              id="elbnnggaummcm"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M97.13 320l29.64-17.15c.23-.14.42 0 .42.24v1.46a.94.94 0 01-.42.72l-29.64 17.18c-.23.13-.42 0-.42-.24v-1.46a.91.91 0 01.42-.75z"
              style={{
                WebkitTransformOrigin: 111.95,
                MsTransformOrigin: 111.95,
                transformOrigin: 111.95,
              }}
              id="elx71q27zbch9"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M81.5 334.57l45.27-26.14c.23-.13.42 0 .42.24v1.46a.91.91 0 01-.42.72L81.5 337c-.23.13-.42 0-.42-.24v-1.47a.94.94 0 01.42-.72z"
              style={{
                WebkitTransformOrigin: 104.135,
                MsTransformOrigin: 104.135,
                transformOrigin: 104.135,
              }}
              id="elpq4xyty5sof"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M81.5 340.13L126.77 314c.23-.13.42 0 .42.24v1.46a.91.91 0 01-.42.72L81.5 342.55c-.23.14-.42 0-.42-.24v-1.46a.93.93 0 01.42-.72z"
              style={{
                WebkitTransformOrigin: 104.135,
                MsTransformOrigin: 104.135,
                transformOrigin: 104.135,
              }}
              id="eldohgixlkvw"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M75.27 313.83a.38.38 0 01-.31-.18.36.36 0 01.13-.49l1-.61a.37.37 0 01.5.14.36.36 0 01-.13.49l-1 .6a.37.37 0 01-.19.05z"
              style={{
                WebkitTransformOrigin: 75.775,
                MsTransformOrigin: 75.775,
                transformOrigin: 75.775,
              }}
              id="elfkloe4vmyor"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M78.51 312a.36.36 0 01-.32-.18.35.35 0 01.14-.49l2.19-1.33a.35.35 0 01.49.14.36.36 0 01-.13.49l-2.19 1.26a.31.31 0 01-.18.11z"
              style={{
                WebkitTransformOrigin: 79.5993,
                MsTransformOrigin: 79.5993,
                transformOrigin: 79.5993,
              }}
              id="elcis3sbs8a8"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M82.89 309.43a.36.36 0 01-.18-.67l2.19-1.27a.37.37 0 01.5.13.36.36 0 01-.14.5l-2.19 1.26a.29.29 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 83.9914,
                MsTransformOrigin: 83.9914,
                transformOrigin: 83.9914,
              }}
              id="elpx95vkq5vf"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M87.27 306.9a.38.38 0 01-.31-.18.36.36 0 01.13-.49l2.2-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.19 1.26a.41.41 0 01-.19.05z"
              style={{
                WebkitTransformOrigin: 88.3703,
                MsTransformOrigin: 88.3703,
                transformOrigin: 88.3703,
              }}
              id="ely59va32p7kb"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M91.66 304.37a.35.35 0 01-.31-.18.36.36 0 01.13-.49l2.19-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.19 1.26a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 92.7554,
                MsTransformOrigin: 92.7554,
                transformOrigin: 92.7554,
              }}
              id="elhqsizcvom4a"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M96 301.84a.38.38 0 01-.31-.18.37.37 0 01.13-.5l2.19-1.26a.37.37 0 01.5.13.35.35 0 01-.14.49l-2.19 1.27a.37.37 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 97.1003,
                MsTransformOrigin: 97.1003,
                transformOrigin: 97.1003,
              }}
              id="elj3hq4w4c7wo"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M100.59 299.21a.36.36 0 01-.32-.18.35.35 0 01.14-.49l2.19-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.19 1.26a.31.31 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 101.68,
                MsTransformOrigin: 101.68,
                transformOrigin: 101.68,
              }}
              id="eljws8mv5plj"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M105.13 296.59a.38.38 0 01-.31-.18.37.37 0 01.13-.5l2.19-1.26a.37.37 0 01.5.13.36.36 0 01-.13.49l-2.2 1.27a.37.37 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 106.23,
                MsTransformOrigin: 106.23,
                transformOrigin: 106.23,
              }}
              id="elag1gpwdipr"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M109.68 294a.33.33 0 01-.31-.18.36.36 0 01.13-.49l2.19-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.19 1.26a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 110.775,
                MsTransformOrigin: 110.775,
                transformOrigin: 110.775,
              }}
              id="elolwrubwoyy"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M114.22 291.33a.36.36 0 01-.18-.67l2.2-1.27a.36.36 0 01.36.63l-2.2 1.27a.34.34 0 01-.18.04z"
              style={{
                WebkitTransformOrigin: 115.323,
                MsTransformOrigin: 115.323,
                transformOrigin: 115.323,
              }}
              id="elapy4hdjcep"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M118.77 288.71a.37.37 0 01-.31-.18.37.37 0 01.13-.5l2.19-1.26a.36.36 0 01.49.13.35.35 0 01-.13.49l-2.14 1.27a.41.41 0 01-.23.05z"
              style={{
                WebkitTransformOrigin: 119.867,
                MsTransformOrigin: 119.867,
                transformOrigin: 119.867,
              }}
              id="el70lx98ct4m"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M123.32 286.08a.36.36 0 01-.32-.18.35.35 0 01.14-.49l2.19-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5L123.5 286a.31.31 0 01-.18.08z"
              style={{
                WebkitTransformOrigin: 124.41,
                MsTransformOrigin: 124.41,
                transformOrigin: 124.41,
              }}
              id="elbavlsfix3r"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M127.86 283.46a.38.38 0 01-.31-.18.37.37 0 01.13-.5l2.19-1.26a.37.37 0 01.5.13.35.35 0 01-.14.49l-2.23 1.27a.37.37 0 01-.14.05z"
              style={{
                WebkitTransformOrigin: 128.96,
                MsTransformOrigin: 128.96,
                transformOrigin: 128.96,
              }}
              id="elbph24bovpw6"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M132.41 280.83a.36.36 0 01-.32-.18.35.35 0 01.14-.49l2.19-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.19 1.26a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 133.5,
                MsTransformOrigin: 133.5,
                transformOrigin: 133.5,
              }}
              id="eldgf0yizbwhp"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M137 278.2a.36.36 0 01-.18-.67l2.19-1.27a.37.37 0 01.5.14.35.35 0 01-.14.49l-2.19 1.27a.37.37 0 01-.18.04z"
              style={{
                WebkitTransformOrigin: 138.101,
                MsTransformOrigin: 138.101,
                transformOrigin: 138.101,
              }}
              id="elmrx7n0kiqlt"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M141.5 275.58a.38.38 0 01-.32-.18.37.37 0 01.14-.5l2.19-1.26a.36.36 0 01.36.62l-2.19 1.27a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 142.578,
                MsTransformOrigin: 142.578,
                transformOrigin: 142.578,
              }}
              id="el2aln4jvzlug"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M146 273a.36.36 0 01-.18-.67l2.2-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.2 1.26a.29.29 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 147.101,
                MsTransformOrigin: 147.101,
                transformOrigin: 147.101,
              }}
              id="ellnyix7gxqqg"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M150.59 270.33a.37.37 0 01-.18-.68l2.19-1.26a.36.36 0 01.36.62l-2.19 1.27a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 151.676,
                MsTransformOrigin: 151.676,
                transformOrigin: 151.676,
              }}
              id="elobwbr791g4"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M155.13 267.7a.38.38 0 01-.31-.18.36.36 0 01.18-.52l2.2-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.19 1.26a.41.41 0 01-.24.08z"
              style={{
                WebkitTransformOrigin: 156.254,
                MsTransformOrigin: 156.254,
                transformOrigin: 156.254,
              }}
              id="elp7kf4bpui2i"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M159.68 265.07a.34.34 0 01-.31-.18.36.36 0 01.13-.49l2.19-1.27a.37.37 0 01.5.14.35.35 0 01-.14.49l-2.19 1.24a.39.39 0 01-.18.07z"
              style={{
                WebkitTransformOrigin: 160.781,
                MsTransformOrigin: 160.781,
                transformOrigin: 160.781,
              }}
              id="el2wjftfl21vn"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M164.23 262.45a.38.38 0 01-.32-.18.37.37 0 01.14-.5l2.19-1.26a.36.36 0 01.36.62l-2.19 1.27a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 165.308,
                MsTransformOrigin: 165.308,
                transformOrigin: 165.308,
              }}
              id="elfzj98r3yve9"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M168.77 259.82a.36.36 0 01-.18-.67l2.19-1.27a.37.37 0 01.5.13.36.36 0 01-.14.5l-2.14 1.26a.29.29 0 01-.23.05z"
              style={{
                WebkitTransformOrigin: 169.871,
                MsTransformOrigin: 169.871,
                transformOrigin: 169.871,
              }}
              id="el87olvt3g6db"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M173.16 257.29a.36.36 0 01-.32-.18.35.35 0 01.14-.49l2.19-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.19 1.26a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 174.25,
                MsTransformOrigin: 174.25,
                transformOrigin: 174.25,
              }}
              id="el6s7d9a2dkes"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M177.54 254.76a.37.37 0 01-.31-.18.36.36 0 01.13-.49l2.19-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.19 1.26a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 178.635,
                MsTransformOrigin: 178.635,
                transformOrigin: 178.635,
              }}
              id="el6xdactgd9x9"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M181.92 252.23a.38.38 0 01-.31-.18.36.36 0 01.13-.49l1-.61a.38.38 0 01.5.14.35.35 0 01-.14.49l-1 .6a.35.35 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 182.426,
                MsTransformOrigin: 182.426,
                transformOrigin: 182.426,
              }}
              id="elwtcjzq4v5eh"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M73.67 353.6a.36.36 0 01-.36-.36V352a.36.36 0 11.72 0v1.2a.36.36 0 01-.36.4z"
              style={{
                WebkitTransformOrigin: 73.6711,
                MsTransformOrigin: 73.6711,
                transformOrigin: 73.6711,
              }}
              id="elbvrszvqtr0c"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M73.67 350.09a.36.36 0 01-.36-.36v-2.3a.36.36 0 11.72 0v2.3a.36.36 0 01-.36.36zm0-4.61a.36.36 0 01-.36-.36v-2.31a.36.36 0 01.72 0v2.31a.36.36 0 01-.36.36zm0-4.61a.36.36 0 01-.36-.36v-2.31a.36.36 0 11.72 0v2.31a.36.36 0 01-.36.36zm0-4.61a.37.37 0 01-.36-.37v-2.3a.36.36 0 01.72 0v2.3a.37.37 0 01-.36.37zm0-4.62a.36.36 0 01-.36-.36V329a.36.36 0 11.72 0v2.3a.36.36 0 01-.36.34zm0-4.61a.36.36 0 01-.36-.36v-2.31a.36.36 0 01.72 0v2.31a.36.36 0 01-.36.33zm0-4.61a.36.36 0 01-.36-.36v-2.31a.36.36 0 01.72 0v2.31a.36.36 0 01-.36.36z"
              style={{
                WebkitTransformOrigin: 73.67,
                MsTransformOrigin: 73.67,
                transformOrigin: 73.67,
              }}
              id="el2yqhr0f0wxi"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M73.67 317.81a.36.36 0 01-.36-.36v-1.21a.36.36 0 01.72 0v1.21a.36.36 0 01-.36.36z"
              style={{
                WebkitTransformOrigin: 73.67,
                MsTransformOrigin: 73.67,
                transformOrigin: 73.67,
              }}
              id="elg60lsgwvdyg"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M182 292.91a.38.38 0 01-.31-.18.37.37 0 01.13-.5l1-.6a.36.36 0 01.49.13.37.37 0 01-.13.5l-1.05.6a.35.35 0 01-.13.05z"
              style={{
                WebkitTransformOrigin: 182.5,
                MsTransformOrigin: 182.5,
                transformOrigin: 182.5,
              }}
              id="elfr9wmcpwbrv"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M78.51 352.66a.35.35 0 01-.31-.18.37.37 0 01.13-.5l2.19-1.26a.37.37 0 01.5.13.35.35 0 01-.14.49l-2.19 1.27a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 79.6103,
                MsTransformOrigin: 79.6103,
                transformOrigin: 79.6103,
              }}
              id="el7ml2t6m3yyf"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M82.9 350.12a.36.36 0 01-.32-.18.35.35 0 01.14-.49l2.19-1.27a.36.36 0 01.36.63l-2.19 1.27a.39.39 0 01-.18.04z"
              style={{
                WebkitTransformOrigin: 83.9918,
                MsTransformOrigin: 83.9918,
                transformOrigin: 83.9918,
              }}
              id="el5mevy78add2"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M87.28 347.59a.38.38 0 01-.31-.18.36.36 0 01.13-.49l2.2-1.27a.36.36 0 11.36.62l-2.2 1.27a.35.35 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 88.3954,
                MsTransformOrigin: 88.3954,
                transformOrigin: 88.3954,
              }}
              id="el93bcpjti9ku"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M91.67 345.06a.38.38 0 01-.31-.18.37.37 0 01.13-.5l2.19-1.26a.37.37 0 01.5.13.36.36 0 01-.13.49l-2.2 1.26a.37.37 0 01-.18.06z"
              style={{
                WebkitTransformOrigin: 92.7696,
                MsTransformOrigin: 92.7696,
                transformOrigin: 92.7696,
              }}
              id="elpque5nuj4bg"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M96.06 342.52a.33.33 0 01-.31-.18.36.36 0 01.13-.49l2.19-1.27a.37.37 0 01.5.14.35.35 0 01-.14.49l-2.19 1.27a.39.39 0 01-.18.04z"
              style={{
                WebkitTransformOrigin: 97.1607,
                MsTransformOrigin: 97.1607,
                transformOrigin: 97.1607,
              }}
              id="el49sjzil4o8i"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M100.6 339.9a.38.38 0 01-.31-.18.37.37 0 01.13-.5l2.2-1.26a.36.36 0 01.36.62l-2.19 1.27a.41.41 0 01-.19.05z"
              style={{
                WebkitTransformOrigin: 101.686,
                MsTransformOrigin: 101.686,
                transformOrigin: 101.686,
              }}
              id="ela5yib36a6m"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M105.15 337.27a.34.34 0 01-.31-.18.36.36 0 01.13-.49l2.19-1.27a.37.37 0 01.5.13.36.36 0 01-.14.5l-2.19 1.26a.31.31 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 106.251,
                MsTransformOrigin: 106.251,
                transformOrigin: 106.251,
              }}
              id="el8nmflbvo42t"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M109.7 334.65a.37.37 0 01-.32-.19.35.35 0 01.14-.49l2.19-1.26a.35.35 0 01.49.13.36.36 0 01-.13.49l-2.19 1.27a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 110.789,
                MsTransformOrigin: 110.789,
                transformOrigin: 110.789,
              }}
              id="elu0kw8nw0eff"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M114.24 332a.38.38 0 01-.31-.18.36.36 0 01.13-.49l2.2-1.27a.36.36 0 01.36.62l-2.2 1.27a.37.37 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 115.327,
                MsTransformOrigin: 115.327,
                transformOrigin: 115.327,
              }}
              id="elxkmnhqd07bq"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M118.79 329.39a.33.33 0 01-.31-.18.35.35 0 01.13-.49l2.19-1.27a.35.35 0 01.49.14.36.36 0 01-.13.49l-2.16 1.26a.31.31 0 01-.21.05z"
              style={{
                WebkitTransformOrigin: 119.883,
                MsTransformOrigin: 119.883,
                transformOrigin: 119.883,
              }}
              id="elh0pi9k9rcji"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M123.33 326.77a.38.38 0 01-.31-.18.37.37 0 01.13-.5l2.2-1.26a.35.35 0 01.49.13.36.36 0 01-.13.49l-2.2 1.27a.35.35 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 124.43,
                MsTransformOrigin: 124.43,
                transformOrigin: 124.43,
              }}
              id="elqmqrgewisg"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M127.88 324.14a.34.34 0 01-.31-.18.36.36 0 01.13-.49l2.19-1.27a.37.37 0 01.5.13.37.37 0 01-.14.5l-2.19 1.26a.31.31 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 128.979,
                MsTransformOrigin: 128.979,
                transformOrigin: 128.979,
              }}
              id="elwir9k1jsda"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M132.43 321.51a.36.36 0 01-.32-.18.35.35 0 01.14-.49l2.19-1.27a.36.36 0 01.36.63l-2.19 1.27a.39.39 0 01-.18.04z"
              style={{
                WebkitTransformOrigin: 133.522,
                MsTransformOrigin: 133.522,
                transformOrigin: 133.522,
              }}
              id="elmh4y3dkcwe"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M137 318.89a.38.38 0 01-.31-.18.36.36 0 01.13-.49L139 317a.37.37 0 01.5.13.36.36 0 01-.13.49l-2.2 1.27a.37.37 0 01-.17 0z"
              style={{
                WebkitTransformOrigin: 138.095,
                MsTransformOrigin: 138.095,
                transformOrigin: 138.095,
              }}
              id="elje8tu0a5fhc"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M141.52 316.26a.36.36 0 01-.32-.18.35.35 0 01.14-.49l2.19-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.19 1.26a.31.31 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 142.61,
                MsTransformOrigin: 142.61,
                transformOrigin: 142.61,
              }}
              id="ellzr4u4rlzdp"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M146.06 313.64a.38.38 0 01-.31-.18.37.37 0 01.13-.5l2.2-1.26a.35.35 0 01.49.13.36.36 0 01-.13.49l-2.2 1.27a.37.37 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 147.16,
                MsTransformOrigin: 147.16,
                transformOrigin: 147.16,
              }}
              id="elm77xqzqfwb"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M150.61 311a.33.33 0 01-.31-.18.36.36 0 01.13-.49l2.19-1.27a.36.36 0 01.49.13.34.34 0 01-.13.49l-2.19 1.32a.41.41 0 01-.18 0z"
              style={{
                WebkitTransformOrigin: 151.709,
                MsTransformOrigin: 151.709,
                transformOrigin: 151.709,
              }}
              id="elhe1n7cjdcl8"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M155.15 308.38a.36.36 0 01-.18-.67l2.2-1.27a.36.36 0 01.36.63l-2.19 1.27a.41.41 0 01-.19.04z"
              style={{
                WebkitTransformOrigin: 156.252,
                MsTransformOrigin: 156.252,
                transformOrigin: 156.252,
              }}
              id="el7mq6c7g1ww"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M159.7 305.76a.37.37 0 01-.18-.68l2.19-1.26a.37.37 0 01.5.13.35.35 0 01-.14.49l-2.19 1.27a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 160.805,
                MsTransformOrigin: 160.805,
                transformOrigin: 160.805,
              }}
              id="el7gpa9inx23k"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M164.25 303.13a.36.36 0 01-.32-.18.35.35 0 01.14-.49l2.19-1.27a.36.36 0 01.49.13.37.37 0 01-.13.5l-2.19 1.26a.31.31 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 165.34,
                MsTransformOrigin: 165.34,
                transformOrigin: 165.34,
              }}
              id="elxcguqt52kb"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M168.79 300.51a.37.37 0 01-.31-.19.36.36 0 01.13-.49l2.19-1.26a.36.36 0 11.37.62l-2.2 1.27a.37.37 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 169.89,
                MsTransformOrigin: 169.89,
                transformOrigin: 169.89,
              }}
              id="elzgb51nnl1z"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M173.18 298a.33.33 0 01-.31-.18.36.36 0 01.13-.49l2.19-1.27a.37.37 0 01.5.13.36.36 0 01-.14.5l-2.19 1.26a.31.31 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 174.281,
                MsTransformOrigin: 174.281,
                transformOrigin: 174.281,
              }}
              id="elq4l70mefjf"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M177.57 295.44a.38.38 0 01-.32-.18.35.35 0 01.14-.49l2.19-1.27a.36.36 0 01.36.62l-2.19 1.27a.41.41 0 01-.18.05z"
              style={{
                WebkitTransformOrigin: 178.646,
                MsTransformOrigin: 178.646,
                transformOrigin: 178.646,
              }}
              id="el5o28khjd83p"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M75.27 354.52a.36.36 0 01-.18-.67l1-.6a.37.37 0 11.37.63l-1 .6a.37.37 0 01-.19.04z"
              style={{
                WebkitTransformOrigin: 75.8043,
                MsTransformOrigin: 75.8043,
                transformOrigin: 75.8043,
              }}
              id="el5kdp8sj9obn"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M184.6 253.74a.36.36 0 01-.36-.36v-1.21a.36.36 0 01.72 0v1.21a.36.36 0 01-.36.36z"
              style={{
                WebkitTransformOrigin: 184.6,
                MsTransformOrigin: 184.6,
                transformOrigin: 184.6,
              }}
              id="elyxpmfcqslws"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M184.6 286a.36.36 0 01-.36-.36v-2.31a.36.36 0 11.72 0v2.31a.36.36 0 01-.36.36zm0-4.61a.36.36 0 01-.36-.36v-2.31a.36.36 0 11.72 0v2.31a.36.36 0 01-.36.38zm0-4.61a.36.36 0 01-.36-.36v-2.31a.36.36 0 11.72 0v2.31a.36.36 0 01-.36.38zm0-4.62a.36.36 0 01-.36-.36v-2.3a.36.36 0 11.72 0v2.3a.36.36 0 01-.36.38zm0-4.61a.36.36 0 01-.36-.36v-2.3a.36.36 0 11.72 0v2.3a.36.36 0 01-.36.38zm0-4.61a.36.36 0 01-.36-.36v-2.31a.36.36 0 11.72 0v2.31a.36.36 0 01-.36.42zm0-4.61a.36.36 0 01-.36-.36v-2.31a.36.36 0 11.72 0V258a.36.36 0 01-.36.35z"
              style={{
                WebkitTransformOrigin: 184.603,
                MsTransformOrigin: 184.603,
                transformOrigin: 184.603,
              }}
              id="eltxaxxn6er5a"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M184.6 289.53a.36.36 0 01-.36-.36V288a.36.36 0 11.72 0v1.2a.36.36 0 01-.36.33z"
              style={{
                WebkitTransformOrigin: 184.6,
                MsTransformOrigin: 184.6,
                transformOrigin: 184.6,
              }}
              id="el8kp78xwfpv"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M183 253.46a.35.35 0 01-.18-.05.33.33 0 01-.18-.31v-3.7a.33.33 0 01.18-.31l3.2-1.85a.33.33 0 01.36 0 .35.35 0 01.18.31v3.7a.35.35 0 01-.18.31l-3.2 1.85a.41.41 0 01-.18.05zm.36-3.85v2.86l2.48-1.43v-2.87z"
              style={{
                WebkitTransformOrigin: 184.6,
                MsTransformOrigin: 184.6,
                transformOrigin: 184.6,
              }}
              id="elr33ukmxgvh8"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M183 294.15a.34.34 0 01-.18 0 .36.36 0 01-.18-.32v-3.7a.34.34 0 01.18-.31l3.2-1.85a.38.38 0 01.36 0 .35.35 0 01.18.31v3.7a.38.38 0 01-.18.32l-3.2 1.85a.39.39 0 01-.18 0zm.36-3.85v2.87l2.48-1.43v-2.87z"
              style={{
                WebkitTransformOrigin: 184.6,
                MsTransformOrigin: 184.6,
                transformOrigin: 184.6,
              }}
              id="elo8j69ntr5np"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M72.07 317.53a.37.37 0 01-.18 0 .35.35 0 01-.18-.31v-3.7a.35.35 0 01.18-.31l3.2-1.85a.36.36 0 01.54.31v3.7a.33.33 0 01-.18.31l-3.2 1.85a.41.41 0 01-.18 0zm.36-3.85v2.86l2.48-1.43v-2.87z"
              style={{
                WebkitTransformOrigin: 73.6702,
                MsTransformOrigin: 73.6702,
                transformOrigin: 73.6702,
              }}
              id="el44ycf375opa"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M72.07 358.23a.37.37 0 01-.18-.05.38.38 0 01-.18-.32v-3.7a.37.37 0 01.18-.31l3.2-1.85a.38.38 0 01.36 0 .33.33 0 01.18.31V356a.36.36 0 01-.18.32l-3.2 1.85a.41.41 0 01-.18.06zm.36-3.86v2.87l2.48-1.43v-2.87z"
              style={{
                WebkitTransformOrigin: 73.6702,
                MsTransformOrigin: 73.6702,
                transformOrigin: 73.6702,
              }}
              id="eluk1081xuoxg"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M186.21 272a3.85 3.85 0 01-1.61 3.08c-.89.52-1.62 0-1.62-1.22a3.86 3.86 0 011.62-3.08c.89-.5 1.61.05 1.61 1.22z"
              style={{
                WebkitTransformOrigin: 184.595,
                MsTransformOrigin: 184.595,
                transformOrigin: 184.595,
              }}
              id="elkussbt1jsmi"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M132.92 280a3.88 3.88 0 01-1.61 3.09c-.89.51-1.62 0-1.62-1.22a3.89 3.89 0 011.62-3.09c.89-.49 1.61.06 1.61 1.22z"
              style={{
                WebkitTransformOrigin: 131.305,
                MsTransformOrigin: 131.305,
                transformOrigin: 131.305,
              }}
              id="elbf2tb1g8win"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M132.92 320.72a3.85 3.85 0 01-1.61 3.08c-.89.52-1.62 0-1.62-1.22a3.86 3.86 0 011.62-3.08c.89-.5 1.61.03 1.61 1.22z"
              style={{
                WebkitTransformOrigin: 131.305,
                MsTransformOrigin: 131.305,
                transformOrigin: 131.305,
              }}
              id="elhbavuic7cj4"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M75.28 336.14a3.88 3.88 0 01-1.61 3.09c-.89.51-1.61 0-1.61-1.22a3.88 3.88 0 011.61-3.09c.89-.51 1.61.08 1.61 1.22z"
              style={{
                WebkitTransformOrigin: 73.67,
                MsTransformOrigin: 73.67,
                transformOrigin: 73.67,
              }}
              id="el4zoqglg6y98"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M135.29 286.14a.31.31 0 01-.29-.14.31.31 0 01.11-.41l45.62-26.34a.3.3 0 11.3.52l-45.59 26.33a.28.28 0 01-.15.04z"
              style={{
                WebkitTransformOrigin: 158.072,
                MsTransformOrigin: 158.072,
                transformOrigin: 158.072,
              }}
              id="eluevuxa8x3tq"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M135.29 288.92a.3.3 0 01-.26-.15.29.29 0 01.11-.4L180.76 262a.31.31 0 01.41.11.29.29 0 01-.11.4l-45.62 26.34a.28.28 0 01-.15.07z"
              style={{
                WebkitTransformOrigin: 158.1,
                MsTransformOrigin: 158.1,
                transformOrigin: 158.1,
              }}
              id="el6dl0k1c9tv"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M135.29 291.7a.32.32 0 01-.26-.15.29.29 0 01.11-.4l45.62-26.34a.3.3 0 01.41.11.29.29 0 01-.11.4l-45.62 26.34a.42.42 0 01-.15.04z"
              style={{
                WebkitTransformOrigin: 158.1,
                MsTransformOrigin: 158.1,
                transformOrigin: 158.1,
              }}
              id="eldgaznmdvig"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M135.29 294.47a.31.31 0 01-.26-.14.31.31 0 01.11-.41l45.62-26.34a.3.3 0 11.3.52l-45.62 26.33a.28.28 0 01-.15.04z"
              style={{
                WebkitTransformOrigin: 158.102,
                MsTransformOrigin: 158.102,
                transformOrigin: 158.102,
              }}
              id="elokruo5ed2c"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M135.29 297.25a.3.3 0 01-.26-.15.29.29 0 01.11-.4l45.62-26.34a.31.31 0 01.41.11.29.29 0 01-.11.4l-45.62 26.34a.28.28 0 01-.15.04z"
              style={{
                WebkitTransformOrigin: 158.1,
                MsTransformOrigin: 158.1,
                transformOrigin: 158.1,
              }}
              id="el41thxmgrgsn"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M135.29 300a.3.3 0 01-.26-.15.29.29 0 01.11-.4L156.78 287a.31.31 0 01.41.11.29.29 0 01-.11.4L135.44 300a.28.28 0 01-.15 0z"
              style={{
                WebkitTransformOrigin: 146.11,
                MsTransformOrigin: 146.11,
                transformOrigin: 146.11,
              }}
              id="elpj6v7o92k4q"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M135.29 306.42a.31.31 0 01-.26-.14.3.3 0 01.11-.41l45.62-26.34a.3.3 0 11.3.52l-45.62 26.33a.28.28 0 01-.15.04z"
              style={{
                WebkitTransformOrigin: 158.1,
                MsTransformOrigin: 158.1,
                transformOrigin: 158.1,
              }}
              id="el9fyrpyadx2n"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M135.29 309.2a.3.3 0 01-.26-.15.29.29 0 01.11-.4l45.62-26.34a.3.3 0 01.41.11.29.29 0 01-.11.4l-45.62 26.34a.28.28 0 01-.15.04z"
              style={{
                WebkitTransformOrigin: 158.1,
                MsTransformOrigin: 158.1,
                transformOrigin: 158.1,
              }}
              id="elzupfgvbxie"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M135.29 312a.32.32 0 01-.26-.15.29.29 0 01.11-.4l45.62-26.34a.3.3 0 11.3.51l-45.62 26.34a.42.42 0 01-.15.04z"
              style={{
                WebkitTransformOrigin: 158.122,
                MsTransformOrigin: 158.122,
                transformOrigin: 158.122,
              }}
              id="elh082gjh9cvw"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M135.29 314.75a.31.31 0 01-.26-.14.3.3 0 01.11-.41l21.64-12.49a.3.3 0 11.3.51l-21.64 12.49a.28.28 0 01-.15.04z"
              style={{
                WebkitTransformOrigin: 146.131,
                MsTransformOrigin: 146.131,
                transformOrigin: 146.131,
              }}
              id="elr8ibakb5ydh"
              fill="#37474F"
              className="animable"
            />
          </g>
          <path
            d="M204.19 281.78a.24.24 0 01-.12 0 .22.22 0 01-.12-.21v-42a.25.25 0 01.12-.21l33.78-19.5a.25.25 0 01.24 0 .24.24 0 01.12.21v42a.27.27 0 01-.12.21l-33.78 19.5zm.24-42.11v41.46l33.3-19.23v-41.46z"
            style={{
              WebkitTransformOrigin: 221.08,
              MsTransformOrigin: 221.08,
              transformOrigin: 221.08,
            }}
            id="elytaysn9nefg"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M206.79 262.28a.21.21 0 01-.12 0 .25.25 0 01-.12-.21V241a.25.25 0 01.12-.21l28.58-16.5a.26.26 0 01.24 0 .24.24 0 01.12.21v21a.24.24 0 01-.12.21l-28.58 16.51zm.24-21.11v20.45l28.1-16.23v-20.45z"
            style={{
              WebkitTransformOrigin: 221.08,
              MsTransformOrigin: 221.08,
              transformOrigin: 221.08,
            }}
            id="elw32af0t0ag"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M207.06 264.88l7.24-4.18c.16-.09.28 0 .28.16v.86a.61.61 0 01-.28.48l-7.24 4.18c-.15.09-.27 0-.27-.16v-.86a.61.61 0 01.27-.48z"
            style={{
              WebkitTransformOrigin: 210.685,
              MsTransformOrigin: 210.685,
              transformOrigin: 210.685,
            }}
            id="els2r3m0ulfdo"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M207.06 267.88l27.16-15.64c.15-.09.28 0 .28.16v.86a.62.62 0 01-.28.48l-27.16 15.64c-.15.09-.27 0-.27-.16v-.86a.61.61 0 01.27-.48z"
            style={{
              WebkitTransformOrigin: 220.645,
              MsTransformOrigin: 220.645,
              transformOrigin: 220.645,
            }}
            id="elwuq9l5av2s"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M207.06 273.88l27.16-15.64c.15-.09.28 0 .28.16v.86a.62.62 0 01-.28.48l-27.16 15.64c-.15.09-.27 0-.27-.16v-.86a.61.61 0 01.27-.48z"
            style={{
              WebkitTransformOrigin: 220.645,
              MsTransformOrigin: 220.645,
              transformOrigin: 220.645,
            }}
            id="elno7t7q97q3o"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M207.06 270.88l27.16-15.64c.15-.09.28 0 .28.16v.86a.62.62 0 01-.28.48l-27.16 15.64c-.15.09-.27 0-.27-.16v-.86a.61.61 0 01.27-.48z"
            style={{
              WebkitTransformOrigin: 220.645,
              MsTransformOrigin: 220.645,
              transformOrigin: 220.645,
            }}
            id="elnl93k1hkyos"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M235.37 245.77l-9.94-1.56a.25.25 0 01-.2-.28.23.23 0 01.27-.2l9.95 1.57a.24.24 0 010 .47z"
            style={{
              WebkitTransformOrigin: 230.434,
              MsTransformOrigin: 230.434,
              transformOrigin: 230.434,
            }}
            id="elr1j87dgzeu"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M216.74 242.84l-9.95-1.57a.24.24 0 01-.2-.27.22.22 0 01.27-.2l10 1.56a.25.25 0 01.2.28.23.23 0 01-.32.2z"
            style={{
              WebkitTransformOrigin: 211.825,
              MsTransformOrigin: 211.825,
              transformOrigin: 211.825,
            }}
            id="elqlr9cnu5et"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M206.79 262.28a.25.25 0 01-.15 0 .23.23 0 010-.34l9.94-13a.24.24 0 01.33 0 .23.23 0 010 .33L207 262.18a.26.26 0 01-.21.1z"
            style={{
              WebkitTransformOrigin: 211.772,
              MsTransformOrigin: 211.772,
              transformOrigin: 211.772,
            }}
            id="elqb1tvwfcoi9"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M225.41 237.84a.3.3 0 01-.15-.05.25.25 0 010-.34l10-13.07a.24.24 0 01.38.3l-10 13.06a.23.23 0 01-.23.1z"
            style={{
              WebkitTransformOrigin: 230.442,
              MsTransformOrigin: 230.442,
              transformOrigin: 230.442,
            }}
            id="eluw2z4w5rnsb"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M221.08 241.78A2.78 2.78 0 00220 244c0 .89.47 1.34 1.05 1a2.76 2.76 0 001-2.21c.07-.89-.4-1.34-.97-1.01z"
            style={{
              WebkitTransformOrigin: 221.028,
              MsTransformOrigin: 221.028,
              transformOrigin: 221.028,
            }}
            id="els5wgs4cl5m"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M223.32 239.06l-.79.47v-.42c0-.27-.14-.4-.32-.3l-2.27 1.31a.82.82 0 00-.31.66v.42l-.8.46a1.71 1.71 0 00-.65 1.37v4.07c0 .55.29.83.65.62l4.49-2.59a1.71 1.71 0 00.65-1.37v-4.07c.03-.55-.29-.83-.65-.63zm-2.24 6.75c-.87.49-1.57-.18-1.57-1.51a4.13 4.13 0 011.57-3.32c.86-.5 1.56.18 1.56 1.51a4.15 4.15 0 01-1.56 3.32z"
            style={{
              WebkitTransformOrigin: 221.076,
              MsTransformOrigin: 221.076,
              transformOrigin: 221.076,
            }}
            id="elmvvymzv1e8"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M245.76 257.78a.19.19 0 01-.12 0 .24.24 0 01-.12-.2v-42a.24.24 0 01.12-.21l33.79-19.5a.21.21 0 01.24 0 .24.24 0 01.12.2v42a.24.24 0 01-.12.21l-33.79 19.5a.16.16 0 01-.12 0zm.24-42.11v41.45l33.31-19.23v-41.45z"
            style={{
              WebkitTransformOrigin: 262.655,
              MsTransformOrigin: 262.655,
              transformOrigin: 262.655,
            }}
            id="elyymxhzoojb"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M248.36 238.27a.27.27 0 01-.12 0 .24.24 0 01-.12-.21V217a.24.24 0 01.12-.21l28.59-16.5a.21.21 0 01.24 0 .24.24 0 01.12.2v21a.27.27 0 01-.12.21l-28.59 16.5a.24.24 0 01-.12.07zm.24-21.1v20.45l28.11-16.23v-20.45z"
            style={{
              WebkitTransformOrigin: 262.655,
              MsTransformOrigin: 262.655,
              transformOrigin: 262.655,
            }}
            id="elztatc2wx3yd"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M248.64 240.87l7.24-4.18a.17.17 0 01.28.16v.86a.62.62 0 01-.28.48l-7.24 4.18c-.15.09-.28 0-.28-.16v-.86a.64.64 0 01.28-.48z"
            style={{
              WebkitTransformOrigin: 252.262,
              MsTransformOrigin: 252.262,
              transformOrigin: 252.262,
            }}
            id="elkbzsw7qp889"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M248.64 243.87l27.16-15.64a.17.17 0 01.28.16v.86a.61.61 0 01-.28.48l-27.16 15.64c-.15.09-.28 0-.28-.16v-.86a.64.64 0 01.28-.48z"
            style={{
              WebkitTransformOrigin: 262.222,
              MsTransformOrigin: 262.222,
              transformOrigin: 262.222,
            }}
            id="elll8y4bafdr9"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M248.64 249.88l27.16-15.64c.15-.09.28 0 .28.16v.86a.64.64 0 01-.28.48l-27.16 15.64c-.15.08-.28 0-.28-.16v-.86a.61.61 0 01.28-.48z"
            style={{
              WebkitTransformOrigin: 262.22,
              MsTransformOrigin: 262.22,
              transformOrigin: 262.22,
            }}
            id="elwy3z5d1m6kf"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M248.64 246.87l27.16-15.64c.15-.08.28 0 .28.16v.86a.61.61 0 01-.28.48l-27.16 15.64c-.15.09-.28 0-.28-.16v-.86a.64.64 0 01.28-.48z"
            style={{
              WebkitTransformOrigin: 262.22,
              MsTransformOrigin: 262.22,
              transformOrigin: 262.22,
            }}
            id="elhp176i6zzsn"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M262.65 214.78a8.6 8.6 0 00-3.89 6.75c0 2.48 1.74 3.49 3.89 2.25a8.6 8.6 0 003.9-6.75c0-2.49-1.74-3.5-3.9-2.25zm2 3.65l-2.9 3.47c-.29.34-.52.26-.52-.18v-3.27a.76.76 0 01.8-.76l2.34.09c.48.02.63.31.32.65z"
            style={{
              WebkitTransformOrigin: 262.655,
              MsTransformOrigin: 262.655,
              transformOrigin: 262.655,
            }}
            id="elxkiytz6igzc"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M236.17 126.86a1.59 1.59 0 00-1.44.2L77.94 217.42a4.8 4.8 0 00-2.16 3.75v9.07a1.56 1.56 0 00.59 1.38c-.37-.24-3.64-2.09-4-2.33a1.54 1.54 0 01-.62-1.39v-9.07a4.78 4.78 0 012.17-3.75l156.78-90.37a1.53 1.53 0 011.55-.14c.31.21 3.15 1.82 3.92 2.29z"
            style={{
              WebkitTransformOrigin: 153.956,
              MsTransformOrigin: 153.956,
              transformOrigin: 153.956,
            }}
            id="eled6e5xknsoh"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M77.94 217.42l156.79-90.36c1.19-.69 2.16-.13 2.16 1.25v9.07a4.8 4.8 0 01-2.16 3.75L77.94 231.49c-1.2.69-2.17.13-2.17-1.25v-9.07a4.78 4.78 0 012.17-3.75z"
            style={{
              WebkitTransformOrigin: 156.33,
              MsTransformOrigin: 156.33,
              transformOrigin: 156.33,
            }}
            id="ele2huy06e1qp"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M76.41 219l-4.06-2.35a4.37 4.37 0 00-.64 2.14v9.07a1.54 1.54 0 00.62 1.39c.4.24 3.67 2.09 4 2.33a1.56 1.56 0 01-.59-1.38v-9.07a4.4 4.4 0 01.67-2.13z"
            style={{
              WebkitTransformOrigin: 74.0564,
              MsTransformOrigin: 74.0564,
              transformOrigin: 74.0564,
            }}
            id="ell9v4ehtf0wg"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M236.17 126.86c-.77-.47-3.61-2.08-4-2.29a1.53 1.53 0 00-1.55.14L73.88 215.08a4.32 4.32 0 00-1.53 1.61l4.06 2.31a4.35 4.35 0 011.53-1.62l156.79-90.36a1.59 1.59 0 011.44-.16z"
            style={{
              WebkitTransformOrigin: 154.26,
              MsTransformOrigin: 154.26,
              transformOrigin: 154.26,
            }}
            id="el4bhs2q51cn3"
            fill="#FFF"
            className="animable"
            opacity="0.4"
          />
          <path
            d="M228.77 137.53a4.64 4.64 0 01-2.17 3.6l-52.54 30.33c-1.19.69-2.16.2-2.16-1.09a4.65 4.65 0 012.16-3.6l52.54-30.33c1.2-.69 2.17-.2 2.17 1.09z"
            style={{
              WebkitTransformOrigin: 200.335,
              MsTransformOrigin: 200.335,
              transformOrigin: 200.335,
            }}
            id="el2zwzv7s7cey"
            fill="#FAFAFA"
            className="animable"
          />
          <g
            id="freepik--gears--inject-9"
            className="animable"
            style={{
              WebkitTransformOrigin: 289,
              MsTransformOrigin: 289,
              transformOrigin: 289,
            }}
          >
            <path
              d="M247 123.92l84-48.48c1.08-.62 1.95-.11 1.95 1.13v75.8a4.31 4.31 0 01-1.95 3.38l-84 48.48c-1.08.62-1.95.11-1.95-1.13v-75.8a4.31 4.31 0 011.95-3.38z"
              style={{
                WebkitTransformOrigin: 289,
                MsTransformOrigin: 289,
                transformOrigin: 289,
              }}
              id="el4a4iwn6jyww"
              fill="#E6E6E6"
              className="animable"
              opacity="0.7"
            />
            <path
              d="M287.44 137.05a5.19 5.19 0 00-.56-.89 3 3 0 00-.34-.37 3.7 3.7 0 00-1-.77 3.2 3.2 0 00-.6-.26 4.87 4.87 0 00-1.77-.23 3.59 3.59 0 00-.65.07l-.47.09-.46.11-.59.2-.46.19a4.41 4.41 0 010 .52V136.39c0 5.56-3.91 12.33-8.72 15.11a9 9 0 01-1.06.55 2.13 2.13 0 00.06.45v.19c0 .17.07.33.11.49a2.23 2.23 0 00.13.44 3.24 3.24 0 00.16.47c.06.14.11.26.17.38a4.48 4.48 0 00.61 1 4.39 4.39 0 00.67.69h.07l.32.23h.06a3.11 3.11 0 00.4.22c.14.06.3.13.45.18a5.55 5.55 0 003.37 0c.32-.1.66-.23 1-.37s.69-.34 1-.54c4.82-2.78 8.73-9.54 8.73-15.11a8.08 8.08 0 00-.63-3.72z"
              style={{
                WebkitTransformOrigin: 279.424,
                MsTransformOrigin: 279.424,
                transformOrigin: 279.424,
              }}
              id="elzpbosdqcp9"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M293.53 127.32l-.89 1.77a1.27 1.27 0 00.08 1.12 10.47 10.47 0 011 2.27.56.56 0 00.77.36l4.6-2.07a.42.42 0 01.36 0zm-5.69-9.69l-3.33-1.93a.66.66 0 00-.24 0 1.52 1.52 0 00-.21 0 .28.28 0 00-.13 0 1.84 1.84 0 00-.22.17s-.09 0-.11.09l-2.72 4.04-3.43-2h-.07l-2.49-1.43h-.12a.54.54 0 00-.26.07l-3.55 2.06-.41.23-1.12.64h-.07a.94.94 0 00-.2.16 1.61 1.61 0 00-.32.48.91.91 0 00-.09.31l-.52 5.75a.29.29 0 010 .09 1.18 1.18 0 01-.08.31 1.89 1.89 0 01-.43.67 30.82 30.82 0 00-2.88 3 1 1 0 01-.43.27.92.92 0 01-.59 0l-3.3-1.17a1 1 0 00-.25 0h-.33l-.14.07a.39.39 0 00-.1.08.44.44 0 00-.12.15l-1.56 2.7-1.65 2.86-.59 1a.82.82 0 00-.09.51.75.75 0 00.25.46l2.66 2.3a.86.86 0 01.13.14 1 1 0 01.18.53 1.21 1.21 0 01-.05.34 29.29 29.29 0 00-1.19 4 1.92 1.92 0 01-.32.64 1.56 1.56 0 01-.35.37l-4.71 3.32a.65.65 0 00-.17.16c-.06.07-.1.15-.16.23a1.37 1.37 0 00-.2.63v6a.42.42 0 00.18.38l6 3.46-2.18 4.4a.68.68 0 00.27.89l8.13 4.72h.11l-.1-.05-.54-.32a.69.69 0 01-.28-.9l3.07-6.18a.91.91 0 00.07-.25 1.32 1.32 0 00-.14-.86 10.33 10.33 0 01-1-2.28.55.55 0 00-.77-.36l-2.31 1-2.28 1a.42.42 0 01-.41 0 .41.41 0 01-.17-.38v-6a1.2 1.2 0 01.2-.63 1.1 1.1 0 01.32-.38l4.73-3.33a1.69 1.69 0 00.34-.36 1.72 1.72 0 00.31-.64 32 32 0 011.2-4 .92.92 0 00.06-.33 1 1 0 00-.32-.69l-2.66-2.28a.79.79 0 01-.24-.46.75.75 0 01.07-.53l1.08-1.87 1.35-2.33 1.37-2.37a.76.76 0 01.59-.37.73.73 0 01.33 0l3.32 1.16a.92.92 0 00.59 0 1 1 0 00.43-.28 31.86 31.86 0 012.86-3 1.82 1.82 0 00.44-.67 1.67 1.67 0 00.1-.4l.52-5.75a.73.73 0 01.09-.31 1.3 1.3 0 01.53-.66l1-.55 2.08-1.21 2.11-1.22a.43.43 0 01.39 0 .39.39 0 01.2.35l.27 2.63.24 2.39a.54.54 0 00.68.48 9.53 9.53 0 012.49-.25 1.28 1.28 0 00.72-.23 1.45 1.45 0 00.28-.27l3.82-5.74a.55.55 0 01.12-.14.69.69 0 01.6-.15.38.38 0 01.15 0zm-11.44 52.94a.25.25 0 01-.08-.12.71.71 0 010-.17l-.5-5a.53.53 0 00-.69-.48 10.42 10.42 0 01-2.49.25 1.3 1.3 0 00-1 .49l-1.1 1.65 5.9 3.41z"
              style={{
                WebkitTransformOrigin: 275.604,
                MsTransformOrigin: 275.604,
                transformOrigin: 275.604,
              }}
              id="el1qy9ltdk3g3"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M292.06 120.07a.38.38 0 00-.15 0 .69.69 0 00-.6.15.55.55 0 00-.12.14l-3.82 5.74a1.45 1.45 0 01-.28.27 1.28 1.28 0 01-.72.23 9.53 9.53 0 00-2.49.25.54.54 0 01-.68-.48l-.24-2.39-.27-2.63a.39.39 0 00-.2-.35.43.43 0 00-.39 0l-2.1 1.19-2.08 1.21-1 .55a1.3 1.3 0 00-.53.66.73.73 0 00-.09.31l-.52 5.75a1.67 1.67 0 01-.1.4 1.82 1.82 0 01-.44.67 31.86 31.86 0 00-2.86 3 1 1 0 01-.43.28.92.92 0 01-.59 0l-3.36-1.11a.73.73 0 00-.33 0 .76.76 0 00-.59.37l-1.37 2.37-1.33 2.35-1.08 1.87a.75.75 0 00-.07.53.79.79 0 00.24.46l2.66 2.28a1 1 0 01.32.69.92.92 0 01-.06.33 32 32 0 00-1.2 4 1.72 1.72 0 01-.31.64 1.69 1.69 0 01-.34.36l-4.73 3.33a1.1 1.1 0 00-.32.38 1.2 1.2 0 00-.2.63v6a.41.41 0 00.17.38.42.42 0 00.41 0l2.28-1 2.31-1a.55.55 0 01.77.36 10.33 10.33 0 001 2.28 1.32 1.32 0 01.14.86.91.91 0 01-.07.25l-3.07 6.18a.69.69 0 00.28.9l.54.32.1.05h-.11l-8.04-4.9a.68.68 0 01-.27-.89l2.18-4.4-6-3.46a.42.42 0 01-.18-.38v-6a1.37 1.37 0 01.2-.63c.06-.08.1-.16.16-.23a.65.65 0 01.17-.16l4.71-3.32a1.56 1.56 0 00.35-.37 1.92 1.92 0 00.32-.64 29.29 29.29 0 011.19-4 1.21 1.21 0 00.05-.34 1 1 0 00-.18-.53.86.86 0 00-.13-.14l-2.66-2.3a.75.75 0 01-.25-.46.82.82 0 01.09-.51l.59-1 1.65-2.86.45-.79 1.14-2a.7.7 0 01.84-.3h.07l3 1.08.26.09a.92.92 0 00.59 0 1 1 0 00.43-.27 30.82 30.82 0 012.88-3 1.89 1.89 0 00.43-.67 1.18 1.18 0 00.08-.31.29.29 0 000-.09l.52-5.75a.91.91 0 01.09-.31 1.61 1.61 0 01.32-.48 1.65 1.65 0 01.27-.21l1.12-.64.41-.23 3.55-2.06a.54.54 0 01.26-.07h.12l2.49 1.43h.07l3.43 2 2.72-4.08c0-.05.08-.05.11-.09a1.84 1.84 0 01.22-.17.28.28 0 01.13 0 1.52 1.52 0 01.21 0 .66.66 0 01.24 0l3.33 1.93z"
              style={{
                WebkitTransformOrigin: 271.894,
                MsTransformOrigin: 271.894,
                transformOrigin: 271.894,
              }}
              id="el5qj0229veo8"
              className="animable"
              opacity="0.55"
            />
            <path
              d="M276.32 170.45a.71.71 0 010-.17l-.5-5a.53.53 0 00-.69-.48 10.42 10.42 0 01-2.49.25 1.3 1.3 0 00-1 .49l-1.1 1.65 5.9 3.41a.25.25 0 01-.12-.15z"
              style={{
                WebkitTransformOrigin: 273.49,
                MsTransformOrigin: 273.49,
                transformOrigin: 273.49,
              }}
              id="el33qrs0xf76h"
              className="animable"
              opacity="0.55"
            />
            <path
              d="M293.53 127.32l-.89 1.77a1.27 1.27 0 00.08 1.12 10.47 10.47 0 011 2.27.56.56 0 00.77.36l4.6-2.07a.42.42 0 01.36 0z"
              style={{
                WebkitTransformOrigin: 295.998,
                MsTransformOrigin: 295.998,
                transformOrigin: 295.998,
              }}
              id="elfu8yerjos8p"
              className="animable"
              opacity="0.55"
            />
            <path
              d="M276.38 124.61a.73.73 0 00-.09.31l-.52 5.75a1.67 1.67 0 01-.1.4l-7.59-4.38a2.11 2.11 0 00.1-.3.29.29 0 000-.09l.52-5.75a1.21 1.21 0 01.09-.31z"
              style={{
                WebkitTransformOrigin: 272.23,
                MsTransformOrigin: 272.23,
                transformOrigin: 272.23,
              }}
              id="eltp4bwld230l"
              className="animable"
              opacity="0.15"
            />
            <path
              d="M266.45 144.79l-7.6-4.39a1 1 0 00-.14-.51.86.86 0 00-.13-.14l-2.66-2.3a.75.75 0 01-.25-.46l7.6 4.39a.79.79 0 00.24.46l2.66 2.28a1 1 0 01.28.67z"
              style={{
                WebkitTransformOrigin: 261.06,
                MsTransformOrigin: 261.06,
                transformOrigin: 261.06,
              }}
              id="elquupp6u938"
              className="animable"
              opacity="0.15"
            />
            <path
              d="M263.27 168.67a.69.69 0 00.28.9l.54.32-8.09-4.71a.68.68 0 01-.27-.89l2.18-4.4 1.6.92a.42.42 0 00.41 0l2.28-1 4.25 2.46a.91.91 0 01-.07.25z"
              style={{
                WebkitTransformOrigin: 261.055,
                MsTransformOrigin: 261.055,
                transformOrigin: 261.055,
              }}
              id="ell9fdfxf432"
              className="animable"
              opacity="0.15"
            />
            <path
              d="M276.4 170.57l-5.9-3.41 1.1-1.65a1.3 1.3 0 011-.49 10.42 10.42 0 002.49-.25.53.53 0 01.69.48l.5 5a.71.71 0 000 .17.25.25 0 00.12.15z"
              style={{
                WebkitTransformOrigin: 273.45,
                MsTransformOrigin: 273.45,
                transformOrigin: 273.45,
              }}
              id="elujv2ot5qa6e"
              className="animable"
              opacity="0.15"
            />
            <path
              d="M288.22 140.75c0 5.57-3.91 12.33-8.73 15.11-.35.2-.7.38-1 .54s-.68.27-1 .37a5.55 5.55 0 01-3.37 0c-.15 0-.31-.12-.45-.18a3.11 3.11 0 01-.4-.22h-.06l-.32-.23h-.07a4.39 4.39 0 01-.67-.69 4.48 4.48 0 01-.61-1c-.06-.12-.11-.24-.17-.38a3.24 3.24 0 01-.16-.47 2.23 2.23 0 01-.13-.44c0-.16-.08-.32-.11-.49v-.19a2.13 2.13 0 01-.06-.45 9 9 0 001.06-.55c4.81-2.78 8.72-9.55 8.72-15.11v-.31-.37a4.38 4.38 0 000-.52l.31-.17.52-.18.46-.11.47-.09a3.59 3.59 0 01.65-.07 4.87 4.87 0 011.77.23 3.2 3.2 0 01.6.26 3.7 3.7 0 011 .77 3 3 0 01.34.37 5.19 5.19 0 01.56.89 8.08 8.08 0 01.85 3.68z"
              style={{
                WebkitTransformOrigin: 279.565,
                MsTransformOrigin: 279.565,
                transformOrigin: 279.565,
              }}
              id="elpk09sq1zeq"
              className="animable"
              opacity="0.65"
            />
            <path
              d="M299.46 130.74a.42.42 0 00-.36 0l-4.6 2.07a.56.56 0 01-.77-.36 10.47 10.47 0 00-1-2.27 1.27 1.27 0 01-.08-1.12l.89-1.77z"
              style={{
                WebkitTransformOrigin: 296.008,
                MsTransformOrigin: 296.008,
                transformOrigin: 296.008,
              }}
              id="el4acgp9fxct1"
              className="animable"
              opacity="0.15"
            />
            <path
              d="M292.06 120.07a.38.38 0 00-.15 0 .69.69 0 00-.6.15.81.81 0 00-.12.14l-3.82 5.74a1.45 1.45 0 01-.28.27l-2.09-1.29-2-1.15-.27-2.63a.39.39 0 00-.2-.35l-1.58-.92 2.72-4.08a.61.61 0 01.33-.26.28.28 0 01.13 0 .59.59 0 01.45.06l3.33 1.93z"
              style={{
                WebkitTransformOrigin: 286.505,
                MsTransformOrigin: 286.505,
                transformOrigin: 286.505,
              }}
              id="eldbfz7i418ce"
              className="animable"
              opacity="0.15"
            />
            <path
              d="M264.88 149.77a1.69 1.69 0 01-.34.36l-4.73 3.33a1.1 1.1 0 00-.32.38l-7.59-4.38a1.88 1.88 0 01.16-.23.65.65 0 01.17-.16l4.71-3.32a1.56 1.56 0 00.35-.37z"
              style={{
                WebkitTransformOrigin: 258.39,
                MsTransformOrigin: 258.39,
                transformOrigin: 258.39,
              }}
              id="elv593386tsva"
              className="animable"
              opacity="0.15"
            />
            <path
              d="M282.49 120.94z"
              style={{
                WebkitTransformOrigin: 282.49,
                MsTransformOrigin: 282.49,
                transformOrigin: 282.49,
              }}
              id="elksa9logjkbo"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M282.49 120.94z"
              style={{
                WebkitTransformOrigin: 282.49,
                MsTransformOrigin: 282.49,
                transformOrigin: 282.49,
              }}
              id="el3idfsgwnuqr"
              className="animable"
              opacity="0.35"
            />
            <path
              d="M299.69 137.11v-6a.39.39 0 00-.59-.38l-4.6 2.07a.55.55 0 01-.76-.36 10.81 10.81 0 00-1-2.28 1.24 1.24 0 01-.07-1.11l3.07-6.18a.71.71 0 00-.28-.9l-3.32-1.92a.7.7 0 00-.92.22l-3.82 5.74a1.25 1.25 0 01-1 .5 10.29 10.29 0 00-2.48.25.54.54 0 01-.69-.49l-.51-5a.38.38 0 00-.62-.31l-5.16 3a1.39 1.39 0 00-.62 1l-.52 5.75a1.86 1.86 0 01-.54 1.07 31.86 31.86 0 00-2.86 3 1 1 0 01-1 .29l-3.4-1.16a.81.81 0 00-.93.35l-3.79 6.57a.8.8 0 00.16 1l2.66 2.29a1 1 0 01.26 1 30.32 30.32 0 00-1.19 4 1.86 1.86 0 01-.67 1l-4.71 3.32a1.38 1.38 0 00-.53 1v6a.38.38 0 00.59.38l4.59-2.07a.56.56 0 01.77.36 10.8 10.8 0 001 2.28 1.24 1.24 0 01.07 1.11l-3.06 6.18a.69.69 0 00.27.9l3.33 1.92a.68.68 0 00.91-.22l3.82-5.74a1.31 1.31 0 011-.5 9.93 9.93 0 002.49-.25.55.55 0 01.69.49l.5 5a.39.39 0 00.63.32l5.16-3a1.42 1.42 0 00.62-1l.51-5.75a2 2 0 01.54-1.07 30.73 30.73 0 002.87-3 1 1 0 011-.29l3.31 1.16a.81.81 0 00.93-.34l3.8-6.58a.81.81 0 00-.17-1l-2.66-2.29a1 1 0 01-.26-1 30.38 30.38 0 001.2-4 1.9 1.9 0 01.66-1l4.72-3.32a1.41 1.41 0 00.6-1.01zm-20.2 18.76c-4.82 2.78-8.73.52-8.73-5s3.91-12.33 8.73-15.12 8.72-.52 8.72 5-3.9 12.33-8.72 15.12z"
              style={{
                WebkitTransformOrigin: 279.473,
                MsTransformOrigin: 279.473,
                transformOrigin: 279.473,
              }}
              id="eluelzhkzeho"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M299.69 137.11v-6a.39.39 0 00-.59-.38l-4.6 2.07a.55.55 0 01-.76-.36 10.81 10.81 0 00-1-2.28 1.24 1.24 0 01-.07-1.11l3.07-6.18a.71.71 0 00-.28-.9l-3.32-1.92a.7.7 0 00-.92.22l-3.82 5.74a1.25 1.25 0 01-1 .5 10.29 10.29 0 00-2.48.25.54.54 0 01-.69-.49l-.51-5a.38.38 0 00-.62-.31l-5.16 3a1.39 1.39 0 00-.62 1l-.52 5.75a1.86 1.86 0 01-.54 1.07 31.86 31.86 0 00-2.86 3 1 1 0 01-1 .29l-3.4-1.16a.81.81 0 00-.93.35l-3.79 6.57a.8.8 0 00.16 1l2.66 2.29a1 1 0 01.26 1 30.32 30.32 0 00-1.19 4 1.86 1.86 0 01-.67 1l-4.71 3.32a1.38 1.38 0 00-.53 1v6a.38.38 0 00.59.38l4.59-2.07a.56.56 0 01.77.36 10.8 10.8 0 001 2.28 1.24 1.24 0 01.07 1.11l-3.06 6.18a.69.69 0 00.27.9l3.33 1.92a.68.68 0 00.91-.22l3.82-5.74a1.31 1.31 0 011-.5 9.93 9.93 0 002.49-.25.55.55 0 01.69.49l.5 5a.39.39 0 00.63.32l5.16-3a1.42 1.42 0 00.62-1l.51-5.75a2 2 0 01.54-1.07 30.73 30.73 0 002.87-3 1 1 0 011-.29l3.31 1.16a.81.81 0 00.93-.34l3.8-6.58a.81.81 0 00-.17-1l-2.66-2.29a1 1 0 01-.26-1 30.38 30.38 0 001.2-4 1.9 1.9 0 01.66-1l4.72-3.32a1.41 1.41 0 00.6-1.01zm-20.2 18.76c-4.82 2.78-8.73.52-8.73-5s3.91-12.33 8.73-15.12 8.72-.52 8.72 5-3.9 12.33-8.72 15.12z"
              style={{
                WebkitTransformOrigin: 279.473,
                MsTransformOrigin: 279.473,
                transformOrigin: 279.473,
              }}
              id="elj7q4kkzq7t"
              className="animable"
              opacity="0.45"
            />
            <path
              d="M261.88 130.51l-1.78-1a.72.72 0 00-.56.34l-1.14 2-.45.79-1.65 2.86-.59 1a.82.82 0 00-.09.51l7.6 4.39a.75.75 0 01.07-.53l1.08-1.87 1.35-2.33 1.37-2.37a.76.76 0 01.59-.37z"
              style={{
                WebkitTransformOrigin: 261.646,
                MsTransformOrigin: 261.646,
                transformOrigin: 261.646,
              }}
              id="el10yo3jprqr2j"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M261.88 130.51l-1.78-1a.72.72 0 00-.56.34l-1.14 2-.45.79-1.65 2.86-.59 1a.82.82 0 00-.09.51l7.6 4.39a.75.75 0 01.07-.53l1.08-1.87 1.35-2.33 1.37-2.37a.76.76 0 01.59-.37z"
              style={{
                WebkitTransformOrigin: 261.646,
                MsTransformOrigin: 261.646,
                transformOrigin: 261.646,
              }}
              id="el1qr0mki6fxs"
              className="animable"
              opacity="0.35"
            />
            <path
              d="M264.34 130.67a1 1 0 01-.59 0l-.26-.09-3-1.07h-.06a.67.67 0 00-.28 0l.1.06 1.68 1 .57.33 5.24 3a.73.73 0 01.33 0l3.32 1.16a.92.92 0 00.59 0z"
              style={{
                WebkitTransformOrigin: 266.065,
                MsTransformOrigin: 266.065,
                transformOrigin: 266.065,
              }}
              id="elf8fdj5w7thl"
              className="animable"
              opacity="0.15"
            />
            <path
              d="M282.46 120.93a.43.43 0 00-.39 0l-2.07 1.26-2.08 1.21-1 .55a1.3 1.3 0 00-.53.66l-7.59-4.38a1.61 1.61 0 01.32-.48.94.94 0 01.2-.16h.07l1.12-.64.41-.23 3.55-2.06a.54.54 0 01.26-.07h.12l2.49 1.43h.07l3.43 2z"
              style={{
                WebkitTransformOrigin: 275.63,
                MsTransformOrigin: 275.63,
                transformOrigin: 275.63,
              }}
              id="el7ba0pghy7yr"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M282.46 120.93a.43.43 0 00-.39 0l-2.07 1.26-2.08 1.21-1 .55a1.3 1.3 0 00-.53.66l-7.59-4.38a1.61 1.61 0 01.32-.48.94.94 0 01.2-.16h.07l1.12-.64.41-.23 3.55-2.06a.54.54 0 01.26-.07h.12l2.49 1.43h.07l3.43 2z"
              style={{
                WebkitTransformOrigin: 275.63,
                MsTransformOrigin: 275.63,
                transformOrigin: 275.63,
              }}
              id="elfs61je4awg"
              className="animable"
              opacity="0.35"
            />
            <path
              d="M321.56 140.94a2.85 2.85 0 00-2.22-1.76H319a4.23 4.23 0 00-2 .42v.77a12.49 12.49 0 01-5.67 9.82c-.22.13-.45.25-.66.35a4.6 4.6 0 00.38 1.48 2.85 2.85 0 002.66 1.79h.59a1.18 1.18 0 00.25 0 .35.35 0 00.11 0h.15a8 8 0 003.68-2.38c2.89-3.22 4.26-7.84 3.07-10.49z"
              style={{
                WebkitTransformOrigin: 316.329,
                MsTransformOrigin: 316.329,
                transformOrigin: 316.329,
              }}
              id="elc5vua61sx3t"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M318.49 151.27a8 8 0 01-3.68 2.38h-.15a.35.35 0 01-.11 0 1.18 1.18 0 01-.25 0h-.56a2.85 2.85 0 01-2.66-1.79 4.6 4.6 0 01-.38-1.48c.21-.1.44-.22.66-.35a12.49 12.49 0 005.67-9.82v-.77a4.23 4.23 0 012-.42h.33a2.85 2.85 0 012.22 1.76c1.17 2.81-.2 7.43-3.09 10.49z"
              style={{
                WebkitTransformOrigin: 316.349,
                MsTransformOrigin: 316.349,
                transformOrigin: 316.349,
              }}
              id="elw7mv82pfuef"
              className="animable"
              opacity="0.65"
            />
            <path
              d="M319.28 159.28l-1.46-2.21c-.09-.15-.37-.14-.6 0a10 10 0 01-.85.54l2.93 1.69a.05.05 0 01-.02-.02z"
              style={{
                WebkitTransformOrigin: 317.835,
                MsTransformOrigin: 317.835,
                transformOrigin: 317.835,
              }}
              id="el78c9g3omp1u"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M326 141a11.17 11.17 0 01-.06 1.14c0 .2.16.37.41.39l2.42.14a.34.34 0 01.18 0z"
              style={{
                WebkitTransformOrigin: 327.445,
                MsTransformOrigin: 327.445,
                transformOrigin: 327.445,
              }}
              id="elf48t33uxwr"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M310.09 158.64a5.08 5.08 0 01-1.23-.72.5.5 0 00-.63.11l-2.89 2.64a.48.48 0 01-.31.14l2.83 1.63 1.75 1a.26.26 0 01-.12-.28l.89-4a.44.44 0 00-.29-.52z"
              style={{
                WebkitTransformOrigin: 307.711,
                MsTransformOrigin: 307.711,
                transformOrigin: 307.711,
              }}
              id="elojmlte9d02m"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M304.63 160.62h.06l.17.1a.17.17 0 01-.06-.08l-1.29-2.84a.49.49 0 010-.34 1 1 0 01.16-.33l2.71-3.27a1.53 1.53 0 00.24-.5.58.58 0 000-.22 12.21 12.21 0 010-2.34.39.39 0 00-.41-.38l-2.42-.15c-.25 0-.4-.19-.34-.4l1.34-4.49a.92.92 0 01.6-.51l2.65-.64a1.13 1.13 0 00.68-.49 24.41 24.41 0 011.42-2.48.93.93 0 00.1-.2.42.42 0 000-.12v-.13a.51.51 0 000-.25l-.86-2.92a.59.59 0 010-.34 1.42 1.42 0 01.08-.18l.13-.17 3.1-3.27c.14-.14.3-.2.41-.14a.16.16 0 01.08.06l1.44 2.21c.1.15.37.14.61 0a12.1 12.1 0 011.75-1 1.39 1.39 0 00.62-.6l1.52-4.1a.39.39 0 01.06-.11.85.85 0 01.39-.28h.17l2.82-.29h.17a.15.15 0 01.1 0 .22.22 0 01.12.28l-.88 4a.46.46 0 00.29.51 4.47 4.47 0 011.23.71.5.5 0 00.63-.11l2.89-2.63c.15-.13.3-.18.41-.13l-5.52-3.2a.19.19 0 00-.1 0c-.31-.17-.66-.38-1-.6l-1.13-.64-.45-.27-1-.59-.16-.1-.79-.45a.39.39 0 00-.28-.05l-2.82.28a.3.3 0 00-.13 0h-.12l-.13.07-.09.08a.46.46 0 00-.14.21l-1.54 4.1a.19.19 0 01-.05.1.67.67 0 01-.11.14 1 1 0 01-.14.15.38.38 0 01-.13.09l-.16.1c-.3.14-.58.3-.89.47l-3-1.71h-.05a.24.24 0 00-.24 0 .39.39 0 00-.17.11l-2.2 2.32-.9.95a1.1 1.1 0 00-.14.18 1.89 1.89 0 00-.09.21.51.51 0 000 .24l.87 2.92a.73.73 0 010 .26.29.29 0 010 .09 1.18 1.18 0 01-.12.28 24.35 24.35 0 00-1.43 2.47 1.1 1.1 0 01-.67.51l-2.65.64a.85.85 0 00-.6.49l-1.34 4.5a.28.28 0 00.11.32s1.18.7 2 1.19l1 .55c0 .42 0 .82.05 1.21a1.37 1.37 0 010 .19.8.8 0 01-.07.2 2 2 0 01-.17.29l-2.83 3.52a.84.84 0 00-.16.33.67.67 0 000 .18.41.41 0 000 .16l1.28 2.84a.28.28 0 00.09.1l.82.47c.11.07.25.14.38.23l3.45 2a4.27 4.27 0 01.38.22l.21.12"
              style={{
                WebkitTransformOrigin: 312.676,
                MsTransformOrigin: 312.676,
                transformOrigin: 312.676,
              }}
              id="el6is96bld0hw"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M304.63 160.62h.06l.17.1a.17.17 0 01-.06-.08l-1.29-2.84a.49.49 0 010-.34 1 1 0 01.16-.33l2.71-3.27a1.53 1.53 0 00.24-.5.58.58 0 000-.22 12.21 12.21 0 010-2.34.39.39 0 00-.41-.38l-2.42-.15c-.25 0-.4-.19-.34-.4l1.34-4.49a.92.92 0 01.6-.51l2.65-.64a1.13 1.13 0 00.68-.49 24.41 24.41 0 011.42-2.48.93.93 0 00.1-.2.42.42 0 000-.12v-.13a.51.51 0 000-.25l-.86-2.92a.59.59 0 010-.34 1.42 1.42 0 01.08-.18l.13-.17 3.1-3.27c.14-.14.3-.2.41-.14a.16.16 0 01.08.06l1.44 2.21c.1.15.37.14.61 0a12.1 12.1 0 011.75-1 1.39 1.39 0 00.62-.6l1.52-4.1a.39.39 0 01.06-.11.85.85 0 01.39-.28h.17l2.82-.29h.17a.15.15 0 01.1 0 .22.22 0 01.12.28l-.88 4a.46.46 0 00.29.51 4.47 4.47 0 011.23.71.5.5 0 00.63-.11l2.89-2.63c.15-.13.3-.18.41-.13l-5.52-3.2a.19.19 0 00-.1 0c-.31-.17-.66-.38-1-.6l-1.13-.64-.45-.27-1-.59-.16-.1-.79-.45a.39.39 0 00-.28-.05l-2.82.28a.3.3 0 00-.13 0h-.12l-.13.07-.09.08a.46.46 0 00-.14.21l-1.54 4.1a.19.19 0 01-.05.1.67.67 0 01-.11.14 1 1 0 01-.14.15.38.38 0 01-.13.09l-.16.1c-.3.14-.58.3-.89.47l-3-1.71h-.05a.24.24 0 00-.24 0 .39.39 0 00-.17.11l-2.2 2.32-.9.95a1.1 1.1 0 00-.14.18 1.89 1.89 0 00-.09.21.51.51 0 000 .24l.87 2.92a.73.73 0 010 .26.29.29 0 010 .09 1.18 1.18 0 01-.12.28 24.35 24.35 0 00-1.43 2.47 1.1 1.1 0 01-.67.51l-2.65.64a.85.85 0 00-.6.49l-1.34 4.5a.28.28 0 00.11.32s1.18.7 2 1.19l1 .55c0 .42 0 .82.05 1.21a1.37 1.37 0 010 .19.8.8 0 01-.07.2 2 2 0 01-.17.29l-2.83 3.52a.84.84 0 00-.16.33.67.67 0 000 .18.41.41 0 000 .16l1.28 2.84a.28.28 0 00.09.1l.82.47c.11.07.25.14.38.23l3.45 2a4.27 4.27 0 01.38.22l.21.12"
              style={{
                WebkitTransformOrigin: 312.676,
                MsTransformOrigin: 312.676,
                transformOrigin: 312.676,
              }}
              id="el3xqppjkrfsi"
              className="animable"
              opacity="0.55"
            />
            <path
              d="M319.28 159.28l-1.46-2.21c-.09-.15-.37-.14-.6 0a10 10 0 01-.85.54l2.93 1.69a.05.05 0 01-.02-.02z"
              style={{
                WebkitTransformOrigin: 317.835,
                MsTransformOrigin: 317.835,
                transformOrigin: 317.835,
              }}
              id="elsykq5n0qn7"
              className="animable"
              opacity="0.65"
            />
            <path
              d="M326 141a11.17 11.17 0 01-.06 1.14c0 .2.16.37.41.39l2.42.14a.34.34 0 01.18 0z"
              style={{
                WebkitTransformOrigin: 327.445,
                MsTransformOrigin: 327.445,
                transformOrigin: 327.445,
              }}
              id="elwczjxl6nppm"
              className="animable"
              opacity="0.55"
            />
            <path
              d="M310.09 158.64a5.08 5.08 0 01-1.23-.72.5.5 0 00-.63.11l-2.89 2.64a.48.48 0 01-.31.14l2.83 1.63 1.75 1a.26.26 0 01-.12-.28l.89-4a.44.44 0 00-.29-.52z"
              style={{
                WebkitTransformOrigin: 307.711,
                MsTransformOrigin: 307.711,
                transformOrigin: 307.711,
              }}
              id="elakvk7p9m1a"
              className="animable"
              opacity="0.65"
            />
            <path
              d="M306.6 153.6a1.57 1.57 0 01-.18.31l-2.71 3.27a1 1 0 00-.16.33l-5.7-3.3a.84.84 0 01.16-.33l2.71-3.28a2 2 0 00.17-.29z"
              style={{
                WebkitTransformOrigin: 302.225,
                MsTransformOrigin: 302.225,
                transformOrigin: 302.225,
              }}
              id="elc9f0fdk34yi"
              className="animable"
              opacity="0.2"
            />
            <path
              d="M306.68 153.19a.83.83 0 01-.08.41l-5.71-3.29a.8.8 0 00.07-.2 1.37 1.37 0 000-.19c0-.39-.05-.79-.05-1.2l2.67 1.54a.43.43 0 00.26.09l2.42.15a.39.39 0 01.41.38 12.21 12.21 0 00.01 2.31z"
              style={{
                WebkitTransformOrigin: 303.786,
                MsTransformOrigin: 303.786,
                transformOrigin: 303.786,
              }}
              id="elj28im2fg6j7"
              className="animable"
              opacity="0.05"
            />
            <path
              d="M308.47 144.11a.85.85 0 01-.36.17l-2.65.64a1 1 0 00-.49.32l-5.71-3.24a.82.82 0 01.3-.25.68.68 0 01.2-.07l2.65-.64a1 1 0 00.35-.17z"
              style={{
                WebkitTransformOrigin: 303.865,
                MsTransformOrigin: 303.865,
                transformOrigin: 303.865,
              }}
              id="el92lbauu5f0n"
              className="animable"
              opacity="0.1"
            />
            <path
              d="M303.52 149.92a.31.31 0 00.08.31l-2.67-1.54L298 147a.28.28 0 01-.11-.32l1.34-4.5a.7.7 0 01.09-.17l5.72 3.29a.62.62 0 00-.11.19z"
              style={{
                WebkitTransformOrigin: 301.458,
                MsTransformOrigin: 301.458,
                transformOrigin: 301.458,
              }}
              id="elyuyjujp1epp"
              className="animable"
              opacity="0.35"
            />
            <path
              d="M303.83 134.09l5.7 3.27a.55.55 0 000 .33l.86 2.92a.72.72 0 010 .38l-5.7-3.3v-.09a.73.73 0 000-.26l-.87-2.92a.49.49 0 010-.29"
              style={{
                WebkitTransformOrigin: 307.107,
                MsTransformOrigin: 307.107,
                transformOrigin: 307.107,
              }}
              id="elzg5v9gefq4"
              className="animable"
              opacity="0.1"
            />
            <path
              d="M319.46 129.93a.51.51 0 00-.15.21l-1.52 4.1a.9.9 0 01-.31.38l-5.7-3.29a1 1 0 00.22-.33.19.19 0 00.05-.1l1.54-4.1a.46.46 0 01.14-.21z"
              style={{
                WebkitTransformOrigin: 315.62,
                MsTransformOrigin: 315.62,
                transformOrigin: 315.62,
              }}
              id="ele5pscqkbhf7"
              className="animable"
              opacity="0.1"
            />
            <path
              d="M303.52 149.92a.31.31 0 00.08.31l-2.67-1.54L298 147a.28.28 0 01-.11-.32l1.34-4.5a.7.7 0 01.09-.17l5.72 3.29a.62.62 0 00-.11.19z"
              style={{
                WebkitTransformOrigin: 301.458,
                MsTransformOrigin: 301.458,
                transformOrigin: 301.458,
              }}
              id="elciiuoyqpsi4"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M303.52 149.92a.31.31 0 00.08.31l-2.67-1.54L298 147a.28.28 0 01-.11-.32l1.34-4.5a.7.7 0 01.09-.17l5.72 3.29a.62.62 0 00-.11.19z"
              style={{
                WebkitTransformOrigin: 301.458,
                MsTransformOrigin: 301.458,
                transformOrigin: 301.458,
              }}
              id="elug6530885nm"
              className="animable"
              opacity="0.35"
            />
            <path
              d="M299.26 142z"
              style={{
                WebkitTransformOrigin: 299.26,
                MsTransformOrigin: 299.26,
                transformOrigin: 299.26,
              }}
              id="elpnxwvn8m3pg"
              className="animable"
              opacity="0.35"
            />
            <path
              d="M313.3 133.59c-.12-.08-.3 0-.44.13l-3.1 3.27a1.34 1.34 0 00-.22.36l-5.7-3.27a1.11 1.11 0 01.08-.21 1.1 1.1 0 01.14-.18l3.1-3.27a.48.48 0 01.17-.12.24.24 0 01.24 0h.05l3 1.71z"
              style={{
                WebkitTransformOrigin: 308.57,
                MsTransformOrigin: 308.57,
                transformOrigin: 308.57,
              }}
              id="eleijn86c2u4"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M313.3 133.59c-.12-.08-.3 0-.44.13l-3.1 3.27a1.34 1.34 0 00-.22.36l-5.7-3.27a1.11 1.11 0 01.08-.21 1.1 1.1 0 01.14-.18l3.1-3.27a.48.48 0 01.17-.12.24.24 0 01.24 0h.05l3 1.71z"
              style={{
                WebkitTransformOrigin: 308.57,
                MsTransformOrigin: 308.57,
                transformOrigin: 308.57,
              }}
              id="el23e66gpagbm"
              className="animable"
              opacity="0.35"
            />
            <path
              d="M323 129.47a.45.45 0 00-.26-.05l-2.82.29a.79.79 0 00-.47.22l-5.7-3.3.09-.08.13-.07h.12a.27.27 0 01.13 0l2.82-.28a.39.39 0 01.28.05z"
              style={{
                WebkitTransformOrigin: 318.375,
                MsTransformOrigin: 318.375,
                transformOrigin: 318.375,
              }}
              id="elu37li9imerp"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M323 129.47a.45.45 0 00-.26-.05l-2.82.29a.79.79 0 00-.47.22l-5.7-3.3.09-.08.13-.07h.12a.27.27 0 01.13 0l2.82-.28a.39.39 0 01.28.05z"
              style={{
                WebkitTransformOrigin: 318.375,
                MsTransformOrigin: 318.375,
                transformOrigin: 318.375,
              }}
              id="elg9j00iomlhq"
              className="animable"
              opacity="0.35"
            />
            <path
              d="M300.77 148.59l.16.09z"
              style={{
                WebkitTransformOrigin: 300.85,
                MsTransformOrigin: 300.85,
                transformOrigin: 300.85,
              }}
              id="elzugskwr9f2o"
              className="animable"
              opacity="0.35"
            />
            <path
              d="M329 142.66a.34.34 0 00-.18 0l-2.42-.14c-.25 0-.43-.19-.41-.39A11.17 11.17 0 00326 141a9.48 9.48 0 00-.05-1.2 1.1 1.1 0 01.26-.71l2.71-3.28a.71.71 0 00.16-.67l-1.28-2.84a.24.24 0 00-.08-.09c-.11-.05-.26 0-.41.13l-2.89 2.63a.5.5 0 01-.63.11 4.47 4.47 0 00-1.23-.71.46.46 0 01-.29-.51l.88-4a.22.22 0 00-.12-.28.39.39 0 00-.27-.06l-2.82.29a.78.78 0 00-.62.43l-1.52 4.1a1.39 1.39 0 01-.62.6 12.1 12.1 0 00-1.75 1c-.24.16-.51.17-.61 0l-1.44-2.21c-.11-.15-.34-.13-.52.07l-3.1 3.27a.81.81 0 00-.24.69l.86 2.92a.94.94 0 01-.16.7 24.41 24.41 0 00-1.42 2.48 1.13 1.13 0 01-.68.49l-2.65.64a.92.92 0 00-.6.51l-1.34 4.49c-.06.21.09.39.34.4l2.42.15a.39.39 0 01.41.38 12.21 12.21 0 000 2.34 1.14 1.14 0 01-.26.72l-2.71 3.27a.77.77 0 00-.16.67l1.26 2.83a.17.17 0 00.06.06.23.23 0 00.11 0 .44.44 0 00.31-.14l2.89-2.64a.5.5 0 01.63-.11 5.08 5.08 0 001.23.72.44.44 0 01.29.5l-.89 4a.26.26 0 00.12.28.5.5 0 00.28 0l2.82-.28a.78.78 0 00.62-.43l1.53-4.1a1.27 1.27 0 01.61-.6c.3-.14.61-.3.9-.47a10 10 0 00.85-.54c.23-.16.51-.17.6 0l1.46 2.21a.13.13 0 00.06.07.38.38 0 00.45-.14l3.11-3.27a.81.81 0 00.23-.68l-.86-2.92a1 1 0 01.16-.71 23.49 23.49 0 001.42-2.47 1.15 1.15 0 01.68-.51l2.65-.64a.85.85 0 00.6-.49l1.34-4.5a.3.3 0 00-.15-.35zm-10.47 8.61a8 8 0 01-3.68 2.38h-.15a.35.35 0 01-.11 0 1.18 1.18 0 01-.25 0h-.56a2.85 2.85 0 01-2.66-1.79 4.6 4.6 0 01-.38-1.48 4.41 4.41 0 01-.05-.82 12.74 12.74 0 013.5-8 9.4 9.4 0 012.14-1.73 6.78 6.78 0 01.71-.36 4.23 4.23 0 012-.42h.33a2.85 2.85 0 012.22 1.76c1.16 2.78-.21 7.4-3.1 10.46z"
              style={{
                WebkitTransformOrigin: 316.311,
                MsTransformOrigin: 316.311,
                transformOrigin: 316.311,
              }}
              id="elwbrkshxix5q"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M329 142.66a.34.34 0 00-.18 0l-2.42-.14c-.25 0-.43-.19-.41-.39A11.17 11.17 0 00326 141a9.48 9.48 0 00-.05-1.2 1.1 1.1 0 01.26-.71l2.71-3.28a.71.71 0 00.16-.67l-1.28-2.84a.24.24 0 00-.08-.09c-.11-.05-.26 0-.41.13l-2.89 2.63a.5.5 0 01-.63.11 4.47 4.47 0 00-1.23-.71.46.46 0 01-.29-.51l.88-4a.22.22 0 00-.12-.28.39.39 0 00-.27-.06l-2.82.29a.78.78 0 00-.62.43l-1.52 4.1a1.39 1.39 0 01-.62.6 12.1 12.1 0 00-1.75 1c-.24.16-.51.17-.61 0l-1.44-2.21c-.11-.15-.34-.13-.52.07l-3.1 3.27a.81.81 0 00-.24.69l.86 2.92a.94.94 0 01-.16.7 24.41 24.41 0 00-1.42 2.48 1.13 1.13 0 01-.68.49l-2.65.64a.92.92 0 00-.6.51l-1.34 4.49c-.06.21.09.39.34.4l2.42.15a.39.39 0 01.41.38 12.21 12.21 0 000 2.34 1.14 1.14 0 01-.26.72l-2.71 3.27a.77.77 0 00-.16.67l1.26 2.83a.17.17 0 00.06.06.23.23 0 00.11 0 .44.44 0 00.31-.14l2.89-2.64a.5.5 0 01.63-.11 5.08 5.08 0 001.23.72.44.44 0 01.29.5l-.89 4a.26.26 0 00.12.28.5.5 0 00.28 0l2.82-.28a.78.78 0 00.62-.43l1.53-4.1a1.27 1.27 0 01.61-.6c.3-.14.61-.3.9-.47a10 10 0 00.85-.54c.23-.16.51-.17.6 0l1.46 2.21a.13.13 0 00.06.07.38.38 0 00.45-.14l3.11-3.27a.81.81 0 00.23-.68l-.86-2.92a1 1 0 01.16-.71 23.49 23.49 0 001.42-2.47 1.15 1.15 0 01.68-.51l2.65-.64a.85.85 0 00.6-.49l1.34-4.5a.3.3 0 00-.15-.35zm-10.47 8.61a8 8 0 01-3.68 2.38h-.15a.35.35 0 01-.11 0 1.18 1.18 0 01-.25 0h-.56a2.85 2.85 0 01-2.66-1.79 4.6 4.6 0 01-.38-1.48 4.41 4.41 0 01-.05-.82 12.74 12.74 0 013.5-8 9.4 9.4 0 012.14-1.73 6.78 6.78 0 01.71-.36 4.23 4.23 0 012-.42h.33a2.85 2.85 0 012.22 1.76c1.16 2.78-.21 7.4-3.1 10.46z"
              style={{
                WebkitTransformOrigin: 316.311,
                MsTransformOrigin: 316.311,
                transformOrigin: 316.311,
              }}
              id="el09jq6e56xgp7"
              className="animable"
              opacity="0.45"
            />
          </g>
        </g>
      </g>
      <g
        id="freepik--Crane--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 376.94,
          MsTransformOrigin: 376.94,
          transformOrigin: 376.94,
        }}
      >
        <g
          id="freepik--Vehicle--inject-9"
          className="animable"
          style={{
            WebkitTransformOrigin: 376.94,
            MsTransformOrigin: 376.94,
            transformOrigin: 376.94,
          }}
        >
          <path
            style={{
              WebkitTransformOrigin: 385.545,
              MsTransformOrigin: 385.545,
              transformOrigin: 385.545,
            }}
            id="el72yq98l1py7"
            fill="#263238"
            d="M397.66 134.58L379.29 169.14 373.43 165.4 391.74 131.16 397.66 134.58z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 367.78,
              MsTransformOrigin: 367.78,
              transformOrigin: 367.78,
            }}
            id="elhyxhp27tz54"
            fill="#455A64"
            d="M362.2 179.22L379.29 169.14 373.43 165.4 356.27 175.81 362.2 179.22z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 359.235,
              MsTransformOrigin: 359.235,
              transformOrigin: 359.235,
            }}
            id="elr8luhnjk5u"
            fill="#263238"
            d="M356.27 179.07L362.2 182.49 362.2 179.22 356.27 175.81 356.27 179.07z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 381.39,
              MsTransformOrigin: 381.39,
              transformOrigin: 381.39,
            }}
            id="elxf0b4xzevga"
            fill="#37474F"
            d="M400.58 136.14L397.66 134.58 379.29 169.14 362.2 179.22 362.2 182.49 382.04 170.67 400.58 136.14z"
            className="animable"
          />
          <g
            id="freepik--Image--inject-9"
            className="animable"
            style={{
              WebkitTransformOrigin: 322.207,
              MsTransformOrigin: 322.207,
              transformOrigin: 322.207,
            }}
          >
            <path
              d="M367.34 94.77A1.45 1.45 0 00366 95l-84 48.48a4.32 4.32 0 00-1.95 3.38v75.74a1.43 1.43 0 00.53 1.25c-.33-.22-3.28-1.89-3.64-2.1a1.39 1.39 0 01-.55-1.25v-75.8a4.29 4.29 0 011.95-3.38l83.94-48.49a1.39 1.39 0 011.4-.12c.41.19 2.97 1.64 3.66 2.06z"
              style={{
                WebkitTransformOrigin: 321.862,
                MsTransformOrigin: 321.862,
                transformOrigin: 321.862,
              }}
              id="eltas9emlyty"
              fill="#EF8C2D"
              className="animable"
              opacity="0.7"
            />
            <path
              d="M282.09 143.43L366 95c1.08-.62 1.95-.12 1.95 1.13v75.8a4.32 4.32 0 01-1.95 3.38l-84 48.47c-1.08.62-1.95.12-1.95-1.12v-75.8a4.29 4.29 0 012.04-3.43z"
              style={{
                WebkitTransformOrigin: 324,
                MsTransformOrigin: 324,
                transformOrigin: 324,
              }}
              id="el8jfg6fusfvr"
              fill="#EF8C2D"
              className="animable"
              opacity="0.7"
            />
            <path
              d="M280.71 144.89l-3.66-2.12a4 4 0 00-.57 1.93v75.8a1.39 1.39 0 00.55 1.25c.36.21 3.31 1.88 3.64 2.1a1.43 1.43 0 01-.53-1.25v-75.79a4 4 0 01.57-1.92z"
              style={{
                WebkitTransformOrigin: 278.592,
                MsTransformOrigin: 278.592,
                transformOrigin: 278.592,
              }}
              id="elq9y5scx7ld"
              className="animable"
              opacity="0.15"
            />
            <path
              d="M367.34 94.77c-.69-.42-3.25-1.87-3.57-2.06a1.39 1.39 0 00-1.4.12l-83.94 48.49a3.94 3.94 0 00-1.38 1.45l3.66 2.12a4 4 0 011.38-1.46L366 95a1.45 1.45 0 011.34-.23z"
              style={{
                WebkitTransformOrigin: 322.195,
                MsTransformOrigin: 322.195,
                transformOrigin: 322.195,
              }}
              id="elfa0ajnikuvu"
              fill="#FFF"
              className="animable"
              opacity="0.5"
            />
            <path
              d="M335.09 168.57l6.64 9.33-30-27.29-31.6 53.53v18.47c0 1.24.87 1.74 1.95 1.12l84-48.47a4.32 4.32 0 001.95-3.38v-10.15l-22.36-16.1z"
              style={{
                WebkitTransformOrigin: 324.08,
                MsTransformOrigin: 324.08,
                transformOrigin: 324.08,
              }}
              id="elz5ezqd2x6o"
              fill="#FAFAFA"
              className="animable"
              opacity="0.95"
            />
            <path
              d="M338.71 123.69c3.91-2.26 7.08-.42 7.08 4.1a15.66 15.66 0 01-7.08 12.27c-3.92 2.26-7.09.43-7.09-4.09a15.66 15.66 0 017.09-12.28z"
              style={{
                WebkitTransformOrigin: 338.705,
                MsTransformOrigin: 338.705,
                transformOrigin: 338.705,
              }}
              id="elxjz4110k92"
              fill="#FAFAFA"
              className="animable"
              opacity="0.95"
            />
            <path
              d="M366.91 95.59c.17 0 .18.44.18.49v75.8a3.44 3.44 0 01-1.5 2.6L281.64 223a1 1 0 01-.42.15c-.17 0-.18-.44-.18-.49v-75.8a3.41 3.41 0 011.5-2.6l83.95-48.48a.87.87 0 01.42-.14m0-.9a1.76 1.76 0 00-.91.26l-84 48.48a4.29 4.29 0 00-1.95 3.38v75.8c0 .88.44 1.39 1.08 1.39a1.78 1.78 0 00.87-.27l84-48.47a4.32 4.32 0 001.95-3.38V96.08c0-.88-.44-1.39-1.08-1.39z"
              style={{
                WebkitTransformOrigin: 324,
                MsTransformOrigin: 324,
                transformOrigin: 324,
              }}
              id="elvqiruk65ed"
              fill="#EF8C2D"
              className="animable"
            />
          </g>
          <path
            d="M448.36 147.22c2.89-2.67 9.68 2.3 9.68 5.16l-26.9 75.3-16-7.67z"
            style={{
              WebkitTransformOrigin: 436.59,
              MsTransformOrigin: 436.59,
              transformOrigin: 436.59,
            }}
            id="el6zzckti5lj"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M448.36 147.22c2.89-2.67 9.68 2.3 9.68 5.16l-26.9 75.3-16-7.67z"
            style={{
              WebkitTransformOrigin: 436.59,
              MsTransformOrigin: 436.59,
              transformOrigin: 436.59,
            }}
            id="el67t5cgksd2n"
            fill="#FFF"
            className="animable"
            opacity="0.7"
          />
          <path
            d="M450.19 119.92c2.89-2.41 5.76-3 8.06-2.13l14.22 6.79-.33.9a10.58 10.58 0 012 4.8c1.26 7.27-2.31 17-8 21.72a10.53 10.53 0 01-4.55 2.37l-.34.94-14.19-6.78c-2.38-.75-4.19-3.12-4.84-6.87-1.22-7.28 2.3-17.01 7.97-21.74z"
            style={{
              WebkitTransformOrigin: 458.186,
              MsTransformOrigin: 458.186,
              transformOrigin: 458.186,
            }}
            id="el9jzqaozwd4h"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M450.19 119.92c2.89-2.41 5.76-3 8.06-2.13l14.22 6.79-.33.9a10.58 10.58 0 012 4.8c1.26 7.27-2.31 17-8 21.72a10.53 10.53 0 01-4.55 2.37l-.34.94-14.19-6.78c-2.38-.75-4.19-3.12-4.84-6.87-1.22-7.28 2.3-17.01 7.97-21.74z"
            style={{
              WebkitTransformOrigin: 458.186,
              MsTransformOrigin: 458.186,
              transformOrigin: 458.186,
            }}
            id="elong4cw2q6hr"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M464.71 126.82c-5.67 4.73-9.24 14.45-8 21.72s6.87 9.33 12.54 4.61 9.24-14.46 8-21.73-6.88-9.33-12.54-4.6z"
            style={{
              WebkitTransformOrigin: 466.98,
              MsTransformOrigin: 466.98,
              transformOrigin: 466.98,
            }}
            id="elzap0fg82dls"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M462.08 144.07a5.24 5.24 0 00.81 2.13c1.13 1.64 3.16 1.76 5.19.08a12.26 12.26 0 003.81-10.39c-.39-2.26-1.64-3.46-3.22-3.4a4.44 4.44 0 00-2.77 1.2 12.18 12.18 0 00-3.82 10.38z"
            style={{
              WebkitTransformOrigin: 466.984,
              MsTransformOrigin: 466.984,
              transformOrigin: 466.984,
            }}
            id="elkjxpjh54o3c"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M462.08 144.07a5.24 5.24 0 00.81 2.13c1.13 1.64 3.16 1.76 5.19.08a12.26 12.26 0 003.81-10.39c-.39-2.26-1.64-3.46-3.22-3.4a4.44 4.44 0 00-2.77 1.2 12.18 12.18 0 00-3.82 10.38z"
            style={{
              WebkitTransformOrigin: 466.984,
              MsTransformOrigin: 466.984,
              transformOrigin: 466.984,
            }}
            id="el9zhn42b6mi"
            className="animable"
            opacity="0.3"
          />
          <path
            d="M462.08 144.07a5.24 5.24 0 00.81 2.13 4.56 4.56 0 002.79-1.21 12.21 12.21 0 003.8-10.37 5.24 5.24 0 00-.81-2.13 4.44 4.44 0 00-2.77 1.2 12.18 12.18 0 00-3.82 10.38z"
            style={{
              WebkitTransformOrigin: 465.78,
              MsTransformOrigin: 465.78,
              transformOrigin: 465.78,
            }}
            id="elubl8t6a8v0e"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M404.31 130.48L451 140.71a4.8 4.8 0 00-1.53-9.47h-.17l-46.29-8a3.64 3.64 0 001.29 7.17z"
            style={{
              WebkitTransformOrigin: 427.432,
              MsTransformOrigin: 427.432,
              transformOrigin: 427.432,
            }}
            id="el1uijclljpv1h"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M404.31 130.48L451 140.71a4.8 4.8 0 00-1.53-9.47h-.17l-46.29-8a3.64 3.64 0 001.29 7.17z"
            style={{
              WebkitTransformOrigin: 427.432,
              MsTransformOrigin: 427.432,
              transformOrigin: 427.432,
            }}
            id="elzvtab5jsh68"
            fill="#FFF"
            className="animable"
            opacity="0.7"
          />
          <path
            d="M392.64 114.21c2.06-1.71 4.1-2.16 5.73-1.52l10.05 5.16-.23.64a7.57 7.57 0 011.4 3.42c.9 5.17-1.64 12.09-5.67 15.45a7.69 7.69 0 01-3.24 1.69l-.24.66-10-5.15c-1.7-.54-3-2.21-3.45-4.88-.92-5.19 1.62-12.1 5.65-15.47z"
            style={{
              WebkitTransformOrigin: 398.286,
              MsTransformOrigin: 398.286,
              transformOrigin: 398.286,
            }}
            id="elijoanc8zfwq"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M392.64 114.21c2.06-1.71 4.1-2.16 5.73-1.52l10.05 5.16-.23.64a7.57 7.57 0 011.4 3.42c.9 5.17-1.64 12.09-5.67 15.45a7.69 7.69 0 01-3.24 1.69l-.24.66-10-5.15c-1.7-.54-3-2.21-3.45-4.88-.92-5.19 1.62-12.1 5.65-15.47z"
            style={{
              WebkitTransformOrigin: 398.286,
              MsTransformOrigin: 398.286,
              transformOrigin: 398.286,
            }}
            id="el1wa6p74tp1u"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M402.9 119.45c-4 3.36-6.57 10.28-5.67 15.45s4.89 6.64 8.92 3.28 6.57-10.28 5.67-15.45-4.89-6.64-8.92-3.28z"
            style={{
              WebkitTransformOrigin: 404.526,
              MsTransformOrigin: 404.526,
              transformOrigin: 404.526,
            }}
            id="el6cqzapief0o"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M401 131.72a3.84 3.84 0 00.58 1.52c.8 1.16 2.25 1.25 3.69 0a8.66 8.66 0 002.71-7.38c-.28-1.61-1.17-2.47-2.29-2.42a3.14 3.14 0 00-2 .85 8.69 8.69 0 00-2.69 7.43z"
            style={{
              WebkitTransformOrigin: 404.487,
              MsTransformOrigin: 404.487,
              transformOrigin: 404.487,
            }}
            id="el56hjeapkunh"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M401 131.72a3.84 3.84 0 00.58 1.52c.8 1.16 2.25 1.25 3.69 0a8.66 8.66 0 002.71-7.38c-.28-1.61-1.17-2.47-2.29-2.42a3.14 3.14 0 00-2 .85 8.69 8.69 0 00-2.69 7.43z"
            style={{
              WebkitTransformOrigin: 404.487,
              MsTransformOrigin: 404.487,
              transformOrigin: 404.487,
            }}
            id="el6m7pmuvbzjo"
            className="animable"
            opacity="0.3"
          />
          <path
            d="M401 131.72a3.84 3.84 0 00.58 1.52 3.24 3.24 0 002-.86 8.71 8.71 0 002.72-7.38 3.73 3.73 0 00-.58-1.51 3.14 3.14 0 00-2 .85 8.69 8.69 0 00-2.72 7.38z"
            style={{
              WebkitTransformOrigin: 403.649,
              MsTransformOrigin: 403.649,
              transformOrigin: 403.649,
            }}
            id="eld575yf6jl9a"
            fill="#455A64"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 385.495,
              MsTransformOrigin: 385.495,
              transformOrigin: 385.495,
            }}
            id="elnyo0cq7zo5"
            fill="#263238"
            d="M398.23 121.09L392.3 117.64 372.76 92.49 377.56 91.51 398.23 121.09z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 378.705,
              MsTransformOrigin: 378.705,
              transformOrigin: 378.705,
            }}
            id="el25n3hgea5nw"
            fill="#37474F"
            d="M398.23 121.09L400.58 118.48 378.45 86.89 356.83 98.76 356.83 103.04 377.19 92.11 398.23 121.09z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 353.41,
              MsTransformOrigin: 353.41,
              transformOrigin: 353.41,
            }}
            id="elko6h15xni7k"
            fill="#263238"
            d="M356.83 103.04L349.99 99.09 349.99 94.81 356.83 98.76 356.83 103.04z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 364.22,
              MsTransformOrigin: 364.22,
              transformOrigin: 364.22,
            }}
            id="elzbuls2cy70d"
            fill="#455A64"
            d="M349.99 94.81L371.6 82.94 378.45 86.89 356.83 98.76 349.99 94.81z"
            className="animable"
          />
          <path
            id="elnw3lkz53ea"
            d="M416.06 323.18v12.7l-27.19-15.69a38 38 0 00-1.46-9.67 46.09 46.09 0 00-1.65-4.8z"
            className="animable"
            style={{
              WebkitTransformOrigin: 400.91,
              MsTransformOrigin: 400.91,
              transformOrigin: 400.91,
            }}
          />
          <path
            d="M449.09 230.82v64.47c-4.75 3.42-8.92 9.14-11.65 15.54l-4.18 2.41v-18l-59.46-31.91v-62.55l12.2-7a3.93 3.93 0 01.74-.33 2 2 0 011.63.14l59.32 34.25h.07l.13.07.26.2.24.25a3.14 3.14 0 01.37.65 2.26 2.26 0 01.15.39l.09.38v.1c0 .03 0 .28.05.42s.04.34.04.52z"
            style={{
              WebkitTransformOrigin: 411.445,
              MsTransformOrigin: 411.445,
              transformOrigin: 411.445,
            }}
            id="elcoce23ifh4g"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M373.79 200.79l59.47 34.32 12.2-7a2.35 2.35 0 012.33-.22L388.4 193.6a2.34 2.34 0 00-2.38.19z"
            style={{
              WebkitTransformOrigin: 410.79,
              MsTransformOrigin: 410.79,
              transformOrigin: 410.79,
            }}
            id="el4jol0wouyge"
            fill="#FFF"
            className="animable"
            opacity="0.3"
          />
          <path
            d="M424.81 316.05a2.39 2.39 0 00.77-1.54q.18-1.23.45-2.46a1.66 1.66 0 00-.09-1.2l-.24-.14c-.23-.14-.26-.68-.05-1.28l1.55-4.49c.23-.66.67-1.16 1-1.1s.78-.42 1.09-1c.38-.78.78-1.55 1.2-2.29a2.13 2.13 0 00.32-1.64 1.72 1.72 0 01.41-1.53l2.69-3.62c.33-.45.72-.66.93-.53l.47.27a1.43 1.43 0 00.69-.5c.53-.54 1.07-1.05 1.63-1.53a2.36 2.36 0 00.82-1.52 1.77 1.77 0 01.84-1.34l3.1-1.79a.64.64 0 01.63-.07l1.53.88a11.5 11.5 0 011.14-.23 1.52 1.52 0 001.1-1 1.07 1.07 0 011.05-.78l2.69.52a.51.51 0 01.17.07l10.84 6.21a.75.75 0 01.28.81l3.31 1.91a.55.55 0 01.22.23l1.55 2.69a1.43 1.43 0 01-.12 1.45 2 2 0 00-.25 1.61 18.38 18.38 0 01.44 1.94l1.1.64a.86.86 0 01.29.77v4.15a1.82 1.82 0 01-.61 1.46 2.34 2.34 0 00-.75 1.54c-.12.82-.27 1.65-.45 2.47a1.68 1.68 0 00.09 1.21l.23.13c.22.13.24.67 0 1.28l-1.55 4.49c-.23.66-.65 1.16-.94 1.1s-.75.43-1.06 1.07c-.38.79-.79 1.58-1.22 2.34a2.14 2.14 0 00-.34 1.61c.17.28 0 .95-.42 1.49l-2.69 3.62c-.34.46-.73.68-.93.56l-.41-.23a1.43 1.43 0 00-.77.45 22.63 22.63 0 01-1.68 1.58 2.31 2.31 0 00-.82 1.46 1.71 1.71 0 01-.83 1.27l-3.11 1.79a.68.68 0 01-.64.09l-1.39-.8a10.46 10.46 0 01-1.31.26 1.46 1.46 0 00-1.08.93 1 1 0 01-1 .72L442 340a.62.62 0 01-.19-.07L431 333.72a.73.73 0 01-.29-.81l-3.27-1.91a.72.72 0 01-.24-.25l-1.55-2.69a1.42 1.42 0 01.11-1.43 2.05 2.05 0 00.24-1.6 18.21 18.21 0 01-.44-2l-1.08-.62a.89.89 0 01-.29-.78v-4.15a1.86 1.86 0 01.62-1.43z"
            style={{
              WebkitTransformOrigin: 446.275,
              MsTransformOrigin: 446.275,
              transformOrigin: 446.275,
            }}
            id="eli5i98v9dnn"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M435 327.89v-4.15a1.86 1.86 0 01.62-1.47 2.39 2.39 0 00.77-1.54q.18-1.23.45-2.46c.14-.66 0-1.26-.26-1.31s-.35-.65-.12-1.31l1.55-4.49c.24-.67.67-1.16 1-1.1s.78-.42 1.09-1.06.78-1.54 1.2-2.28a2 2 0 00.32-1.64 1.75 1.75 0 01.41-1.53l2.69-3.62c.4-.54.87-.73 1-.43s.67.17 1.11-.27 1.07-1.05 1.63-1.53a2.39 2.39 0 00.82-1.52 1.77 1.77 0 01.84-1.34l3.11-1.79c.46-.27.83-.1.83.37a.58.58 0 00.82.57 13.11 13.11 0 011.64-.36 1.52 1.52 0 001.1-1 1 1 0 011-.78l2.69.52c.4.08.58.55.4 1.05a1.11 1.11 0 00.32 1.27 8.08 8.08 0 011.21.9.73.73 0 001.09-.2c.3-.4.73-.41 1 0l1.55 2.69a1.43 1.43 0 01-.12 1.45 2 2 0 00-.25 1.61 18.38 18.38 0 01.44 1.94c.1.55.43.85.77.66s.62.14.62.75v4.15a1.82 1.82 0 01-.61 1.46 2.39 2.39 0 00-.76 1.54c-.11.82-.26 1.65-.44 2.47-.14.67 0 1.26.25 1.32s.33.64.1 1.3l-1.55 4.49c-.23.66-.65 1.16-.94 1.1s-.75.43-1.06 1.07c-.38.79-.79 1.58-1.22 2.34a2.14 2.14 0 00-.34 1.61c.17.28 0 .95-.42 1.49l-2.69 3.62c-.4.54-.86.75-1 .47s-.65-.13-1.09.31a22.63 22.63 0 01-1.68 1.58 2.31 2.31 0 00-.82 1.46 1.71 1.71 0 01-.83 1.27l-3.11 1.79c-.46.27-.84.13-.84-.3s-.37-.65-.82-.51a10.53 10.53 0 01-1.68.36 1.46 1.46 0 00-1.08.93 1 1 0 01-1 .72L442 340a.71.71 0 01-.43-1 1 1 0 00-.34-1.22 8.94 8.94 0 01-1.22-.93.7.7 0 00-1.01.15.52.52 0 01-.94 0l-1.55-2.69a1.42 1.42 0 01.11-1.43 2 2 0 00.24-1.6c-.17-.62-.32-1.27-.44-2s-.42-.86-.76-.66-.66-.12-.66-.73z"
            style={{
              WebkitTransformOrigin: 451.67,
              MsTransformOrigin: 451.67,
              transformOrigin: 451.67,
            }}
            id="el5u1lxnfl2aa"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M444.59 287.63l9.26 5.32a.48.48 0 01.2.44.58.58 0 00.82.57l.52-.14c.38-.09.75-.17 1.12-.22a1.24 1.24 0 00.67-.36l-10.83-6.22a1.23 1.23 0 01-.68.36c-.33.09-.67.16-1.08.25z"
            style={{
              WebkitTransformOrigin: 450.885,
              MsTransformOrigin: 450.885,
              transformOrigin: 450.885,
            }}
            id="elwzv5dzkzwo"
            fill="#263238"
            className="animable"
          />
          <path
            id="elk1uilswtyp"
            d="M438.15 290.9l10.85 6.22a2 2 0 00.33-.95 1.52 1.52 0 01.23-.73l-10.84-6.21a1.43 1.43 0 00-.24.73 1.91 1.91 0 01-.33.94z"
            className="animable"
            style={{
              WebkitTransformOrigin: 443.855,
              MsTransformOrigin: 443.855,
              transformOrigin: 443.855,
            }}
          />
          <path
            d="M435.28 293.44l10.31 5.93h.06a.29.29 0 01.11.11.33.33 0 00.37.15 1.41 1.41 0 00.74-.43c.53-.54 1.08-1.05 1.63-1.53a2.55 2.55 0 00.5-.58l-10.85-6.22a2.79 2.79 0 01-.49.58c-.56.47-1.1 1-1.64 1.53a1.29 1.29 0 01-.74.46z"
            style={{
              WebkitTransformOrigin: 442.14,
              MsTransformOrigin: 442.14,
              transformOrigin: 442.14,
            }}
            id="eltlscu1ysvs"
            fill="#263238"
            className="animable"
          />
          <path
            d="M427.81 304l10.84 6.21a.44.44 0 01.36-.13c.3.06.78-.42 1.09-1.05s.78-1.55 1.2-2.3a2.06 2.06 0 00.32-1.63.64.64 0 01-.06-.4l-10.84-6.22a.63.63 0 00.06.4 2.09 2.09 0 01-.32 1.64c-.43.74-.82 1.5-1.2 2.28s-.79 1.12-1.09 1.06a.48.48 0 00-.36.14z"
            style={{
              WebkitTransformOrigin: 434.74,
              MsTransformOrigin: 434.74,
              transformOrigin: 434.74,
            }}
            id="el93vkhmfibbw"
            fill="#263238"
            className="animable"
          />
          <path
            id="eljz5ffb4dal"
            d="M438.65 310.19L427.81 304a.48.48 0 01.36-.14.45.45 0 00.16 0l10.84 6.14a.31.31 0 01-.16 0 .44.44 0 00-.36.19z"
            className="animable"
            style={{
              WebkitTransformOrigin: 433.49,
              MsTransformOrigin: 433.49,
              transformOrigin: 433.49,
            }}
          />
          <path
            id="elc6zx0iu4r78"
            d="M430.78 298.86a.63.63 0 01-.06-.4l10.84 6.22a.64.64 0 00.06.4 1 1 0 01.05.57l-10.84-6.22a1 1 0 00-.05-.57z"
            className="animable"
            style={{
              WebkitTransformOrigin: 436.2,
              MsTransformOrigin: 436.2,
              transformOrigin: 436.2,
            }}
          />
          <path
            d="M424.4 316.56l10.86 6.21a1.08 1.08 0 01.39-.5 2.35 2.35 0 00.77-1.54c.12-.82.27-1.63.45-2.46a1.54 1.54 0 00-.11-1.22l-.14-.08-.12-.07-10.55-6a1.61 1.61 0 01.09 1.2c-.18.82-.33 1.65-.45 2.46a2.37 2.37 0 01-.77 1.54 1.21 1.21 0 00-.42.46z"
            style={{
              WebkitTransformOrigin: 430.674,
              MsTransformOrigin: 430.674,
              transformOrigin: 430.674,
            }}
            id="elfc4qlwiq6w"
            fill="#263238"
            className="animable"
          />
          <path
            id="elj1oxudzvtd"
            d="M424.81 316.05a1.42 1.42 0 00.46-.56l10.84 6.21a1.37 1.37 0 01-.46.57 1.08 1.08 0 00-.39.5l-10.86-6.21a1.21 1.21 0 01.41-.51z"
            className="animable"
            style={{
              WebkitTransformOrigin: 430.255,
              MsTransformOrigin: 430.255,
              transformOrigin: 430.255,
            }}
          />
          <path
            d="M425.53 327.2l10.84 6.22a1.24 1.24 0 01.23-.55 1.77 1.77 0 00.28-.76 1.82 1.82 0 000-.84 18.21 18.21 0 01-.44-2c-.1-.54-.42-.84-.76-.65a.3.3 0 01-.36 0l-9.72-5.57c.12.69.27 1.35.44 2a2 2 0 01-.25 1.6 1.23 1.23 0 00-.26.55z"
            style={{
              WebkitTransformOrigin: 431.23,
              MsTransformOrigin: 431.23,
              transformOrigin: 431.23,
            }}
            id="elzp51cdw5gqd"
            fill="#263238"
            className="animable"
          />
          <path
            id="elhut3tbzrest"
            d="M436.6 332.87a1.24 1.24 0 00-.23.55l-10.84-6.22a1.23 1.23 0 01.22-.55 1.52 1.52 0 00.25-.65l10.84 6.21a1.73 1.73 0 01-.24.66z"
            className="animable"
            style={{
              WebkitTransformOrigin: 431.185,
              MsTransformOrigin: 431.185,
              transformOrigin: 431.185,
            }}
          />
          <path
            d="M462.13 294.69a8.62 8.62 0 011.2.91.73.73 0 001.09-.21.56.56 0 01.71-.25l-3.23-1.86a1.05 1.05 0 00-.05.19 1.07 1.07 0 00.28 1.22z"
            style={{
              WebkitTransformOrigin: 463.448,
              MsTransformOrigin: 463.448,
              transformOrigin: 463.448,
            }}
            id="ela4u38zk0yyo"
            fill="#263238"
            className="animable"
          />
          <path
            id="elyrryibbt1v"
            d="M431 333.71a1.52 1.52 0 01.2.11l10.48 6 .09.05a.78.78 0 01-.22-.91 1 1 0 00-.33-1.22 7.65 7.65 0 01-1.23-.93.69.69 0 00-.99.19.56.56 0 01-.61.27l-.12-.07-7.53-4.3a.71.71 0 00.26.81z"
            className="animable"
            style={{
              WebkitTransformOrigin: 436.236,
              MsTransformOrigin: 436.236,
              transformOrigin: 436.236,
            }}
          />
          <path
            d="M448.13 338.61l1.38.8a.41.41 0 01-.18-.39c0-.43-.38-.65-.83-.52z"
            style={{
              WebkitTransformOrigin: 448.82,
              MsTransformOrigin: 448.82,
              transformOrigin: 448.82,
            }}
            id="elj95afp6smil"
            fill="#263238"
            className="animable"
          />
          <path
            d="M457.39 332.77l.32.18a.26.26 0 00-.32-.18z"
            style={{
              WebkitTransformOrigin: 457.55,
              MsTransformOrigin: 457.55,
              transformOrigin: 457.55,
            }}
            id="elny47cil3eyc"
            fill="#263238"
            className="animable"
          />
          <path
            d="M467 303.07c.09.55.43.85.76.65a.36.36 0 01.28 0l-.09-.05-.15-.09z"
            style={{
              WebkitTransformOrigin: 467.52,
              MsTransformOrigin: 467.52,
              transformOrigin: 467.52,
            }}
            id="elgd54u1ghtub"
            fill="#263238"
            className="animable"
          />
          <path
            id="ele263fhuulv"
            d="M446.4 287.05l10.83 6.22a1.53 1.53 0 00.44-.64 1.27 1.27 0 01.46-.62l-10.85-6.21a1.19 1.19 0 00-.46.62 1.66 1.66 0 01-.42.63z"
            className="animable"
            style={{
              WebkitTransformOrigin: 452.265,
              MsTransformOrigin: 452.265,
              transformOrigin: 452.265,
            }}
          />
          <path
            d="M427.93 321.77c0 6.73 2.25 11.47 5.78 13.51l2.39 1.37c-3.48-2.07-5.68-6.79-5.68-13.46 0-12.29 7.47-26.58 16.69-31.9 4-2.3 7.64-2.55 10.51-1.11l-2.05-1.18c-2.94-1.71-6.76-1.55-10.94.87-9.22 5.32-16.7 19.61-16.7 31.9z"
            style={{
              WebkitTransformOrigin: 442.775,
              MsTransformOrigin: 442.775,
              transformOrigin: 442.775,
            }}
            id="eljr9ytcp2p1r"
            fill="#37474F"
            className="animable"
          />
          <path
            id="elr96c8yy3cie"
            d="M430.42 323.2c0 6.67 2.2 11.38 5.68 13.45l.73.38c-3.48-2.06-4.8-6.23-4.8-12.9 0-12.29 7.48-26.58 16.69-31.9 4.13-2.39 7-3.11 9.94-1.48l-1-.57c-2.87-1.44-6.53-1.19-10.51 1.11-9.26 5.32-16.73 19.61-16.73 31.91z"
            className="animable"
            style={{
              WebkitTransformOrigin: 444.54,
              MsTransformOrigin: 444.54,
              transformOrigin: 444.54,
            }}
          />
          <path
            d="M447.28 285.8l10.85 6.2a.75.75 0 01.58-.16l1.11.21 1.58.31a.38.38 0 01.1 0l-.17-.11-9.85-5.64-.23-.13-.23-.14-.09-.05-.12-.07h-.25l-2.7-.51a.72.72 0 00-.58.09z"
            style={{
              WebkitTransformOrigin: 454.39,
              MsTransformOrigin: 454.39,
              transformOrigin: 454.39,
            }}
            id="el6sstbvtqnew"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M438.72 289.23l10.84 6.21a1.81 1.81 0 01.6-.6l3.11-1.79a.65.65 0 01.58-.1l-9.26-5.32-1-.56-.35-.21-.19-.11a.68.68 0 00-.63.08l-3.1 1.8a1.72 1.72 0 00-.6.6z"
            style={{
              WebkitTransformOrigin: 446.285,
              MsTransformOrigin: 446.285,
              transformOrigin: 446.285,
            }}
            id="el5tedwsdij5a"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M430.72 298.46l10.84 6.22a2.47 2.47 0 01.47-1.13l2.69-3.62c.3-.41.65-.62.87-.56l-10.31-5.93-.46-.26c-.23-.13-.6.07-.94.52l-2.7 3.64a2.35 2.35 0 00-.46 1.12z"
            style={{
              WebkitTransformOrigin: 438.155,
              MsTransformOrigin: 438.155,
              transformOrigin: 438.155,
            }}
            id="eljvqdx5fzo1s"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M425.67 310.69l.07.05.2.11 10.55 6a1.4 1.4 0 010-1.24l1.55-4.49a2.22 2.22 0 01.61-1L427.81 304a2.1 2.1 0 00-.6 1l-1.56 4.48c-.2.52-.19 1.05.02 1.21z"
            style={{
              WebkitTransformOrigin: 432.078,
              MsTransformOrigin: 432.078,
              transformOrigin: 432.078,
            }}
            id="elucl1yaw4ilc"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M424.19 317.53v4.14a.89.89 0 00.29.78l1.08.62 9.72 5.57a1 1 0 01-.24-.75v-4.15a2.18 2.18 0 01.22-1l-10.86-6.21a2.34 2.34 0 00-.21 1z"
            style={{
              WebkitTransformOrigin: 429.731,
              MsTransformOrigin: 429.731,
              transformOrigin: 429.731,
            }}
            id="elpyuoh5rol"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M425.65 328.08l1.55 2.69a.58.58 0 00.24.25l.73.42.27.15 2.28 1.32 7.53 4.3a.74.74 0 01-.25-.21l-1.55-2.69a1.37 1.37 0 01-.12-.88l-10.84-6.22a1.3 1.3 0 00.16.87z"
            style={{
              WebkitTransformOrigin: 431.861,
              MsTransformOrigin: 431.861,
              transformOrigin: 431.861,
            }}
            id="elcll0uxvxk1h"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M442.42 321.55c0-6.84 4.16-14.79 9.29-17.75s9.29.18 9.29 7-4.16 14.79-9.29 17.75-9.29-.16-9.29-7z"
            style={{
              WebkitTransformOrigin: 451.71,
              MsTransformOrigin: 451.71,
              transformOrigin: 451.71,
            }}
            id="elxcfybxg9k29"
            fill="#37474F"
            className="animable"
          />
          <ellipse
            id="elohka86dgjbl"
            cx="451.71"
            cy="316.18"
            fill="#EF8C2D"
            rx="11.56"
            ry="6.54"
            style={{
              WebkitTransformOrigin: 451.71,
              MsTransformOrigin: 451.71,
              transformOrigin: 451.71,
            }}
            className="animable"
            transform="rotate(-66.95)"
          />
          <path
            d="M446.43 319.23c0-3.88 2.37-8.4 5.28-10.09s5.28.11 5.28 4-2.36 8.4-5.28 10.09-5.28-.11-5.28-4z"
            style={{
              WebkitTransformOrigin: 451.71,
              MsTransformOrigin: 451.71,
              transformOrigin: 451.71,
            }}
            id="el1vcgwism4ufh"
            fill="#263238"
            className="animable"
          />
          <path
            d="M448.68 317.94a7.28 7.28 0 013-5.8c1.68-1 3 .06 3 2.29a7.26 7.26 0 01-3 5.8c-1.68.97-3-.06-3-2.29z"
            style={{
              WebkitTransformOrigin: 451.68,
              MsTransformOrigin: 451.68,
              transformOrigin: 451.68,
            }}
            id="el2ukw35lzhr"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M451.38 313.64a.81.81 0 01.33-.63c.18-.1.33 0 .33.25a.79.79 0 01-.33.63c-.18.11-.33 0-.33-.25z"
            style={{
              WebkitTransformOrigin: 451.71,
              MsTransformOrigin: 451.71,
              transformOrigin: 451.71,
            }}
            id="elbxnkhz28mr6"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M450 315.54c-.13-.1-.13-.38 0-.63s.34-.36.47-.27.13.38 0 .63-.31.36-.47.27z"
            style={{
              WebkitTransformOrigin: 450.235,
              MsTransformOrigin: 450.235,
              transformOrigin: 450.235,
            }}
            id="elc5nfcecyi9v"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M449.66 317.81c-.18.1-.33 0-.33-.25a.79.79 0 01.33-.63c.18-.11.33 0 .33.25a.81.81 0 01-.33.63z"
            style={{
              WebkitTransformOrigin: 449.66,
              MsTransformOrigin: 449.66,
              transformOrigin: 449.66,
            }}
            id="el5mpqjpjqd8"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M450.5 319.13c-.13.25-.34.37-.47.27s-.13-.38 0-.62.34-.37.47-.27.13.37 0 .62z"
            style={{
              WebkitTransformOrigin: 450.265,
              MsTransformOrigin: 450.265,
              transformOrigin: 450.265,
            }}
            id="elvlly00d098"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M452 318.73a.81.81 0 01-.33.63c-.18.1-.33 0-.33-.25a.79.79 0 01.33-.63c.22-.11.33 0 .33.25z"
            style={{
              WebkitTransformOrigin: 451.67,
              MsTransformOrigin: 451.67,
              transformOrigin: 451.67,
            }}
            id="el1d6h1dozlc0i"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M453.4 316.83c.12.1.12.38 0 .63s-.34.36-.47.27-.13-.38 0-.63.34-.36.47-.27z"
            style={{
              WebkitTransformOrigin: 453.161,
              MsTransformOrigin: 453.161,
              transformOrigin: 453.161,
            }}
            id="eljtz2pq8801"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M453.76 314.56c.18-.1.33 0 .33.25a.79.79 0 01-.33.63c-.18.11-.33 0-.33-.25a.81.81 0 01.33-.63z"
            style={{
              WebkitTransformOrigin: 453.76,
              MsTransformOrigin: 453.76,
              transformOrigin: 453.76,
            }}
            id="el24x4cfdzjto"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M452.93 313.24c.13-.25.34-.37.47-.27s.12.38 0 .62-.34.37-.47.27-.13-.37 0-.62z"
            style={{
              WebkitTransformOrigin: 453.163,
              MsTransformOrigin: 453.163,
              transformOrigin: 453.163,
            }}
            id="elhn0pfbp02ka"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M450.49 316.89a2.92 2.92 0 011.22-2.34c.68-.39 1.23 0 1.23.93a2.93 2.93 0 01-1.23 2.34c-.71.39-1.22-.02-1.22-.93z"
            style={{
              WebkitTransformOrigin: 451.715,
              MsTransformOrigin: 451.715,
              transformOrigin: 451.715,
            }}
            id="elkqi15o2ob7r"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M437.44 310.83a44.22 44.22 0 00-1.77 5 37.42 37.42 0 00-1.58 9.68l-18 10.42v-12.75l17.21-9.94z"
            style={{
              WebkitTransformOrigin: 426.765,
              MsTransformOrigin: 426.765,
              transformOrigin: 426.765,
            }}
            id="elzxqxiskwf9"
            fill="#263238"
            className="animable"
          />
          <path
            className="animable"
            style={{
              WebkitTransformOrigin: 370.06,
              MsTransformOrigin: 370.06,
              transformOrigin: 370.06,
            }}
            id="elby6mgevd9pa"
            d="M367.17 323.51H372.95V329.28999999999996H367.17z"
            transform="rotate(180)"
          />
          <path
            id="elm3sxow0rald"
            d="M437.44 310.83a44.22 44.22 0 00-1.77 5l-19.62 11.27v-3.92l17.21-9.94z"
            className="animable"
            style={{
              WebkitTransformOrigin: 426.745,
              MsTransformOrigin: 426.745,
              transformOrigin: 426.745,
            }}
          />
          <path
            d="M449.09 230.82v64.47c-4.75 3.42-8.92 9.14-11.65 15.54l-4.18 2.41v-78.13l12.19-7.05a2.59 2.59 0 011.91-.37 1.6 1.6 0 01.33.11h.07l.13.07.26.2.24.25a2.75 2.75 0 01.37.65 2.26 2.26 0 01.15.39l.09.38v.1c0 .03 0 .28.05.42s.04.38.04.56z"
            style={{
              WebkitTransformOrigin: 441.175,
              MsTransformOrigin: 441.175,
              transformOrigin: 441.175,
            }}
            id="el9q3euq9h22c"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M449.09 230.82v64.47c-4.75 3.42-8.92 9.14-11.65 15.54l-4.18 2.41v-78.13l12.19-7.05a2.59 2.59 0 011.91-.37 1.6 1.6 0 01.33.11h.07l.13.07.26.2.24.25a2.75 2.75 0 01.37.65 2.26 2.26 0 01.15.39l.09.38v.1c0 .03 0 .28.05.42s.04.38.04.56z"
            style={{
              WebkitTransformOrigin: 441.175,
              MsTransformOrigin: 441.175,
              transformOrigin: 441.175,
            }}
            id="elf0txosa09m"
            className="animable"
            opacity="0.2"
          />
          <path
            style={{
              WebkitTransformOrigin: 432.51,
              MsTransformOrigin: 432.51,
              transformOrigin: 432.51,
            }}
            id="elmebbjtumes"
            fill="#37474F"
            d="M431.76 235.97L433.26 235.11 433.26 313.25 431.76 314.11 431.76 235.97z"
            className="animable"
          />
          <path
            d="M436.32 285.84v-46.18a3.47 3.47 0 011.45-2.77l7-4c.8-.46 1.45 0 1.45 1.1v46.18a3.49 3.49 0 01-1.45 2.78l-7 4c-.77.46-1.45-.04-1.45-1.11z"
            style={{
              WebkitTransformOrigin: 441.27,
              MsTransformOrigin: 441.27,
              transformOrigin: 441.27,
            }}
            id="elm7f0m1ly9q"
            fill="#37474F"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 441.175,
              MsTransformOrigin: 441.175,
              transformOrigin: 441.175,
            }}
            id="elivl1gz1dpzr"
            fill="#FFF"
            d="M449.09 285.11L433.26 294.25 433.26 295.21 449.09 286.07 449.09 285.11z"
            className="animable"
            opacity="0.7"
          />
          <path
            style={{
              WebkitTransformOrigin: 409.865,
              MsTransformOrigin: 409.865,
              transformOrigin: 409.865,
            }}
            id="el6nbqo5gpy05"
            fill="#263238"
            d="M416.06 323.18L393.76 310.31 403.67 304.59 425.97 317.46 416.06 323.18z"
            className="animable"
          />
          <path
            d="M314.68 313.66a2.8 2.8 0 00.92-1.84c.14-1 .32-1.95.53-2.92a1.92 1.92 0 00-.1-1.44l-.29-.17c-.27-.16-.31-.8-.06-1.52l1.86-5.35c.27-.79.78-1.38 1.14-1.31s.93-.5 1.3-1.26c.45-.92.93-1.83 1.43-2.72a2.5 2.5 0 00.39-2 2 2 0 01.48-1.81l3.21-4.32c.4-.54.85-.79 1.11-.64l.56.32a1.65 1.65 0 00.89-.52c.63-.64 1.28-1.25 1.94-1.82a2.79 2.79 0 001-1.81 2.09 2.09 0 011-1.59l3.7-2.14a.78.78 0 01.75-.09l1.82 1.05c.46-.11.91-.2 1.35-.26a1.86 1.86 0 001.32-1.2 1.26 1.26 0 011.24-.93l3.21.62a.66.66 0 01.21.08l12.91 7.41a.88.88 0 01.34 1l3.94 2.27a.71.71 0 01.26.28l1.85 3.21a1.7 1.7 0 01-.14 1.72 2.43 2.43 0 00-.31 1.92 20.18 20.18 0 01.54 2.32l1.31.75a1.07 1.07 0 01.34.93v4.93a2.16 2.16 0 01-.73 1.75 2.8 2.8 0 00-.9 1.83c-.14 1-.31 2-.52 2.95a2 2 0 00.09 1.43l.28.16c.27.16.3.8 0 1.53L363 319.8c-.27.8-.77 1.39-1.12 1.32s-.89.51-1.26 1.27c-.46.95-.94 1.88-1.45 2.79a2.52 2.52 0 00-.41 1.91c.2.34 0 1.14-.5 1.78l-3.21 4.32c-.4.54-.86.8-1.11.66l-.48-.28a1.74 1.74 0 00-.91.55c-.66.66-1.32 1.29-2 1.88a2.78 2.78 0 00-1 1.74 2 2 0 01-1 1.51l-3.7 2.13a.81.81 0 01-.77.11l-1.65-.95a13 13 0 01-1.56.31 1.75 1.75 0 00-1.29 1.11 1.19 1.19 0 01-1.22.85l-3.2-.62a.53.53 0 01-.22-.08l-12.91-7.4a.87.87 0 01-.34-1l-3.92-2.26a.87.87 0 01-.28-.29l-1.81-3.16a1.72 1.72 0 01.13-1.71 2.4 2.4 0 00.29-1.9 22.36 22.36 0 01-.52-2.34l-1.28-.74a1.05 1.05 0 01-.34-.93v-4.94a2.21 2.21 0 01.72-1.78z"
            style={{
              WebkitTransformOrigin: 340.294,
              MsTransformOrigin: 340.294,
              transformOrigin: 340.294,
            }}
            id="eltowh51o1d2c"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M326.86 327.76v-4.94a2.18 2.18 0 01.74-1.75 2.84 2.84 0 00.92-1.84c.14-1 .32-2 .53-2.92.18-.8.05-1.5-.3-1.57s-.42-.77-.15-1.56l1.85-5.35c.28-.79.79-1.38 1.15-1.31s.93-.5 1.3-1.26c.45-.93.93-1.84 1.43-2.72a2.5 2.5 0 00.38-2 2.06 2.06 0 01.49-1.81l3.2-4.32c.48-.65 1-.87 1.25-.51s.79.2 1.32-.33 1.28-1.25 1.94-1.82a2.79 2.79 0 001-1.81 2.09 2.09 0 011-1.59l3.7-2.14c.55-.32 1-.12 1 .44a.69.69 0 001 .68 13.39 13.39 0 011.95-.42 1.87 1.87 0 001.32-1.2 1.26 1.26 0 011.24-.93l3.21.62c.47.09.69.66.48 1.26a1.3 1.3 0 00.38 1.51 10.53 10.53 0 011.44 1.07.85.85 0 001.29-.24c.36-.48.88-.49 1.15 0l1.85 3.21a1.7 1.7 0 01-.14 1.72 2.43 2.43 0 00-.31 1.92 20.18 20.18 0 01.54 2.32c.11.65.51 1 .92.77s.73.17.73.91v4.93a2.16 2.16 0 01-.73 1.75 2.8 2.8 0 00-.9 1.83c-.14 1-.31 2-.52 2.95-.17.79-.06 1.49.29 1.56s.4.76.12 1.56L363 319.8c-.27.8-.77 1.39-1.12 1.32s-.89.51-1.26 1.27c-.46.95-.94 1.88-1.45 2.79a2.52 2.52 0 00-.41 1.91c.2.34 0 1.14-.5 1.78l-3.21 4.32c-.48.64-1 .89-1.21.55s-.77-.15-1.29.38-1.32 1.29-2 1.88a2.78 2.78 0 00-1 1.74 2 2 0 01-1 1.51l-3.7 2.13c-.55.32-1 .16-1-.36s-.44-.76-1-.61a12.64 12.64 0 01-2 .44 1.75 1.75 0 00-1.29 1.11 1.19 1.19 0 01-1.22.85l-3.2-.62c-.48-.09-.71-.62-.51-1.19a1.24 1.24 0 00-.4-1.45 10.23 10.23 0 01-1.46-1.11.83.83 0 00-1.26.19.62.62 0 01-1.12 0l-1.85-3.21a1.7 1.7 0 01.13-1.71 2.44 2.44 0 00.29-1.9 22.33 22.33 0 01-.53-2.34c-.11-.65-.5-1-.9-.78s-.67-.2-.67-.93z"
            style={{
              WebkitTransformOrigin: 346.76,
              MsTransformOrigin: 346.76,
              transformOrigin: 346.76,
            }}
            id="el8zze4m370zx"
            fill="#263238"
            className="animable"
          />
          <path
            d="M338.25 279.8l11 6.33h.06a.62.62 0 01.23.53.7.7 0 001 .69c.21-.07.41-.12.62-.17a10.76 10.76 0 011.33-.26 1.54 1.54 0 00.8-.43l-12.9-7.42a1.54 1.54 0 01-.81.43c-.43.1-.87.19-1.33.3z"
            style={{
              WebkitTransformOrigin: 345.77,
              MsTransformOrigin: 345.77,
              transformOrigin: 345.77,
            }}
            id="el3s3dbo8zhl4"
            fill="#263238"
            className="animable"
          />
          <path
            id="elj9zdqygxjmc"
            d="M330.58 283.69l12.92 7.41a2.29 2.29 0 00.39-1.13 1.7 1.7 0 01.29-.87l-12.92-7.4a1.67 1.67 0 00-.29.87 2.36 2.36 0 01-.39 1.12z"
            className="animable"
            style={{
              WebkitTransformOrigin: 337.38,
              MsTransformOrigin: 337.38,
              transformOrigin: 337.38,
            }}
          />
          <path
            d="M327.16 286.72l12.28 7.06h.08a.27.27 0 01.13.13.38.38 0 00.44.17 1.57 1.57 0 00.88-.51c.63-.64 1.28-1.25 1.95-1.82a2.8 2.8 0 00.58-.69l-12.92-7.41a3.31 3.31 0 01-.59.69c-.66.57-1.31 1.17-1.95 1.82a1.59 1.59 0 01-.88.56z"
            style={{
              WebkitTransformOrigin: 335.33,
              MsTransformOrigin: 335.33,
              transformOrigin: 335.33,
            }}
            id="el3hx56500s7d"
            fill="#263238"
            className="animable"
          />
          <path
            d="M318.25 299.27l12.93 7.4a.54.54 0 01.42-.15c.36.07.93-.5 1.3-1.26.45-.93.93-1.84 1.43-2.73a2.42 2.42 0 00.38-1.95.79.79 0 01-.07-.48l-12.91-7.4a.7.7 0 00.07.47 2.53 2.53 0 01-.38 2c-.51.88-1 1.79-1.43 2.72-.38.76-.94 1.33-1.31 1.26a.56.56 0 00-.43.12z"
            style={{
              WebkitTransformOrigin: 326.512,
              MsTransformOrigin: 326.512,
              transformOrigin: 326.512,
            }}
            id="el6u0vch5ppat"
            fill="#263238"
            className="animable"
          />
          <path
            id="elefx88xbvop"
            d="M331.18 306.67l-12.93-7.4a.56.56 0 01.43-.16.35.35 0 00.2 0l12.92 7.4a.36.36 0 01-.2 0 .54.54 0 00-.42.16z"
            className="animable"
            style={{
              WebkitTransformOrigin: 325.025,
              MsTransformOrigin: 325.025,
              transformOrigin: 325.025,
            }}
          />
          <path
            id="elgc6adm5eb4"
            d="M321.8 293.17a.7.7 0 01-.07-.47l12.91 7.4a.79.79 0 00.07.48 1.15 1.15 0 01.06.69l-12.92-7.42a1.2 1.2 0 00-.05-.68z"
            className="animable"
            style={{
              WebkitTransformOrigin: 328.257,
              MsTransformOrigin: 328.257,
              transformOrigin: 328.257,
            }}
          />
          <path
            d="M314.2 314.26l12.93 7.4a1.26 1.26 0 01.47-.59 2.79 2.79 0 00.91-1.84c.15-1 .33-1.94.54-2.92a1.78 1.78 0 00-.13-1.46l-.17-.1-.15-.09-12.6-7.19a1.9 1.9 0 01.11 1.42c-.22 1-.39 2-.54 2.93a2.84 2.84 0 01-.92 1.84 1.43 1.43 0 00-.45.6z"
            style={{
              WebkitTransformOrigin: 321.675,
              MsTransformOrigin: 321.675,
              transformOrigin: 321.675,
            }}
            id="eljpnvgikw8w"
            fill="#263238"
            className="animable"
          />
          <path
            id="eltofke3j1ls"
            d="M314.68 313.66a1.57 1.57 0 00.55-.68l12.92 7.41a1.7 1.7 0 01-.55.68 1.26 1.26 0 00-.47.59l-12.93-7.4a1.43 1.43 0 01.48-.6z"
            className="animable"
            style={{
              WebkitTransformOrigin: 321.175,
              MsTransformOrigin: 321.175,
              transformOrigin: 321.175,
            }}
          />
          <path
            d="M315.54 326.94l12.91 7.41a1.69 1.69 0 01.28-.66 2 2 0 00.33-.9 2.19 2.19 0 000-1 22.93 22.93 0 01-.53-2.34c-.11-.65-.5-1-.9-.78a.38.38 0 01-.43 0L315.57 322a23.8 23.8 0 00.53 2.34 2.36 2.36 0 01-.29 1.9 1.49 1.49 0 00-.27.7z"
            style={{
              WebkitTransformOrigin: 322.329,
              MsTransformOrigin: 322.329,
              transformOrigin: 322.329,
            }}
            id="el2e3lbcxtrq7"
            fill="#263238"
            className="animable"
          />
          <path
            id="elql4d5f3wycs"
            d="M328.73 333.69a1.69 1.69 0 00-.28.66l-12.91-7.41a1.49 1.49 0 01.27-.66 2 2 0 00.32-.82l12.91 7.4a2 2 0 01-.31.83z"
            className="animable"
            style={{
              WebkitTransformOrigin: 322.29,
              MsTransformOrigin: 322.29,
              transformOrigin: 322.29,
            }}
          />
          <path
            d="M359.15 288.21a9.76 9.76 0 011.43 1.08.87.87 0 001.3-.25.67.67 0 01.84-.3h-.05l-3.85-2.22a1.42 1.42 0 00-.05.22 1.28 1.28 0 00.38 1.47z"
            style={{
              WebkitTransformOrigin: 360.699,
              MsTransformOrigin: 360.699,
              transformOrigin: 360.699,
            }}
            id="elpno1z0n138o"
            fill="#263238"
            className="animable"
          />
          <path
            id="elaf4r6x92bnr"
            d="M322.07 334.7l.24.14 12.53 7.16.11.05a.9.9 0 01-.26-1.08 1.24 1.24 0 00-.4-1.45 9.54 9.54 0 01-1.46-1.11.83.83 0 00-1.26.19.67.67 0 01-.73.32l-.14-.08-9-5.12a.85.85 0 00.37.98z"
            className="animable"
            style={{
              WebkitTransformOrigin: 328.306,
              MsTransformOrigin: 328.306,
              transformOrigin: 328.306,
            }}
          />
          <path
            d="M342.47 340.54l1.65.95a.48.48 0 01-.23-.46c0-.52-.45-.78-1-.62z"
            style={{
              WebkitTransformOrigin: 343.295,
              MsTransformOrigin: 343.295,
              transformOrigin: 343.295,
            }}
            id="el3oklyqi2q36"
            fill="#263238"
            className="animable"
          />
          <path
            d="M353.5 333.58l.38.21v-.05a.33.33 0 00-.38-.16z"
            style={{
              WebkitTransformOrigin: 353.69,
              MsTransformOrigin: 353.69,
              transformOrigin: 353.69,
            }}
            id="elckt236hs4b5"
            fill="#263238"
            className="animable"
          />
          <path
            d="M365 298.2c.11.65.51 1 .91.77a.43.43 0 01.34-.05l-.11-.06-.19-.11z"
            style={{
              WebkitTransformOrigin: 365.625,
              MsTransformOrigin: 365.625,
              transformOrigin: 365.625,
            }}
            id="eloscld2fmjpg"
            fill="#263238"
            className="animable"
          />
          <path
            id="elgxbuzk62pqb"
            d="M340.41 279.1l12.9 7.42a1.88 1.88 0 00.52-.77 1.43 1.43 0 01.55-.73l-12.92-7.41a1.5 1.5 0 00-.55.74 2 2 0 01-.5.75z"
            className="animable"
            style={{
              WebkitTransformOrigin: 347.395,
              MsTransformOrigin: 347.395,
              transformOrigin: 347.395,
            }}
          />
          <path
            d="M318.4 320.47c0 8 2.68 13.67 6.89 16.1l2.85 1.63c-4.15-2.46-6.77-8.08-6.77-16 0-14.65 8.89-31.67 19.89-38 4.74-2.74 9.09-3 12.51-1.32l-2.44-1.41c-3.5-2-8.05-1.84-13 1-11.02 6.33-19.93 23.36-19.93 38z"
            style={{
              WebkitTransformOrigin: 336.085,
              MsTransformOrigin: 336.085,
              transformOrigin: 336.085,
            }}
            id="elpkkhbiqtrnf"
            fill="#37474F"
            className="animable"
          />
          <path
            id="elixcjls6ez8h"
            d="M321.37 322.17c0 7.95 2.62 13.57 6.77 16l.86.45c-4.14-2.46-5.72-7.43-5.72-15.37 0-14.65 8.91-31.67 19.9-38 4.91-2.84 8.36-3.7 11.84-1.76l-1.25-.68c-3.42-1.71-7.77-1.42-12.51 1.33-11 6.36-19.89 23.39-19.89 38.03z"
            className="animable"
            style={{
              WebkitTransformOrigin: 338.195,
              MsTransformOrigin: 338.195,
              transformOrigin: 338.195,
            }}
          />
          <path
            d="M341.46 277.61l12.92 7.39a.88.88 0 01.69-.2l1.33.26 1.88.36h.12l-.2-.13-11.74-6.73-.27-.15-.28-.16-.11-.06-.14-.09-.09-.05h-.1a.45.45 0 00-.11 0l-3.21-.62a.87.87 0 00-.69.18z"
            style={{
              WebkitTransformOrigin: 349.93,
              MsTransformOrigin: 349.93,
              transformOrigin: 349.93,
            }}
            id="eldbn6lfbqqdn"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M331.26 281.7l12.92 7.4a2.07 2.07 0 01.7-.71l3.71-2.14a.83.83 0 01.7-.12l-11-6.33-1.19-.68-.42-.24-.23-.13a.76.76 0 00-.74.09L332 281a2.13 2.13 0 00-.74.7z"
            style={{
              WebkitTransformOrigin: 340.275,
              MsTransformOrigin: 340.275,
              transformOrigin: 340.275,
            }}
            id="el472iz7uyv0y"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M321.73 292.7l12.91 7.4a3 3 0 01.56-1.34l3.2-4.31c.36-.48.78-.74 1-.67l-12.28-7.06-.55-.31c-.27-.16-.72.08-1.12.62l-3.21 4.33a2.66 2.66 0 00-.51 1.34z"
            style={{
              WebkitTransformOrigin: 330.565,
              MsTransformOrigin: 330.565,
              transformOrigin: 330.565,
            }}
            id="elvti3xujpfmo"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M315.71 307.27l.09.06.23.14 12.57 7.18a1.7 1.7 0 010-1.48l1.85-5.34a2.67 2.67 0 01.73-1.16l-12.93-7.4a2.88 2.88 0 00-.71 1.16l-1.86 5.34c-.24.69-.22 1.31.03 1.5z"
            style={{
              WebkitTransformOrigin: 323.345,
              MsTransformOrigin: 323.345,
              transformOrigin: 323.345,
            }}
            id="elnzibvrfzkp"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M314 315.41v4.94c0 .48.13.81.34.93l1.28.74 11.59 6.64a1.17 1.17 0 01-.29-.9v-4.94a2.77 2.77 0 01.26-1.15l-12.93-7.4a2.73 2.73 0 00-.25 1.14z"
            style={{
              WebkitTransformOrigin: 320.605,
              MsTransformOrigin: 320.605,
              transformOrigin: 320.605,
            }}
            id="el3202nzapt3k"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M315.68 328l1.85 3.21a.87.87 0 00.28.29l.87.5.33.19 2.72 1.57 9 5.12a.7.7 0 01-.25-.26l-1.85-3.21a1.76 1.76 0 01-.15-1L315.57 327a1.55 1.55 0 00.11 1z"
            style={{
              WebkitTransformOrigin: 323.131,
              MsTransformOrigin: 323.131,
              transformOrigin: 323.131,
            }}
            id="el2pphk26i4oc"
            fill="#37474F"
            className="animable"
          />
          <ellipse
            id="el5mefoip0e0o"
            cx="346.74"
            cy="313.82"
            className="animable"
            rx="16.99"
            ry="9.61"
            style={{
              WebkitTransformOrigin: 346.74,
              MsTransformOrigin: 346.74,
              transformOrigin: 346.74,
            }}
            transform="rotate(-66.95)"
          />
          <ellipse
            id="elg3hj3vqxy49"
            cx="346.74"
            cy="313.82"
            fill="#EF8C2D"
            rx="13.77"
            ry="7.79"
            style={{
              WebkitTransformOrigin: 346.74,
              MsTransformOrigin: 346.74,
              transformOrigin: 346.74,
            }}
            className="animable"
            transform="rotate(-66.95)"
          />
          <path
            d="M340.45 317.45c0-4.63 2.81-10 6.29-12s6.29.12 6.29 4.75-2.82 10-6.29 12-6.29-.12-6.29-4.75z"
            style={{
              WebkitTransformOrigin: 346.74,
              MsTransformOrigin: 346.74,
              transformOrigin: 346.74,
            }}
            id="eldimkk3kvdcs"
            fill="#263238"
            className="animable"
          />
          <path
            d="M343.12 315.91c0-2.67 1.62-5.76 3.62-6.92s3.62.08 3.62 2.74-1.62 5.76-3.62 6.91-3.62-.07-3.62-2.73z"
            style={{
              WebkitTransformOrigin: 346.74,
              MsTransformOrigin: 346.74,
              transformOrigin: 346.74,
            }}
            id="el7fxuop6xn48"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M346.34 310.79a.93.93 0 01.4-.75c.21-.13.39 0 .39.29a1 1 0 01-.39.76c-.22.12-.4-.01-.4-.3z"
            style={{
              WebkitTransformOrigin: 346.735,
              MsTransformOrigin: 346.735,
              transformOrigin: 346.735,
            }}
            id="el66yslzux7ks"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M344.73 313c-.15-.11-.15-.44 0-.74s.41-.44.56-.32.15.45 0 .74-.4.48-.56.32z"
            style={{
              WebkitTransformOrigin: 345.01,
              MsTransformOrigin: 345.01,
              transformOrigin: 345.01,
            }}
            id="el9uadquzmrpb"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M344.3 315.75c-.22.13-.4 0-.4-.3a1 1 0 01.4-.75c.21-.12.39 0 .39.3a1 1 0 01-.39.75z"
            style={{
              WebkitTransformOrigin: 344.295,
              MsTransformOrigin: 344.295,
              transformOrigin: 344.295,
            }}
            id="elztorhp02pz"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M345.29 317.33c-.15.29-.4.43-.56.32s-.15-.45 0-.74.41-.44.56-.32.15.41 0 .74z"
            style={{
              WebkitTransformOrigin: 345.008,
              MsTransformOrigin: 345.008,
              transformOrigin: 345.008,
            }}
            id="eleelrtym1dxd"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M347.13 316.85a1 1 0 01-.39.75c-.22.12-.4 0-.4-.3a.93.93 0 01.4-.75c.26-.13.39.01.39.3z"
            style={{
              WebkitTransformOrigin: 346.735,
              MsTransformOrigin: 346.735,
              transformOrigin: 346.735,
            }}
            id="el93u2z4kdqje"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M348.74 314.59c.16.12.16.45 0 .74s-.4.44-.55.32-.16-.44 0-.74.4-.43.55-.32z"
            style={{
              WebkitTransformOrigin: 348.467,
              MsTransformOrigin: 348.467,
              transformOrigin: 348.467,
            }}
            id="ely463m68ahng"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M349.18 311.88c.22-.12.39 0 .39.3a.92.92 0 01-.39.75c-.22.13-.39 0-.39-.29a1 1 0 01.39-.76z"
            style={{
              WebkitTransformOrigin: 349.18,
              MsTransformOrigin: 349.18,
              transformOrigin: 349.18,
            }}
            id="elsppvwy75mml"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M348.19 310.31c.15-.3.4-.44.55-.32s.16.45 0 .74-.4.44-.55.32-.19-.45 0-.74z"
            style={{
              WebkitTransformOrigin: 348.459,
              MsTransformOrigin: 348.459,
              transformOrigin: 348.459,
            }}
            id="elrvtoipim6hs"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M345.28 314.66a3.5 3.5 0 011.46-2.79c.8-.47 1.46 0 1.46 1.1a3.53 3.53 0 01-1.46 2.8c-.81.46-1.46-.03-1.46-1.11z"
            style={{
              WebkitTransformOrigin: 346.74,
              MsTransformOrigin: 346.74,
              transformOrigin: 346.74,
            }}
            id="elj63canccda"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M371.15 326.37c-.73-.42-1.32-.07-1.32.77v17.07l-34.69-20.08v-17a2.91 2.91 0 00-1.32-2.28z"
            style={{
              WebkitTransformOrigin: 352.485,
              MsTransformOrigin: 352.485,
              transformOrigin: 352.485,
            }}
            id="elpfigfd5i9f"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M371.15 326.37c-.73-.42-1.32-.07-1.32.77v17.07l-34.69-20.08v-17a2.91 2.91 0 00-1.32-2.28z"
            style={{
              WebkitTransformOrigin: 352.485,
              MsTransformOrigin: 352.485,
              transformOrigin: 352.485,
            }}
            id="elqzmmk5blwh9"
            className="animable"
            opacity="0.1"
          />
          <path
            d="M364.87 343.42a2.8 2.8 0 00.92-1.84c.14-1 .32-1.95.54-2.92a2 2 0 00-.11-1.44l-.29-.17c-.27-.16-.31-.8-.06-1.52l1.86-5.35c.27-.79.79-1.38 1.15-1.31s.92-.5 1.29-1.26c.45-.92.93-1.83 1.44-2.72a2.49 2.49 0 00.38-2 2 2 0 01.48-1.81l3.21-4.32c.4-.54.85-.79 1.12-.64l.55.32a1.65 1.65 0 00.89-.52c.63-.64 1.28-1.25 1.94-1.82a2.79 2.79 0 001-1.81 2.09 2.09 0 011-1.59l3.7-2.14a.78.78 0 01.75-.09l1.82 1.05a13.54 13.54 0 011.36-.26 1.86 1.86 0 001.31-1.2 1.26 1.26 0 011.24-.93l3.21.62a.66.66 0 01.21.08l12.91 7.41a.88.88 0 01.34 1l3.94 2.27a.71.71 0 01.26.28l1.85 3.21a1.68 1.68 0 01-.14 1.72 2.39 2.39 0 00-.3 1.92 22.53 22.53 0 01.53 2.32l1.31.75c.21.12.34.45.34.93v4.93a2.19 2.19 0 01-.72 1.75 2.74 2.74 0 00-.91 1.83c-.14 1-.31 2-.52 3a2 2 0 00.09 1.43l.28.16c.27.16.3.8 0 1.53l-1.85 5.34c-.27.8-.77 1.39-1.11 1.32s-.9.51-1.27 1.27c-.46 1-.94 1.88-1.45 2.79a2.52 2.52 0 00-.41 1.91c.2.34 0 1.14-.5 1.78l-3.19 4.32c-.4.54-.86.8-1.1.66l-.49-.28a1.68 1.68 0 00-.91.55c-.66.66-1.32 1.29-2 1.88a2.78 2.78 0 00-1 1.74 2 2 0 01-1 1.51l-3.7 2.13a.81.81 0 01-.77.11l-1.65-.95a13 13 0 01-1.56.31 1.75 1.75 0 00-1.29 1.11 1.19 1.19 0 01-1.22.85l-3.2-.62a.58.58 0 01-.22-.08l-12.91-7.4a.87.87 0 01-.34-1l-3.9-2.27a.87.87 0 01-.28-.29l-1.85-3.21A1.72 1.72 0 01366 356a2.4 2.4 0 00.29-1.9 22.36 22.36 0 01-.52-2.34l-1.28-.74a1.05 1.05 0 01-.34-.93v-4.94a2.21 2.21 0 01.72-1.73z"
            style={{
              WebkitTransformOrigin: 390.482,
              MsTransformOrigin: 390.482,
              transformOrigin: 390.482,
            }}
            id="elrm4ubee73ak"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M377.06 357.52v-4.94a2.18 2.18 0 01.73-1.75 2.84 2.84 0 00.92-1.84c.14-1 .32-1.95.53-2.92.18-.8.05-1.5-.3-1.57s-.42-.77-.15-1.56l1.85-5.35c.28-.79.79-1.38 1.15-1.31s.93-.5 1.3-1.26c.45-.93.93-1.84 1.43-2.72a2.5 2.5 0 00.38-2 2.06 2.06 0 01.49-1.81l3.21-4.32c.47-.65 1-.87 1.24-.51s.79.2 1.32-.33 1.28-1.25 1.94-1.82a2.79 2.79 0 001-1.81 2.09 2.09 0 011-1.59l3.7-2.14c.55-.32 1-.12 1 .44a.7.7 0 001 .68 13 13 0 011.94-.42 1.86 1.86 0 001.32-1.2 1.26 1.26 0 011.24-.93l3.21.62c.48.09.69.66.48 1.26a1.3 1.3 0 00.38 1.51 10 10 0 011.44 1.07.86.86 0 001.3-.24c.35-.48.87-.49 1.14 0l1.86 3.21a1.72 1.72 0 01-.15 1.72 2.43 2.43 0 00-.31 1.92 20.18 20.18 0 01.54 2.32c.11.65.51 1 .92.77s.73.17.73.91v4.93a2.19 2.19 0 01-.72 1.75 2.74 2.74 0 00-.91 1.83c-.14 1-.31 2-.52 3-.17.79-.06 1.49.29 1.56s.4.76.12 1.56l-1.85 5.34c-.27.8-.77 1.39-1.11 1.32s-.9.51-1.27 1.27c-.46 1-.94 1.88-1.45 2.79a2.52 2.52 0 00-.41 1.91c.2.34 0 1.14-.5 1.78l-3.25 4.35c-.48.64-1 .89-1.21.55s-.77-.15-1.29.38-1.32 1.29-2 1.88a2.78 2.78 0 00-1 1.74 2 2 0 01-1 1.51l-3.7 2.13c-.55.32-1 .16-1-.36s-.44-.77-1-.61a12.64 12.64 0 01-2 .44 1.75 1.75 0 00-1.29 1.11 1.19 1.19 0 01-1.22.85l-3.2-.62c-.48-.09-.71-.62-.51-1.19a1.24 1.24 0 00-.4-1.45 10.23 10.23 0 01-1.44-1.16.83.83 0 00-1.26.19.62.62 0 01-1.12 0l-1.85-3.21a1.7 1.7 0 01.13-1.71 2.44 2.44 0 00.29-1.9 22.33 22.33 0 01-.53-2.34c-.11-.65-.5-1-.9-.78s-.7-.2-.7-.93z"
            style={{
              WebkitTransformOrigin: 396.95,
              MsTransformOrigin: 396.95,
              transformOrigin: 396.95,
            }}
            id="elj67jaeovi8q"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M388.44 309.56l11 6.33h.06a.62.62 0 01.23.53.7.7 0 001 .69c.21-.07.41-.12.62-.17a10.76 10.76 0 011.33-.26 1.54 1.54 0 00.8-.43l-12.9-7.42a1.5 1.5 0 01-.81.43c-.43.1-.87.19-1.33.3z"
            style={{
              WebkitTransformOrigin: 395.96,
              MsTransformOrigin: 395.96,
              transformOrigin: 395.96,
            }}
            id="elakwjdt516yc"
            fill="#263238"
            className="animable"
          />
          <path
            id="elg5c53ghchef"
            d="M380.77 313.45l12.92 7.41a2.29 2.29 0 00.39-1.13 1.7 1.7 0 01.29-.87l-12.92-7.4a1.67 1.67 0 00-.29.87 2.26 2.26 0 01-.39 1.12z"
            className="animable"
            style={{
              WebkitTransformOrigin: 387.57,
              MsTransformOrigin: 387.57,
              transformOrigin: 387.57,
            }}
          />
          <path
            d="M377.35 316.48l12.28 7.06h.08a.27.27 0 01.13.13.38.38 0 00.44.17 1.57 1.57 0 00.88-.51c.63-.64 1.28-1.25 1.95-1.82a2.8 2.8 0 00.58-.69l-12.92-7.41a3.31 3.31 0 01-.59.69c-.66.57-1.31 1.17-1.94 1.82a1.65 1.65 0 01-.89.56z"
            style={{
              WebkitTransformOrigin: 385.52,
              MsTransformOrigin: 385.52,
              transformOrigin: 385.52,
            }}
            id="elxllcgpspnv"
            fill="#263238"
            className="animable"
          />
          <path
            d="M368.44 329l12.93 7.4a.54.54 0 01.42-.15c.36.07.93-.5 1.3-1.26.45-.93.93-1.84 1.43-2.73a2.42 2.42 0 00.38-2 .79.79 0 01-.07-.48l-12.91-7.4a.7.7 0 00.07.47 2.54 2.54 0 01-.38 2c-.51.88-1 1.79-1.43 2.72-.37.76-.94 1.33-1.3 1.26a.56.56 0 00-.44.17z"
            style={{
              WebkitTransformOrigin: 376.707,
              MsTransformOrigin: 376.707,
              transformOrigin: 376.707,
            }}
            id="el7f82c9v0a4q"
            fill="#263238"
            className="animable"
          />
          <path
            id="eljtmbzj3xmbf"
            d="M381.37 336.43L368.44 329a.56.56 0 01.44-.16.32.32 0 00.19 0l12.92 7.4a.36.36 0 01-.2 0 .54.54 0 00-.42.19z"
            className="animable"
            style={{
              WebkitTransformOrigin: 375.215,
              MsTransformOrigin: 375.215,
              transformOrigin: 375.215,
            }}
          />
          <path
            id="elnqhy234ny9r"
            d="M372 322.93a.7.7 0 01-.07-.47l12.91 7.4a.79.79 0 00.07.48 1.13 1.13 0 01.06.68L372 323.61a1.2 1.2 0 000-.68z"
            className="animable"
            style={{
              WebkitTransformOrigin: 378.457,
              MsTransformOrigin: 378.457,
              transformOrigin: 378.457,
            }}
          />
          <path
            d="M364.39 344l12.93 7.4a1.26 1.26 0 01.47-.59 2.75 2.75 0 00.91-1.84c.15-1 .33-1.94.54-2.92a1.83 1.83 0 00-.12-1.46l-.17-.1-.15-.09-12.57-7.19a1.93 1.93 0 01.11 1.43c-.22 1-.39 2-.54 2.93a2.84 2.84 0 01-.92 1.84 1.51 1.51 0 00-.49.59z"
            style={{
              WebkitTransformOrigin: 371.864,
              MsTransformOrigin: 371.864,
              transformOrigin: 371.864,
            }}
            id="el0qco587i3mo"
            fill="#263238"
            className="animable"
          />
          <path
            id="elto845kfngp"
            d="M364.87 343.42a1.57 1.57 0 00.55-.68l12.92 7.41a1.7 1.7 0 01-.55.68 1.26 1.26 0 00-.47.59L364.39 344a1.51 1.51 0 01.48-.58z"
            className="animable"
            style={{
              WebkitTransformOrigin: 371.365,
              MsTransformOrigin: 371.365,
              transformOrigin: 371.365,
            }}
          />
          <path
            d="M365.73 356.7l12.92 7.41a1.54 1.54 0 01.27-.66 2 2 0 00.33-.9 2.19 2.19 0 000-1 22.93 22.93 0 01-.53-2.34c-.11-.65-.49-1-.9-.78a.38.38 0 01-.43 0l-11.59-6.64c.15.82.33 1.61.53 2.34A2.36 2.36 0 01366 356a1.49 1.49 0 00-.27.7z"
            style={{
              WebkitTransformOrigin: 372.519,
              MsTransformOrigin: 372.519,
              transformOrigin: 372.519,
            }}
            id="els8yb4sn6aqq"
            fill="#263238"
            className="animable"
          />
          <path
            id="elva3zf7a7cpj"
            d="M378.92 363.45a1.54 1.54 0 00-.27.66l-12.92-7.41a1.49 1.49 0 01.27-.7 2 2 0 00.32-.82l12.92 7.4a2.15 2.15 0 01-.32.87z"
            className="animable"
            style={{
              WebkitTransformOrigin: 372.485,
              MsTransformOrigin: 372.485,
              transformOrigin: 372.485,
            }}
          />
          <path
            d="M409.34 318a9.76 9.76 0 011.43 1.08.87.87 0 001.3-.25.67.67 0 01.84-.3h-.05l-3.86-2.29c0 .06 0 .14-.05.22a1.29 1.29 0 00.39 1.54z"
            style={{
              WebkitTransformOrigin: 410.875,
              MsTransformOrigin: 410.875,
              transformOrigin: 410.875,
            }}
            id="el3ez7rsz9n4b"
            fill="#263238"
            className="animable"
          />
          <path
            id="el0u4kzo7c0oc"
            d="M372.26 364.46l.24.14 12.5 7.19h.11a.91.91 0 01-.26-1.08 1.24 1.24 0 00-.4-1.45 9.54 9.54 0 01-1.45-1.06.83.83 0 00-1.26.19.67.67 0 01-.73.32l-.14-.08-9-5.12a.85.85 0 00.39.95z"
            className="animable"
            style={{
              WebkitTransformOrigin: 378.476,
              MsTransformOrigin: 378.476,
              transformOrigin: 378.476,
            }}
          />
          <path
            d="M392.67 370.3l1.64.95a.48.48 0 01-.23-.46c0-.52-.45-.78-1-.62z"
            style={{
              WebkitTransformOrigin: 393.49,
              MsTransformOrigin: 393.49,
              transformOrigin: 393.49,
            }}
            id="el82dd2m573s2"
            fill="#263238"
            className="animable"
          />
          <path
            d="M403.69 363.34l.38.21v-.05a.32.32 0 00-.38-.16z"
            style={{
              WebkitTransformOrigin: 403.88,
              MsTransformOrigin: 403.88,
              transformOrigin: 403.88,
            }}
            id="el2f3hci7ddk8"
            fill="#263238"
            className="animable"
          />
          <path
            d="M415.15 328c.11.65.51 1 .91.77a.43.43 0 01.34-.05l-.11-.06-.19-.11z"
            style={{
              WebkitTransformOrigin: 415.775,
              MsTransformOrigin: 415.775,
              transformOrigin: 415.775,
            }}
            id="elq0xduhok3j"
            fill="#263238"
            className="animable"
          />
          <path
            id="els5rpj4ivz7d"
            d="M390.6 308.86l12.9 7.42a1.88 1.88 0 00.52-.77 1.43 1.43 0 01.55-.73l-12.92-7.41a1.5 1.5 0 00-.55.74 1.9 1.9 0 01-.5.75z"
            className="animable"
            style={{
              WebkitTransformOrigin: 397.585,
              MsTransformOrigin: 397.585,
              transformOrigin: 397.585,
            }}
          />
          <path
            d="M368.59 350.23c0 8 2.68 13.67 6.89 16.1l2.85 1.63c-4.15-2.46-6.77-8.08-6.77-16 0-14.65 8.9-31.67 19.89-38 4.74-2.74 9.09-3 12.51-1.32l-2.44-1.41c-3.5-2-8-1.84-13 1-11.02 6.33-19.93 23.36-19.93 38z"
            style={{
              WebkitTransformOrigin: 386.275,
              MsTransformOrigin: 386.275,
              transformOrigin: 386.275,
            }}
            id="elvo4m9quop3"
            fill="#37474F"
            className="animable"
          />
          <path
            id="elfycr7vkscf"
            d="M371.56 351.93c0 7.95 2.62 13.57 6.77 16l.86.45c-4.14-2.46-5.72-7.43-5.72-15.37 0-14.65 8.91-31.67 19.9-38 4.91-2.84 8.36-3.7 11.84-1.76l-1.25-.68c-3.42-1.71-7.77-1.42-12.51 1.33-10.99 6.36-19.89 23.38-19.89 38.03z"
            className="animable"
            style={{
              WebkitTransformOrigin: 388.385,
              MsTransformOrigin: 388.385,
              transformOrigin: 388.385,
            }}
          />
          <path
            d="M391.65 307.37l12.92 7.41a.89.89 0 01.69-.2l1.33.26 1.88.36h.12l-.2-.13-11.74-6.73-.27-.15-.28-.16-.1-.03-.14-.09-.09-.05-.1-.05h-.11l-3.21-.62a.87.87 0 00-.7.18z"
            style={{
              WebkitTransformOrigin: 400.12,
              MsTransformOrigin: 400.12,
              transformOrigin: 400.12,
            }}
            id="el8rd6bcqwm1x"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M381.45 311.46l12.92 7.4a2.07 2.07 0 01.7-.71l3.71-2.14a.83.83 0 01.7-.12l-11-6.33-1.19-.68-.42-.24-.22-.13a.78.78 0 00-.75.09l-3.7 2.14a2.13 2.13 0 00-.75.72z"
            style={{
              WebkitTransformOrigin: 390.465,
              MsTransformOrigin: 390.465,
              transformOrigin: 390.465,
            }}
            id="elb63y6bcyk8q"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M371.92 322.46l12.91 7.4a3 3 0 01.56-1.34l3.2-4.31c.36-.48.78-.74 1-.67l-12.28-7.06-.55-.31c-.27-.16-.72.08-1.12.62l-3.21 4.33a2.76 2.76 0 00-.51 1.34z"
            style={{
              WebkitTransformOrigin: 380.755,
              MsTransformOrigin: 380.755,
              transformOrigin: 380.755,
            }}
            id="eluzzisz6a5k"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M365.9 337l.09.06.23.13 12.57 7.19a1.7 1.7 0 010-1.48l1.85-5.34a2.67 2.67 0 01.73-1.16l-12.93-7.4a2.88 2.88 0 00-.71 1.16l-1.86 5.34c-.23.72-.22 1.34.03 1.5z"
            style={{
              WebkitTransformOrigin: 373.537,
              MsTransformOrigin: 373.537,
              transformOrigin: 373.537,
            }}
            id="el92fvqekfuw"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M364.14 345.17v4.94c0 .48.13.81.34.93l1.28.74 11.59 6.64a1.17 1.17 0 01-.29-.9v-4.94a2.77 2.77 0 01.26-1.15L364.39 344a2.89 2.89 0 00-.25 1.17z"
            style={{
              WebkitTransformOrigin: 370.745,
              MsTransformOrigin: 370.745,
              transformOrigin: 370.745,
            }}
            id="el8u3wprdtg6c"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M365.87 357.74l1.85 3.22a.87.87 0 00.28.29l.88.5.32.19 2.72 1.57 9 5.12a.78.78 0 01-.25-.26l-1.85-3.21a1.69 1.69 0 01-.14-1.05l-12.92-7.41a1.51 1.51 0 00.11 1.04z"
            style={{
              WebkitTransformOrigin: 373.317,
              MsTransformOrigin: 373.317,
              transformOrigin: 373.317,
            }}
            id="elt1y0mi31dhr"
            fill="#37474F"
            className="animable"
          />
          <ellipse
            id="elm7x1qys21x"
            cx="396.93"
            cy="343.58"
            fill="#37474F"
            rx="16.99"
            ry="9.61"
            style={{
              WebkitTransformOrigin: 396.93,
              MsTransformOrigin: 396.93,
              transformOrigin: 396.93,
            }}
            className="animable"
            transform="rotate(-66.95)"
          />
          <ellipse
            id="elkajxtlbanhf"
            cx="396.93"
            cy="343.58"
            fill="#EF8C2D"
            rx="13.77"
            ry="7.79"
            style={{
              WebkitTransformOrigin: 396.93,
              MsTransformOrigin: 396.93,
              transformOrigin: 396.93,
            }}
            className="animable"
            transform="rotate(-66.95)"
          />
          <path
            d="M390.64 347.21c0-4.63 2.81-10 6.29-12s6.29.12 6.29 4.75-2.82 10-6.29 12-6.29-.12-6.29-4.75z"
            style={{
              WebkitTransformOrigin: 396.93,
              MsTransformOrigin: 396.93,
              transformOrigin: 396.93,
            }}
            id="elo2miasp1n9"
            fill="#263238"
            className="animable"
          />
          <path
            d="M393.31 345.67c0-2.67 1.62-5.76 3.62-6.92s3.62.08 3.62 2.74-1.62 5.76-3.62 6.91-3.62-.07-3.62-2.73z"
            style={{
              WebkitTransformOrigin: 396.93,
              MsTransformOrigin: 396.93,
              transformOrigin: 396.93,
            }}
            id="eloa3f0aaa8tf"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M396.53 340.55a.93.93 0 01.4-.75c.22-.13.39 0 .39.29a1 1 0 01-.39.76c-.22.15-.4-.01-.4-.3z"
            style={{
              WebkitTransformOrigin: 396.925,
              MsTransformOrigin: 396.925,
              transformOrigin: 396.925,
            }}
            id="eljuzuu6j2rcf"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M394.92 342.8c-.15-.11-.15-.44 0-.74s.41-.44.56-.32.15.45 0 .74-.4.44-.56.32z"
            style={{
              WebkitTransformOrigin: 395.2,
              MsTransformOrigin: 395.2,
              transformOrigin: 395.2,
            }}
            id="eloz47rzowat"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M394.49 345.51c-.22.13-.4 0-.4-.3a.94.94 0 01.4-.75c.21-.12.39 0 .39.3a1 1 0 01-.39.75z"
            style={{
              WebkitTransformOrigin: 394.485,
              MsTransformOrigin: 394.485,
              transformOrigin: 394.485,
            }}
            id="elw5pv1e317qc"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M395.48 347.09c-.15.29-.4.43-.56.32s-.15-.45 0-.74.41-.44.56-.32.15.44 0 .74z"
            style={{
              WebkitTransformOrigin: 395.198,
              MsTransformOrigin: 395.198,
              transformOrigin: 395.198,
            }}
            id="el0max9jl50289"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M397.32 346.61a.93.93 0 01-.39.75c-.22.12-.4 0-.4-.3a.93.93 0 01.4-.75c.22-.13.39.01.39.3z"
            style={{
              WebkitTransformOrigin: 396.925,
              MsTransformOrigin: 396.925,
              transformOrigin: 396.925,
            }}
            id="elhl3gqkhbjs"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M398.93 344.35c.16.12.16.45 0 .74s-.4.44-.55.32-.16-.44 0-.74.4-.43.55-.32z"
            style={{
              WebkitTransformOrigin: 398.657,
              MsTransformOrigin: 398.657,
              transformOrigin: 398.657,
            }}
            id="elam5qnhkrb4u"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M399.37 341.64c.22-.12.39 0 .39.3a.92.92 0 01-.39.75c-.22.13-.39 0-.39-.3a.93.93 0 01.39-.75z"
            style={{
              WebkitTransformOrigin: 399.37,
              MsTransformOrigin: 399.37,
              transformOrigin: 399.37,
            }}
            id="el400k576ejb"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M398.38 340.07c.15-.3.4-.44.55-.32s.16.44 0 .74-.4.44-.55.32-.16-.45 0-.74z"
            style={{
              WebkitTransformOrigin: 398.655,
              MsTransformOrigin: 398.655,
              transformOrigin: 398.655,
            }}
            id="el68wcfq7x4yr"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M395.47 344.42a3.5 3.5 0 011.46-2.79c.81-.47 1.46 0 1.46 1.1a3.51 3.51 0 01-1.46 2.8c-.81.47-1.46-.03-1.46-1.11z"
            style={{
              WebkitTransformOrigin: 396.93,
              MsTransformOrigin: 396.93,
              transformOrigin: 396.93,
            }}
            id="el9wlbbzxyzj"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M328.37 280.17l1.92-5.57 16.5-47.78 21.64 12.43 28.38 16.3L378.24 309zm-5.76 1.63v16.56l11.21 6.47 37.33 21.55 10.92 6.3c3.71-7.26 8.92-13.46 14.68-16.78 9-5.18 16.57-1.86 19.31 7.28l15.7-9.07V236l-59.46-34.35-25.09 14.48h-.06l-1.38.8a2.07 2.07 0 00-.25.15 7.68 7.68 0 00-2.41 2.86 11.06 11.06 0 00-.6 1.41l-19.43 57.72a8.52 8.52 0 00-.47 2.73zm66.31 24.69l16.16-49.33a5 5 0 012-2.7l19.51-11.27a1.58 1.58 0 011.17-.22 2.89 2.89 0 01.16 1v40.79a3.93 3.93 0 01-1.64 3.13L390 308.82a1.55 1.55 0 01-1.17.23 4.15 4.15 0 01.09-2.56z"
            style={{
              WebkitTransformOrigin: 377.185,
              MsTransformOrigin: 377.185,
              transformOrigin: 377.185,
            }}
            id="el3el2h4a0bas"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M372.3 201.64l-25.09 14.49a.09.09 0 01-.06 0l-1.38.81-.25.15a7.68 7.68 0 00-2.41 2.86 7.93 7.93 0 00-.59 1.41l-19.44 57.73a8.41 8.41 0 00-.47 2.68v16.56l11.21 6.47 37.33 21.55 10.92 6.3c3.71-7.26 8.92-13.46 14.67-16.78 9-5.18 16.58-1.86 19.32 7.28l15.7-9.07V236zM378.24 309l-49.87-28.79 1.93-5.57 16.49-47.78 21.64 12.43 28.38 16.31zm49.65-24.21a3.94 3.94 0 01-1.64 3.13L390 308.82a1.6 1.6 0 01-1.17.24 4.18 4.18 0 01.11-2.57l16.16-49.33a5 5 0 012-2.7l19.51-11.27a1.53 1.53 0 011.17-.21 2.73 2.73 0 01.16 1z"
            style={{
              WebkitTransformOrigin: 377.185,
              MsTransformOrigin: 377.185,
              transformOrigin: 377.185,
            }}
            id="elok6gm9ind2"
            className="animable"
            opacity="0.1"
          />
          <path
            style={{
              WebkitTransformOrigin: 362.59,
              MsTransformOrigin: 362.59,
              transformOrigin: 362.59,
            }}
            id="elbd4m0i77684"
            fill="#455A64"
            d="M396.81 255.56L378.24 308.96 328.37 280.17 330.3 274.6 346.79 226.82 368.43 239.25 396.81 255.56z"
            className="animable"
          />
          <path
            d="M344.52 218L404 252.3a6 6 0 011.25-1l26.51-15.3-59.46-34.35-25.09 14.48h-.06l-1.38.8a2.07 2.07 0 00-.25.15 6.45 6.45 0 00-1 .92z"
            style={{
              WebkitTransformOrigin: 388.14,
              MsTransformOrigin: 388.14,
              transformOrigin: 388.14,
            }}
            id="ele1z3pypigug"
            fill="#FFF"
            className="animable"
            opacity="0.3"
          />
          <g
            style={{
              WebkitTransformOrigin: 335.035,
              MsTransformOrigin: 335.035,
              transformOrigin: 335.035,
            }}
            id="el2ozr7as3e8y"
            className="animable"
            opacity="0.7"
          >
            <path
              d="M347.46 216l-1.69 1a8.2 8.2 0 00-3.25 4.43l-19.44 57.72a8.57 8.57 0 00-.47 2.69v1.57s17.33-51.81 19.39-57.85 2.74-8 5.46-9.56z"
              style={{
                WebkitTransformOrigin: 335.035,
                MsTransformOrigin: 335.035,
                transformOrigin: 335.035,
              }}
              id="elstwb9tfkk4"
              fill="#FFF"
              className="animable"
            />
          </g>
          <path
            d="M362.58 308.79l-19.8-11.28a2.66 2.66 0 00-4.32 2.49v11a8.31 8.31 0 003.81 6.59l19.8 11.28a3.74 3.74 0 001.84.56 2.29 2.29 0 001.18-.31 3 3 0 001.3-2.74v-11a8.33 8.33 0 00-3.81-6.59zm-22.88 3.65a5.48 5.48 0 01-.21-1.41v-.34l25.82 14.76h.05v.94a3.55 3.55 0 01-.06.67zm25.66 6.24l-25.82-14.76h-.05v-1.64L365.31 317h.05zm-25.87-13.61l25.82 14.76h.05v1.64l-25.82-14.76h-.05zm0 2.81l25.82 14.76h.05v1.64l-25.82-14.76h-.05zm25.87 7.51v.48l-25.82-14.76h-.05V300a4.39 4.39 0 010-.53l25.66 14.68a5.36 5.36 0 01.21 1.24zm-25.09-17.24a1.26 1.26 0 01.67-.17 2.7 2.7 0 011.33.42l19.8 11.28a7 7 0 012.59 3L339.9 298.5a1.12 1.12 0 01.37-.35zM362.58 328l-19.8-11.28a6.78 6.78 0 01-2.48-2.72l24.57 14a1 1 0 01-.29.25 2 2 0 01-2-.25z"
            style={{
              WebkitTransformOrigin: 352.412,
              MsTransformOrigin: 352.412,
              transformOrigin: 352.412,
            }}
            id="elh3pzck810db"
            className="animable"
            opacity="0.2"
          />
          <path
            d="M361.88 309.15l-19.8-11.28a2.66 2.66 0 00-4.32 2.49v11a8.29 8.29 0 003.81 6.59l19.8 11.28a3.71 3.71 0 001.84.56 2.29 2.29 0 001.18-.31 3 3 0 001.29-2.74v-11a8.32 8.32 0 00-3.8-6.59zM339 312.79a5.77 5.77 0 01-.2-1.4v-.34l25.82 14.76h.05v.95a3.37 3.37 0 01-.06.66zm25.66 6.21l-25.82-14.76v-1.64l25.82 14.76h.05zm-25.87-13.61l25.82 14.76h.05v1.64l-25.82-14.76zm0 2.81l25.82 14.8h.05v1.64l-25.82-14.77zm25.87 7.5v.49l-25.82-14.76v-1.09a4.39 4.39 0 010-.53l25.66 14.67a5.51 5.51 0 01.16 1.26zm-25.09-17.2a1.35 1.35 0 01.67-.16 2.78 2.78 0 011.33.41l19.8 11.25a7.1 7.1 0 012.59 3l-24.77-14.16a1.3 1.3 0 01.38-.34zm22.31 29.87l-19.8-11.28a6.94 6.94 0 01-2.49-2.77l24.58 14.05a1.13 1.13 0 01-.29.25 2 2 0 01-2-.25z"
            style={{
              WebkitTransformOrigin: 351.707,
              MsTransformOrigin: 351.707,
              transformOrigin: 351.707,
            }}
            id="elw8uxq3me8sd"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M384.69 319.17v4.19a1.48 1.48 0 01-.66 1.15l-1.16.66a1.46 1.46 0 01-1.32 0l-8.59-5a1.47 1.47 0 01-.66-1.14v-4.2c0-.42.3-.59.66-.38l8.59 5a1.46 1.46 0 001.32 0l1.16-.67c.36-.21.66-.04.66.39z"
            style={{
              WebkitTransformOrigin: 378.495,
              MsTransformOrigin: 378.495,
              transformOrigin: 378.495,
            }}
            id="elnyv8x61got8"
            fill="#F0F0F0"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 327.635,
              MsTransformOrigin: 327.635,
              transformOrigin: 327.635,
            }}
            id="els1zwuk8uabs"
            fill="#F0F0F0"
            d="M322.61 285.42L332.66 291.23 332.66 296.95 322.61 291.14 322.61 285.42z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 402.78,
              MsTransformOrigin: 402.78,
              transformOrigin: 402.78,
            }}
            id="eliaqj2y7ammg"
            fill="#455A64"
            d="M373.8 200.78L433.26 235.11 431.76 235.97 372.3 201.65 373.8 200.78z"
            className="animable"
          />
          <path
            d="M382.07 316.13v16.55c3.71-7.26 8.92-13.46 14.68-16.78 9-5.18 16.57-1.86 19.31 7.28l15.7-9.07V236l-26.53 15.32a8.23 8.23 0 00-3.26 4.43l-19.43 57.72a8.57 8.57 0 00-.47 2.66zm6.85-9.64l16.16-49.33a5 5 0 012-2.7l19.51-11.27a1.58 1.58 0 011.17-.22 2.89 2.89 0 01.16 1v40.79a3.93 3.93 0 01-1.64 3.13L390 308.82a1.55 1.55 0 01-1.17.23 4.15 4.15 0 01.09-2.56z"
            style={{
              WebkitTransformOrigin: 406.915,
              MsTransformOrigin: 406.915,
              transformOrigin: 406.915,
            }}
            id="elgngjhjuro6n"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M405.23 251.29a8.19 8.19 0 00-3.26 4.42l-19.43 57.73a8.57 8.57 0 00-.47 2.69v16.55c3.71-7.26 8.92-13.46 14.67-16.78 9-5.18 16.58-1.86 19.32 7.28l15.7-9.07V236zm22.66 33.46a3.94 3.94 0 01-1.64 3.13L390 308.82a1.6 1.6 0 01-1.17.24 4.18 4.18 0 01.11-2.57l16.16-49.33a5 5 0 012-2.7l19.51-11.27a1.53 1.53 0 011.17-.21 2.73 2.73 0 01.16 1z"
            style={{
              WebkitTransformOrigin: 406.915,
              MsTransformOrigin: 406.915,
              transformOrigin: 406.915,
            }}
            id="eldjp7efk3v49"
            className="animable"
            opacity="0.2"
          />
          <path
            d="M406.92 250.32l-1.69 1a8.2 8.2 0 00-3.25 4.43l-19.44 57.72a8.57 8.57 0 00-.47 2.69v1.57s17.33-51.77 19.41-57.81 2.72-8.04 5.44-9.6z"
            style={{
              WebkitTransformOrigin: 394.495,
              MsTransformOrigin: 394.495,
              transformOrigin: 394.495,
            }}
            id="elwev4fd0nz38"
            fill="#FFF"
            className="animable"
            opacity="0.7"
          />
          <path
            d="M427.89 244v40.8a3.94 3.94 0 01-1.64 3.13L390 308.82a1.6 1.6 0 01-1.17.24 4.18 4.18 0 01.11-2.57l16.16-49.33a5 5 0 012-2.7l19.51-11.27a1.53 1.53 0 011.17-.21 2.73 2.73 0 01.11 1.02z"
            style={{
              WebkitTransformOrigin: 408.289,
              MsTransformOrigin: 408.289,
              transformOrigin: 408.289,
            }}
            id="elf4bm1ci73x"
            fill="#37474F"
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--Barrier--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 263.588,
          MsTransformOrigin: 263.588,
          transformOrigin: 263.588,
        }}
      >
        <g
          id="freepik--barrier--inject-9"
          className="animable"
          style={{
            WebkitTransformOrigin: 263.588,
            MsTransformOrigin: 263.588,
            transformOrigin: 263.588,
          }}
        >
          <path
            d="M278.84 384.69l-3.22 1.86a1.27 1.27 0 01-1.17 0l-1-.59a1 1 0 01-.45-1l10.69-50.61a1.78 1.78 0 01.72-1l3.22-1.85a1.27 1.27 0 011.17 0l1 .59a1 1 0 01.44 1l-10.69 50.6a1.74 1.74 0 01-.71 1z"
            style={{
              WebkitTransformOrigin: 281.62,
              MsTransformOrigin: 281.62,
              transformOrigin: 281.62,
            }}
            id="elhf0d3zko1p4"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M278.84 384.69l-3.22 1.86c-.32.19-.52 0-.45-.32l10.69-50.61a1.72 1.72 0 01.73-1l3.22-1.86c.32-.18.52 0 .44.33l-10.69 50.6a1.74 1.74 0 01-.72 1z"
            style={{
              WebkitTransformOrigin: 282.712,
              MsTransformOrigin: 282.712,
              transformOrigin: 282.712,
            }}
            id="el1ukdbo5xij9"
            fill="#263238"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 289.33,
              MsTransformOrigin: 289.33,
              transformOrigin: 289.33,
            }}
            id="elbn5bb3if49"
            fill="#37474F"
            d="M277.12 381.39L278.07 376.87 280.27 375.61 300.58 387.34 301.54 392.95 299.34 394.22 277.12 381.39z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 288.23,
              MsTransformOrigin: 288.23,
              transformOrigin: 288.23,
            }}
            id="elas8fphy24du"
            fill="#455A64"
            d="M299.34 394.22L277.12 381.39 278.07 376.87 298.39 388.6 299.34 394.22z"
            className="animable"
          />
          <path
            d="M303 398.62l-3.22 1.86a1.27 1.27 0 01-1.17 0l-1-.59a1.62 1.62 0 01-.7-1l-10.74-63.27a1 1 0 01.47-1l3.22-1.86a1.33 1.33 0 011.17 0l1 .59a1.65 1.65 0 01.7 1l10.74 63.26a1 1 0 01-.47 1.01z"
            style={{
              WebkitTransformOrigin: 294.821,
              MsTransformOrigin: 294.821,
              transformOrigin: 294.821,
            }}
            id="el8irvujh8snb"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M286.12 335.62l.4 2.4 10.34 60.86a1.68 1.68 0 00.69 1l1 .59a1.27 1.27 0 001.17 0l3.22-1.86a1 1 0 00.47-1l-10.7-63v-.22a1.69 1.69 0 00-.7-1l-1-.59a1.36 1.36 0 00-1.18 0l-3.21 1.86a1 1 0 00-.5.96z"
            style={{
              WebkitTransformOrigin: 294.768,
              MsTransformOrigin: 294.768,
              transformOrigin: 294.768,
            }}
            id="el7hmdkft0qtc"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M292.7 334.36l10.74 63.26a1 1 0 01-.47 1l-3.22 1.86a.44.44 0 01-.7-.33l-10.74-63.26a1 1 0 01.47-1L292 334a.44.44 0 01.7.36z"
            style={{
              WebkitTransformOrigin: 295.875,
              MsTransformOrigin: 295.875,
              transformOrigin: 295.875,
            }}
            id="elk0nllvj27g"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M283.15 336.82l1.69 1a2.57 2.57 0 001.68.23 2.08 2.08 0 00.65-.23l5.57-3.21v-.22a1.69 1.69 0 00-.7-1l-1-.59a1.36 1.36 0 00-1.18 0c.09-.36.33-.49 0-.67l-1-.59a1.27 1.27 0 00-1.17 0l-3.22 1.85a1.78 1.78 0 00-.72 1z"
            style={{
              WebkitTransformOrigin: 287.945,
              MsTransformOrigin: 287.945,
              transformOrigin: 287.945,
            }}
            id="eldjg5ln6vk5i"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M281.2 332.87a.82.82 0 01.48-.68l6.44-3.72a2.61 2.61 0 012.34 0l3.15 1.82a.83.83 0 01.48.67v1.47a.83.83 0 01-.48.67l-6.44 3.72a2.61 2.61 0 01-2.34 0l-3.15-1.82a.83.83 0 01-.48-.67c-.01-.25 0-1.22 0-1.46z"
            style={{
              WebkitTransformOrigin: 287.643,
              MsTransformOrigin: 287.643,
              transformOrigin: 287.643,
            }}
            id="el6lyadf0bwqo"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M281.2 332.87a.82.82 0 01.48-.68l4.32-2.5v7.41a2.5 2.5 0 01-1.17-.28l-3.15-1.82a.83.83 0 01-.48-.67c-.01-.25 0-1.22 0-1.46z"
            style={{
              WebkitTransformOrigin: 283.598,
              MsTransformOrigin: 283.598,
              transformOrigin: 283.598,
            }}
            id="el6xod9as7aui"
            fill="#263238"
            className="animable"
          />
          <path
            d="M287.17 335.36l6.44-3.72a.71.71 0 000-1.35l-3.15-1.82a2.61 2.61 0 00-2.34 0l-6.44 3.72a.71.71 0 000 1.35l3.15 1.82a2.61 2.61 0 002.34 0z"
            style={{
              WebkitTransformOrigin: 287.645,
              MsTransformOrigin: 287.645,
              transformOrigin: 287.645,
            }}
            id="ele8yo3wxwlqn"
            fill="#455A64"
            className="animable"
          />
          <g
            id="freepik--Clock--inject-9"
            className="animable"
            style={{
              WebkitTransformOrigin: 287.929,
              MsTransformOrigin: 287.929,
              transformOrigin: 287.929,
            }}
          >
            <path
              d="M291.21 325l-2.21-1.24a2.34 2.34 0 00-2.35.35 7.46 7.46 0 00-3.08 6 2.39 2.39 0 001.09 2.12l2.18 1.28a2.39 2.39 0 002.36-.36 7.4 7.4 0 003.07-6 2.38 2.38 0 00-1.06-2.15z"
              style={{
                WebkitTransformOrigin: 287.922,
                MsTransformOrigin: 287.922,
                transformOrigin: 287.922,
              }}
              id="el59ww39qrwzy"
              fill="#455A64"
              className="animable"
            />
            <path
              d="M286.81 333.47l-2.13-1.26a2.39 2.39 0 01-1.09-2.12 6.84 6.84 0 01.65-3l2.18 1.28a6.57 6.57 0 00-.65 3 2.4 2.4 0 001.04 2.1z"
              style={{
                WebkitTransformOrigin: 285.199,
                MsTransformOrigin: 285.199,
                transformOrigin: 285.199,
              }}
              id="elctofgxs4lzc"
              fill="#263238"
              className="animable"
            />
            <path
              d="M289.22 333.14a7.4 7.4 0 003.07-6c-.1-2.14-1.64-2.93-3.45-1.77a7.42 7.42 0 00-3.07 6c.1 2.14 1.65 2.93 3.45 1.77z"
              style={{
                WebkitTransformOrigin: 289.03,
                MsTransformOrigin: 289.03,
                transformOrigin: 289.03,
              }}
              id="elbk1l6f5t4tu"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M287.71 333c-1.06.05-1.24-1.14-1.26-1.66a6.81 6.81 0 012.76-5.37 2.47 2.47 0 011.14-.42c1.07-.05 1.24 1.14 1.26 1.66a6.75 6.75 0 01-2.76 5.37 2.32 2.32 0 01-1.14.42z"
              style={{
                WebkitTransformOrigin: 289.03,
                MsTransformOrigin: 289.03,
                transformOrigin: 289.03,
              }}
              id="elmafc8wqujt"
              fill="#263238"
              className="animable"
            />
            <path
              d="M289.21 326a2.54 2.54 0 011-.4 2.69 2.69 0 01.3 1.13 6.79 6.79 0 01-2.77 5.37 2.51 2.51 0 01-1 .41 2.59 2.59 0 01-.29-1.14 6.81 6.81 0 012.76-5.37z"
              style={{
                WebkitTransformOrigin: 288.48,
                MsTransformOrigin: 288.48,
                transformOrigin: 288.48,
              }}
              id="elkvgybdart5"
              fill="#EF8C2D"
              className="animable"
            />
          </g>
          <path
            d="M228.37 413.83l-3.22 1.85a1.27 1.27 0 01-1.17 0l-1-.59a1 1 0 01-.44-1l10.69-50.6a1.7 1.7 0 01.72-1l3.22-1.86a1.33 1.33 0 011.17 0l1 .59a1 1 0 01.45 1l-10.69 50.61a1.75 1.75 0 01-.73 1z"
            style={{
              WebkitTransformOrigin: 231.165,
              MsTransformOrigin: 231.165,
              transformOrigin: 231.165,
            }}
            id="elth9enanzcua"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M228.37 413.83l-3.22 1.85c-.32.19-.52.05-.44-.32l10.69-50.61a1.72 1.72 0 01.72-1l3.22-1.86c.32-.19.52 0 .45.32l-10.69 50.62a1.75 1.75 0 01-.73 1z"
            style={{
              WebkitTransformOrigin: 232.248,
              MsTransformOrigin: 232.248,
              transformOrigin: 232.248,
            }}
            id="elnduciumiv8q"
            fill="#263238"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 238.805,
              MsTransformOrigin: 238.805,
              transformOrigin: 238.805,
            }}
            id="el30aed66nmi"
            fill="#37474F"
            d="M226.6 410.6L227.55 406.08 229.74 404.82 250.06 416.55 251.01 422.17 248.82 423.43 226.6 410.6z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 237.71,
              MsTransformOrigin: 237.71,
              transformOrigin: 237.71,
            }}
            id="el875syaxvyi"
            fill="#455A64"
            d="M248.82 423.43L226.6 410.6 227.55 406.08 247.87 417.81 248.82 423.43z"
            className="animable"
          />
          <path
            d="M252.5 427.76l-3.22 1.86a1.31 1.31 0 01-1.16 0l-1-.6a1.64 1.64 0 01-.7-1l-10.74-63.26a1 1 0 01.47-1l3.22-1.86a1.27 1.27 0 011.17 0l1 .59a1.62 1.62 0 01.7 1L253 426.75a1 1 0 01-.5 1.01z"
            style={{
              WebkitTransformOrigin: 244.339,
              MsTransformOrigin: 244.339,
              transformOrigin: 244.339,
            }}
            id="el90iynn97cb"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M235.65 364.76l.41 2.39L246.39 428a1.64 1.64 0 00.7 1l1 .6a1.36 1.36 0 001.18 0l3.21-1.86a1.08 1.08 0 00.48-1l-10.71-63.05v-.22a1.62 1.62 0 00-.69-1l-1-.59a1.27 1.27 0 00-1.17 0l-3.22 1.85a1.06 1.06 0 00-.52 1.03z"
            style={{
              WebkitTransformOrigin: 244.304,
              MsTransformOrigin: 244.304,
              transformOrigin: 244.304,
            }}
            id="el9ho4vgqm95s"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M242.23 363.49L253 426.75a1 1 0 01-.47 1l-3.22 1.86a.43.43 0 01-.69-.33L237.85 366a1 1 0 01.47-1l3.21-1.85a.44.44 0 01.7.34z"
            style={{
              WebkitTransformOrigin: 245.425,
              MsTransformOrigin: 245.425,
              transformOrigin: 245.425,
            }}
            id="elj408dr7j4ee"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M232.69 366l1.68 1a2.53 2.53 0 001.69.23 2 2 0 00.65-.23l5.56-3.21v-.22a1.62 1.62 0 00-.69-1l-1-.59a1.27 1.27 0 00-1.17 0c.08-.37.32-.49 0-.68l-1-.59a1.27 1.27 0 00-1.17 0l-3.22 1.86a1.69 1.69 0 00-.73 1z"
            style={{
              WebkitTransformOrigin: 237.48,
              MsTransformOrigin: 237.48,
              transformOrigin: 237.48,
            }}
            id="el9ryjkiwbkie"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M230.73 362a.83.83 0 01.49-.69l6.43-3.71a2.54 2.54 0 012.34 0l3.15 1.82a.83.83 0 01.49.66v1.47a.82.82 0 01-.49.68l-6.43 3.77a2.54 2.54 0 01-2.34 0l-3.15-1.82a.83.83 0 01-.49-.66c0-.3.01-1.27 0-1.52z"
            style={{
              WebkitTransformOrigin: 237.18,
              MsTransformOrigin: 237.18,
              transformOrigin: 237.18,
            }}
            id="elk3o19jwmbug"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M230.73 362a.83.83 0 01.49-.69l4.32-2.49v7.4a2.36 2.36 0 01-1.17-.28l-3.15-1.82a.83.83 0 01-.49-.66c0-.24.01-1.21 0-1.46z"
            style={{
              WebkitTransformOrigin: 233.135,
              MsTransformOrigin: 233.135,
              transformOrigin: 233.135,
            }}
            id="eluqcq02w4jf"
            fill="#263238"
            className="animable"
          />
          <path
            d="M236.71 364.49l6.43-3.71c.65-.38.65-1 0-1.35l-3.14-1.82a2.54 2.54 0 00-2.34 0l-6.43 3.71c-.65.38-.65 1 0 1.35l3.15 1.82a2.54 2.54 0 002.33 0z"
            style={{
              WebkitTransformOrigin: 237.185,
              MsTransformOrigin: 237.185,
              transformOrigin: 237.185,
            }}
            id="elfu9l7biv86j"
            fill="#455A64"
            className="animable"
          />
          <g
            id="freepik--clock--inject-9"
            className="animable"
            style={{
              WebkitTransformOrigin: 237.486,
              MsTransformOrigin: 237.486,
              transformOrigin: 237.486,
            }}
          >
            <path
              d="M240.75 354.18l-2.17-1.27a2.39 2.39 0 00-2.36.36 7.4 7.4 0 00-3.07 6 2.38 2.38 0 001.08 2.13l2.17 1.28a2.34 2.34 0 002.35-.35 7.43 7.43 0 003.08-6 2.35 2.35 0 00-1.08-2.15z"
              style={{
                WebkitTransformOrigin: 237.491,
                MsTransformOrigin: 237.491,
                transformOrigin: 237.491,
              }}
              id="elpv0kik5j3lc"
              fill="#455A64"
              className="animable"
            />
            <path
              d="M236.35 362.6l-2.13-1.25a2.35 2.35 0 01-1.08-2.12 6.62 6.62 0 01.65-3l2.21 1.27a6.76 6.76 0 00-.65 3 2.38 2.38 0 001 2.1z"
              style={{
                WebkitTransformOrigin: 234.743,
                MsTransformOrigin: 234.743,
                transformOrigin: 234.743,
              }}
              id="elhiqyj38ecpc"
              fill="#263238"
              className="animable"
            />
            <path
              d="M238.75 362.28a7.43 7.43 0 003.08-6c-.1-2.14-1.65-2.94-3.45-1.78a7.47 7.47 0 00-3.08 6c.11 2.14 1.7 2.94 3.45 1.78z"
              style={{
                WebkitTransformOrigin: 238.565,
                MsTransformOrigin: 238.565,
                transformOrigin: 238.565,
              }}
              id="elekws7ml1te5"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M237.24 362.13c-1.06.05-1.23-1.14-1.26-1.66a6.79 6.79 0 012.77-5.37 2.45 2.45 0 011.14-.43c1.06-.05 1.23 1.15 1.26 1.66a6.81 6.81 0 01-2.76 5.37 2.38 2.38 0 01-1.15.43z"
              style={{
                WebkitTransformOrigin: 238.565,
                MsTransformOrigin: 238.565,
                transformOrigin: 238.565,
              }}
              id="el84te1mqu64n"
              fill="#263238"
              className="animable"
            />
            <path
              d="M238.75 355.1a2.51 2.51 0 011-.41 2.52 2.52 0 01.29 1.13 6.78 6.78 0 01-2.76 5.37 2.43 2.43 0 01-1 .41 2.69 2.69 0 01-.3-1.13 6.79 6.79 0 012.77-5.37z"
              style={{
                WebkitTransformOrigin: 238.01,
                MsTransformOrigin: 238.01,
                transformOrigin: 238.01,
              }}
              id="elerihyz2ju1"
              fill="#EF8C2D"
              className="animable"
            />
          </g>
          <path
            style={{
              WebkitTransformOrigin: 266.795,
              MsTransformOrigin: 266.795,
              transformOrigin: 266.795,
            }}
            id="eliktl7aiif37"
            fill="#EF8C2D"
            d="M233.34 372.96L301.35 333.7 300.24 333.06 232.24 372.32 233.34 372.96z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 266.795,
              MsTransformOrigin: 266.795,
              transformOrigin: 266.795,
            }}
            id="elwijw1enhac"
            fill="#FFF"
            d="M233.34 372.96L301.35 333.7 300.24 333.06 232.24 372.32 233.34 372.96z"
            className="animable"
            opacity="0.4"
          />
          <path
            style={{
              WebkitTransformOrigin: 268.995,
              MsTransformOrigin: 268.995,
              transformOrigin: 268.995,
            }}
            id="elrxxiiz9y9m"
            fill="#EF8C2D"
            d="M233.34 372.96L236.65 392.6 304.65 353.33 301.35 333.7 233.34 372.96z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 234.445,
              MsTransformOrigin: 234.445,
              transformOrigin: 234.445,
            }}
            id="elkqk4t1hrgm"
            fill="#E0E0E0"
            d="M232.24 372.32L235.54 391.96 236.65 392.6 233.34 372.96 232.24 372.32z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 234.395,
              MsTransformOrigin: 234.395,
              transformOrigin: 234.395,
            }}
            id="el4g2k8lb67wo"
            fill="#EF8C2D"
            d="M232.93 376.41L234.75 387.27 235.86 387.91 234.03 377.05 232.93 376.41z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 234.395,
              MsTransformOrigin: 234.395,
              transformOrigin: 234.395,
            }}
            id="el7fhyydtl3om"
            d="M232.93 376.41L234.75 387.27 235.86 387.91 234.03 377.05"
            className="animable"
            opacity="0.2"
          />
          <path
            style={{
              WebkitTransformOrigin: 236.46,
              MsTransformOrigin: 236.46,
              transformOrigin: 236.46,
            }}
            id="el1ed4na1k3yl"
            fill="#EBEBEB"
            d="M239.58 369.36L236.65 392.6 233.34 372.96 239.58 369.36z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 301.53,
              MsTransformOrigin: 301.53,
              transformOrigin: 301.53,
            }}
            id="elcisu0ypzhs"
            fill="#EBEBEB"
            d="M298.41 356.94L301.35 333.7 304.65 353.33 298.41 356.94z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 246.155,
              MsTransformOrigin: 246.155,
              transformOrigin: 246.155,
            }}
            id="elzxdaw44bdd"
            fill="#EBEBEB"
            d="M248.06 386L251 362.76 244.25 366.66 241.31 389.9 248.06 386z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 257.575,
              MsTransformOrigin: 257.575,
              transformOrigin: 257.575,
            }}
            id="elanpo2ineaz"
            fill="#EBEBEB"
            d="M259.48 379.41L262.42 356.17 255.67 360.07 252.73 383.31 259.48 379.41z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 268.995,
              MsTransformOrigin: 268.995,
              transformOrigin: 268.995,
            }}
            id="elkz4nmbtw8ea"
            fill="#EBEBEB"
            d="M270.9 372.82L273.84 349.58 267.09 353.48 264.15 376.71 270.9 372.82z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 280.415,
              MsTransformOrigin: 280.415,
              transformOrigin: 280.415,
            }}
            id="el7f9ig81yf99"
            fill="#EBEBEB"
            d="M282.32 366.23L285.26 342.99 278.51 346.88 275.57 370.12 282.32 366.23z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 291.835,
              MsTransformOrigin: 291.835,
              transformOrigin: 291.835,
            }}
            id="elgt6l6i6nm8w"
            fill="#EBEBEB"
            d="M293.74 359.63L296.68 336.39 289.93 340.29 286.99 363.53 293.74 359.63z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 247.07,
              MsTransformOrigin: 247.07,
              transformOrigin: 247.07,
            }}
            id="elpvyxhezfyal"
            fill="#FAFAFA"
            d="M244.25 366.66L243.14 366.02 249.9 362.13 251 362.76 244.25 366.66z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 258.49,
              MsTransformOrigin: 258.49,
              transformOrigin: 258.49,
            }}
            id="elb6sio7yvr35"
            fill="#FAFAFA"
            d="M255.67 360.07L254.56 359.43 261.32 355.53 262.42 356.17 255.67 360.07z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 269.91,
              MsTransformOrigin: 269.91,
              transformOrigin: 269.91,
            }}
            id="el9koodkdonb5"
            fill="#FAFAFA"
            d="M267.09 353.48L265.98 352.84 272.74 348.94 273.84 349.58 267.09 353.48z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 281.33,
              MsTransformOrigin: 281.33,
              transformOrigin: 281.33,
            }}
            id="elci3i6o4dov"
            fill="#FAFAFA"
            d="M278.51 346.88L277.4 346.24 284.16 342.35 285.26 342.99 278.51 346.88z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 292.75,
              MsTransformOrigin: 292.75,
              transformOrigin: 292.75,
            }}
            id="eltv76t1fbk5h"
            fill="#FAFAFA"
            d="M289.93 340.29L288.82 339.65 295.58 335.76 296.68 336.39 289.93 340.29z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 235.91,
              MsTransformOrigin: 235.91,
              transformOrigin: 235.91,
            }}
            id="el7n4tbehy7t"
            fill="#FAFAFA"
            d="M233.34 372.96L232.24 372.32 238.48 368.72 239.58 369.36 233.34 372.96z"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 268.945,
              MsTransformOrigin: 268.945,
              transformOrigin: 268.945,
            }}
            id="elf3ytu8kq4fi"
            fill="#EF8C2D"
            d="M234.03 377.05L235.86 387.91 303.86 348.65 302.04 337.79 234.03 377.05z"
            className="animable"
          />
          <path
            d="M238.68 383.63a1.7 1.7 0 01-.71-1.33l-.75-4.72q-.06-.33.36-.57l.29-.16c.28-.16.44-.14.47.08l.74 4.62a.86.86 0 00.27.58.4.4 0 00.49 0 .94.94 0 00.4-.52 1.65 1.65 0 00.06-.77l-.74-4.62c0-.21.09-.4.37-.56l.28-.16c.28-.16.44-.14.47.08l.75 4.72a3.29 3.29 0 01-.2 1.86 2.76 2.76 0 01-1.18 1.3 1.36 1.36 0 01-1.37.17z"
            style={{
              WebkitTransformOrigin: 239.348,
              MsTransformOrigin: 239.348,
              transformOrigin: 239.348,
            }}
            id="elytrslv9co79"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M242.46 381.65l-1-6.5c0-.22.09-.4.37-.56l.19-.11a.36.36 0 01.24-.08c.06 0 .12.05.18.14l1.82 2.57a5.66 5.66 0 01.37.65 6.4 6.4 0 01-.19-.77l-.51-3.24c0-.21.08-.4.36-.56l.17-.1c.28-.16.44-.14.47.08l1 6.5c0 .22-.09.4-.37.56l-.14.08a.45.45 0 01-.24.09c-.07 0-.13-.05-.19-.15l-1.92-2.65a4.37 4.37 0 01-.3-.54v.05a5.91 5.91 0 01.16.66l.54 3.34c0 .22-.09.4-.37.56l-.17.1c-.28.12-.44.1-.47-.12z"
            style={{
              WebkitTransformOrigin: 243.695,
              MsTransformOrigin: 243.695,
              transformOrigin: 243.695,
            }}
            id="el85gvp8ao9wf"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M246.84 379.35a.43.43 0 01-.11-.27l-1-6.3a.78.78 0 010-.35.39.39 0 01.19-.21l1.37-.79a1.13 1.13 0 011.52.08 4.93 4.93 0 01.86 2.42 7.3 7.3 0 010 2.93 2.67 2.67 0 01-1.24 1.68l-1.37.79c-.06.05-.17.06-.22.02zm1.34-2.12a1.08 1.08 0 00.47-.82 7 7 0 00-.11-1.82 5.26 5.26 0 00-.44-1.62.41.41 0 00-.63-.19l-.47.22.71 4.45z"
            style={{
              WebkitTransformOrigin: 247.764,
              MsTransformOrigin: 247.764,
              transformOrigin: 247.764,
            }}
            id="elqzl7os9dq4e"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M251 377a.38.38 0 01-.12-.27l-1-6.3a.66.66 0 010-.35.37.37 0 01.18-.21l2.13-1.23a.12.12 0 01.17 0 .82.82 0 01.12.36l.05.31a1.52 1.52 0 010 .42.3.3 0 01-.14.21l-1.27.73.23 1.48 1.13-.65a.13.13 0 01.18 0 1 1 0 01.11.36v.31a1.15 1.15 0 010 .41.3.3 0 01-.14.22l-1.12.65.26 1.64 1.27-.73a.13.13 0 01.18 0 1 1 0 01.11.36l.05.31a1.52 1.52 0 010 .42.29.29 0 01-.13.21l-2.14 1.23c.01.11-.06.11-.11.11z"
            style={{
              WebkitTransformOrigin: 251.625,
              MsTransformOrigin: 251.625,
              transformOrigin: 251.625,
            }}
            id="elrfo6rfiv1ap"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M254.17 374.89l-1-6.4a.66.66 0 010-.35.47.47 0 01.2-.22l1.21-.7a1.2 1.2 0 011.13-.15c.32.15.54.59.65 1.31a2.89 2.89 0 01-.41 2.11.59.59 0 01.35.24 1.59 1.59 0 01.29.49l.74 1.71v.11c0 .18-.1.36-.4.53l-.26.15c-.25.14-.41.14-.47 0l-.69-1.71a.52.52 0 00-.2-.23q-.09 0-.27.06l-.18.1.37 2.3c0 .22-.09.41-.38.57l-.25.14c-.23.18-.4.16-.43-.06zm.91-4.65a.57.57 0 00.23-.38 1.56 1.56 0 000-.72.89.89 0 00-.21-.55.24.24 0 00-.32 0l-.38.22.27 1.7z"
            style={{
              WebkitTransformOrigin: 255.238,
              MsTransformOrigin: 255.238,
              transformOrigin: 255.238,
            }}
            id="eleps5m30piui"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M259.11 371.5a4.87 4.87 0 01-.9-2.51 7 7 0 010-3 2.84 2.84 0 011.34-1.75 2.16 2.16 0 01.64-.25c.16 0 .26.08.3.31a2.63 2.63 0 010 .74c0 .25-.1.37-.2.38a1.19 1.19 0 00-.48.16 1.26 1.26 0 00-.56.93 5.76 5.76 0 00.06 1.82 4.15 4.15 0 00.48 1.58.53.53 0 00.78.17 1 1 0 00.22-.16 2.45 2.45 0 00.23-.23h.06c.1-.06.19 0 .26.13a1.84 1.84 0 01.18.62.71.71 0 010 .29.56.56 0 01-.14.26 2.25 2.25 0 01-.31.3 3 3 0 01-.41.28c-.66.35-1.16.34-1.55-.07z"
            style={{
              WebkitTransformOrigin: 259.791,
              MsTransformOrigin: 259.791,
              transformOrigin: 259.791,
            }}
            id="elbr85foyuddq"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M261.35 367.19c-.39-2.42 0-4 1.31-4.72a1.17 1.17 0 011.54.07 4.67 4.67 0 01.92 2.48 7.18 7.18 0 010 3 2.77 2.77 0 01-1.26 1.69q-1.93 1.13-2.51-2.52zm2.65.81a2.34 2.34 0 00.12-.84 10.34 10.34 0 00-.14-1.45 8.5 8.5 0 00-.3-1.33 1 1 0 00-.35-.57.41.41 0 00-.45 0 1 1 0 00-.39.45 2.13 2.13 0 00-.13.85 8.7 8.7 0 00.14 1.43 7.79 7.79 0 00.3 1.35 1.11 1.11 0 00.36.57.44.44 0 00.45 0 .91.91 0 00.39-.46z"
            style={{
              WebkitTransformOrigin: 263.242,
              MsTransformOrigin: 263.242,
              transformOrigin: 263.242,
            }}
            id="elnhedv8poggd"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M266.28 367.89l-1-6.5c0-.21.09-.4.37-.56l.18-.11a.48.48 0 01.24-.08.24.24 0 01.18.15l1.82 2.56c.1.14.22.36.38.65a7.63 7.63 0 01-.18-.76l-.52-3.24c0-.22.09-.41.37-.57l.17-.1c.28-.16.43-.13.47.08l1 6.5c0 .22-.09.41-.37.57l-.14.08a.56.56 0 01-.24.08.23.23 0 01-.19-.14l-1.93-2.66a4.37 4.37 0 01-.3-.54v.05a4.58 4.58 0 01.17.66l.53 3.34q.06.33-.36.57l-.18.1c-.28.12-.43.09-.47-.13z"
            style={{
              WebkitTransformOrigin: 267.52,
              MsTransformOrigin: 267.52,
              transformOrigin: 267.52,
            }}
            id="el9jouivjtgv6"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M271 365.44a.68.68 0 01-.57-.11.54.54 0 01-.15-.3 2 2 0 010-.64c0-.24.1-.38.19-.43a.31.31 0 01.2 0h.21a.8.8 0 00.52-.08 1 1 0 00.37-.38 1 1 0 00.08-.62.82.82 0 00-.16-.41.52.52 0 00-.26-.19 2 2 0 00-.41-.1 1.67 1.67 0 01-.94-.46 2.28 2.28 0 01-.46-1.17 3 3 0 01.19-1.67 2.41 2.41 0 011-1.16 1.6 1.6 0 01.53-.19.9.9 0 01.47 0 .3.3 0 01.18.27 2.44 2.44 0 010 .61c0 .23-.08.37-.16.41a.39.39 0 01-.22 0 1.1 1.1 0 00-.49.12.78.78 0 00-.37.4 1.05 1.05 0 000 .46.7.7 0 00.23.49 1.36 1.36 0 00.52.18h.18a1.44 1.44 0 01.86.46 2.23 2.23 0 01.42 1.1 3.7 3.7 0 010 1.15 2.93 2.93 0 01-.4 1.08 2.36 2.36 0 01-.83.8 1.94 1.94 0 01-.73.38z"
            style={{
              WebkitTransformOrigin: 271.291,
              MsTransformOrigin: 271.291,
              transformOrigin: 271.291,
            }}
            id="elolec9rklr2s"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M274.37 363.23l-.87-5.48-.8.46a.13.13 0 01-.18 0 1 1 0 01-.11-.35l-.05-.32a1.21 1.21 0 010-.42.32.32 0 01.14-.21l2.74-1.58a.13.13 0 01.18 0 .71.71 0 01.11.35l.05.32a1 1 0 010 .41.3.3 0 01-.14.22l-.81.47.87 5.47q.06.33-.36.57l-.3.17c-.28.16-.43.13-.47-.08z"
            style={{
              WebkitTransformOrigin: 273.971,
              MsTransformOrigin: 273.971,
              transformOrigin: 273.971,
            }}
            id="eldy0rbv3p7s"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M277 361.73l-1-6.4a.67.67 0 010-.35.49.49 0 01.21-.22l1.2-.7a1.22 1.22 0 011.14-.15c.32.15.53.59.65 1.31a2.89 2.89 0 01-.41 2.11.65.65 0 01.35.24 2.11 2.11 0 01.29.49l.73 1.71.05.11c0 .18-.11.36-.41.53l-.25.15c-.25.14-.41.14-.48 0l-.68-1.71a.59.59 0 00-.2-.23.32.32 0 00-.28.06l-.17.1.36 2.3c0 .21-.09.41-.38.57l-.24.14c-.32.21-.48.15-.48-.06zm.92-4.65a.61.61 0 00.23-.38 1.9 1.9 0 000-.72 1.12 1.12 0 00-.21-.56.26.26 0 00-.33 0l-.38.22.27 1.7z"
            style={{
              WebkitTransformOrigin: 278.093,
              MsTransformOrigin: 278.093,
              transformOrigin: 278.093,
            }}
            id="eljvwrill2l1a"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M281.07 359.16a1.72 1.72 0 01-.72-1.33l-.75-4.72c0-.22.09-.41.37-.57l.29-.16c.28-.17.43-.14.47.08l.73 4.62a.91.91 0 00.28.58.39.39 0 00.48 0 1 1 0 00.41-.52 1.65 1.65 0 00.06-.77l-.74-4.62c0-.22.09-.4.37-.56l.28-.17c.28-.16.43-.13.47.09l.75 4.72a3.3 3.3 0 01-.2 1.86 2.8 2.8 0 01-1.18 1.3 1.38 1.38 0 01-1.37.17z"
            style={{
              WebkitTransformOrigin: 281.737,
              MsTransformOrigin: 281.737,
              transformOrigin: 281.737,
            }}
            id="ellld7zrh1xn"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M285.12 356.48a4.91 4.91 0 01-.91-2.51 7.19 7.19 0 01.05-3 2.87 2.87 0 011.34-1.75 2.06 2.06 0 01.64-.25c.16 0 .26.08.3.31a2.7 2.7 0 010 .75c0 .24-.1.36-.21.37a1.2 1.2 0 00-1 1.1 5.77 5.77 0 00.06 1.82 4.15 4.15 0 00.48 1.57.51.51 0 00.77.17 1.53 1.53 0 00.23-.15l.23-.23.06-.05c.06-.05.18 0 .26.13a2.09 2.09 0 01.18.63.87.87 0 010 .28.7.7 0 01-.14.27c-.08.08-.18.18-.31.29a3.81 3.81 0 01-.41.29c-.69.39-1.23.37-1.62-.04z"
            style={{
              WebkitTransformOrigin: 285.844,
              MsTransformOrigin: 285.844,
              transformOrigin: 285.844,
            }}
            id="elxmd8mizpyr"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M288.84 354.87l-.84-5.47-.8.46a.13.13 0 01-.18 0 1 1 0 01-.11-.36l-.05-.31a1.21 1.21 0 010-.42.3.3 0 01.14-.21l2.7-1.56a.12.12 0 01.18 0 1 1 0 01.11.36l.05.32a1.15 1.15 0 010 .41.34.34 0 01-.14.22l-.81.46.87 5.48c0 .21-.09.4-.36.56l-.3.17c-.3.13-.42.11-.46-.11z"
            style={{
              WebkitTransformOrigin: 288.45,
              MsTransformOrigin: 288.45,
              transformOrigin: 288.45,
            }}
            id="elghjzf3ak78"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M291.42 353.38l-1-6.5c0-.22.09-.41.37-.57l.3-.18c.27-.16.43-.12.47.1l1 6.5q.06.33-.36.57l-.3.18c-.29.16-.44.13-.48-.1z"
            style={{
              WebkitTransformOrigin: 291.494,
              MsTransformOrigin: 291.494,
              transformOrigin: 291.494,
            }}
            id="eld3wyyv0xjh"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M292.69 349.1q-.58-3.63 1.31-4.73a1.18 1.18 0 011.54.08 4.63 4.63 0 01.92 2.47 7.14 7.14 0 010 3 2.72 2.72 0 01-1.26 1.7c-1.3.75-2.13-.1-2.51-2.52zm2.65.77a2.36 2.36 0 00.12-.85 10.35 10.35 0 00-.14-1.44 10.06 10.06 0 00-.3-1.34 1.18 1.18 0 00-.35-.57.44.44 0 00-.45 0 .91.91 0 00-.39.45 2.11 2.11 0 00-.13.84 8.62 8.62 0 00.13 1.44 8.21 8.21 0 00.31 1.34 1 1 0 00.36.57.42.42 0 00.45 0 .94.94 0 00.39-.44z"
            style={{
              WebkitTransformOrigin: 294.587,
              MsTransformOrigin: 294.587,
              transformOrigin: 294.587,
            }}
            id="eliqlcengfft"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M297.62 349.8l-1-6.5c0-.21.08-.4.36-.56l.19-.11a.48.48 0 01.24-.08q.09 0 .18.15l1.82 2.56a5.09 5.09 0 01.37.65 6 6 0 01-.18-.77l-.52-3.24c0-.21.09-.4.36-.56l.18-.1c.28-.16.43-.14.47.08l1 6.5c0 .22-.09.41-.36.57l-.14.07a.51.51 0 01-.25.09c-.07 0-.13-.05-.19-.14l-1.92-2.66a4.37 4.37 0 01-.3-.54v.05a6.5 6.5 0 01.17.66l.53 3.34c0 .22-.09.4-.37.57l-.17.09c-.28.13-.43.08-.47-.12z"
            style={{
              WebkitTransformOrigin: 298.855,
              MsTransformOrigin: 298.855,
              transformOrigin: 298.855,
            }}
            id="eldd60fvyi2z6"
            fill="#455A64"
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--Character--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 192.366,
          MsTransformOrigin: 192.366,
          transformOrigin: 192.366,
        }}
      >
        <g
          id="freepik--character--inject-9"
          className="animable"
          style={{
            WebkitTransformOrigin: 192.366,
            MsTransformOrigin: 192.366,
            transformOrigin: 192.366,
          }}
        >
          <path
            d="M205.66 252.17L178 327.6a1 1 0 01-.48.52 2.82 2.82 0 01-2.53 0 1 1 0 01-.52-.62.74.74 0 010-.21 2 2 0 01.1-.52l27.79-75.85a1.79 1.79 0 012.3-1.06 1.77 1.77 0 011 2.31z"
            style={{
              WebkitTransformOrigin: 190.125,
              MsTransformOrigin: 190.125,
              transformOrigin: 190.125,
            }}
            id="ellftw236dos"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M205.66 252.17L178 327.6a1 1 0 01-.48.52 2.82 2.82 0 01-2.53 0 1 1 0 01-.52-.62.74.74 0 010-.21 2 2 0 01.1-.52l27.79-75.85a1.79 1.79 0 012.3-1.06 1.77 1.77 0 011 2.31z"
            style={{
              WebkitTransformOrigin: 190.125,
              MsTransformOrigin: 190.125,
              transformOrigin: 190.125,
            }}
            id="el04t2r4ljkyy8"
            className="animable"
            opacity="0.5"
          />
          <path
            style={{
              WebkitTransformOrigin: 182.51,
              MsTransformOrigin: 182.51,
              transformOrigin: 182.51,
            }}
            id="eluecofdozdnb"
            fill="#EF8C2D"
            d="M180.51 294.71H184.51V326.79999999999995H180.51z"
            className="animable"
            transform="rotate(-60)"
          />
          <path
            style={{
              WebkitTransformOrigin: 182.51,
              MsTransformOrigin: 182.51,
              transformOrigin: 182.51,
            }}
            id="eleugckcmnj0c"
            d="M180.51 294.71H184.51V326.79999999999995H180.51z"
            className="animable"
            opacity="0.6"
            transform="rotate(-60)"
          />
          <path
            style={{
              WebkitTransformOrigin: 202.72,
              MsTransformOrigin: 202.72,
              transformOrigin: 202.72,
            }}
            id="el1zt3wwy4hbs"
            fill="#EF8C2D"
            d="M200.72 283.05H204.72V315.14H200.72z"
            className="animable"
            transform="rotate(-60)"
          />
          <path
            style={{
              WebkitTransformOrigin: 202.72,
              MsTransformOrigin: 202.72,
              transformOrigin: 202.72,
            }}
            id="elkz85bpkgpln"
            d="M200.72 283.05H204.72V315.14H200.72z"
            className="animable"
            opacity="0.6"
            transform="rotate(-60)"
          />
          <path
            d="M183.05 247l14.82-8.56a4.11 4.11 0 013.72-.29 4.07 4.07 0 012.27 3l24.61 115.11a2.24 2.24 0 010 .24 1 1 0 010 .25 1 1 0 01-.51.62 2.82 2.82 0 01-2.53 0 1.12 1.12 0 01-.49-.54v-.11l-24.58-114.88c-.05-.25-.15-.4-.23-.43a.64.64 0 00-.47.12l-14.83 8.56"
            style={{
              WebkitTransformOrigin: 205.764,
              MsTransformOrigin: 205.764,
              transformOrigin: 205.764,
            }}
            id="el6xkqy1v4f2i"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M183.05 247l14.82-8.56a4.11 4.11 0 013.72-.29 4.07 4.07 0 012.27 3l24.61 115.11a2.24 2.24 0 010 .24 1 1 0 010 .25 1 1 0 01-.51.62 2.82 2.82 0 01-2.53 0 1.12 1.12 0 01-.49-.54v-.11l-24.58-114.88c-.05-.25-.15-.4-.23-.43a.64.64 0 00-.47.12l-14.83 8.56"
            style={{
              WebkitTransformOrigin: 205.764,
              MsTransformOrigin: 205.764,
              transformOrigin: 205.764,
            }}
            id="elih5mwpov7h"
            className="animable"
            opacity="0.4"
          />
          <path
            d="M195.49 302.09l-9-5.22a1.2 1.2 0 01-.53-.93v-.22a1.2 1.2 0 01.53-.93l21.67-12.51a1.19 1.19 0 011.07 0l9 5.22a1.18 1.18 0 01.54.93v.22a1.18 1.18 0 01-.54.93l-21.66 12.51a1.21 1.21 0 01-1.08 0z"
            style={{
              WebkitTransformOrigin: 202.365,
              MsTransformOrigin: 202.365,
              transformOrigin: 202.365,
            }}
            id="eleplm3p7k0y8"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M196 300.94v1.27a1.16 1.16 0 00.54-.12l10.83-6.26 10.83-6.25a1.18 1.18 0 00.54-.93v-.22a1.08 1.08 0 00-.16-.53z"
            style={{
              WebkitTransformOrigin: 207.37,
              MsTransformOrigin: 207.37,
              transformOrigin: 207.37,
            }}
            id="elwpi1sfo2m3d"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M186.08 295.19a1.18 1.18 0 00-.15.53v.22a1.2 1.2 0 00.53.93l9 5.22a1.19 1.19 0 00.54.12v-1.27z"
            style={{
              WebkitTransformOrigin: 190.965,
              MsTransformOrigin: 190.965,
              transformOrigin: 190.965,
            }}
            id="eldjcejglwxbs"
            className="animable"
            opacity="0.1"
          />
          <path
            d="M191.58 283.78l-15-8.66a1.2 1.2 0 01-.53-.94V274a1.2 1.2 0 01.53-.93l21.67-12.51a1.19 1.19 0 011.07 0l15 8.66a1.21 1.21 0 01.54.93v.22a1.21 1.21 0 01-.54.93l-21.66 12.51a1.21 1.21 0 01-1.08-.03z"
            style={{
              WebkitTransformOrigin: 195.455,
              MsTransformOrigin: 195.455,
              transformOrigin: 195.455,
            }}
            id="el5szu7ydrewg"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M192.12 282.63v1.28a1.2 1.2 0 00.54-.13l10.83-6.25 10.83-6.26a1.21 1.21 0 00.54-.93v-.22a1.08 1.08 0 00-.16-.53z"
            style={{
              WebkitTransformOrigin: 203.49,
              MsTransformOrigin: 203.49,
              transformOrigin: 203.49,
            }}
            id="elvyzf5pzeb5"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M176.19 273.44a1.18 1.18 0 00-.15.53v.21a1.2 1.2 0 00.53.94l15 8.66a1.16 1.16 0 00.54.13v-1.28z"
            style={{
              WebkitTransformOrigin: 184.075,
              MsTransformOrigin: 184.075,
              transformOrigin: 184.075,
            }}
            id="el8sbj5ybq9r6"
            className="animable"
            opacity="0.1"
          />
          <path
            d="M207.24 357l-9-5.21a1.18 1.18 0 01-.54-.93v-.22a1.18 1.18 0 01.54-.93l21.66-12.51a1.21 1.21 0 011.08 0l9 5.22a1.21 1.21 0 01.54.93v.21a1.21 1.21 0 01-.54.94L208.31 357a1.13 1.13 0 01-1.07 0z"
            style={{
              WebkitTransformOrigin: 214.11,
              MsTransformOrigin: 214.11,
              transformOrigin: 214.11,
            }}
            id="el2yvis2a5wnn"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M197.83 350.11a1.08 1.08 0 00-.16.53v.22a1.18 1.18 0 00.54.93l9 5.21a1.05 1.05 0 00.54.13v-1.27z"
            style={{
              WebkitTransformOrigin: 202.71,
              MsTransformOrigin: 202.71,
              transformOrigin: 202.71,
            }}
            id="el60lk0ctkb5i"
            className="animable"
            opacity="0.1"
          />
          <path
            d="M207.78 355.86v1.27a1 1 0 00.53-.13l10.84-6.25L230 344.5a1.21 1.21 0 00.54-.94v-.21a1.2 1.2 0 00-.16-.53z"
            style={{
              WebkitTransformOrigin: 219.16,
              MsTransformOrigin: 219.16,
              transformOrigin: 219.16,
            }}
            id="eldvx17swd445"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M203.32 338.7l-9-5.22a1.2 1.2 0 01-.53-.93v-.21a1.22 1.22 0 01.53-.94L216 318.9a1.19 1.19 0 011.07 0l9 5.21a1.18 1.18 0 01.54.93v.22a1.18 1.18 0 01-.54.93L204.4 338.7a1.21 1.21 0 01-1.08 0z"
            style={{
              WebkitTransformOrigin: 210.2,
              MsTransformOrigin: 210.2,
              transformOrigin: 210.2,
            }}
            id="elcm1ahofde2i"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M193.91 331.81a1.12 1.12 0 00-.15.53v.21a1.2 1.2 0 00.53.93l9 5.22a1.2 1.2 0 00.54.13v-1.28z"
            style={{
              WebkitTransformOrigin: 198.795,
              MsTransformOrigin: 198.795,
              transformOrigin: 198.795,
            }}
            id="el534li9jval"
            className="animable"
            opacity="0.1"
          />
          <path
            d="M203.86 337.55v1.28a1.16 1.16 0 00.54-.13l10.83-6.26 10.83-6.25a1.18 1.18 0 00.54-.93V325a1.08 1.08 0 00-.16-.53z"
            style={{
              WebkitTransformOrigin: 215.23,
              MsTransformOrigin: 215.23,
              transformOrigin: 215.23,
            }}
            id="elbom628x4d2"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M199.41 320.39l-9-5.21a1.18 1.18 0 01-.54-.93V314a1.18 1.18 0 01.54-.93L212 300.59a1.21 1.21 0 011.08 0l9 5.21a1.23 1.23 0 01.54.94v.26a1.19 1.19 0 01-.54.93l-21.66 12.51a1.21 1.21 0 01-1.01-.05z"
            style={{
              WebkitTransformOrigin: 206.245,
              MsTransformOrigin: 206.245,
              transformOrigin: 206.245,
            }}
            id="elp5b8ob44cb"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M190 313.5a1.08 1.08 0 00-.16.53v.22a1.18 1.18 0 00.54.93l4.51 2.6 4.52 2.61a1.05 1.05 0 00.54.13v-1.28z"
            style={{
              WebkitTransformOrigin: 194.895,
              MsTransformOrigin: 194.895,
              transformOrigin: 194.895,
            }}
            id="elximpdmlm9fm"
            className="animable"
            opacity="0.1"
          />
          <path
            d="M200 319.24v1.28a1.08 1.08 0 00.54-.13l10.83-6.25 10.83-6.26a1.19 1.19 0 00.54-.93v-.21a1.05 1.05 0 00-.16-.53z"
            style={{
              WebkitTransformOrigin: 211.37,
              MsTransformOrigin: 211.37,
              transformOrigin: 211.37,
            }}
            id="elr5z8v2cj4q"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M222.41 310.74l-.75-8-6.77.4s2.29 8.74 2.67 9.55c1.18.18 4.85-1.95 4.85-1.95z"
            style={{
              WebkitTransformOrigin: 218.65,
              MsTransformOrigin: 218.65,
              transformOrigin: 218.65,
            }}
            id="elxnwm0ze4h9"
            fill="#FFA8A7"
            className="animable"
          />
          <path
            d="M224.52 313.49a3.87 3.87 0 010 .9c-.06 1-.13 2.07-.19 3.1a8.31 8.31 0 01-.16 1.22 8 8 0 000 1.47v1.54a2.9 2.9 0 000 .66c.06.22-3.23 2.15-4.56 2.9l-.24.12a2.11 2.11 0 01-2.17-.48 3.69 3.69 0 01.85-4.07s.17 0 .12-1.3c0-.93 0-1.87-.13-2.8-.09-.68-.23-1.36-.35-2s-.2-1.33-.4-2.27a1.19 1.19 0 010-.72 1 1 0 01.33-.36 7.22 7.22 0 014.29-1.64.78.78 0 01.52.13.76.76 0 01.2.4 4.12 4.12 0 00.33 1.1 3.3 3.3 0 00.6.64 3.3 3.3 0 011 1.3 1.94 1.94 0 01-.04.16z"
            style={{
              WebkitTransformOrigin: 220.74,
              MsTransformOrigin: 220.74,
              transformOrigin: 220.74,
            }}
            id="elbfzjb9666b6"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M225.17 313.83c.62.23.48 1.92.11 3.55a7.77 7.77 0 00-.36 3.54c.22 1.35.34 2-.21 2.46a53.93 53.93 0 01-4.9 2.6 16.1 16.1 0 00.92-5.09c.07-3 .25-4.92 1.62-6.3a2.63 2.63 0 012.82-.76z"
            style={{
              WebkitTransformOrigin: 222.703,
              MsTransformOrigin: 222.703,
              transformOrigin: 222.703,
            }}
            id="ely5anygm5qw"
            fill="#263238"
            className="animable"
          />
          <path
            d="M214.2 323.39a8.2 8.2 0 003.67 1.55c2 .2 2-.55 2.23-2.87s.22-5.53 1.11-7a3.8 3.8 0 013-1.93c.13-.06.73.38.93.65a3.32 3.32 0 00-2.55 1.05c-1.21 1.21-1.4 3.74-1.43 6s-.09 4.95-1.92 5.25a6.66 6.66 0 01-5-1.45.93.93 0 01-.04-1.25z"
            style={{
              WebkitTransformOrigin: 219.559,
              MsTransformOrigin: 219.559,
              transformOrigin: 219.559,
            }}
            id="el1bhm5eb92ct"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M218 320.85a4.06 4.06 0 00-.42.63 4.43 4.43 0 00-.35 3.36c-2.34-.19-3-1.45-3-1.45a2.24 2.24 0 011.19-2 3.06 3.06 0 01.5-.27 3.42 3.42 0 012.08-.27z"
            style={{
              WebkitTransformOrigin: 216.115,
              MsTransformOrigin: 216.115,
              transformOrigin: 216.115,
            }}
            id="elw0fhrbnlg9k"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M218.15 320a.66.66 0 00-.27.42 1.32 1.32 0 00.68 1.2c.25.14.46.08.06-.4a1.49 1.49 0 01-.37-.55 2.53 2.53 0 01-.1-.67z"
            style={{
              WebkitTransformOrigin: 218.361,
              MsTransformOrigin: 218.361,
              transformOrigin: 218.361,
            }}
            id="elsqlmc2f4p1"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M218.11 318.35a.7.7 0 00-.18.38 2 2 0 00.82 1.26c.43.18.19-.25 0-.47a3.71 3.71 0 01-.45-.7 2.42 2.42 0 01-.19-.47z"
            style={{
              WebkitTransformOrigin: 218.46,
              MsTransformOrigin: 218.46,
              transformOrigin: 218.46,
            }}
            id="elqqr1ztrd6o"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M218 316.57a.47.47 0 00-.1.41 2.22 2.22 0 001 .73c.19 0 .34-.12 0-.34a6.13 6.13 0 01-.63-.44 1.37 1.37 0 01-.27-.36z"
            style={{
              WebkitTransformOrigin: 218.495,
              MsTransformOrigin: 218.495,
              transformOrigin: 218.495,
            }}
            id="el6umlr6p6a2k"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M222.15 305.8a9.54 9.54 0 00-6.28 1.66s-.62-1.68-3.71-12.63c-1.14-4-1.89-7.48-2.57-10.07a29.7 29.7 0 01-1.19-6.09c-.55-6.94-.72-27.19-1-29.8 0 0 14.4-4.4 14.41-4.37a26.74 26.74 0 010 12.47c-1.2 8.46-2.26 17.72-2.65 22.28a26.31 26.31 0 011.93 7c.37 2.75 1.06 19.55 1.06 19.55z"
            style={{
              WebkitTransformOrigin: 214.974,
              MsTransformOrigin: 214.974,
              transformOrigin: 214.974,
            }}
            id="elgg9cbgc24t8"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M191.12 312.75c-.27-.05-.12.9-.06 1.26s.61 1.25 2.54 1.83 3.72.22 5 .51 3.32 1.64 4.47 1.94a6.42 6.42 0 004.13-.37c.32-.18 1-.65 1-1s.05-.79 0-.79z"
            style={{
              WebkitTransformOrigin: 199.593,
              MsTransformOrigin: 199.593,
              transformOrigin: 199.593,
            }}
            id="el5k2mj38gc8"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M208.13 316.15a3.84 3.84 0 01-1.91 1.25 6 6 0 01-3.35 0 9.84 9.84 0 01-2-.89 11.25 11.25 0 00-2.19-.8 29.06 29.06 0 00-3.85-.42 4.11 4.11 0 01-2.38-.9 2.88 2.88 0 01-.73-2.22 1.43 1.43 0 01.07-.58c.08-.17.21-.2.38-.31.4-.24.75-.56 1.17-.77.57-.29 2.82-1 3.75-.66l.49.11c.38.08 1.86.24 2.25.25a1.76 1.76 0 001.1-.3c.27-.21.45-.52.75-.67a1.54 1.54 0 011.06 0 2.8 2.8 0 001.83 0c.7-.26 1.32-.9 2-1.18a.59.59 0 01.67.13 1.07 1.07 0 01.16.79 16.66 16.66 0 00.24 2.72c.16 1.3.9 2.66.49 4.45z"
            style={{
              WebkitTransformOrigin: 199.979,
              MsTransformOrigin: 199.979,
              transformOrigin: 199.979,
            }}
            id="els05ecv41pla"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M198.38 309.91a1.37 1.37 0 00-1.14.6 2.48 2.48 0 00-.32.6 1.64 1.64 0 00-.1.9.16.16 0 00.18.12.19.19 0 00.15-.15 3.37 3.37 0 01.38-1.16 1.5 1.5 0 01.33-.42 1.38 1.38 0 01.48-.25 1.07 1.07 0 01.23 0h.12a.18.18 0 00-.1-.16.4.4 0 00-.21-.08z"
            style={{
              WebkitTransformOrigin: 197.743,
              MsTransformOrigin: 197.743,
              transformOrigin: 197.743,
            }}
            id="eljn3b9d45cqb"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M199.64 310.09a.93.93 0 00-.63.11 1.28 1.28 0 00-.42.58 2.54 2.54 0 00-.26.85c0 .15-.07.75.22.67a.21.21 0 00.15-.14.8.8 0 00.05-.21 3.5 3.5 0 01.23-.9c.13-.32.37-.75.76-.8h.12c0-.07-.06-.11-.12-.13z"
            style={{
              WebkitTransformOrigin: 199.091,
              MsTransformOrigin: 199.091,
              transformOrigin: 199.091,
            }}
            id="elhw9nl2x0mif"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M200.74 309.8a.83.83 0 00-.42.23 1.59 1.59 0 00-.27.45 2.82 2.82 0 00-.22.94 1.33 1.33 0 00.11.71c0 .08.13.17.22.14s.1-.19.12-.27a4 4 0 000-.49 2.91 2.91 0 01.71-1.62l.05-.07c0-.05-.09-.06-.13-.06z"
            style={{
              WebkitTransformOrigin: 200.43,
              MsTransformOrigin: 200.43,
              transformOrigin: 200.43,
            }}
            id="elcbol9s5sqzf"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M197.1 309.88c-.47-.09-1.55.39-2.26 1.75a10.33 10.33 0 00-.8 3.58 3.74 3.74 0 01-2.35-1.36 2.55 2.55 0 01-.59-1.21 2.67 2.67 0 01.83-2c.81-.7 1.63-1.14 3.71-1.11 1.88 0 2 .49 2 .49z"
            style={{
              WebkitTransformOrigin: 194.37,
              MsTransformOrigin: 194.37,
              transformOrigin: 194.37,
            }}
            id="elndo709m00qc"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M206.59 303.16s.24 4.26.4 5.41c.1.64-.37 1.3-2.27 1.88-1.35.4-2.77 0-2.79-.39L201 306z"
            style={{
              WebkitTransformOrigin: 204.001,
              MsTransformOrigin: 204.001,
              transformOrigin: 204.001,
            }}
            id="el7ex0wxp5yr7"
            fill="#FFA8A7"
            className="animable"
          />
          <path
            d="M222.07 245.45l-23.16 2.4s-5 30.32-5.16 33.56c-.08 2.35 7 25.15 7 25.15s4.4.59 6.25-1.58c-.2-3.06-.53-10.82-.75-13.61a43 43 0 00-2-10.66S209.69 265 211.8 258c10.42-4 10.27-12.55 10.27-12.55z"
            style={{
              WebkitTransformOrigin: 207.91,
              MsTransformOrigin: 207.91,
              transformOrigin: 207.91,
            }}
            id="el4kwfdnu3ihm"
            fill="#455A64"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 210.07,
              MsTransformOrigin: 210.07,
              transformOrigin: 210.07,
            }}
            id="el65ktc4v0q79"
            fill="#FFA8A7"
            d="M205.16 208.68L206.12 216.95 214.98 215.66 214.04 206.73 205.16 208.68z"
            className="animable"
          />
          <path
            d="M217.52 211.35c2.48-.26 4.72.22 6 2.61s3 11.06 3.63 15c.61 3.7.89 4.38-1.11 5.69-1.32.86-6.82 3.71-6.82 3.71z"
            style={{
              WebkitTransformOrigin: 222.56,
              MsTransformOrigin: 222.56,
              transformOrigin: 222.56,
            }}
            id="elwrcz2ytzp4"
            fill="#FFA8A7"
            className="animable"
          />
          <path
            d="M216.47 211.19c2.7-.31 4.8-.2 5.82.73s2.1 1.89 2.83 5.49 1.69 8.73 1.69 8.73-2 4.22-9.29 3.63z"
            style={{
              WebkitTransformOrigin: 221.64,
              MsTransformOrigin: 221.64,
              transformOrigin: 221.64,
            }}
            id="el27iosmiknbd"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M216.47 211.19c2.7-.31 4.8-.2 5.82.73s2.1 1.89 2.83 5.49 1.69 8.73 1.69 8.73-2 4.22-9.29 3.63z"
            style={{
              WebkitTransformOrigin: 221.64,
              MsTransformOrigin: 221.64,
              transformOrigin: 221.64,
            }}
            id="el08a1a52butcw"
            className="animable"
            opacity="0.3"
          />
          <path
            d="M221.87 215.37a5.78 5.78 0 00-6.09-4.13s-.65.06-1.25.16c-.31 1.35-6.19 2.85-8.87 1.63-1.06.28-3.8.81-4.68 1.11-2.52.83-3.56 3.32-4 11.55 0 0 1.57 18.81 1.57 24.08a31.44 31.44 0 0014.94.26c7.52-1.68 8.54-4.58 8.54-4.58l-.72-8.64s1.48-16.39.56-21.44z"
            style={{
              WebkitTransformOrigin: 209.576,
              MsTransformOrigin: 209.576,
              transformOrigin: 209.576,
            }}
            id="elo6pjo53iqns"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M221.87 215.37a5.78 5.78 0 00-6.09-4.13s-.65.06-1.25.16c-.31 1.35-6.19 2.85-8.87 1.63-1.06.28-3.8.81-4.68 1.11-2.52.83-3.56 3.32-4 11.55 0 0 1.57 18.81 1.57 24.08a31.44 31.44 0 0014.94.26c7.52-1.68 8.54-4.58 8.54-4.58l-.72-8.64s1.48-16.39.56-21.44z"
            style={{
              WebkitTransformOrigin: 209.576,
              MsTransformOrigin: 209.576,
              transformOrigin: 209.576,
            }}
            id="el94cp58v6u2t"
            fill="#FFF"
            className="animable"
            opacity="0.7"
          />
          <path
            d="M169.78 208.6a2.16 2.16 0 011.94 1.69c0 .7-1.47 1.2-1.47 1.2z"
            style={{
              WebkitTransformOrigin: 170.75,
              MsTransformOrigin: 170.75,
              transformOrigin: 170.75,
            }}
            id="el8kvrvb8iq3"
            fill="#F28F8F"
            className="animable"
          />
          <g
            id="freepik--Brush--inject-9"
            className="animable"
            style={{
              WebkitTransformOrigin: 166.403,
              MsTransformOrigin: 166.403,
              transformOrigin: 166.403,
            }}
          >
            <path
              d="M166.13 204l1.81 10.56a.68.68 0 00.5.5 2.2 2.2 0 002-.33.73.73 0 00.32-.64l-1.81-10.57z"
              style={{
                WebkitTransformOrigin: 168.445,
                MsTransformOrigin: 168.445,
                transformOrigin: 168.445,
              }}
              id="elrgjtxwktrq"
              fill="#455A64"
              className="animable"
            />
            <path
              style={{
                WebkitTransformOrigin: 167.755,
                MsTransformOrigin: 167.755,
                transformOrigin: 167.755,
              }}
              id="elwm35f0kthqj"
              d="M169.38 206.24L166.87 208.32 166.13 204 168.91 203.52 169.38 206.24z"
              className="animable"
              opacity="0.15"
            />
            <path
              d="M168.86 194.81l.37 2.15 1.68-1.39.76 4.42a4.66 4.66 0 01-1.46 4l-3 2.45a1.58 1.58 0 01-1.38.42s-1.81-.65-2.18-.81a1.52 1.52 0 01-.79-1.22l-.76-4.42z"
              style={{
                WebkitTransformOrigin: 166.903,
                MsTransformOrigin: 166.903,
                transformOrigin: 166.903,
              }}
              id="ely970rikx3dk"
              fill="#37474F"
              className="animable"
            />
            <path
              d="M167.26 206.41l3-2.45a4.66 4.66 0 001.46-4l-.76-4.42-6.71 5.57.76 4.42a1.3 1.3 0 002.25.88z"
              style={{
                WebkitTransformOrigin: 168.003,
                MsTransformOrigin: 168.003,
                transformOrigin: 168.003,
              }}
              id="el7dwvio5jrhi"
              fill="#455A64"
              className="animable"
            />
            <path
              style={{
                WebkitTransformOrigin: 167,
                MsTransformOrigin: 167,
                transformOrigin: 167,
              }}
              id="el5ikdmhptyxl"
              fill="#F0F0F0"
              d="M164.2 201.14L170.91 195.57 169.81 189.12 163.09 194.69 164.2 201.14z"
              className="animable"
            />
            <path
              style={{
                WebkitTransformOrigin: 162.625,
                MsTransformOrigin: 162.625,
                transformOrigin: 162.625,
              }}
              id="elwol71sr88vm"
              fill="#EBEBEB"
              d="M164.2 201.14L163.09 194.69 161.05 193.93 162.15 200.38 164.2 201.14z"
              className="animable"
            />
            <path
              style={{
                WebkitTransformOrigin: 167.96,
                MsTransformOrigin: 167.96,
                transformOrigin: 167.96,
              }}
              id="elc15go5vzuea"
              fill="#FAFAFA"
              d="M164.53 203.06L171.24 197.49 171.39 198.36 164.69 203.98 164.53 203.06z"
              className="animable"
            />
            <path
              style={{
                WebkitTransformOrigin: 163.585,
                MsTransformOrigin: 163.585,
                transformOrigin: 163.585,
              }}
              id="elk6pxi96jfzp"
              fill="#E6E6E6"
              d="M164.53 203.06L162.48 202.3 162.64 203.22 164.69 203.98 164.53 203.06z"
              className="animable"
            />
            <path
              d="M161.39 195.9s.89 1.43 2.13 1.3l-.43-2.51-2-.76z"
              style={{
                WebkitTransformOrigin: 162.305,
                MsTransformOrigin: 162.305,
                transformOrigin: 162.305,
              }}
              id="eleqz5he3vubo"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              d="M161.39 195.9s.89 1.43 2.13 1.3l-.43-2.51-2-.76z"
              style={{
                WebkitTransformOrigin: 162.305,
                MsTransformOrigin: 162.305,
                transformOrigin: 162.305,
              }}
              id="el93ly09inn1"
              className="animable"
              opacity="0.1"
            />
            <path
              d="M163.52 197.2a2.71 2.71 0 002.49-.73c.77-.81.68-3.18 2.38-3.52a2.79 2.79 0 012.23.89l-.81-4.72-6.72 5.57z"
              style={{
                WebkitTransformOrigin: 166.855,
                MsTransformOrigin: 166.855,
                transformOrigin: 166.855,
              }}
              id="el5spxw6jqloj"
              fill="#EF8C2D"
              className="animable"
            />
            <path
              style={{
                WebkitTransformOrigin: 165.43,
                MsTransformOrigin: 165.43,
                transformOrigin: 165.43,
              }}
              id="elguurohtzra"
              fill="#EF8C2D"
              d="M169.81 189.12L167.76 188.36 161.05 193.93 163.09 194.69 169.81 189.12z"
              className="animable"
            />
            <path
              style={{
                WebkitTransformOrigin: 165.43,
                MsTransformOrigin: 165.43,
                transformOrigin: 165.43,
              }}
              id="elxemv9l1xogk"
              fill="#FFF"
              d="M169.81 189.12L167.76 188.36 161.05 193.93 163.09 194.69 169.81 189.12z"
              className="animable"
              opacity="0.7"
            />
          </g>
          <path
            d="M201.18 214.07c-1.69.36-1.9-.13-6.46 2.64-4.24 2.57-11.61 6.81-14.38 8.39a45.11 45.11 0 01-7.25-8.9 3.78 3.78 0 01-.38-2c.16-1.73-.21-1.88-.27-3.44-.05-1.22.3-1.88 0-2a1.37 1.37 0 00-1.68.82 6.94 6.94 0 00-.53 1.89s-2.57 2.29-2.84 0l-.49-2.91a2.45 2.45 0 00-2.6 2c-.33 1.52-.54 4.84.59 7 .54 1 2.83 1.88 4.09 2.71 1.41.93 5.69 10.12 8.32 11.89 1.62 1.07 2.67.87 4.76.16 4.69-1.59 16.15-7.32 16.15-7.32s4.79-2.87 2.97-10.93z"
            style={{
              WebkitTransformOrigin: 182.815,
              MsTransformOrigin: 182.815,
              transformOrigin: 182.815,
            }}
            id="ell02gkti2piq"
            fill="#FFA8A7"
            className="animable"
          />
          <path
            d="M201.61 214c-3.72-.08-5.8 1.88-9.86 4.23l-5.84 3.37s-1.14 5.64 3.18 8.21l9.78-4.56c2.74-1.79 4.67-6.72 2.74-11.25z"
            style={{
              WebkitTransformOrigin: 194.082,
              MsTransformOrigin: 194.082,
              transformOrigin: 194.082,
            }}
            id="eln5llo36zguf"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M201.61 214c-3.72-.08-5.8 1.88-9.86 4.23l-5.84 3.37s-1.14 5.64 3.18 8.21l9.78-4.56c2.74-1.79 4.67-6.72 2.74-11.25z"
            style={{
              WebkitTransformOrigin: 194.082,
              MsTransformOrigin: 194.082,
              transformOrigin: 194.082,
            }}
            id="elhte2suncigp"
            className="animable"
            opacity="0.3"
          />
          <path
            d="M199.5 198s-2 3.92-1.81 4.22a6.63 6.63 0 001.71.75z"
            style={{
              WebkitTransformOrigin: 198.589,
              MsTransformOrigin: 198.589,
              transformOrigin: 198.589,
            }}
            id="el1tpmf53iomj"
            fill="#F28F8F"
            className="animable"
          />
          <path
            d="M200.43 200.45a2.32 2.32 0 112.39-2.32 2.37 2.37 0 01-2.39 2.32zm0-4.13a1.81 1.81 0 101.86 1.81 1.83 1.83 0 00-1.86-1.81z"
            style={{
              WebkitTransformOrigin: 200.5,
              MsTransformOrigin: 200.5,
              transformOrigin: 200.5,
            }}
            id="elip717z46l7"
            fill="#263238"
            className="animable"
          />
          <path
            d="M205.07 190.37c-4.07.83-5.24 2.57-5.63 8.83-.4 6.55.1 8 1 8.84.59.59 3.2.88 4.77.8 5.69-.27 7.87-2.09 10.09-5.38 2.61-3.88 3.3-9.13.5-11.29-3.95-3.03-9.08-2.17-10.73-1.8z"
            style={{
              WebkitTransformOrigin: 208.452,
              MsTransformOrigin: 208.452,
              transformOrigin: 208.452,
            }}
            id="el9wc9crm8koi"
            fill="#FFA8A7"
            className="animable"
          />
          <path
            d="M200.43 208c.59.59 3.2.88 4.77.8l-.11-.92a28 28 0 01-4.66.12z"
            style={{
              WebkitTransformOrigin: 202.815,
              MsTransformOrigin: 202.815,
              transformOrigin: 202.815,
            }}
            id="el3dxplwkr3w9"
            fill="#F28F8F"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 201.875,
              MsTransformOrigin: 201.875,
              transformOrigin: 201.875,
            }}
            id="elzw78ru35o99"
            fill="#263238"
            d="M198.26 197.65L205.49 198.55 205.19 199.05 198.26 198.17 198.26 197.65z"
            className="animable"
          />
          <path
            d="M199.53 192.37a2.23 2.23 0 00.61.27 2.37 2.37 0 002.82 2.83 1.64 1.64 0 00.13 1.38 1.61 1.61 0 001.15.77c-.14 0 0 1.1 0 1.2a1.06 1.06 0 00.72.83s.64-2.42 2.74-2.12 2.4 2.93 1.07 4.52-2.56 1-2.58 1c.13.06.17.83.21 1a11.56 11.56 0 00.54 1.73 3.56 3.56 0 002.14 2.19c.75.33 4.25-.07 5-.92 1.24-3.12 3-4.28 4.37-7a11 11 0 001.42-4.15 3.82 3.82 0 00-1.57-3.9 1.78 1.78 0 00-1.74-2.89 2.87 2.87 0 00-1.34-2.91 2.84 2.84 0 00-2.08-.28c-.2 0-1.74.67-1.7.93a2.58 2.58 0 00-5-.17 4.25 4.25 0 00-2.56-1.8 2.86 2.86 0 00-2.59.55 2.66 2.66 0 00-.28 2.93 2 2 0 00-1.86.72 2.3 2.3 0 00-.54 1.82 1.88 1.88 0 00.92 1.47z"
            style={{
              WebkitTransformOrigin: 209.267,
              MsTransformOrigin: 209.267,
              transformOrigin: 209.267,
            }}
            id="el87tfz7uffon"
            fill="#263238"
            className="animable"
          />
          <path
            d="M204.91 201l.08-2.74a1.37 1.37 0 10-.08 2.74z"
            style={{
              WebkitTransformOrigin: 204.284,
              MsTransformOrigin: 204.284,
              transformOrigin: 204.284,
            }}
            id="elwt4gpt76f4"
            fill="#263238"
            className="animable"
          />
          <path
            d="M185.45 263.84l-27.69 75.34-.07.17a1.21 1.21 0 01-.44.43 2.82 2.82 0 01-2.53 0 1 1 0 01-.52-.62.67.67 0 010-.2 1.91 1.91 0 01.1-.53l27.8-75.85a1.78 1.78 0 113.35 1.23z"
            style={{
              WebkitTransformOrigin: 169.876,
              MsTransformOrigin: 169.876,
              transformOrigin: 169.876,
            }}
            id="elz37ubbulep9"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M185.45 263.84l-27.69 75.34-.07.17a1.21 1.21 0 01-.44.43 2.82 2.82 0 01-2.53 0 1 1 0 01-.52-.62.67.67 0 010-.2 1.91 1.91 0 01.1-.53l27.8-75.85a1.78 1.78 0 113.35 1.23z"
            style={{
              WebkitTransformOrigin: 169.876,
              MsTransformOrigin: 169.876,
              transformOrigin: 169.876,
            }}
            id="elq6fjwvgc1n"
            className="animable"
            opacity="0.5"
          />
          <path
            d="M184.83 250.09a2.79 2.79 0 00-1.19 2.67l24.62 115.12a1.27 1.27 0 010 .28.67.67 0 010 .2 1 1 0 01-.51.62 2.76 2.76 0 01-2.53 0 1.18 1.18 0 01-.49-.51v-.15l-24.58-114.8a6.38 6.38 0 012.9-6.53"
            style={{
              WebkitTransformOrigin: 194.159,
              MsTransformOrigin: 194.159,
              transformOrigin: 194.159,
            }}
            id="elfor0y2v3nxe"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M208.26 367.88l-24.62-115.12a2.79 2.79 0 011.19-2.67l-1.78-3.1a6.38 6.38 0 00-2.9 6.53l24.57 114.78v.15a1.18 1.18 0 00.49.51 2.76 2.76 0 002.53 0 1 1 0 00.51-.62.67.67 0 000-.2 1.27 1.27 0 00.01-.26z"
            style={{
              WebkitTransformOrigin: 194.157,
              MsTransformOrigin: 194.157,
              transformOrigin: 194.157,
            }}
            id="eldollzlzlm18"
            className="animable"
            opacity="0.4"
          />
        </g>
      </g>
      <g
        id="freepik--Sign--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 86.4013,
          MsTransformOrigin: 86.4013,
          transformOrigin: 86.4013,
        }}
      >
        <g
          id="freepik--Signal--inject-9"
          className="animable"
          style={{
            WebkitTransformOrigin: 86.4013,
            MsTransformOrigin: 86.4013,
            transformOrigin: 86.4013,
          }}
        >
          <path
            d="M83.33 292.71a.8.8 0 01.48-.67 2.61 2.61 0 012.37 0 .83.83 0 01.49.67v103.65c0 .53-.75.95-1.67.95s-1.67-.42-1.67-.95V292.71z"
            style={{
              WebkitTransformOrigin: 85,
              MsTransformOrigin: 85,
              transformOrigin: 85,
            }}
            id="el3p64h33141o"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M104.34 262.69l-15.75-15.16a2.47 2.47 0 00-.27-.21L86 246a1 1 0 00-1.45.52L68 281.74a2.22 2.22 0 00.44 2.4l15.75 15.15a1.65 1.65 0 00.27.21l2.29 1.32a1 1 0 001.45-.52l16.63-35.21a2.21 2.21 0 00-.49-2.4z"
            style={{
              WebkitTransformOrigin: 86.4085,
              MsTransformOrigin: 86.4085,
              transformOrigin: 86.4085,
            }}
            id="elo0bdyjlfth"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M104.78 265.09L88.15 300.3a1 1 0 01-1.72.32l-15.76-15.16a2.26 2.26 0 01-.44-2.4l16.64-35.21a1 1 0 011.72-.32l15.75 15.16a2.21 2.21 0 01.44 2.4z"
            style={{
              WebkitTransformOrigin: 87.5093,
              MsTransformOrigin: 87.5093,
              transformOrigin: 87.5093,
            }}
            id="el2cb5rhllxxk"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M72.06 284v-.14l16.07-34L103 264.13v.15l-16.07 34z"
            style={{
              WebkitTransformOrigin: 87.53,
              MsTransformOrigin: 87.53,
              transformOrigin: 87.53,
            }}
            id="elige6t3pczzi"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M86.59 300.76l-2.18-1.26a1.56 1.56 0 01-.27-.2l-15.75-15.16a2.11 2.11 0 01-.58-1.3l2.26 1.31a2.2 2.2 0 00.61 1.31l15.75 15.16z"
            style={{
              WebkitTransformOrigin: 77.2,
              MsTransformOrigin: 77.2,
              transformOrigin: 77.2,
            }}
            id="elv01alqmtpq"
            fill="#263238"
            className="animable"
          />
          <path
            d="M88.25 254.32c.41-.23.74 0 .74.43a1.62 1.62 0 01-.74 1.28c-.41.24-.74.05-.74-.42a1.64 1.64 0 01.74-1.29z"
            style={{
              WebkitTransformOrigin: 88.25,
              MsTransformOrigin: 88.25,
              transformOrigin: 88.25,
            }}
            id="el8xazjasid09"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M86.67 292.81c.41-.24.74-.05.74.42a1.64 1.64 0 01-.74 1.29c-.41.23-.74 0-.74-.43a1.62 1.62 0 01.74-1.28z"
            style={{
              WebkitTransformOrigin: 86.67,
              MsTransformOrigin: 86.67,
              transformOrigin: 86.67,
            }}
            id="el2jtd74pa0li"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M92.16 265.84l.8-1.53 2.19-.48a1.74 1.74 0 00-.91-1.45c-.82-.35-2 .32-2.8 1.62a5.8 5.8 0 00-.94 3.26 1.6 1.6 0 01-.2.82l-1.12 2.13-.66 1.24 1.48 1.24.56-1 1-2a1.14 1.14 0 01.68-.62A3.9 3.9 0 0094.5 267l.15-.33-2.2.49z"
            style={{
              WebkitTransformOrigin: 91.835,
              MsTransformOrigin: 91.835,
              transformOrigin: 91.835,
            }}
            id="elfxgn48z3qt7"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M86.59 275.18l-1 1.88-1 2a1.09 1.09 0 01-.68.62 3.86 3.86 0 00-2.26 2.11c-.06.11-.11.22-.16.33l2.2-.49.29 1.27-.8 1.53-2.18.48a1.71 1.71 0 00.9 1.45c.82.35 2-.32 2.8-1.62a5.8 5.8 0 00.94-3.26 1.58 1.58 0 01.2-.82l1.28-2.44.86-1.66z"
            style={{
              WebkitTransformOrigin: 84.49,
              MsTransformOrigin: 84.49,
              transformOrigin: 84.49,
            }}
            id="ell7rqtf75x5"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            d="M94.53 277.26l-6-4.71a.6.6 0 00-.9.15l-.7 1.19a.59.59 0 00.1.73l5.69 5.53c.22.23.45.08.62-.18l1.08-2c.22-.28.24-.59.11-.71z"
            style={{
              WebkitTransformOrigin: 90.7287,
              MsTransformOrigin: 90.7287,
              transformOrigin: 90.7287,
            }}
            id="elid401kvmvs"
            fill="#FAFAFA"
            className="animable"
          />
          <path
            style={{
              WebkitTransformOrigin: 83.985,
              MsTransformOrigin: 83.985,
              transformOrigin: 83.985,
            }}
            id="elkcqrdit3zul"
            fill="#FAFAFA"
            d="M83.01 270.18L86.67 273.23 87.13 272.45 83.46 269.4 83.24 268.54 81.24 267.49 80.84 268.33 82.17 269.98 83.01 270.18z"
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--Paint--inject-9"
        className="animable"
        style={{
          WebkitTransformOrigin: 134.082,
          MsTransformOrigin: 134.082,
          transformOrigin: 134.082,
        }}
      >
        <g
          id="freepik--paint--inject-9"
          className="animable"
          style={{
            WebkitTransformOrigin: 134.082,
            MsTransformOrigin: 134.082,
            transformOrigin: 134.082,
          }}
        >
          <path
            d="M148 347.86h-26l1.17 18.48c0 1.72 1.09 3.45 3.34 4.78 4.51 2.67 11.86 2.75 16.42.18 2.3-1.3 3.45-3 3.45-4.75z"
            style={{
              WebkitTransformOrigin: 135,
              MsTransformOrigin: 135,
              transformOrigin: 135,
            }}
            id="elo1e746a3nc"
            fill="#37474F"
            className="animable"
          />
          <path
            d="M125.82 342.1c-5.11 2.88-5.17 7.64-.12 10.63s13.29 3.07 18.4.2 5.16-7.64.11-10.63-13.28-3.07-18.39-.2z"
            style={{
              WebkitTransformOrigin: 134.957,
              MsTransformOrigin: 134.957,
              transformOrigin: 134.957,
            }}
            id="el3tv1qrpyery"
            fill="#455A64"
            className="animable"
          />
          <path
            d="M146.84 347.64a3.32 3.32 0 01-.18 1 6.28 6.28 0 01-3.12 3.28 19.27 19.27 0 01-17.26-.18 6.35 6.35 0 01-3-3.29 3.12 3.12 0 01-.18-1.09c0-1.58 1.19-3.11 3.3-4.28a17.3 17.3 0 018.64-2 17.48 17.48 0 018.62 2.14c2.06 1.29 3.2 2.83 3.18 4.42z"
            style={{
              WebkitTransformOrigin: 134.97,
              MsTransformOrigin: 134.97,
              transformOrigin: 134.97,
            }}
            id="elpc6frfxw0t"
            fill="#263238"
            className="animable"
          />
          <path
            d="M135 349.7a17.41 17.41 0 00-8.64 2l-.13.07a19.27 19.27 0 0017.26.18l.13-.07a17.7 17.7 0 00-8.62-2.18z"
            style={{
              WebkitTransformOrigin: 134.925,
              MsTransformOrigin: 134.925,
              transformOrigin: 134.925,
            }}
            id="elgtsuaar1689"
            fill="#EF8C2D"
            className="animable"
          />
          <path
            d="M135.86 353.88a8.16 8.16 0 01-2.36-.89 3.35 3.35 0 01-1.61-1.75 1.7 1.7 0 01-.1-.59 1.83 1.83 0 01.18-.75 15.5 15.5 0 00-5.59 1.77l-.13.07a17.65 17.65 0 008.6 2.13c.36.03.69.02 1.01.01z"
            style={{
              WebkitTransformOrigin: 131.055,
              MsTransformOrigin: 131.055,
              transformOrigin: 131.055,
            }}
            id="elx24598uhw8d"
            className="animable"
            opacity="0.15"
          />
          <path
            d="M139.9 354.8c.51-.28.92 0 .91.54a2 2 0 01-.93 1.58c-.51.29-.92.05-.91-.54a2 2 0 01.93-1.58z"
            style={{
              WebkitTransformOrigin: 139.89,
              MsTransformOrigin: 139.89,
              transformOrigin: 139.89,
            }}
            id="elrbqqu7iy12"
            fill="#263238"
            className="animable"
          />
          <path
            d="M122.27 353c-1.12 2.87-2 6.78.52 8.64l.4.28v1.21c-.33-.2-.67-.43-1-.67-3.73-2.77-1.44-8.81 0-11.75z"
            style={{
              WebkitTransformOrigin: 121.677,
              MsTransformOrigin: 121.677,
              transformOrigin: 121.677,
            }}
            id="elbe0o13z1cqk"
            fill="#E0E0E0"
            className="animable"
          />
          <path
            d="M140.33 356.1c-.14.29-3.72 7.15-9.62 8.53a8.8 8.8 0 01-2 .24 10.25 10.25 0 01-5.5-1.72v-1.21a9 9 0 007.29 1.71c5.45-1.27 8.93-7.95 9-8a.5.5 0 01.68-.21.49.49 0 01.15.66z"
            style={{
              WebkitTransformOrigin: 131.805,
              MsTransformOrigin: 131.805,
              transformOrigin: 131.805,
            }}
            id="el7q57at01yju"
            fill="#E0E0E0"
            className="animable"
          />
        </g>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          />
          <feFlood floodColor="#32DFEC" floodOpacity="1" result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          />
          <feFlood floodColor="red" floodOpacity="0.5" result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        </filter>
      </defs>
    </svg>
  );
}

export default UnderConstructionSVG;
