import React from 'react';
import './APIRequestMenu.css';

export default function APIRequestMenu({
  activeRequestMenu,
  toggelActiveRequestMenu,
}) {
  return (
    <div className="api-content__request-menu request-menu">
      {['Request', 'Response', 'Code Sample'].map((btnName) => (
        <button
          type="button"
          className={
            activeRequestMenu === btnName
              ? 'request-menu__request-menu-btn request-menu__request-menu-btn--active'
              : 'request-menu__request-menu-btn'
          }
          onClick={() => toggelActiveRequestMenu(btnName)}
        >
          {btnName}
        </button>
      ))}
    </div>
  );
}
