import { createSlice, createAction } from '@reduxjs/toolkit';
import {
  endRowPageBtnReducer,
  nextPageBtnReducer,
  prevPageBtnReducer,
  startRowPageBtnReducer,
} from '../../utils/paginationUtils';
import fetchUsers, { updatePagination } from './BlockbriefUsersThunk';

/* Zoning Changes Actions */

// Pagination: Select Page in the Start Row Action
const startRowPageBtnAction = createAction(
  'admin/blockbriefUsers/startRowPageBtn'
);

// Pagination: Select Page in the End Row Action
const endRowPageBtnAction = createAction('admin/blockbriefUsers/endRowPageBtn');

// Pagination: Go to the Next Page Action
const nextPageBtnAction = createAction(
  'admin/blockbriefUsers/pagination/nextPageBtn'
);

// Pagination: Go to the Prev Page Action
const prevPageBtnAction = createAction(
  'admin/blockbriefUsers/pagination/prevPageBtn'
);

const initialState = {
  currentActiveUsers: [],
  status: 'idle',
  searchQuery: '',
  pagination: {
    // Corelogic Dashbaord Pagination State: tracking it in the store to render the previous history when the user changes tabs.
    paginationFetched: false, // indicates whether pagination is updated after mounting of the pagination component
    currentActive: 1, // indicates the current active page (number) in the pagination component
    totalRecords: 1000, // indicates the totalRecords returned
    totalPages: 100, // indicates the total pages
    /* 
              Note: totalPagesSecondSet and highestPageSetOne can both be derived from the currentActive, totalPages 
              We're tracking these both states to handle two edge cases specific to our Pagination Component - 
               1. totalPagesSecondSet: this can be calculated based on total pages and a check that the the
                  items in secondSet cannot be greater than 3 - 
                  keeping track of it here to not calculate it in every reducer  
               2. highestPageSetOne: to keep track of the firstRow Pagination -
                  because when the currentActive moves to second row -
                  we wont be able to keep track of pagination rendered in firstSet
            */
    totalPagesSecondSet: 3,
    highestPageSetOne: 4,
  },
};

const blockbriefUsersSlice = createSlice({
  name: 'adminSignin',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload.searchStr;
    },
  },
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.status = 'pending';
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.currentActiveUsers = action.payload.users;
    },
    [updatePagination]: (state, action) => {
      state.pagination = action.payload;
    },
    [startRowPageBtnAction]: startRowPageBtnReducer,
    [endRowPageBtnAction]: endRowPageBtnReducer,
    [nextPageBtnAction]: nextPageBtnReducer,
    [prevPageBtnAction]: prevPageBtnReducer,
  },
});

export {
  updatePagination,
  startRowPageBtnAction,
  endRowPageBtnAction,
  nextPageBtnAction,
  prevPageBtnAction,
};
export const { setSearchQuery } = blockbriefUsersSlice.actions;
export default blockbriefUsersSlice.reducer;
