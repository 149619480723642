import React, { useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import './ZoneFeatureTray.css';
import CancelSVG from '../../assets/images/cancel.png';
import Spinner from '../../assets/svg/Spinner';
import ZoneFeatureMenu from '../ZoneFeatureMenu';
import { resetCurrentSelectedFeature } from '../../reducers/blockbriefMapSlice';

const generateHeading = (currentActive) => {
  if (currentActive === 'Zoning Map') {
    return { heading: 'Zoning Details', subHeading: 'Zone Data' };
  }
  if (currentActive === 'Minimum Lot Size Map') {
    return { heading: 'Min. Lot Size', subHeading: 'Min. Lot Data' };
  }

  if (currentActive === 'Building Height Map') {
    return {
      heading: 'Building Height',
      subHeading: 'Building Height Data',
    };
  }

  if (currentActive === 'Floor Space Ratio Map') {
    return {
      heading: 'Floor Space Ratio',
      subHeading: '',
    };
  }

  if (currentActive === 'Heritage Map') {
    return {
      heading: 'Heritage Map',
      subHeading: '',
    };
  }

  if (currentActive === 'Other Constraint Maps') {
    return {
      heading: 'Constraints',
      subHeading: '',
    };
  }

  if (currentActive === 'Land Use Map') {
    return {
      heading: 'Land Use Details',
      subHeading: 'Land Use Data',
    };
  }

  if (currentActive === 'Local Plan Precincts Map') {
    return {
      heading: 'Local Plan Precincts Details',
      subHeading: 'Local Plan Precincts Data',
    };
  }

  if (currentActive === 'Territory Plan Overlay Zones') {
    return {
      heading: 'Territory Plan Overlay Zones',
      subHeading: 'Territory Plan Data',
    };
  }

  if (currentActive === 'Adelaide Policy Maps') {
    return {
      heading: 'Adelaide Policy Details',
      subHeading: 'Adelaide Policy Data',
    };
  }

  if (currentActive === 'Zoning Policy Maps') {
    return {
      heading: 'Zoning Policy Details',
      subHeading: 'Zoning Policy Data',
    };
  }

  return { heading: 'Zoning Details', subHeading: 'Zone Data' };
};

export default function ZoneFeatures() {
  const mapState = useSelector((state) => state.blockbriefMap);
  const dispatch = useDispatch();
  const { heading, subHeading } = generateHeading(mapState.currentActiveMap);
  const [currentSelected, setCurrentSelected] = useState('Zone Data');
  return mapState.currentSelectedFeature.tray ? (
    <div className="map-container__zone-feature-tray zone-feature-tray">
      {mapState.currentActiveMap === 'Zoning Map' ? (
        <div className="zone-feature-tray__feature-heading">
          <h1 className="feature-heading__heading-zoning">
            {mapState.currentSelectedFeature.zoneCode}
          </h1>
          <button
            type="button"
            onClick={() => dispatch(resetCurrentSelectedFeature())}
          >
            <img src={CancelSVG} alt="cancel" />
          </button>
        </div>
      ) : (
        <>
          <div className="zone-feature-tray__feature-heading">
            <h1 className="feature-heading__heading">{heading}</h1>
            <button
              type="button"
              onClick={() => dispatch(resetCurrentSelectedFeature())}
            >
              <img src={CancelSVG} alt="cancel" />
            </button>
          </div>
          <div className="zone-feature-tray__heading-row">
            <h3 className="zone-feture-tray__heading">
              {mapState.currentSelectedFeature.zoneCode}
            </h3>
          </div>
        </>
      )}
      <h2 className="zone-feaure-tray__address">
        {mapState.currentSelectedFeature.address.loading ? (
          <Spinner
            spinnerColor="#4d4d4d"
            spinnerSize={{ width: '2em', height: '2em' }}
          />
        ) : (
          mapState.currentSelectedFeature.address.value
        )}
      </h2>
      {mapState.currentActiveMap === 'Zoning Map' ? (
        <ZoneFeatureMenu
          zoneData={mapState.currentSelectedFeature.zoneDetails}
          pluData={mapState.currentSelectedFeature.pluDetails}
          buildingControls={mapState.currentSelectedFeature.buildingControls}
          previousZone={mapState.currentSelectedFeature.previousZone}
          currentSelected={currentSelected}
          setCurrentSelected={setCurrentSelected}
        />
      ) : (
        <>
          <h3 className="zone-feature-tray__details-heading">{subHeading}</h3>
          <div className="zone-feature-tray__table-wrapper">
            <table className="zone-feature-tray__feature-table">
              <tbody>
                {mapState.currentSelectedFeature.zoneDetails
                  .map((el) => (
                    <tr key={nanoid()}>
                      <td>{el.propName}</td>
                      <td>{el.propVal}</td>
                    </tr>
                  ))
                  .reverse()}
              </tbody>
            </table>
          </div>{' '}
        </>
      )}
    </div>
  ) : null;
}
