import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import './DropdownTrayItems.css';

export default function DropdownTrayItems({
  selectedValue,
  filter,
  selectionFn,
  itemType,
}) {
  const createItemChildren = (checkboxType, selected, filterVal, checkFn) => {
    let itemJSX = null;
    if (checkboxType === 'default') {
      itemJSX = filterVal;
    } else if (checkboxType === 'checkboxes') {
      // If the item is of checkbox type than the selectedValue will be an array
      // We have to loop this array and check if the current filter item we're rendering is the same as one of the selected
      const selectID = nanoid();
      itemJSX = selected.includes(filterVal) ? (
        <label
          htmlFor={selectID}
          key={filterVal}
          onChange={() => checkFn(filterVal)}
        >
          <input
            type="checkbox"
            name="map-zone-checkbox"
            id={selectID}
            checked
            defaultChecked={false}
          />
          {filterVal}
        </label>
      ) : (
        <label
          htmlFor={selectID}
          key={filterVal}
          onChange={() => checkFn(filterVal)}
        >
          <input type="checkbox" name="map-zone-checkbox" id={selectID} />
          {filterVal}
        </label>
      );
    }
    return itemJSX;
  };

  return (
    <span
      className={
        selectedValue === filter
          ? 'list-items__item active'
          : 'list-items__item'
      }
      onMouseDown={!(itemType === 'checkboxes') ? selectionFn : null}
    >
      {createItemChildren(itemType, selectedValue, filter, selectionFn)}
    </span>
  );
}
