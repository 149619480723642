import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LayerIcon from '../../assets/images/layers.png';
import BrightnessIcon from '../../assets/images/brightness.png';
import PhotoCamera from '../../assets/images/photo-camera-interface.png';
import DownloadIcon from '../../assets/images/direct-download.svg';

import './MapControls.css';
import { toggleParcelDownloadStatus } from '../../reducers/blockbriefMapSlice';

export default function MapControls({ setMapType, mapType, tangramLayer }) {
  const [slider, toggleSlider] = useState(false);
  const [sliderVal, setSliderVal] = useState(50);
  const [layerChange, toggleLayerChange] = useState(false);
  const mapState = useSelector((state) => state.blockbriefMap);
  const dispatch = useDispatch();
  // This hook set's up the Leaflet Measure Control in our custom control tray
  useEffect(() => {
    const mapControls = document.getElementsByClassName('map-controls')[0];
    const measureTool = document.getElementsByClassName(
      'leaflet-control-measure'
    )[0];
    if (measureTool) mapControls.children[4].append(measureTool);
  });

  // This hook updates the layer as the opacity control changes
  useEffect(() => {
    // First check if tangramLayer ref is set or not and the scene object is either available
    if (tangramLayer && tangramLayer.scene) {
      tangramLayer.scene.config.global.alpha = sliderVal / 100;
      tangramLayer.scene.updateConfig({ rebuild: true });
    }
  }, [sliderVal]);

  return (
    <div className="map-container__map-controls map-controls">
      {/* Layers Control Button */}
      <button
        type="button"
        className="map-controls__btn layers"
        onClick={() => toggleLayerChange(!layerChange)}
      >
        <img src={LayerIcon} alt="Layers Button icon" />
        <div
          className={
            layerChange
              ? 'btn__layer-change btn__layer-change--active'
              : 'btn__layer-change'
          }
          onChange={() => {
            if (mapType === 'roadmap') setMapType('satellite');
            else setMapType('roadmap');
            toggleLayerChange(!layerChange);
          }}
        >
          <label htmlFor="googleRoadmap">
            <input
              type="radio"
              name="GoogleRoadmap"
              id="googleRoadmap"
              checked={mapType === 'roadmap'}
            />
            Roadmap
          </label>
          <label htmlFor="googleSatellite">
            <input
              type="radio"
              name="GoogleSatellite"
              id="googleSatellite"
              checked={mapType === 'satellite'}
            />
            Satellite
          </label>
        </div>
      </button>
      {/* Opactiy Control Button */}
      <button
        type="button"
        className="map-controls__btn"
        onClick={() => toggleSlider(!slider)}
      >
        <div className="opacity">
          <img src={BrightnessIcon} alt="Layers Button icon" />
        </div>
        <div
          className={slider ? 'btn__slider btn__slider--active' : 'btn__slider'}
        >
          <input
            type="range"
            name="range-slider"
            id="range-slider"
            className="slider"
            value={sliderVal}
            min={0}
            max={100}
            onChange={(e) => setSliderVal(Number(e.target.value))}
          />
        </div>
      </button>
      {/* Screen Shot Control Button */}
      <button type="button" className="map-controls__btn screenshot">
        <img src={PhotoCamera} alt="Layers Button icon" />
      </button>
      {/* Download Parcels Control Button */}
      <button
        type="button"
        className="map-controls__btn download"
        onClick={() => {
          const currentLayer = mapState.activeMapLayer;
          if (
            ['nsw', 'wa', 'qld', 'qld_brisbane', 'tas', 'vic'].includes(
              currentLayer
            )
          ) {
            dispatch(toggleParcelDownloadStatus());
            return;
          }
        }}
      >
        <img src={DownloadIcon} alt="Download Parcels" />
      </button>
      <button type="button" className="map-controls__btn measure-tool">
        {' '}
      </button>
    </div>
  );
}
