import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './Webinars.css';
// import PublicHeader from '../../components/PublicHeader';
import PublicFooter from '../../components/PublicFooter';
import CalendarIcon from '../../assets/images/calendar.svg';
import ClockIcon from '../../assets/images/clock.svg';
import WebinarUpcoming from '../../assets/images/webinar-upcoming.jpg';
import LeftArrow from '../../assets/images/arrow--right-white.svg';
import RightIconBlue from '../../assets/images/arrow-right-blue.svg';
import LoadingIndicator from '../../assets/svg/Loading-indicator';
import Application from '../Application';
import PublicHeader from '../../components/PublicHeader';

export default function Webinars() {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const [userEmail, setUserEmail] = useState('');
  const [coverageRequest, setCoverageRequest] = useState('');
  const [webinarStatus, toggleWebinarStatus] = useState(false);
  const emailRef = useRef(null);
  const coverageRef = useRef(null);
  const submitWebinar = async () => {
    if (userEmail && coverageRequest) {
      toggleWebinarStatus(!webinarStatus);
      const requestURL = `${process.env.REACT_APP_API_HOSTNAME}/request-for-coverage`;
      const response = await axios({
        url: requestURL,
        method: 'POST',
        data: {
          email: userEmail,
          coverageAddress: coverageRequest,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setUserEmail('');
        setCoverageRequest('');
        toggleWebinarStatus(false);
        coverageRef.current.value = '';
        emailRef.current.value = '';
      }
    }
  };
  return (
    <div className="webinars">
      {!loggedIn ? (
        <PublicHeader />
      ) : (
        <div
          style={{ backgroundColor: 'white', width: '100vw', height: '120%' }}
        >
          <Application />
        </div>
      )}
      <div className="webinars__webinar-heading webinar-heading">
        <h1 className="webinar-heading__heading">Blockbrief Webinars</h1>
        <h3 className="webinar-heading__sub-heading">
          <span className="webinar-heading__highlight">Live Session</span> to
          make most of your Blockbrief Membership <br />
        </h3>
        <p className="webinars-heading__details">
          Join one of our webinars to learn how you can get the most out of{' '}
          <span className="webinar-heading__highlight">Blockbrief Data</span>
        </p>
        <button type="button" className="webinar-heading__more-btn">
          See Upcoming webinars <img src={LeftArrow} alt="Left Arrow" />
        </button>
      </div>
      <div className="webinars__upcoming-webinars webinars-item-container">
        <div className="webinar-container__left">
          <h3 className="webinar-container__heading">Upcoming Webinars</h3>
          <div className="webinar-container__webinar-item webinar-item">
            <h3 className="webinar-item__heading">Blockbrief Weekly Webinar</h3>
            <p className="webinar-item__webinar-details">
              Our upcoming webinars will show you how to turn Blockbrief Data
              into business and investment outcomes.
            </p>
            <div className="webinar-item__date-time-details">
              <div className="date">
                <img
                  src={CalendarIcon}
                  alt="Calendar Icon"
                  className="date-icon"
                />
                25-12-2021
              </div>
              <div className="time">
                <img src={ClockIcon} alt="Clock Icon" />
                12:00 PM AET
              </div>
            </div>
            <a
              href="https://calendly.com/blockbrief/15min"
              target="_blank"
              rel="noreferrer"
              className="webinar-item__register-btn"
            >
              Register Now
            </a>
          </div>
        </div>
        <div className="webinar-container__right webinar-image">
          <img src={WebinarUpcoming} alt="Upcoming Webinar" />
        </div>
      </div>
      <div className="webinars-container__on-demand-webinars webinars-item-container">
        <div className="webinar-container__left">
          <h3 className="ondemand-webinar__header webinar-container__heading">
            on-demand webinar
          </h3>
          <p className="ondemand-webinar__content">
            We&apos;ve recorded our previous webinars so you can watch them at
            any time, <br />
            wherever you want.
          </p>
        </div>
        <div className="webinar-container__right">
          <div className="on-demand-item">
            <h3 className="on-demand__heading">Search Sites Explained</h3>
            <p className="on-demand__description">
              Short video on how to search for development sites on blockbrief
            </p>
            <a href="#">
              See Details <img src={RightIconBlue} alt="Details" />
            </a>
          </div>
          <div className="on-demand-item">
            <h3 className="on-demand__heading">What is Blockbrief?</h3>
            <p className="on-demand__description">
              Blockbiref is zoning information online portal ..
            </p>
            <a href="#">
              See Details <img src={RightIconBlue} alt="Details" />
            </a>
          </div>
          <div className="on-demand-item">
            <h3 className="on-demand__heading">Search Sites Explained</h3>
            <p className="on-demand__description">
              Short video on how to search for development sites on blockbrief
            </p>
            <a href="#">
              See Details <img src={RightIconBlue} alt="Details" />
            </a>
          </div>
          <div className="on-demand-item">
            <h3 className="on-demand__heading">Search Sites Explained</h3>
            <p className="on-demand__description">
              Short video on how to search for development sites on blockbrief
            </p>
            <a href="#">
              See Details <img src={RightIconBlue} alt="Details" />
            </a>
          </div>
          <div className="on-demand-item">
            <h3 className="on-demand__heading">Search Sites Explained</h3>
            <p className="on-demand__description">
              Short video on how to search for development sites on blockbrief
            </p>
            <a href="#">
              See Details <img src={RightIconBlue} alt="Details" />
            </a>
          </div>
          <div className="on-demand-item">
            <h3 className="on-demand__heading">Search Sites Explained</h3>
            <p className="on-demand__description">
              Short video on how to search for development sites on blockbrief
            </p>
            <a href="#">
              See Details <img src={RightIconBlue} alt="Details" />
            </a>
          </div>
          <div className="on-demand-item">
            <h3 className="on-demand__heading">Search Sites Explained</h3>
            <p className="on-demand__description">
              Short video on how to search for development sites on blockbrief
            </p>
            <a href="#">
              See Details <img src={RightIconBlue} alt="Details" />
            </a>
          </div>
        </div>
      </div>
      {loggedIn ? (
        <div className="webinar-container__coverage-form coverage-form">
          <div className="webinar-container__left">
            <h3 className="ondemand-webinar__header webinar-container__heading">
              Coverage Request
            </h3>
            <p className="ondemand-webinar__content">
              Submit a coverage request to get Zoning Data, <br />
              for your area.
            </p>
          </div>
          <div className="webinar-container__right">
            <div className="coverage-form-item">
              <h3 className="email">Email</h3>
              <input
                type="email"
                name="user-email"
                id="user-email"
                ref={emailRef}
                placeholder="Enter your Email"
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <div className="coverage-form-item">
              <h3 className="address">Coverage Request</h3>
              <textarea
                name="coverage-address"
                id="coverage-address"
                placeholder="Enter your message and address here ..."
                cols="30"
                ref={coverageRef}
                rows="10"
                onChange={(e) => setCoverageRequest(e.target.value)}
              />
            </div>
            <button
              type="button"
              className="webinar-coverage__submit"
              onClick={() => submitWebinar()}
            >
              {webinarStatus ? <LoadingIndicator /> : 'Submit'}
            </button>
          </div>
        </div>
      ) : null}

      <PublicFooter />
    </div>
  );
}
