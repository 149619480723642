const zoningAPIData = {
  requestMethod: 'GET',

  requestURL:
    'https://api.blockbrief.com/zoning?api_key=<api_key>&address=<address>',

  requestDescr:
    'Get the zoning details about a specific address. The address can be anyvalid String address in Australia.',

  requestData: [
    ['api_key', 'string', 'Blockbrief API Credentials'],
    [
      'address',
      'string',
      'An Address String for which you want to get the zoning details',
    ],
  ],
  responseData: [
    {
      code: 200,
      responseTableData: [
        [
          'status',
          'Boolean',
          'Indicates whether the request was successful or not',
        ],
        [
          'data',
          'Object',
          'Zoning Changes Data Object - contains zoning data fields',
        ],
        ['link', 'String', 'Requsted Link'],
      ],
    },
    {
      code: 400,
      responseTableData: [
        ['status', 'Boolean', 'Status False for failed request'],
        ['message', 'Object', 'Error Message'],
        ['link', 'String', 'Requsted Link'],
      ],
    },
    {
      code: 401,
      responseTableData: [
        ['status', 'Boolean', 'Status False for failed request'],
        ['message', 'Object', 'Error Message'],
        ['link', 'String', 'Requsted Link'],
      ],
    },
  ],
  sampleCode: `const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  
  const url =
  "https://api.blockbrief.com/zoning_api?address=Sydney%20Olympic%20Park%20NSW&api_key=4d216789398881d46c91487b844c777898508766";
  
  fetch(url,requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`,
};

const thirdPartyAPIData = {
  requestMethod: 'GET',

  requestURL:
    'https://api.blockbrief.com/permits_api?api_key=<api_key>&&bottom_left_lat=&bottom_left_lng=&top_right_lat=&top_right_lng=',

  requestDescr:
    'Get Land use permits for a specific map bounds. The map bounds can be any specific map bound in the Australia Region.',

  requestData: [
    ['api_key', 'string', 'Blockbrief API Credentials'],
    ['bottom_left_lat', 'number', 'The bottom left Lat location'],
    ['bottom_left_lng', 'number', 'The bottom left Lng location'],
    ['top_right_lat', 'number', 'The top right Lat location'],
    ['top_right_lng', 'number', 'The top right Lng location'],
  ],
  responseData: [
    {
      code: 200,
      responseTableData: [
        [
          'status',
          'Boolean',
          'Indicates whether the request was successful or not',
        ],
        [
          'data',
          'Object',
          'Zoning Changes Data Object - contains zoning data fields',
        ],
        ['link', 'String', 'Requsted Link'],
      ],
    },
    {
      code: 400,
      responseTableData: [
        ['status', 'Boolean', 'Status False for failed request'],
        ['message', 'Object', 'Error Message'],
        ['link', 'String', 'Requsted Link'],
      ],
    },
    {
      code: 401,
      responseTableData: [
        ['status', 'Boolean', 'Status False for failed request'],
        ['message', 'Object', 'Error Message'],
        ['link', 'String', 'Requsted Link'],
      ],
    },
  ],
  sampleCode: `const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  
  const url =
  "https://api.blockbrief.com/zoning_api?api_key=4d216789398881d46c91487b844c7778985087&bottom_left_lat=-33.869230758192515&bottom_left_lng=151.20114326477054&top_right_lat=-33.86840228432006&top_right_lng=151.21744036674502";
  
  fetch(url,requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`,
};

const rezoningPermitData = {
  requestMethod: 'GET',

  requestURL:
    'https://api.blockbrief.com/rezoning_permits_api?api_key=api_key&bottom_left_lat=&bottom_left_lng=&top_right_lat=&top_right_lng=',

  requestDescr:
    'To get the rezoning permit data send a GET request to this enpoint. The map bounds can be any specific map bound in the Australia Region.',

  requestData: [
    ['api_key', 'string', 'Blockbrief API Credentials'],
    ['bottom_left_lat', 'number', 'The bottom left Lat location'],
    ['bottom_left_lng', 'number', 'The bottom left Lng location'],
    ['top_right_lat', 'number', 'The top right Lat location'],
    ['top_right_lng', 'number', 'The top right Lng location'],
  ],
  responseData: [
    {
      code: 200,
      responseTableData: [
        [
          'status',
          'Boolean',
          'Indicates whether the request was successful or not',
        ],
        [
          'data',
          'Object',
          'Zoning Changes Data Object - contains zoning data fields',
        ],
        ['link', 'String', 'Requsted Link'],
      ],
    },
    {
      code: 400,
      responseTableData: [
        ['status', 'Boolean', 'Status False for failed request'],
        ['message', 'Object', 'Error Message'],
        ['link', 'String', 'Requsted Link'],
      ],
    },
    {
      code: 401,
      responseTableData: [
        ['status', 'Boolean', 'Status False for failed request'],
        ['message', 'Object', 'Error Message'],
        ['link', 'String', 'Requsted Link'],
      ],
    },
  ],
  sampleCode: `const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  
  const url =
  "https://api.blockbrief.com/rezoning_permit_api?api_key=4d216789398881d46c91487b844c7778985087&bottom_left_lat=-33.869230758192515&bottom_left_lng=151.20114326477054&top_right_lat=-33.86840228432006&top_right_lng=151.21744036674502";
  
  fetch(url,requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`,
};

const blockbriefPermitData = {
  requestMethod: 'GET',

  requestURL:
    'https://api.blockbrief.com/blockbrief_permits_api?api_key=<api_key>&bottom_left_lat=&bottom_left_lng=&top_right_lat=&top_right_lng=',

  requestDescr:
    'To get the Blockbrief Permit data send a GET request to this endpoint. The map bounds can be any specific map bound in the Australia Region.',

  requestData: [
    ['api_key', 'string', 'Blockbrief API Credentials'],
    ['bottom_left_lat', 'number', 'The bottom left Lat location'],
    ['bottom_left_lng', 'number', 'The bottom left Lng location'],
    ['top_right_lat', 'number', 'The top right Lat location'],
    ['top_right_lng', 'number', 'The top right Lng location'],
  ],
  responseData: [
    {
      code: 200,
      responseTableData: [
        [
          'status',
          'Boolean',
          'Indicates whether the request was successful or not',
        ],
        [
          'data',
          'Object',
          'Zoning Changes Data Object - contains zoning data fields',
        ],
        ['link', 'String', 'Requsted Link'],
      ],
    },
    {
      code: 400,
      responseTableData: [
        ['status', 'Boolean', 'Status False for failed request'],
        ['message', 'Object', 'Error Message'],
        ['link', 'String', 'Requsted Link'],
      ],
    },
    {
      code: 401,
      responseTableData: [
        ['status', 'Boolean', 'Status False for failed request'],
        ['message', 'Object', 'Error Message'],
        ['link', 'String', 'Requsted Link'],
      ],
    },
  ],
  sampleCode: `const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  
  const url =
  "https://api.blockbrief.com/blockbrief_permits_api?api_key=4d216789398881d46c91487b844c7778985087&bottom_left_lat=-33.869230758192515&bottom_left_lng=151.20114326477054&top_right_lat=-33.86840228432006&top_right_lng=151.21744036674502";
  
  fetch(url,requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`,
};

const requestCoverageData = {
  requestMethod: 'GET',

  requestURL:
    'https://api.blockbrief.com/request_coverage_api?api_key=<api_key>&bottom_left_lat=&bottom_left_lng=&top_right_lat=&top_right_lng=',

  requestDescr:
    'To submit a Coverage request, send a GET request to this endpoint.',

  requestData: [
    ['api_key', 'string', 'Blockbrief API Credentials'],
    ['type', 'string', 'new_coverage_area'],
    ['address', 'string', 'Any valid address in Australia Region'],
  ],
  responseData: [
    {
      code: 200,
      responseTableData: [
        [
          'status',
          'Boolean',
          'Indicates whether the request was successful or not',
        ],
        [
          'data',
          'Object',
          'Zoning Changes Data Object - contains zoning data fields',
        ],
        ['link', 'String', 'Requsted Link'],
      ],
    },
    {
      code: 400,
      responseTableData: [
        ['status', 'Boolean', 'Status False for failed request'],
        ['message', 'Object', 'Error Message'],
        ['link', 'String', 'Requsted Link'],
      ],
    },
    {
      code: 401,
      responseTableData: [
        ['status', 'Boolean', 'Status False for failed request'],
        ['message', 'Object', 'Error Message'],
        ['link', 'String', 'Requsted Link'],
      ],
    },
  ],
  sampleCode: `const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  
  const url =
  "https://api.blockbrief.com/rezoning_permit_api?api_key=4d216789398881d46c91487b844c7778985087&address=Sydney%20Olympic%20Park%20NSW";
  
  fetch(url,requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`,
};
export {
  zoningAPIData,
  thirdPartyAPIData,
  rezoningPermitData,
  blockbriefPermitData,
  requestCoverageData,
};
